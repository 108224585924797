import { Classes, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import { CheckRoles } from "../common/CheckRoles";

const Menu6110 = () => {
  return (
    <Menu className={Classes.ELEVATION_2}>
      <MenuDivider title="6110" />

      <MenuItem icon="database" text="PK">
        <CheckRoles route={ROUTES.project_6110PK}>
          <MenuItem
            tagName={Link}
            to={ROUTES.project_6110PK}
            icon="plus"
            text="Összes PK"
          />
        </CheckRoles>

        <CheckRoles route={ROUTES.project_6110PKCreate}>
          <MenuItem
            tagName={Link}
            to={ROUTES.project_6110PKCreate}
            icon="plus"
            text="Új PK létrehozása"
          />
        </CheckRoles>
      </MenuItem>
      <MenuItem icon="database" text="RK">
        <CheckRoles route={ROUTES.project_6110RK}>
          <MenuItem
            tagName={Link}
            to={ROUTES.project_6110RK}
            icon="plus"
            text="Összes RK"
          />
        </CheckRoles>

        <CheckRoles route={ROUTES.project_6110RKCreate}>
          <MenuItem
            tagName={Link}
            to={ROUTES.project_6110RKCreate}
            icon="plus"
            text="Új RK létrehozása"
          />
        </CheckRoles>
      </MenuItem>
      <MenuItem icon="database" text="KKK/PTT">
        <CheckRoles route={ROUTES.project_6110KKK}>
          <MenuItem
            tagName={Link}
            to={ROUTES.project_6110KKK}
            icon="plus"
            text="Összes KKK/PTT"
          />
        </CheckRoles>

        <CheckRoles route={ROUTES.project_6110KKKCreate}>
          <MenuItem
            tagName={Link}
            to={ROUTES.project_6110KKKCreate}
            icon="plus"
            text="Új KKK/PTT létrehozása"
          />
        </CheckRoles>
      </MenuItem>
      <MenuItem icon="database" text="Akkreditált vizsgaközpontok">
        <CheckRoles route={ROUTES.project_6110examCenterCreate}>
          <MenuItem
            tagName={Link}
            to={ROUTES.project_6110examCenterCreate}
            icon="plus"
            text="Új akkreditált vizsgaközpont"
          />
        </CheckRoles>

        <CheckRoles route={ROUTES.project_6110examCenter}>
          <MenuItem
            tagName={Link}
            to={ROUTES.project_6110examCenter}
            icon="plus"
            text="Összes Akkreditált vizsgaközpont"
          />
        </CheckRoles>
      </MenuItem>

      <MenuItem
        icon="align-left"
        tagName={Link}
        to={ROUTES.project_6110StaticTexts}
        text="Szövegek"
      />
    </Menu>
  );
};

export default Menu6110;
