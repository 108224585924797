import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  NumericInput,
  Spinner,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getLocations,
  modifyLocation,
  refreshLocation,
} from "../../redux/actions/szakkepzes/locations";
import { TableAdvanced } from "../common/TableAdvanced";

const Locations = (props) => {
  const [editLocation, setEditLocation] = useState();

  const handleDialogClose = () => {
    setEditLocation(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await props.modifyLocation(editLocation.id, {
      lat: editLocation.lat,
      lng: editLocation.lng,
    });
    setEditLocation(null);
    props.getLocations();
  };

  const handleRefresh = async (id) => {
    const res = await props.refreshLocation(id);
    props.getLocations();
  };

  const handleInput = (name) => (value) => {
    setEditLocation((loc) => ({ ...loc, [name]: value }));
  };

  useEffect(() => {
    if (props.locations.length === 0) props.getLocations();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Helyszín",
        accessor: "name",
      },
      {
        Header: "Szélességi koordináta",
        accessor: "lat",
      },
      {
        Header: "Hosszúsági koordináta",
        accessor: "lng",
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <>
            <Button
              small
              icon="edit"
              minimal
              text="Szerkeszt"
              onClick={() => setEditLocation(row.original)}
            />
            <Button
              small
              minimal
              onClick={() => handleRefresh(row.original.id)}
              icon="refresh"
              text="Újratölt"
            />
          </>
        ),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="map-marker" />
        &nbsp;&nbsp;<span>Szakképzések térképes koordinátái</span>
      </h2>

      <TableAdvanced data={props.locations} columns={columns} />

      <Dialog
        title="Koordináta módosítás"
        isOpen={!!editLocation}
        onClose={handleDialogClose}
        usePortal={false}
      >
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <h3>{editLocation?.name}</h3>
            <br />
            <FormGroup label="Szélességi koordináta">
              <NumericInput
                required
                value={editLocation?.lat}
                name="lat"
                onValueChange={handleInput("lat")}
              />
            </FormGroup>
            <FormGroup label="Hosszúsági koordináta">
              <NumericInput
                required
                value={editLocation?.lng}
                name="lng"
                onValueChange={handleInput("lng")}
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="Mégsem" onClick={handleDialogClose} />
              <Button
                type="submit"
                intent="success"
                icon="floppy-disk"
                text="Mentés"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const { locations } = state;
  console.log("mapStateToProps", locations);
  return { ...locations };
};

export default connect(mapStateToProps, {
  getLocations,
  modifyLocation,
  refreshLocation,
})(Locations);
