import {
  Button,
  Card,
  FormGroup,
  InputGroup,
  MenuItem,
  Position,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAllSpecialisations6110,
  createSpecialisationDocument6110,
} from "../../../redux/6110/rk";
import FileUpload from "../../common/FileUpload";
import { Suggest } from "@blueprintjs/select";
import style from "./RK.module.scss";

const SpecialisationForm = (props) => {
  const [state, setState] = useState(props.initialState);

  useEffect(() => {
    //   if (props.categories.length === 0) props.getAllFaqCategory();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const filterQualifications = (query, qualif, _index, exactMatch) => {
    const normalizedName = qualif.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedName === normalizedQuery;
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0;
    }
  };

  const handleUpload = (media) => {
    setState((pk) => ({ ...pk, mediaId: media.id }));
  };

  const itemRenderer = (item, { handleClick, modifiers, query }) => (
    <MenuItem
      key={item.id}
      text={item.name}
      onClick={handleClick}
      active={modifiers.active}
    />
  );

  console.log(state);

  return (
    <div style={{ maxWidth: "800px" }}>
      <h2>{props.title}</h2>
      <form onSubmit={handleSubmit}>
        <FormGroup label="Szakirány">
          <Suggest
            items={props.specialization}
            itemRenderer={itemRenderer}
            inputValueRenderer={(item) => item.name}
            noResults={<MenuItem disabled={true} text="Nincs találat" />}
            onItemSelect={(item, e) =>
              setState((rk) => ({ ...rk, KEORSpecializationId: item.id }))
            }
            popoverProps={{
              popoverClassName: style.qualifselectPopover,
              position: Position.BOTTOM_LEFT,
            }}
            itemPredicate={filterQualifications}
            inputProps={{ required: true, placeholder: "Válasszon..." }}
          ></Suggest>
        </FormGroup>
        <Card>
          <FormGroup
            label="Dokumentum neve"
            helperText="A file letöltéskor létrejött file név prefixe."
            labelInfo="(kötelező)"
          >
            <InputGroup
              isRequired
              name="fileName"
              placeholder="Fájl címe"
              value={state.fileName}
              onChange={handleInput}
            />
          </FormGroup>

          <FormGroup
            label="Dokumentum"
            helperText="Engedélyezett formátumok: pdf"
            labelInfo="(kötelező)"
          >
            <FileUpload onUpload={handleUpload} isRequired />
          </FormGroup>
          <FormGroup label="Publikálva ekkor" labelInfo="(kötelező)">
            <InputGroup
              type="date"
              name="publishDate"
              value={state.publishDate}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup
            label="Archiválva ekkor"
            labelInfo="(Csak ha archiválni szeretnénk!)"
          >
            <InputGroup
              type="date"
              name="archiveDate"
              value={state.archiveDate}
              onChange={handleInput}
            />
          </FormGroup>
        </Card>

        {/* <FormGroup label="Témakör">
              <HTMLSelect
                required
                onChange={handleInput}
                name="category_id"
                value={state.category_id}
              >
                <option value="" disabled>
                  Válasszon
                </option>
                {props.categories.map((cat, i) => (
                  <option key={i} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup> */}

        <br />
        <Button
          type="submit"
          text={props.submitBtnText}
          icon="floppy-disk"
          intent="success"
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state.rk };
};
export default connect(mapStateToProps, { getAllSpecialisations6110 })(
  SpecialisationForm
);
