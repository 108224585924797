import {
  ABOUT_ITEM_GET_BEGIN,
  ABOUT_ITEM_GET_SUCCESS,
  ABOUT_ITEM_GET_FAILURE,
} from "../actionTypes";

const initialState = {
  rows: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ABOUT_ITEM_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ABOUT_ITEM_GET_SUCCESS: {
      return {
        rows: action.payload,
        loading: false,
        error: null,
      };
    }
    case ABOUT_ITEM_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    // case ABOUT_ITEM_: {
    //     return {
    //         ...state,
    //         loading: true,
    //     };

    // }
    // case CAROUSEL_UPDATE_SUCCESS: {
    //     return {
    //         ...state,
    //         loading: false,
    //     };

    // }
    // case CAROUSEL_UPDATE_FAILURE: {

    //     return {
    //         ...state,
    //         loading: false,
    //         error: action.payload
    //     };

    // }

    default:
      return state;
  }
}
