import {
  Button,
  FormGroup,
  InputGroup,
  NumericInput,
  HTMLSelect,
  Checkbox,
} from "@blueprintjs/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MyCkEditor from "../../common/MyCkEditor";
import { getCategories } from "../../../redux/actions/ikk/menu";
import { API_BASE_IKK } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import { slugify } from "../../../utils/helpers";

const MenuItemForm = (props) => {
  const [state, setState] = useState(props.initialState);

  useEffect(() => {
    props.getCategories();
  }, []);

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setState((s) => ({ ...s, [name]: val }));
  };

  //   const handleNumInput = (val) => {
  //     setState((s) => ({ ...s, position: val }));
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  const handleMediaUpload = (media) => {
    console.log("media:", media);
    setState((s) => ({ ...s, cover_id: media.id }));
  };

  const generateSlug = () => {
    setState((s) => ({ ...s, slug: slugify(s.name) }));
  };

  console.log(state, props);

  return (
    <form style={{ maxWidth: 800, marginBottom: 100 }} onSubmit={handleSubmit}>
      <h2>{props.title}</h2>

      <FormGroup label="Cím" labelInfo="(kötelező)">
        <InputGroup
          required
          name="name"
          value={state.name}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Leírás" labelInfo="(kötelező)">
        <MyCkEditor
          data={state.body}
          onDataChange={(body) => setState((s) => ({ ...s, body }))}
        />
      </FormGroup>
      <FormGroup label="Slug" labelInfo="(kötelező)">
        <InputGroup
          required
          name="slug"
          value={state.slug}
          onChange={handleInput}
          rightElement={<Button onClick={generateSlug}>Generálás</Button>}
        />
      </FormGroup>
      <FormGroup label="Link">
        <InputGroup
          name="link"
          value={state.link}
          onChange={handleInput}
        />
      </FormGroup>
      <Checkbox
        label="Kifelé mutató link"
        name="isExternal"
        checked={state.isExternal}
        onChange={handleInput}
      />
      <FormGroup label="Pozíció" labelInfo="(kötelező)">
        <NumericInput
          required
          min={1}
          name="order"
          value={state.order}
          onValueChange={(e) =>
            handleInput({
              target: { name: "order", value: e },
            })
          }
        />
      </FormGroup>
      <FormGroup label="Menü kategória" labelInfo="(kötelező)">
        <HTMLSelect
          required
          name="category_id"
          value={state.category_id}
          onChange={(e) =>
            handleInput({
              target: { name: e.target.name, value: Number(e.target.value) },
            })
          }
          style={{ maxWidth: "300px" }}
        >
          <option value="" disabled>
            Válasszon
          </option>
          {props.categories.map((c, idx) => (
            <option key={idx} value={c.id}>
              {c.name}
            </option>
          ))}
        </HTMLSelect>
      </FormGroup>

      <FormGroup label="Kép">
        <FileUpload
          onUpload={handleMediaUpload}
          isRequired={false}
        />
      </FormGroup>
      <FormGroup>
        {state.cover_id ? (
          <img src={`${API_BASE_IKK}/media/${state.cover_id}`} width="200" />
        ) : (
          <p>Nincs kép feltöltve</p>
        )}
      </FormGroup>
      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  const { categories } = state.menu;
  return { categories };
};

export default connect(mapStateToProps, { getCategories })(MenuItemForm);
