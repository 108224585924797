import {
  PAGE_CREATE_BEGIN,
  PAGE_CREATE_FAILURE,
  PAGE_CREATE_SUCCESS,
  PAGE_DELETE_BEGIN,
  PAGE_DELETE_FAILURE,
  PAGE_DELETE_SUCCESS,
  PAGE_GET_BEGIN,
  PAGE_GET_FAILURE,
  PAGE_GET_SUCCESS,
  PAGE_UPDATE_BEGIN,
  PAGE_UPDATE_SUCCESS,
} from "../../../redux/actionTypes";
import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getPages = () => async (dispatch) => {
  dispatch({ type: PAGE_GET_BEGIN });
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.PAGE);
    dispatch({ type: PAGE_GET_SUCCESS, payload: json });
    return json;
  } catch (error) {
    dispatch({ type: PAGE_GET_FAILURE, payload: error });
    dispatch(addToastDanger("Oldalak betöltése sikertelen"));
  }
};

export const getOnePage = (slug) => async (dispatch) => {
  try {
    const json = await http.get(
      API_BASE_IKK_ADMIN + API_ENDPOINT.PAGE + "/" + slug
    );
    return json;
  } catch (error) {
    dispatch({ type: PAGE_GET_FAILURE, payload: error });
    dispatch(addToastDanger(`Oldal betöltése sikertelen: ${error}`));
  }
};

export const updatePage = (slug, data) => async (dispatch) => {
  dispatch({ type: PAGE_UPDATE_BEGIN });
  try {
    const json = await http.put(
      API_BASE_IKK_ADMIN + API_ENDPOINT.PAGE + "/" + slug,
      data
    );
    dispatch({ type: PAGE_UPDATE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Oldal módosítása sikeres"));
    return json;
  } catch (error) {
    dispatch({ type: PAGE_UPDATE_SUCCESS, payload: error });
    dispatch(addToastDanger(`Oldal módosítása sikertelen: ${error}`));
  }
};

export const createPage = (data) => async (dispatch) => {
  dispatch({ type: PAGE_CREATE_BEGIN });
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.PAGE, data);

    dispatch({ type: PAGE_CREATE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Oldal létrehozva"));
    return json;
  } catch (error) {
    dispatch({ type: PAGE_CREATE_FAILURE, payload: error });
    dispatch(addToastDanger(`Oldal létrehozása sikertelen: ${error}`));
  }
};

export const deletePage = (id) => async (dispatch) => {
  dispatch({ type: PAGE_DELETE_BEGIN });
  try {
    const json = await http.del(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.PAGE}/${id}`
    );
    dispatch({ type: PAGE_DELETE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Oldal törölve"));
    return json;
  } catch (error) {
    dispatch({ type: PAGE_DELETE_FAILURE, payload: error });
    dispatch(addToastDanger(`Oldal törlése sikertelen: ${error}`));
  }
};
