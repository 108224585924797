import {
  SZAKKEPZES_CONNECTIONS_IMPORT_BEGIN,
  SZAKKEPZES_CONNECTIONS_IMPORT_FAILURE,
  SZAKKEPZES_CONNECTIONS_IMPORT_SUCCESS,
} from "../../actionTypes";

const initialState = {
  response: null,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SZAKKEPZES_CONNECTIONS_IMPORT_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SZAKKEPZES_CONNECTIONS_IMPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        response: action.payload,
      };
    }
    case SZAKKEPZES_CONNECTIONS_IMPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: null,
      };
    }
    default:
      return state;
  }
}
