import { Button, Card, FormGroup, H4, InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { deleteDocument } from "../../../redux/actions/szakkepesites/pk";
import { API_BASE_IKK } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";

const QualifForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };
  const handleDocumentChange = (e) => {
    const { name, value } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleMediaUpload = (media) => {
    setState((s) => ({ ...s, mediaId: media.id }));
    console.log("handleMediaUpload:", media);
  };

  console.log("qualifform state:", state);

  return (
    <>
      <div style={{ maxWidth: "800px", marginBottom: 100 }}>
        <h2>{props.title}</h2>
        <form onSubmit={handleSubmit}>
          <>
            <H4>Dokumentum</H4>
            <Card style={{ marginBottom: 20 }}>
              <FormGroup label="Dokumentum neve">
                <InputGroup
                  required
                  name="fileName"
                  value={state.fileName}
                  onChange={handleDocumentChange}
                />
              </FormGroup>
              {!props.edit && (
                <FormGroup label="Fajl">
                  <FileUpload isRequired onUpload={handleMediaUpload} />
                  <p>
                    <a
                      target="_blank"
                      href={`${API_BASE_IKK}/media/${state.mediaId}`}
                      rel="noreferrer"
                    >{`${API_BASE_IKK}/media/${state.mediaId}`}</a>
                  </p>
                </FormGroup>
              )}

              <FormGroup label="Verzió">
                <InputGroup
                  required
                  onChange={handleDocumentChange}
                  name="version"
                  value={state.version}
                />
              </FormGroup>
              <FormGroup label="Publikálva ekkor">
                <InputGroup
                  type="date"
                  name="publishDate"
                  value={state.publishDate}
                  onChange={handleDocumentChange}
                />
              </FormGroup>
              <FormGroup
                label="Archiválva ekkor"
                labelInfo="(Csak ha archiválni szeretnénk!)"
              >
                <InputGroup
                  type="date"
                  name="archiveDate"
                  value={state.archiveDate}
                  onChange={handleDocumentChange}
                />
              </FormGroup>
            </Card>
          </>

          <br />
          <Button
            type="submit"
            text={props.submitBtnText}
            icon="floppy-disk"
            intent="success"
          />
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.pk };
};
export default connect(mapStateToProps, { deleteDocument })(QualifForm);
