import { Classes, FormGroup, InputGroup, TextArea } from "@blueprintjs/core";
import { Grid } from "../../../common/LayoutHelpers";
import { useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";

export const CTA = ({ initialState, onComponentSave }) => {
  const { state, isDirty, handleSimpleInput } = useInputState(initialState);

  return (
    <>
      <Grid columns={2}>
        <FormGroup label="Cím" labelInfo="(kötelező)">
          <InputGroup
            required
            name="title"
            value={state.title}
            onChange={handleSimpleInput}
          />
        </FormGroup>
        <FormGroup label="Alcím" labelInfo="(kötelező)">
          <InputGroup
            required
            name="subtitle"
            value={state.subtitle}
            onChange={handleSimpleInput}
          />
        </FormGroup>
      </Grid>

      <FormGroup label="Szöveg" labelInfo="(kötelező)" helperText="Max 2 sor">
        <TextArea
          className={Classes.FILL}
          rows={2}
          required
          name="text"
          value={state.text}
          onChange={handleSimpleInput}
        />
      </FormGroup>

      <Grid columns={2}>
        <FormGroup label="Gomb felirat" labelInfo="(kötelező)">
          <InputGroup
            required
            value={state.buttonText}
            name="buttonText"
            onChange={handleSimpleInput}
          />
        </FormGroup>
        <FormGroup label="Link" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="https://..."
            name="link"
            value={state.link}
            onChange={handleSimpleInput}
          />
        </FormGroup>
      </Grid>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
