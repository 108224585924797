import React from "react";
import { connect } from "react-redux";
import { uploadNews } from "../../../redux/actions/ikk/news";
import { ROUTES } from "../../../utils/routes";
import NewsForm from "./NewsForm";

const emptyState = {
  title: "",
  short_description: "",
  body: "",
  published_at: null,
  priority_date: null,
  category_id: null,
  cover_id: null, // media.id
  tags: [], // tag ids
};

const NewsCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const data = await props.uploadNews({
      ...state,
      category_id: Number(state.category_id),
    });
    // reset form is submit was successful
    if (data && data.id) {
      setState(emptyState);
      props.history.push(ROUTES.news);
    }
  };

  return (
    <NewsForm
      title="Hír felvitele"
      initialState={emptyState}
      submitBtnText="Létrehoz"
      imageRequired={true}
      onSubmit={handleCreateSubmit}
    />
  );
};

export default connect(null, { uploadNews })(NewsCreate);
