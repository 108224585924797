import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Elevation,
  FormGroup,
  H3,
  H4,
  HTMLSelect,
  InputGroup,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getPages } from "../../../redux/actions/ikk/pages";
import { slugify } from "../../../utils/helpers";
import { API_BASE_IKK } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import { CollapsibleRichText } from "./components/CollapsibleRichtext";
import { CTA } from "./components/CTA";
import { Divider as DividerComponent } from "./components/Divider";
import { Documents } from "./components/Documents";
import { FancyAccordion } from "./components/FancyAccordion";
import { GalleryWithPreview } from "./components/GalleryWithPreview";
import { ImageGrid } from "./components/ImageGrid";
import { RichText } from "./components/RichText";
import { SectionHeading } from "./components/SectionHeading";
import { SimpleAccordion } from "./components/SimpleAccordion";

/**
 * A custom utility hook to handle form input states
 * @param {*} defaultValue
 */
export function useInputState(defaultValue) {
  const [state, setState] = useState({ ...defaultValue });

  const handleSimpleInput = (e) => {
    const { name, value, type, checked } = e.target;
    const realValue = type === "checkbox" ? checked : value;
    setState((s) => ({ ...s, [name]: realValue }));
  };

  const isDirty = JSON.stringify(defaultValue) !== JSON.stringify(state);

  return { state, setState, isDirty, handleSimpleInput: handleSimpleInput };
}

export const colors = ["blue", "green", "yellow", "red"];

export const icons = [
  "bell",
  "book",
  "calendar",
  "chevron_right",
  "close_circle",
  "crosshair",
  "download",
  "external",
  "bullet",
  "link",
  "corner_rigth_down",
  "corner_right_up",
  "facebook",
  "info",
  "info_fill",
  "left_arrow",
  "left_long_arrow",
  "forward_arrow",
  "linkedin",
  "mail",
  "mail_fill",
  "man",
  "minus",
  "phone",
  "plus",
  "repeat",
  "right_arrow_circle",
  "right_arrow",
  "right_long_icon",
  "rotate_ccw",
  "search",
  "up_arrow",
  "label",
  "email_outlined",
  "doc_text_outlined",
  "doc_text",
  "files_labeled",
  "files_folder_bookmarked",
  "files_folder_labeled",
  "files_folder_shared",
  "map_places_company",
  "accessibility",
  "feed",
  "warning",
  "menu",
  "youtube",
  "upload",
  "clear",
];

const NonEditableFields = () => {
  return <i>Nem szerkeszthető...</i>;
};

// const BaseFileFields = ({ onSave, defaultValue }) => {
//   const { state, handleSimpleInput } = useInputState(defaultValue);

//   return (
//     <>
//       <FormGroup label="Cím" labelInfo="(kötelező)">
//         <InputGroup
//           required
//           name="title"
//           value={state.title}
//           onChange={handleSimpleInput}
//         />
//       </FormGroup>
//       <FormGroup label="Alcím" labelInfo="(kötelező)">
//         <InputGroup
//           required
//           name="subtitle"
//           value={state.subtitle}
//           onChange={handleSimpleInput}
//         />
//       </FormGroup>
//     </>
//   );
// };

const COMPONENT_META = {
  cta: {
    name: "CTA (figyelemfelhívó)",
    desc: "...",
    fields: CTA,
    defaults: {
      title: "",
      subtitle: "",
      text: "",
      link: "",
      buttonText: "",
    },
  },
  divider: {
    name: "Elválasztó vonal",
    desc: "...",
    fields: DividerComponent,
    defaults: { isDashed: true },
  },
  sectionHeading: {
    name: "Szekció cím",
    desc: "...",
    fields: SectionHeading,
    defaults: {
      title: "",
      icon: "",
      color: "",
    },
  },
  richText: {
    name: "Szabadon formázható szöveg",
    desc: "...",
    fields: RichText,
  },
  collapse: {
    name: "Bővebben gomb",
    desc: "...",
    fields: CollapsibleRichText,
    defaults: {
      button: "",
      richText: null,
    },
  },
  documents: {
    name: "Dokumentum kártyák",
    desc: "...",
    fields: Documents,
    defaults: {
      documents: [],
    },
  },
  simpleAccordion: {
    name: "Harmonika (Accordion, kategorizálás nélkül)",
    desc: "...",
    fields: SimpleAccordion,
    defaults: {
      items: [],
    },
  },
  detailedAccordion: {
    name: "Harmonika - Színes (Accordion, kategorizálás nélkül)",
    desc: "...",
    fields: FancyAccordion,
    defaults: {
      items: [],
    },
  },
  imageGrid: {
    name: "Képek egymás mellett",
    desc: "...",
    fields: ImageGrid,
    defaults: {
      clickable: false,
      centered: false,
      images: [],
    },
  },
  gallery: {
    name: "Galéria előnézettel",
    desc: "...",
    fields: GalleryWithPreview,
    defaults: {
      previewImages: [],
      images: [],
      title: "",
      description: "",
    },
  },

  // SPECIAL COMPONENTS
  "kkk-ptt": {
    name: "KKK/PTT lista",
    desc: "...",
    fields: NonEditableFields,
    defaults: {},
  },

  "pk-list": {
    name: "PK lista",
    desc: "...",
    fields: NonEditableFields,
    defaults: {},
  },

  accreditedExamCenterList: {
    name: "Akkreditált vizsgaközpontok lista",
    desc: "...",
    fields: NonEditableFields,
    defaults: {},
  },
};

const PagesForm = (props) => {
  const compSelectRef = useRef();
  let components = props.initialState.components.map((comp, i) => ({
    ...comp,
    id: i,
  }));
  const { state, setState, handleSimpleInput } = useInputState({
    ...props.initialState,
    components: components,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state);
  };

  const handleMediaUpload = (media) => {
    console.log("media:", media);
    setState((s) => ({ ...s, media_id: media.id }));
  };

  const generateSlug = () => {
    setState((s) => ({ ...s, slug: slugify(s.title) }));
  };

  /**
   * Adds a new component to `state.components[]` with the default values.
   */
  const addComponent = () => {
    const key = compSelectRef.current.value;
    if (!key) return;
    const defaults = { ...COMPONENT_META[key].defaults };
    setState((s) => ({
      ...s,
      components: [
        ...s.components,
        { id: Math.random(), type: key, data: defaults },
      ],
    }));
  };

  /**
   * Removes the component from `state.components[]`
   * @param {number} index
   */
  const removeComponent = (index) => {
    const key = state.components[index].type;
    const name = COMPONENT_META[key].name;
    if (!window.confirm(`Biztosan törli?\n\n${name}`)) return;
    setState((s) => ({
      ...s,
      components: s.components.filter((_, i) => i !== index),
    }));
  };

  /**
   * Moves the component up or down in the list
   * @param {number} index
   * @param {number} dir -1: up, 1: down
   */
  const move = (index, dir) => {
    if (index === 0 && dir === -1) return; // first
    if (index === state.components.length - 1 && dir === 1) return; // last

    const newIndex = index + dir;

    setState((s) => {
      const temp = structuredClone(s.components);
      const item = temp.splice(index, 1)[0];
      temp.splice(newIndex, 0, item);
      setState((s) => ({ ...s, components: temp }));
    });
  };

  /**
   * Callback that fires when the OK button was clicked on the individual component forms.
   * @param {number} index index of the component in `state.components[]`
   * @param {any} componentData the modified component data
   */
  const handleComponentSave = (index, componentData) => {
    const temp = structuredClone(state);
    temp.components[index].data = { ...componentData };
    setState(temp);
  };

  console.log(state);

  return (
    <form style={{ maxWidth: 800, marginBottom: 100 }} onSubmit={handleSubmit}>
      <h2>{props.title}</h2>

      <FormGroup label="Cím" labelInfo="(kötelező)">
        <InputGroup
          required
          name="title"
          value={state.title}
          onChange={handleSimpleInput}
        />
      </FormGroup>

      <FormGroup label="Alcím">
        <InputGroup
          name="subtitle"
          value={state.subtitle}
          onChange={handleSimpleInput}
        />
      </FormGroup>

      <FormGroup
        label="Slug"
        labelInfo="(kötelező)"
        helperText="Egyedinek kell lennie"
      >
        <InputGroup
          required
          name="slug"
          value={state.slug}
          onChange={handleSimpleInput}
          rightElement={
            <Button icon="refresh" onClick={generateSlug}>
              Generálás
            </Button>
          }
        />
      </FormGroup>
      <FormGroup
        label="Oldal"
        labelInfo="(kötelező)"
        contentClassName="formHelperRight"
      >
        <select
          onChange={handleSimpleInput}
          name="domain"
          defaultValue={state.domain}
        >
          <option value="ikk" selected={state.page === "ikk"}>
            IKK.hu
          </option>
          <option value="6110" selected={state.category_id === "6110"}>
            6110
          </option>
        </select>
      </FormGroup>
      <FormGroup label="Illusztráció" labelInfo="(SVG ikon)">
        <FileUpload onUpload={handleMediaUpload} isRequired={false} />
      </FormGroup>
      <FormGroup>
        {state.media_id ? (
          <img
            src={`${API_BASE_IKK}/media/${state.media_id}/sm`}
            height="100"
          />
        ) : (
          <p>Nincs illusztráció feltöltve</p>
        )}
      </FormGroup>

      <Divider style={{ margin: "30px 0" }} />

      <H3>Komponensek ({state.components.length})</H3>

      <FormGroup label="Komponens">
        <HTMLSelect defaultValue={""} elementRef={compSelectRef}>
          <option value="" disabled>
            Válasszon...
          </option>
          {Object.entries(COMPONENT_META)
            .sort(([k1, v1], [k2, v2]) => v1.name.localeCompare(v2.name))
            .map(([k, v]) => (
              <option key={k} value={k}>
                {v.name}
              </option>
            ))}
        </HTMLSelect>
        <Button
          icon="add"
          intent="primary"
          minimal
          onClick={addComponent}
          style={{ margin: "0 10px" }}
        >
          Hozzáad
        </Button>
      </FormGroup>

      {state.components.map((comp, i) => (
        <ComponentCard
          key={comp.id}
          component={state.components[i]}
          onComponentSave={(data) => handleComponentSave(i, data)}
          onMove={(dir) => move(i, dir)}
          onRemove={() => removeComponent(i)}
        />
      ))}

      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

const ComponentCard = ({ component, onComponentSave, onRemove, onMove }) => {
  const Fields = COMPONENT_META[component.type]?.fields;

  if (!Fields) return null;

  return (
    <div>
      <div
        style={{
          margin: "40px 0 5px 0",
          display: "flex",
          gap: 10,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <H4>{COMPONENT_META[component.type].name}</H4>
        <ButtonGroup minimal>
          <Button icon="chevron-down" onClick={() => onMove(1)} />
          <Button icon="chevron-up" onClick={() => onMove(-1)} />
          <Button
            icon="trash"
            intent="danger"
            title="Komponens törlése"
            onClick={onRemove}
          />
        </ButtonGroup>
      </div>

      <Card elevation={Elevation.THREE} style={{ padding: 10, width: "100%" }}>
        <Fields
          state={component.data}
          initialState={component.data}
          onComponentSave={onComponentSave}
        />
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { pages } = state.pages;
  return { pages };
};

export default connect(mapStateToProps, { getPages })(PagesForm);
