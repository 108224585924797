import {
  Button,
  FormGroup,
  HTMLSelect,
  InputGroup,
  NumericInput,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { slugify } from "../../../utils/helpers";
import MyCkEditor from "../../common/MyCkEditor";

const colors = [
  { name: "Kék (alapértelmezett)", value: "#0084f4" },
  { name: "Piros", value: "#de095c" },
  { name: "Sárga", value: "#ffc55d" },
  { name: "Zöld", value: "#00c48c" },
];
const MenuCategoryForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setState((s) => ({ ...s, [name]: val }));
  };

  //   const handleNumInput = (val) => {
  //     setState((s) => ({ ...s, position: val }));
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  const generateSlug = () => {
    setState((s) => ({ ...s, slug: slugify(s.name) }));
  };

  return (
    <form style={{ maxWidth: 800, marginBottom: 100 }} onSubmit={handleSubmit}>
      <h2>{props.title}</h2>

      <FormGroup label="Cím" labelInfo="(kötelező)">
        <InputGroup
          required
          name="name"
          value={state.name}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Alcím" labelInfo="(kötelező)">
        <InputGroup
          required
          name="subtitle"
          value={state.subtitle}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Leírás" labelInfo="(kötelező)">
        <MyCkEditor
          data={state.body}
          onDataChange={(body) => setState((s) => ({ ...s, body }))}
        />
      </FormGroup>
      <FormGroup label="Slug" labelInfo="(kötelező)">
        <InputGroup
          required
          name="slug"
          value={state.slug}
          onChange={handleInput}
          rightElement={<Button onClick={generateSlug}>Generálás</Button>}
        />
      </FormGroup>
      <FormGroup label="Pozíció" labelInfo="(kötelező)">
        <NumericInput
          required
          min={1}
          max={4}
          name="order"
          value={state.order}
          onValueChange={(e) =>
            handleInput({
              target: { name: "order", value: new Number(e) },
            })
          }
        />
      </FormGroup>
      <FormGroup label="Szín">
        <HTMLSelect
          name="color"
          value={state.color}
          onChange={handleInput}
          style={{ maxWidth: "300px" }}
        >
          <option value="" disabled>
            Válasszon
          </option>
          {colors.map((c, idx) => (
            <option key={idx} value={c.value}>
              {c.name}
            </option>
          ))}
        </HTMLSelect>
      </FormGroup>

      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

export default connect(null, {})(MenuCategoryForm);
