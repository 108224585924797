import { Card, H4 } from "@blueprintjs/core";
import React from "react";
import { SZAKKEPESITES_URL } from "../../../utils/routes";
import { LayoutDefault } from "../../LayoutDefault";
import MenuSzakkepesites from "./MenuSzakkepesites";

const styleWrap = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "auto 1fr",
  marginTop: "20px",
};

export const LayoutSzakkepesites = ({ children }) => {
  return (
    <LayoutDefault>
      <div style={{ ...styleWrap }}>
        <div>
          <MenuSzakkepesites />
        </div>

        <Card elevation={2} style={{ height: "calc(100vh - 90px)", overflow: "auto" }}>
          <H4>
            <a href={SZAKKEPESITES_URL} target="_blank">
              Szakképesítés
            </a>
          </H4>
          {children}
        </Card>
      </div>
    </LayoutDefault>
  );
};
