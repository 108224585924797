import http from "../../../utils/http";
import { API_BASE_SKILLSCOM } from "../../../utils/routes";
import { addToastDanger } from "../common/toast";

export const getMe = () => async (dispatch) => {
  try {
    const json = await http.get(API_BASE_SKILLSCOM + "/user/me");
    dispatch({ type: "SKILLSCOM_ME_SUCCESS", payload: json });
    return json;
  } catch (error) {
    dispatch({ type: "SKILLSCOM_ME_FAILED" });
    dispatch(addToastDanger(`SkillsCom user lekérése sikertelen: ${error}`));
  }
};
