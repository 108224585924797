import {
  Button,
  Callout,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import React, { useState,useEffect } from "react";
import http from "../../../utils/http";
import {  API_ENDPOINT, API_BASE_IKK_ADMIN } from "../../../utils/routes";

const CategoriesCreate = () => {
  const [state, setState] = useState({});
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = async () => {
    const res = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.GROUPS);
    setGroups(res);
  };

  const submitGroup = async () => {
    console.log(state)
    const res = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.CATEGORIES, state);

    setState(null); // reset input
  };

  return (
    <>
      <h2>Új Hír kategória</h2>
      <Callout
        icon="info-sign"
        intent="primary"
        style={{ marginBottom: "20px" }}
      >
       Hír kategória létrehozásával tudsz egy új kategóriát létrehozni, amelyhez híreket tudsz majd rendelni.
      </Callout>
      <FormGroup label="Megnevezés" helperText="Uj szabad szoveges cimke">
        <InputGroup
          fill={false}
          onChange={(e) => setState({ name: e.target.value })}
        />
      </FormGroup>
      <FormGroup
          label="Hír csoport"
          labelInfo=""
          helperText="Hír csoport"
          contentClassName="formHelperRight"
        >
          <select onChange={(e) => setState(s=>({...s, group_id:e.target.value? Number(e.target.value):null }))} name="group_id">
            <option value="" selected={state?.group_id === null} >
              Nincs -
            </option>
            {groups.map((cat) => (
              <option value={cat.id} selected={state?.group_id === cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </FormGroup>
      <Button intent="success" onClick={submitGroup}>
        Mentes
      </Button>
    </>
  );
};

export default CategoriesCreate;
