import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { showToast } from "../../../utils/helpers";
import { authFetch } from "../../../utils/http";
import { API_BASE_SCHOLARSHIP, ROUTES } from "../../../utils/routes";
import { ScholarshipTemplateForm } from "./ScholarshipTemplateForm";

export const ScholarshipTemplateEdit = (props) => {
  const [template, setTemplate] = useState();

  const { id } = useParams();

  useEffect(() => {
    if (id) getTemplate();
  }, [id]);

  const getTemplate = async () => {
    authFetch(`${API_BASE_SCHOLARSHIP}/studentship-template/${id}`)
      .then((data) => {
        setTemplate(data);
      })
      .catch((err) => {
        console.error(err);
        showToast({
          intent: "danger",
          message: "Hiba történt a sablon lekérése közben!",
        });
      });
  };

  const handleEditSubmit = async (template) => {
    authFetch(`${API_BASE_SCHOLARSHIP}/studentship-template/${id}`, {
      method: "PUT",
      body: JSON.stringify(template),
    })
      .then((data) => {
        if (data.id) {
          showToast({ message: "Sablon szerkesztése sikeres" });
          props.history.push(ROUTES.scholarshipTemplates);
        }
      })
      .catch((err) => {
        console.error(err);
        showToast({
          intent: "danger",
          message: "Hiba történt a sablon szerkesztése közben!",
        });
      });
  };

  if (!template) return <Spinner />;

  return (
    <ScholarshipTemplateForm
      isEditing
      initialState={template}
      submitBtnText="Módosít"
      title="Ösztöndíj sablon szerkesztése"
      handleSubmit={handleEditSubmit}
      imageRequired={false}
    />
  );
};
