import { Button, MenuItem, Position } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { API_BASE_SZAKKEPESITES } from "../../../utils/routes";
//import style from "../szakkepzes/KKK/KKK.module.scss";
import { getNews } from "../../../redux/actions/skillscom/news";

const SuggestNews = ({
  selectedItem,
  onSelect, // callback, signature: (item, event) => {}
  ...props
}) => {
  useEffect(() => {
    props.getNews();
  }, []);

  const itemRenderer = (item, { handleClick, modifiers, query }) => (
    <MenuItem
      key={item.id}
      text={item.title}
      label={new Date(item.createdAt).toLocaleDateString()}
      onClick={handleClick}
      active={modifiers.active}
    />
  );

  const filterNews = (query, article, _index, exactMatch) => {
    const normalizedName = article.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedName === normalizedQuery;
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0;
    }
  };

  return (
    <Suggest
      selectedItem={selectedItem ? props.news.find((n) => n.id === selectedItem) : null}
      fill
      items={props.news || []}
      itemRenderer={itemRenderer}
      inputValueRenderer={(item) => item.title}
      noResults={<MenuItem disabled={true} text="Nincs találat" />}
      onItemSelect={onSelect}
      popoverProps={{
        popoverClassName: "popover",
        position: Position.BOTTOM_LEFT,
      }}
      itemPredicate={filterNews}
      inputProps={{
        placeholder: "Válasszon...",
        rightElement: (
          <Button minimal icon="cross" onClick={(e) => onSelect({ id: null })} />
        ),
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { news, loading } = state.skillsComNews;
  return { news, loading };
};
export default connect(mapStateToProps, { getNews })(SuggestNews);
