import React from "react";
import { TopNavbar } from "./common/Navbar";

// layout for wrapping its children with the navbar component
export const LayoutDefault = ({ children }) => {
  return (
    <>
      <TopNavbar />
      <div style={{ paddingTop: 30 }}>{children}</div>
    </>
  );
};
