import { Button, Callout } from "@blueprintjs/core";
import React from "react";
import { Grid } from "../../common/LayoutHelpers";

export const SaveButton = ({ onClick }) => {
  return (
    <>
      <Callout intent="warning">Nincs mentve!</Callout>
      <Button
        type="button"
        intent="primary"
        icon="tick"
        style={{ marginTop: 5 }}
        onClick={onClick}
      >
        Mentés
      </Button>
    </>
  );
};
