import React from "react";
import { connect } from "react-redux";
import { uploadPost } from "../../../redux/actions/skillscom/post";
import { ROUTES } from "../../../utils/routes";
import PostsForm from "./PostsForm";

const emptyState = {
  title: "",
  lead: "",
  body: "",
  coverImgId: null,
  tags: [],
  sendPush: false,
  relatedNewsId: null,
};

const PostsCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const data = await props.uploadPost(state);
    // reset form is submit was successful
    if (data && data.id) {
      setState(emptyState);
      props.history.push(ROUTES.skillsComPosts);
    }
  };

  return (
    <PostsForm
      title="Poszt felvitele"
      initialState={emptyState}
      submitBtnText="Létrehoz"
      imageRequired={false}
      onSubmit={handleCreateSubmit}
    />
  );
};

export default connect(null, { uploadPost })(PostsCreate);
