import {
  SZAKKEPESITES_GET_QUALIFS_BEGIN,
  SZAKKEPESITES_GET_QUALIFS_FAIL,
  SZAKKEPESITES_GET_QUALIFS_SUCCESS,
} from "../../actionTypes";

const initialState = {
  qualifications: [],
  loading: false,
  error: null,
  singleQualif: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SZAKKEPESITES_GET_QUALIFS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SZAKKEPESITES_GET_QUALIFS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        qualifications: action.payload,
      };
    }
    case SZAKKEPESITES_GET_QUALIFS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        qualifications: [],
      };
    }

    default:
      return state;
  }
}
