import {
  Button,
  Callout,
  FormGroup,
  HTMLSelect,
  Checkbox,
  InputGroup,
  NumericInput,
  TagInput,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { RandomPasswordButton } from "../common/RandomPasswordButton";
import { ToggleVisibilityButton } from "../common/ToggleVisibilityButton";

const InstituteForm = (props) => {
  const [institute, setInstitute] = useState(props.initialState);

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setInstitute((s) => ({ ...s, [name]: val }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(institute, setInstitute);
  };

  const RightElement = () => {
    return (
      <span>
        <RandomPasswordButton handleInput={handleInput} isDisabled={props.isEditing} />
        <ToggleVisibilityButton />
      </span>
    );
  };

  const handleDomainRemove = (val, index) => {
    const newDomains = institute.realDomains.filter((p) => p !== val);
    handleInput({ target: { name: "realDomains", value: newDomains } });
  };

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={() => {}}
      onKeyPress={() => {}}
      style={{ maxWidth: 800, marginBottom: 100 }}
      autoComplete="off"
    >
      <h2>{props.title}</h2>

      {/* show a warning, since there is no test API for CEAM */}
      {process.env.REACT_APP_CMS_ENV !== "live" && (
        <Callout title="Figyelem!" intent="warning">
          Az EDIR hozzáférés kezelővel az alábbiakban az <b>éles</b> adatokat szerkeszti!
        </Callout>
      )}
      <br />

      <FormGroup label="Név" labelInfo="(kötelező)">
        <InputGroup
          required
          name="name"
          placeholder="Intézmény teljes neve"
          value={institute.name}
          onChange={handleInput}
        />
      </FormGroup>
      <FormGroup label="DB ID" labelInfo="(kötelező)">
        <NumericInput
          name="dbId"
          disabled={props.isEditing}
          value={institute.dbId}
          min={0}
          onValueChange={(num) => handleInput({ target: { name: "dbId", value: num } })}
        />
      </FormGroup>
      <FormGroup label="Tárhely" labelInfo="(GB, kötelező)">
        <NumericInput
          name="storage"
          disabled={props.isEditing}
          value={institute.storage}
          onChange={handleInput}
          min={0}
          onValueChange={(num) =>
            handleInput({ target: { name: "storage", value: num } })
          }
        />
      </FormGroup>
      <FormGroup label="Frontend EDIR domain" labelInfo="(kötelező)">
        <InputGroup
          required
          disabled={props.isEditing}
          name="frontendEdirDomain"
          placeholder="*.www.intezmeny.edir.hu"
          value={institute.frontendEdirDomain}
          onChange={handleInput}
        />
      </FormGroup>
      <FormGroup label="CMS EDIR domain" labelInfo="(kötelező)">
        <InputGroup
          required
          disabled={props.isEditing}
          name="backendEdirDomain"
          placeholder="*.cms.intezmeny.edir.hu"
          value={institute.backendEdirDomain}
          onChange={handleInput}
        />
      </FormGroup>
      <FormGroup
        label="Igazi domain(ek)"
        labelInfo="(kötelező)"
        helperText="A www kezdetű domaineket is külön fel kell vinni ha van."
      >
        <TagInput
          fill
          addOnBlur
          placeholder="Vesszővel elválasztott domain nevek"
          onChange={(values) =>
            handleInput({ target: { name: "realDomains", value: values } })
          }
          onKeyDown={(e) => console.log(e.code)}
          values={institute.realDomains}
          onRemove={handleDomainRemove}
          separator={/[,\n\r\s]/}
        />
      </FormGroup>
      <FormGroup label="Main (IKK) CMS domain" labelInfo="(kötelező)">
        <InputGroup
          required
          disabled={props.isEditing}
          name="mainCMSDomain"
          value={institute.mainCMSDomain}
          onChange={handleInput}
        />
      </FormGroup>
      <FormGroup label="Admin user" labelInfo="(kötelező)">
        <InputGroup
          name="username"
          type="email"
          disabled={props.isEditing}
          value={institute.username}
          placeholder="IKK admin email"
          onChange={handleInput}
        />
      </FormGroup>
      <FormGroup label="Admin jelszó" labelInfo="(kötelező)">
        <InputGroup
          required
          name="password"
          type="password"
          disabled={props.isEditing}
          value={institute.password}
          onChange={handleInput}
          minLength={8}
          rightElement={<RightElement />}
        />
      </FormGroup>
      <FormGroup label="Tag" labelInfo="(kötelező)">
        <HTMLSelect
          name="tag"
          value={institute.tag}
          onChange={handleInput}
          disabled={props.isEditing}
        >
          <option value="prod">prod</option>
          <option value="dev">dev</option>
        </HTMLSelect>
      </FormGroup>
      <FormGroup label="Cert létrehozva" labelInfo="">
        <Checkbox
          disabled={props.isEditing}
          label="Cert létrehozva"
          name="createdCertForEdirDomain"
          onChange={handleInput}
          checked={institute.createdCertForEdirDomain}
        />
      </FormGroup>
      <FormGroup label="Verzió" labelInfo="">
        <NumericInput
          name="version"
          disabled={props.isEditing}
          value={institute.version}
          min={0}
          onValueChange={(n) => handleInput({ target: { name: "version", value: n } })}
        />
      </FormGroup>

      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

export default InstituteForm;
