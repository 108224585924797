import { Button, Callout, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  deleteDocument,
  getDocument,
} from "../../../redux/actions/ikk/documents";
import { API_BASE_IKK, API_ENDPOINT, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const DocsAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);

  useEffect(() => {
    props.getDocument();
  }, []);

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = async () => {
    setRowToDelete(null);
    await props.deleteDocument(rowToDelete.id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Cím",
        accessor: "title",
      },
      {
        Header: "Alcím",
        accessor: "subtitle",
      },
      {
        Header: "Szöveg",
        accessor: "description",
        Cell: ({ value }) => (
          <div dangerouslySetInnerHTML={{ __html: value }} />
        ),
      },
      {
        Header: "Kiemelt?",
        accessor: "featured",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Sorrend",
        accessor: "order",
      },
      {
        Header: "oldal",
        accessor: "page",
      },
      {
        Header: "Fájl",
        accessor: "media_id",
        Cell: ({ value }) => (
          <Button
            small
            minimal
            icon="document-open"
            text="Megnyit"
            onClick={() =>
              window.open(
                `${API_BASE_IKK + API_ENDPOINT.MEDIA}/${value}`,
                "_blank"
              )
            }
          />
        ),
      },
      {
        Header: "Létrehozva",
        accessor: "created_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.documentsEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2>Összes letoltheto dokumentum</h2>

      <Callout intent="primary">
        Egy dokumentum mindig legyen kiemeltre allitva, mer akkor nez ki jol az
        oldalon!
      </Callout>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      <TableAdvanced columns={columns} data={props.documents} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { documents, loading } = state.documents;
  return { documents, loading };
};
export default connect(mapStateToProps, { getDocument, deleteDocument })(
  DocsAll
);
