import { Button, Checkbox, FormGroup, Icon, TextArea } from "@blueprintjs/core";
import "moment/locale/hu";
import React, { useState } from "react";
import { isIKK } from "../../../utils/auth";
import { API_BASE_IKK } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import TagsMultiSelect from "../../common/TagsMultiSelect";
import SuggestNews from "./SuggestNews";

const PostsForm = ({ initialState, title, submitBtnText, onSubmit, imageRequired }) => {
  const [state, setState] = useState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(state, setState);
  };

  const handleInput = (e) => {
    if (e.target.type === "checkbox") {
      setState((s) => ({ ...s, [e.target.name]: e.target.checked }));
    } else {
      const { name, value } = e.target;
      setState((s) => ({ ...s, [name]: value }));
    }
  };

  const handleCoverUpload = (media) => {
    setState((s) => ({ ...s, coverImgId: media.id }));
  };

  const handleTagsChange = (newTags) => {
    console.log("handleTagsChange", newTags);
    const tagIds = newTags.map((t) => t.id);
    setState((s) => ({ ...s, tags: [...tagIds] }));
  };

  const handleRelatedNewsSelect = (item, e) => {
    setState((s) => ({ ...s, relatedNewsId: item.id }));
  };

  return (
    <form style={{ maxWidth: "800px" }} onSubmit={handleSubmit}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="label" />
        &nbsp;&nbsp;<span>{title}</span>
      </h2>

      <FormGroup
        label="Összefoglaló"
        labelInfo="(kötelező)"
        contentClassName="formHelperRight"
      >
        <TextArea
          fill
          growVertically
          placeholder="Rövid összefoglaló..."
          style={{ resize: "none" }}
          name="body"
          value={state.body}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup
        label="Tagek"
        helperText="Cimkek melyek kapcsolodnak a poszt tartalmahoz"
      >
        <TagsMultiSelect initialTags={state.tags} onChange={handleTagsChange} />
      </FormGroup>

      <FormGroup
        label="Kapcsolódó hír"
        helperText='Itt lehetoseg van megjelolni egy korabban felvitt hirt "forrasnak" pl vagy emlites celjabol, ami egy linkkent jelenik meg a poszt alatt.'
      >
        <SuggestNews
          selectedItem={state.relatedNewsId}
          onSelect={handleRelatedNewsSelect}
        />
      </FormGroup>

      <FormGroup
        label="Borítókép"
        labelInfo={imageRequired && "(kötelező)"}
        helperText="Engedélyezett formátumok: jpg, png, webp."
      >
        <FileUpload onUpload={handleCoverUpload} isRequired={imageRequired} />
      </FormGroup>

      <FormGroup>
        {state.coverImgId ? (
          <img src={`${API_BASE_IKK}/media/${state.coverImgId}`} height="100" />
        ) : (
          <p>Nincs kép felöltve</p>
        )}
      </FormGroup>

      {!isIKK() && (
        <FormGroup label="Push küldése" helperText="Küldjön push értesítést?">
          <Checkbox onChange={handleInput} name="sendPush" checked={state.sendPush}>
            Értesítés küldése
          </Checkbox>
        </FormGroup>
      )}

      <br />

      <Button text={submitBtnText} icon="floppy-disk" intent="success" type="submit" />

      <br />
    </form>
  );
};

export default PostsForm;
