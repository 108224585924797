import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { Details } from "../../../common/Details";
import MyCkEditor from "../../../common/MyCkEditor";
import { DeleteButton } from "../DeleteButton";
import { useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";

const SimpleAccordionItemFields = ({ item, onItemChange }) => {
  return (
    <>
      <FormGroup label="Cím" labelInfo="(kötelező)">
        <InputGroup
          required
          value={item.title}
          onChange={(e) => onItemChange("title", e.target.value)}
        />
      </FormGroup>
      <FormGroup label="Tartalom" labelInfo="(kötelező)">
        <MyCkEditor
          data={item.body?.html}
          onDataChange={(data) => onItemChange("body", { html: data })}
        />
      </FormGroup>
    </>
  );
};

export const SimpleAccordion = ({ initialState, onComponentSave }) => {
  const { state, isDirty, setState } = useInputState(initialState);

  const onAddNewItem = () => {
    setState((s) => ({
      ...s,
      items: [...s.items, { title: "Title", categoryName: "" }],
    }));
  };

  const onRemoveItem = (index) => {
    const temp = structuredClone(state.items);
    temp.splice(index, 1);
    setState((s) => ({ ...s, items: temp }));
  };

  const onItemChange = (index) => (key, value) => {
    const temp = structuredClone(state);
    temp.items[index][key] = value;
    setState(temp);
  };

  return (
    <>
      <FormGroup label="Itemek">
        {state.items.map((item, i) => (
          <Details key={i} summary={`${i + 1}. ${item.title}`}>
            <SimpleAccordionItemFields item={item} onItemChange={onItemChange(i)} />
            <DeleteButton onClick={() => onRemoveItem(i)}>Item törlése</DeleteButton>
          </Details>
        ))}

        <Button minimal icon="plus" intent="primary" onClick={onAddNewItem}>
          Új item
        </Button>
      </FormGroup>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
