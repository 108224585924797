import http from "../../../utils/http";
import {
  API_BASE_IKK_ADMIN,
  API_ENDPOINT,
  API_AUTH_PROVIDER,
} from "../../../utils/routes";
import { LOGIN_BEGIN, LOGIN_FAILURE, LOGIN_SUCCESS } from "../../actionTypes";
import { addToastDanger } from "./toast";
import jwt from "jsonwebtoken";
import { isAuthorized } from "../../../utils/auth";

export const login = (username, password, remember) => async (dispatch) => {
  try {
    dispatch(loginBegin());
    const json = await http.post(`${API_AUTH_PROVIDER}/auth/login`, {
      email: username,
      password,
    });
    sessionStorage.setItem("token", json.token);
    if (
      isAuthorized("cms", process.env.REACT_APP_AUTH_DOMAIN) ||
      isAuthorized("auth")
    ) {
      dispatch(loginSuccess({ ...json, remember }));
    } else {
      dispatch(addToastDanger("Nincs jogosultsága a felület megtekintéséhez"));
    }
  } catch (error) {
    dispatch(addToastDanger("Login failed"));
  }
};

export const loginBegin = () => ({
  type: LOGIN_BEGIN,
});

export const loginSuccess = (user) => {
  if (user.remember) {
    localStorage.setItem("token", user.token);
    localStorage.setItem("refreshToken", user.refreshToken);
  } else {
    sessionStorage.setItem("token", user.token);
    sessionStorage.setItem("refreshToken", user.refreshToken);
  }
  window.location.replace("/");
  return {
    type: LOGIN_SUCCESS,
    payload: { user },
  };
};

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: { error },
});
