import http from "../../../utils/http";
import { API_BASE_SKILLSCOM } from "../../../utils/routes";
import {
  SKILLSCOM_NEWS_ADD_BEGIN,
  SKILLSCOM_NEWS_ADD_FAILURE,
  SKILLSCOM_NEWS_ADD_SUCCESS,
  SKILLSCOM_NEWS_DELETE_BEGIN,
  SKILLSCOM_NEWS_DELETE_SUCCESS,
  SKILLSCOM_NEWS_GET_BEGIN,
  SKILLSCOM_NEWS_GET_FAILURE,
  SKILLSCOM_NEWS_GET_ONE_SUCCESS,
  SKILLSCOM_NEWS_GET_SUCCESS,
  SKILLSCOM_NEWS_UPDATE_BEGIN,
  SKILLSCOM_NEWS_UPDATE_FAILURE,
  SKILLSCOM_NEWS_UPDATE_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const uploadNews = (data) => async (dispatch) => {
  dispatch(uploadNewsBegin());
  try {
    const json = await http.post(`${API_BASE_SKILLSCOM}/news`, data);
    dispatch(uploadNewsSuccess(json));
    dispatch(addToastSuccess("Hír feltöltés sikeres"));
    return json;
  } catch (error) {
    dispatch(uploadNewsFailure(error));
    dispatch(addToastDanger(`Hír feltöltés sikertelen: ${error}`));
  }
};

export const deleteNews = (id) => async (dispatch) => {
  dispatch(newsDeleteBegin());
  try {
    const json = await http.del(`${API_BASE_SKILLSCOM}/news/${id}`);
    dispatch(newsDeleteSuccess(id));
    dispatch(addToastSuccess("Törles sikeres"));
    console.log("json:", json);
    return json; // null
  } catch (error) {
    dispatch(addToastDanger(`Törles sikertelen: ${error}`));
  }
};

export const updateNews = (id, data) => async (dispatch) => {
  dispatch(updateNewsBegin());
  try {
    const json = await http.put(`${API_BASE_SKILLSCOM}/news/${id}`, data);
    dispatch(updateNewsSuccess(json));
    dispatch(addToastSuccess("Hír frissítve"));
    return json;
  } catch (error) {
    dispatch(updateNewsFailure(error));
    dispatch(addToastDanger(`Hír frissítése sikertelen: ${error}`));
  }
};

export const getOneNews = (id) => async (dispatch) => {
  dispatch(getNewsBegin());
  try {
    const json = await http.get(`${API_BASE_SKILLSCOM}/news/${id}`);
    dispatch(getOneNewsSuccess(json.article));
    return json.article;
  } catch (error) {
    dispatch(getNewsFailure(error));
    dispatch(addToastDanger(`Hír betöltése sikertelen: ${error}`));
  }
};

export const getNews = (offset = 0, limit = 30) => async (dispatch) => {
  dispatch(getNewsBegin());
  try {
    const json = await http.get(
      `${API_BASE_SKILLSCOM}/news?offset=${offset}&limit=${limit}`
    );
    dispatch(getNewsSuccess(json));
    return json;
  } catch (error) {
    dispatch(getNewsFailure(error));
    dispatch(addToastDanger("Hírek betöltése sikertelen."));
  }
};

export const approveNews = (id) => async (dispatch) => {
  try {
    const json = await http.post(`${API_BASE_SKILLSCOM}/news/${id}/approve`);
    dispatch(addToastSuccess("Hír elfogadva!"));
    return json;
  } catch (error) {
    console.error(error);
    dispatch(addToastDanger("Hír elfogadás sikertelen!"));
  }
};

export const updateNewsBegin = () => ({
  type: SKILLSCOM_NEWS_UPDATE_BEGIN,
});

export const updateNewsSuccess = (news) => ({
  type: SKILLSCOM_NEWS_UPDATE_SUCCESS,
  payload: { news },
});

export const updateNewsFailure = (error) => ({
  type: SKILLSCOM_NEWS_UPDATE_FAILURE,
  payload: { error },
});

export const uploadNewsBegin = () => ({
  type: SKILLSCOM_NEWS_ADD_BEGIN,
});

export const uploadNewsSuccess = (news) => ({
  type: SKILLSCOM_NEWS_ADD_SUCCESS,
  payload: { news },
});

export const uploadNewsFailure = (error) => ({
  type: SKILLSCOM_NEWS_ADD_FAILURE,
  payload: { error },
});

export const getNewsBegin = () => ({
  type: SKILLSCOM_NEWS_GET_BEGIN,
});

export const getNewsSuccess = (news) => ({
  type: SKILLSCOM_NEWS_GET_SUCCESS,
  payload: news,
});

export const getOneNewsSuccess = (article) => ({
  type: SKILLSCOM_NEWS_GET_ONE_SUCCESS,
  payload: article,
});

export const getNewsFailure = (error) => ({
  type: SKILLSCOM_NEWS_GET_FAILURE,
  payload: { error },
});

const newsDeleteBegin = () => ({
  type: SKILLSCOM_NEWS_DELETE_BEGIN,
});

const newsDeleteSuccess = (id) => ({
  type: SKILLSCOM_NEWS_DELETE_SUCCESS,
  payload: { id },
});
