import React, { useState } from "react";

export const NotificationContext = React.createContext({
  message: null,
  addNotif: () => {},
  removeNotif: () => {},
});

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  const removeNotification = () => setNotification(null);

  const addNotification = (message, intent) => {
    setNotification({ message, intent });
  };

  const contextValue = {
    notif: notification,
    addNotif: (message, intent) => addNotification(message, intent),
    removeNotif: () => removeNotification(),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};
