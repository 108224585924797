import { AnchorButton, Button, Spinner } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getInstitutes,
  verifyInstitute,
} from "../../../redux/actions/skillscom/institutes";
import { SKILLSCOM_URL } from "../../../utils/routes";
import { TableAdvanced } from "../../common/TableAdvanced";

const InstituteAll = (props) => {
  useEffect(() => {
    getAllInstitutesRecursively(0, 100);
  }, []);

  const getAllInstitutesRecursively = async (offset, limit) => {
    const j = await props.getInstitutes(offset, limit);
    if (Array.isArray(j) && j.length === limit) {
      getAllInstitutesRecursively(offset + limit, limit);
    }
  };

  const handleVerify = (row) => () => {
    const { id, name } = row.original;
    if (
      window.confirm(`Biztosan megerősíti az alábbi regisztációt?\n\n${name}`)
    ) {
      props.verifyInstitute(id);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Intézmény név",
        accessor: "name",
      },
      {
        Header: "OM azonosító",
        accessor: "OMNumber",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Elfogadva",
        accessor: "verified",
        Cell: ({ value, row }) => {
          if (value === null) {
            return <Button onClick={handleVerify(row)}>Elfogadom</Button>;
          } else return <p>{new Date(value).toLocaleString()}</p>;
        },
      },
      {
        Header: "Web",
        Cell: ({ row }) => (
          <AnchorButton
            small
            minimal
            icon="document-open"
            text="Profil"
            target="_blank"
            href={`${SKILLSCOM_URL}/intezmenyek/${row.original.slug}`}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes regisztrált intézmény</h2>

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.institutes} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { institutes, loading } = state.skillsComInstitutes;
  return { institutes, loading };
};
export default connect(mapStateToProps, {
  getInstitutes,
  verifyInstitute,
})(InstituteAll);
