import { getToken } from "../../../utils/auth";
import { API_BASE_SZAKKEPZES, API_ENDPOINT } from "../../../utils/routes";
import {
  SZAKKEPZES_EXPORT_BEGIN,
  SZAKKEPZES_EXPORT_FAILURE,
  SZAKKEPZES_EXPORT_SUCCESS,
} from "../../actionTypes";

export const exportFile = () => async (dispatch) => {
  dispatch(downladFileBegin());
  return fetch(API_BASE_SZAKKEPZES + API_ENDPOINT.SZAKKEPZES_EXPORT, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      dispatch(downladFileSuccess(blob));
      const href = window.URL.createObjectURL(blob);
      const a = document.body.appendChild(document.createElement("a"));
      a.download = `ikk-szakkepzes-export-${new Date().toISOString()}.xlsx`;
      a.href = href;
      a.click();
      a.remove();
      return blob;
    })
    .catch((error) => dispatch(downladFileFailure(error)));
};

export const downladFileBegin = () => ({
  type: SZAKKEPZES_EXPORT_BEGIN,
});
export const downladFileSuccess = (items) => ({
  type: SZAKKEPZES_EXPORT_FAILURE,
  payload: items,
});
export const downladFileFailure = (err) => {
  console.log(err);
  return {
    type: SZAKKEPZES_EXPORT_SUCCESS,
    payload: { err },
  };
};
