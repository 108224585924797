import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT, ROUTES } from "../../../utils/routes";
import CareerForm from "./CareerForm";

const CareerEdit = (props) => {
  const [job, setJob] = useState();

  const { id } = useParams();

  useEffect(() => {
    getJob();
  }, []);

  async function getJob() {
    const ca = await http.get(`${API_BASE_IKK_ADMIN}/career/${id}`);
    setJob(ca);
  }

  const handleEditSubmit = async (state, setState) => {
    const res = await http.put(
      `${API_BASE_IKK_ADMIN + API_ENDPOINT.CAREERS}/${job.id}`,
      state
    );
    if (res) props.history.push(ROUTES.careers);
  };

  if (!job) return <Spinner />;

  return (
    <CareerForm
      initialState={job}
      submitBtnText="Módosít"
      title="Álláshirdetés módosítása"
      handleSubmit={handleEditSubmit}
    />
  );
};

export default CareerEdit;
