import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  TextArea,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getItems, modifyItem } from "../../../redux/actions/ikk/publicInfo";
import FileUpload from "../../common/FileUpload";

const ArchiveEditDataDialog = (props) => {
  const [item, setItem] = useState();

  // the item is initially received from the props but managed in local state afterwards
  useEffect(() => {
    setItem(props.item);
  }, [props]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setItem((it) => ({ ...it, [name]: value }));
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setItem((it) => ({ ...it, [name]: checked }));
  };

  const handleDialogClose = () => {
    props.setItem(null);
  };

  const handleUpload = (media) => {
    setItem((it) => ({ ...it, media_id: media.id }));
  };

  const handleSave = async () => {
    //todo api
    await props.modifyItem(item.id, item.name, item.media_id, item.visible);
    props.setItem(null);
    props.getItems();
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      title="Adat módosítása"
      isOpen={!!item}
      usePortal={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup label="Adat neve">
          <TextArea
            fill
            growVertically
            name="name"
            value={item?.name}
            onChange={handleInput}
            style={{ resize: "none" }}
          />
        </FormGroup>

        <FormGroup label="Fájl cseréje">
          <FileUpload onUpload={handleUpload} />
        </FormGroup>

        <FormGroup>
          <Checkbox
            label="Látható"
            name="visible"
            checked={item?.visible}
            onChange={handleCheckbox}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Mégse" onClick={handleDialogClose} />

          <Button
            text="Mentés"
            intent="success"
            icon="floppy-disk"
            onClick={handleSave}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default connect(null, { modifyItem, getItems })(ArchiveEditDataDialog);
