import { Classes, Menu, MenuItem } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";

const AuthMenu = () => {
  return (
    <Menu className={Classes.ELEVATION_2}>
      <MenuItem icon="key" text="Jogosultságok" tagName={Link} to="#" />
    </Menu>
  );
};

export default AuthMenu;
