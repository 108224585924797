import {
  PROJECT_GET_BEGIN,
    PROJECT_GET_FAILURE,
    PROJECT_GET_SUCCESS

} from "../actionTypes";

const initialState = {
  projects: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROJECT_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case PROJECT_GET_SUCCESS: {
      return {
        projects: action.payload,
        loading: false,
        error: null,
      };
    }
    case PROJECT_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }


    default:
      return state;
  }
}
