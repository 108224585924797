import http from "../../../utils/http";
import { API_BASE_SKILLSCOM } from "../../../utils/routes";
import {
  SKILLSCOM_EVENT_ADD_BEGIN,
  SKILLSCOM_EVENT_ADD_FAILURE,
  SKILLSCOM_EVENT_ADD_SUCCESS,
  SKILLSCOM_EVENT_DELETE_BEGIN,
  SKILLSCOM_EVENT_DELETE_FAILURE,
  SKILLSCOM_EVENT_DELETE_SUCCESS,
  SKILLSCOM_EVENT_GET_BEGIN,
  SKILLSCOM_EVENT_GET_FAILURE,
  SKILLSCOM_EVENT_GET_SUCCESS,
  SKILLSCOM_EVENT_UPDATE_BEGIN,
  SKILLSCOM_EVENT_UPDATE_FAILURE,
  SKILLSCOM_EVENT_UPDATE_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const uploadSKILLSCOM_EVENT = (data) => async (dispatch) => {
  dispatch(uploadSKILLSCOM_EVENTBegin());
  try {
    const json = await http.post(API_BASE_SKILLSCOM + "/events", data);
    dispatch(uploadSKILLSCOM_EVENTSuccess(json));
    dispatch(addToastSuccess("Esemény létrehozva"));
    return json;
  } catch (error) {
    dispatch(uploadSKILLSCOM_EVENTFailure(error));
    dispatch(addToastDanger(`Esemény létrehozása sikertelen: ${error}`));
  }
};

export const updateSKILLSCOM_EVENT = (id, data) => async (dispatch) => {
  dispatch(updateSKILLSCOM_EVENTBegin());
  try {
    const json = await http.put(`${API_BASE_SKILLSCOM}/events/${id}`, data);
    dispatch(updateSKILLSCOM_EVENTSuccess(json));
    dispatch(addToastSuccess("Esemény módosítva"));
    return json;
  } catch (error) {
    dispatch(updateSKILLSCOM_EVENTFailure(error));
    dispatch(addToastDanger(`Esemény módosítása sikertelen: ${error}`));
  }
};

export const getSKILLSCOM_EVENT = (offset = 0, limit = 30) => async (
  dispatch
) => {
  dispatch(getSKILLSCOM_EVENTBegin());
  try {
    const json = await http.get(
      `${API_BASE_SKILLSCOM}/events?offset=${offset}&limit=${limit}`
    );
    dispatch(getSKILLSCOM_EVENTSuccess(json));
    console.log("event json:", json);
    return json;
  } catch (error) {
    dispatch(getSKILLSCOM_EVENTFailure(error));
    dispatch(addToastDanger(`Események betöltése sikertelen: ${error}`));
  }
};

export const getOneEvent = (id) => async (dispatch) => {
  try {
    const json = await http.get(`${API_BASE_SKILLSCOM}/events/${id}`);
    console.log("event json:", json);
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Események betöltése sikertelen: ${error}`));
  }
};

export const deleteSKILLSCOM_EVENT = (id) => async (dispatch) => {
  dispatch(deleteSKILLSCOM_EVENTBegin());
  try {
    const json = await http.del(`${API_BASE_SKILLSCOM}/events/${id}`);
    dispatch(deleteSKILLSCOM_EVENTSuccess(id));
    dispatch(addToastSuccess("Esemény törölve"));
    return json;
  } catch (error) {
    dispatch(deleteSKILLSCOM_EVENTFailure(error));
    dispatch(addToastDanger(`Esemény törlése sikertelen: ${error}`));
  }
};

export const approveEvent = (id) => async (dispatch) => {
  try {
    const json = await http.post(`${API_BASE_SKILLSCOM}/events/${id}/approve`);
    dispatch(addToastSuccess("Esemény elfogadva!"));
    return json;
  } catch (error) {
    console.error(error);
    dispatch(addToastDanger("Esemény elfogadása sikertelen!"));
  }
};

export const updateSKILLSCOM_EVENTBegin = () => ({
  type: SKILLSCOM_EVENT_UPDATE_BEGIN,
});

export const updateSKILLSCOM_EVENTSuccess = (SKILLSCOM_EVENT) => ({
  type: SKILLSCOM_EVENT_UPDATE_SUCCESS,
  payload: { SKILLSCOM_EVENT },
});

export const updateSKILLSCOM_EVENTFailure = (error) => ({
  type: SKILLSCOM_EVENT_UPDATE_FAILURE,
  payload: { error },
});

export const uploadSKILLSCOM_EVENTBegin = () => ({
  type: SKILLSCOM_EVENT_ADD_BEGIN,
});

export const uploadSKILLSCOM_EVENTSuccess = (SKILLSCOM_EVENT) => ({
  type: SKILLSCOM_EVENT_ADD_SUCCESS,
  payload: { SKILLSCOM_EVENT },
});

export const uploadSKILLSCOM_EVENTFailure = (error) => ({
  type: SKILLSCOM_EVENT_ADD_FAILURE,
  payload: { error },
});

export const getSKILLSCOM_EVENTBegin = () => ({
  type: SKILLSCOM_EVENT_GET_BEGIN,
});

export const getSKILLSCOM_EVENTSuccess = (events) => ({
  type: SKILLSCOM_EVENT_GET_SUCCESS,
  payload: events,
});

export const getSKILLSCOM_EVENTFailure = (error) => ({
  type: SKILLSCOM_EVENT_GET_FAILURE,
  payload: { error },
});

export const deleteSKILLSCOM_EVENTBegin = () => ({
  type: SKILLSCOM_EVENT_DELETE_BEGIN,
});

export const deleteSKILLSCOM_EVENTSuccess = (id) => ({
  type: SKILLSCOM_EVENT_DELETE_SUCCESS,
  payload: id,
});

export const deleteSKILLSCOM_EVENTFailure = (error) => ({
  type: SKILLSCOM_EVENT_DELETE_FAILURE,
  payload: { error },
});
