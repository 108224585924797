import { Button, Callout, FormGroup, InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { uploadEditor } from "../../../redux/actions/skillscom/editor";
import { ROUTES } from "../../../utils/routes";

const EditorCreate = (props) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await props.uploadEditor({ email });
    if (data) props.history.push(ROUTES.skillsComEditors);
  };

  return (
    <div style={{ maxWidth: "700px", marginBottom: 50 }}>
      <h2>Új szerkesztő felvétele az intézménybe</h2>

      <Callout intent="primary">
        Csak olyan szerkesztőt lehet hozzáadni aki már regisztrált Skillscomba (tanárként)
        az alábbi e-mail címmel és nincs hozzáadva egyik másik intézményhez sem.
      </Callout>
      <br />

      <form onSubmit={handleSubmit}>
        <FormGroup
          label="E-mail cím"
          labelInfo="(kötelező)"
          contentClassName="formHelperRight"
        >
          <InputGroup
            onChange={(v) => setEmail(v.target.value)}
            name="email"
            value={email}
            required
            type="email"
            placeholder="Szerkesztő e-mail címe"
          />
        </FormGroup>

        <Button text={"Hozzáadás"} icon="floppy-disk" intent="success" type="submit" />
      </form>
    </div>
  );
};

export default connect(null, { uploadEditor })(EditorCreate);
