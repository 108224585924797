import React from "react";
import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT, ROUTES } from "../../../utils/routes";
import CareerForm from "./CareerForm";

const initialState = {
  title: "",
  body: "",
};

const CareerCreate = (props) => {
  // submit button pressed on create form
  const handleSubmit = async (state, setState) => {
    await http.post(`${API_BASE_IKK_ADMIN + API_ENDPOINT.CAREERS}`, state);
    setState(initialState);
    props.history.push(ROUTES.careers);
  };

  return (
    <CareerForm
      title="Új álláshirdetés létrehozása"
      submitBtnText="Létrehoz"
      initialState={initialState}
      handleSubmit={handleSubmit}
    />
  );
};

export default CareerCreate;
