export const LIMIT = {
  NEWS_TITLE_MAX_LEN: 128,
  NEWS_LEAD_MAX_LEN: 400,
  SLIDE_TITLE_MAX_LEN: 55,
  SLIDE_BODY_MAX_LEN: 120,
  EVENT_TITLE_MAX_LEN: 128,

  DOCUMENT_TITLE_MAX_LEN: 128,
  DOCUMENT_SUBTITLE_MAX_LEN: 128,
  DOCUMENT_BUTTON_MAX_LEN: 20,
  DOCUMENT_TEXT_MAX_LEN: 256,
  FAQ_QUESTION_MAX_LEN: 200,
  // FAQ_ANSWER_MAX_LEN: 10000,

  PROFCARD_QUALIF_BODY_MAX_LEN: 2000,
  PROFCARD_QUALIF_LEAD_MAX_LEN: 100,
  PROFCARD_QUALIF_COMP_MAX_LEN: 1000,
  PROFCARD_SECTOR_BODY_MAX_LEN: 2000,
};
