import { Button, Spinner } from "@blueprintjs/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getToken } from "../../../utils/auth";
import http from "../../../utils/http";
import { API_BASE_SKILLSCOM } from "../../../utils/routes";
import { TableAdvanced } from "../../common/TableAdvanced";

// download pdf by manually creating a link and clicking it
const downloadCV = async (user) => {
  try {
    const res = await fetch(
      `${API_BASE_SKILLSCOM}/user/${user.studentId}/cv/pdf`,
      {
        headers: { Authorization: `Bearer ${await getToken()}` },
      }
    );
    const blob = await res.blob();
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = `SkillsCom_CV_${user.name.replaceAll(/\s/g, "_")}_${
      user.cvUpdatedAt
    }.pdf`;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
  } catch (error) {
    console.error(error);
  }
};

const JobApplicants = () => {
  const [applicants, setApplicants] = useState();
  const { id } = useParams();
  const [job, setJob] = useState();

  useEffect(() => {
    // get applicants
    http
      .get(`${API_BASE_SKILLSCOM}/jobs/${id}/applicants`)
      .then((data) => setApplicants(data));
    // get job details
    http.get(`${API_BASE_SKILLSCOM}/jobs/${id}`).then((data) => setJob(data));
  }, [id]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "studentId",
      },
      {
        Header: "Név",
        accessor: "name",
      },
      {
        Header: "CV frissítve",
        accessor: "cvUpdatedAt",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "CV Letöltés",
        Cell: ({ row }) => (
          <Button
            icon="download"
            small
            onClick={() => downloadCV(row.original)}
          >
            PDF
          </Button>
        ),
      },
      // {
      //   Header: "Művelet",
      //   Cell: ({ row }) => (
      //     <TableActionButtons
      //       row={row}
      //       editHref={generatePath(ROUTES.skillsComJobsEdit, {
      //         id: row.original.id,
      //       })}
      //       onDelBtnClick={handleDeleteDialog}
      //     />
      //   ),
      // },
    ],
    []
  );

  if (!applicants || !job) return <Spinner />;

  return (
    <>
      <h1>
        <i>{job.title}</i> állásra jelenkezők
      </h1>

      <TableAdvanced
        columns={columns}
        data={applicants}
        idField={"studentId"}
      />
    </>
  );
};
export default JobApplicants;
