import { NonIdealState } from "@blueprintjs/core";
import React from "react";

export const NotFoundPage = () => (
  <div style={{ height: "90vh" }}>
    <NonIdealState
      title="Hiba"
      description="A keresett oldal nem található, kérjük ellenőrizze az url-t."
      icon="zoom-out"
    />
  </div>
);
