import { Toaster } from "@blueprintjs/core";
import { store } from "..";
import { addToastSuccess } from "../redux/actions/common/toast";

// pause execution for a given time
export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// copy the given string to the clipboard
export const copyToClipboard = (str, message = null) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  if (message) {
    store.dispatch(addToastSuccess(message));
  }
};

// check any content (body in GET, query params in POST)
// before uploading, if it contains invalid urls
export const checkTestUrl = (params) => {
  const isLive = process.env.REACT_APP_CMS_ENV === "live";
  if (isLive && params && JSON.stringify(params).includes(".develops.hu"))
    throw new Error("A feltölteni kívánt tartalom develops.hu linket tartalmaz!");
};

/**
 * Creates a slug from a given string.
 */
export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáãäâèéëêìíïîòóöőôùúüűûñç·/_,:;";
  var to = "aaaaaeeeeiiiiooooouuuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

/**
 * Shows a standalone (success by default) toast message.
 * An object with `message` key has to be provided as props.
 */
const toaster = Toaster.create({ position: "bottom" });
export const showToast = (props) => {
  toaster.show({ intent: "success", ...props });
};
