import { isAuthorized } from "../../utils/auth";
import { getRouteDataFromRoute } from "../../utils/routes";
import React from "react";

export const CheckRoles = ({ route, children }) => {
  if (!route || !isAuthorized(getRouteDataFromRoute(route))) {
    return null;
  }

  return <>{children}</>;
};
