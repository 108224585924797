import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneItem, updateItem } from "../../../redux/actions/ikk/menu";
import { ROUTES } from "../../../utils/routes";
import MenuItemForm from "./MenuItemForm";
import { generatePath } from "react-router-dom";

const MenuItemEdit = (props) => {
  const { id } = useParams();
  const [item, setItem] = useState();

  useEffect(() => {
    async function getData() {
      const res = await props.getOneItem(id);
      setItem({ ...res });
    }
    if (id) getData(id);
  }, [id]);

  const handleEditSubmit = async (formstate, setFormstate) => {
    const body = { ...formstate };
    const res = await props.updateItem(id, body);
    console.log(res);
    if (res)
      props.history.push(
        generatePath(ROUTES.menuItem, {
          id: formstate.category_id,
        })
      );
  };

  if (!item) return <Spinner />;

  return (
    <MenuItemForm
      initialState={item}
      submitBtnText="Módosít"
      title="Menü item szerkesztése"
      handleSubmit={handleEditSubmit}
    />
  );
};

export default connect(null, { updateItem, getOneItem })(MenuItemEdit);
