import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getOneEvent,
  updateSKILLSCOM_EVENT,
} from "../../../redux/actions/skillscom/event";
import { ROUTES } from "../../../utils/routes";
import EventForm from "./EventForm";

const EventEdit = (props) => {
  const [event, setEvent] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (id) fetchEvent(id);
  }, [id]);

  async function fetchEvent(id) {
    const ev = await props.getOneEvent(id);
    setEvent(ev);
  }

  const handleEditSubmit = async (state) => {
    const realTags = [];
    if (state.hasOwnProperty("tags")) {
      for (const tag of state.tags) {
        if (Number.isInteger(tag)) {
          realTags.push(tag);
        } else if (tag?.hasOwnProperty("id")) {
          realTags.push(tag.id);
        }
      }
    }
    const res = await props.updateSKILLSCOM_EVENT(event.id, {
      ...state,
      tags: realTags,
    });
    console.log("res:", res);
    if (res) props.history.push(ROUTES.skillsComEvents);
  };

  if (!event) return <Spinner />;

  return (
    <EventForm
      initialState={event}
      submitBtnText="Módosít"
      title="Esemény módosítása"
      handleSubmit={handleEditSubmit}
    />
  );
};

export default connect(null, { getOneEvent, updateSKILLSCOM_EVENT })(EventEdit);
