import {
  Button,
  Checkbox,
  FormGroup,
  Icon,
  InputGroup,
  TextArea,
} from "@blueprintjs/core";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import "moment/locale/hu";
import React, { useState } from "react";
import { isIKK } from "../../../utils/auth";
import CKEditorUploadAdapter from "../../../utils/CKEditorUploadAdapter";
import { LIMIT } from "../../../utils/limits";
import { API_BASE_IKK } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import TagsMultiSelect from "../../common/TagsMultiSelect";

const NewsForm = ({ initialState, title, submitBtnText, onSubmit, imageRequired }) => {
  const [state, setState] = useState({ ...initialState });
  const [step, setStep] = useState(0);

  const handleInput = (e) => {
    if (e.target.type === "checkbox") {
      setState((s) => ({ ...s, [e.target.name]: e.target.checked }));
    } else {
      const { name, value } = e.target;
      setState((s) => ({ ...s, [name]: value }));
    }
  };

  const handleInputDate = (name) => (date, isUser) => {
    setState((s) => ({ ...s, [name]: date ? date.toISOString() : null }));
  };

  const handleCoverUpload = (media) => {
    setState((s) => ({ ...s, coverImgId: media.id }));
  };

  const handleTagsChange = (newTags) => {
    console.log("handleTagsChange", newTags);
    const tagIds = newTags.map((t) => t.id);
    setState((s) => ({ ...s, tags: [...tagIds] }));
  };

  return (
    <div style={{ maxWidth: "800px" }}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="manual" />
        &nbsp;&nbsp;<span>{title}</span>
      </h2>

      <div style={{ display: step === 1 ? "none" : "" }}>
        <FormGroup
          label="Cím"
          labelInfo="(kötelező)"
          helperText={`${state.title?.length} / ${LIMIT.NEWS_TITLE_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <InputGroup
            placeholder="Hír címe..."
            required
            value={state.title}
            onChange={handleInput}
            name="title"
            intent={state.title.length > LIMIT.EVENT_TITLE_MAX_LEN ? "danger" : ""}
          />
        </FormGroup>

        <FormGroup
          label="Összefoglaló"
          labelInfo="(kötelező)"
          helperText={`${state.lead?.length} / ${LIMIT.NEWS_LEAD_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <TextArea
            fill
            growVertically
            placeholder="Rövid összefoglaló..."
            style={{ resize: "none" }}
            name="lead"
            value={state.lead}
            onChange={handleInput}
            intent={state.lead.length > LIMIT.NEWS_LEAD_MAX_LEN ? "danger" : ""}
          />
        </FormGroup>

        <FormGroup
          label="Tagek"
          helperText="Az itt felvitt összerendelések szerint lesz látható a hír a szakmakártyák oldalain. A listaban csak a mar korabban a Tagek menuben felvitt tagek lathatoak."
        >
          <TagsMultiSelect initialTags={state.tags} onChange={handleTagsChange} />
        </FormGroup>

        <FormGroup
          label="Borítókép"
          labelInfo={imageRequired && "(kötelező)"}
          helperText="Engedélyezett formátumok: jpg, png, webp."
        >
          <FileUpload onUpload={handleCoverUpload} isRequired={imageRequired} />
        </FormGroup>

        <FormGroup>
          {state.coverImgId ? (
            <img src={`${API_BASE_IKK}/media/${state.coverImgId}/sm`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        {!isIKK() && (
          <FormGroup label="Push küldése" helperText="Küldjön push értesítést?">
            <Checkbox onChange={handleInput} name="sendPush" checked={state.sendPush}>
              Értesítés küldése
            </Checkbox>
          </FormGroup>
        )}

        <br />

        <Button
          onClick={() => setStep(1)}
          text="Tovább"
          intent="primary"
          rightIcon="chevron-right"
        />
      </div>
      <div style={{ display: step === 0 && "none" }}>
        <CKEditor
          editor={ClassicEditor}
          data={state.body}
          onInit={(editor) => {
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              );
            editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
              return new CKEditorUploadAdapter(loader);
            };
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setState((s) => ({ ...s, body: data }));
          }}
          config={{
            image: {
              styles: ["full", "alignRight", "alignLeft"],
              toolbar: [
                "imageStyle:full",
                "imageStyle:alignRight",
                "imageStyle:alignLeft",
                "|",
                "imageTextAlternative",
              ],
            },
            mediaEmbed: {
              previewsInData: true,
            },
            link: {
              decorators: {
                isExternal: {
                  mode: "manual",
                  label: "Új oldalon nyílik meg",
                  attributes: {
                    target: "_blank",
                  },
                },
              },
            },
          }}
        />

        <br />
        <Button
          text="Vissza"
          icon="chevron-left"
          style={{ marginRight: "20px" }}
          onClick={() => setStep(0)}
        />
        <Button
          intent="success"
          text={submitBtnText}
          icon="floppy-disk"
          onClick={() => onSubmit(state, setState)}
        />
      </div>
      <br />
    </div>
  );
};

export default NewsForm;
