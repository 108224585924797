import React from "react";
import DocsForm from "./DocsForm";
import { connect } from "react-redux";
import { createDocument } from "../../../redux/actions/ikk/documents";
import { ROUTES } from "../../../utils/routes";

const initialState = {
  title: "",
  subtitle: "",
  description: "",
  btn_label: "",
  order: "",
  media_id: "",
  featured: false,
  page: "ikk",
};

const DocsCreate = (props) => {
  // submit button pressed on create form
  const handleSubmit = async (state, setState) => {
    await props.createDocument(state);
    setState(initialState);
    props.history.push(ROUTES.documents);
  };

  return (
    <DocsForm
      title="Uj dokumentum letrehozasa"
      submitBtnText="Letrehoz"
      initialState={initialState}
      handleSubmit={handleSubmit}
    />
  );
};

export default connect(null, { createDocument })(DocsCreate);
