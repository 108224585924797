import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  Spinner,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getImages, updateItem } from "../../redux/actions/ikk/constPageData";
import FileUpload from "../common/FileUpload";

const uploadDefaultText = "Válasszon egy képet...";

const StaticImages = (props) => {
  const [page, setPage] = useState("home");
  const [item, setItem] = useState(null); // selected static image
  const [uploadedMedia, setUploadedMedia] = useState(); // response from media upload

  useEffect(() => {
    props.getImages(page);
  }, [page]);

  const handleUpload = (media) => {
    setUploadedMedia(media);
  };

  const handleDialogClose = () => {
    setItem(null);
  };

  const handleSave = async () => {
    const res = await props.updateItem(
      page,
      item.id,
      item.value,
      uploadedMedia.id
    );
  };

  return (
    <>
      <h2>Statikus képek</h2>
      <b>Szűrés: </b>
      <HTMLSelect
        onChange={(e) => setPage(e.target.value)}
        style={{ marginLeft: "10px" }}
      >
        <option value="home">Főoldal</option>
        <option value="contact">Kapcsolat</option>
        <option value="about">Rólunk</option>
      </HTMLSelect>

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        props.images.map((i) => (
          <div
            key={i.id}
            style={{ marginTop: "20px", display: "flex", alignItems: "center" }}
          >
            <a
              href={i.media?.uri.find((u) => u.size === "xl").uri}
              target="_blank"
            >
              <img
                style={{
                  border: "1px solid lightgrey",
                  cursor: "pointer",
                }}
                src={i.media?.uri.find((u) => u.size === "xs").uri}
                alt="Nincs kép feltöltve"
              />
            </a>

            <div style={{ marginLeft: "20px" }}>
              <b>ID:</b> {`${i.id} (${i.media ? i.media.id : "-"})`}
              <div>
                <b>Kulcs:</b> {i.key}
              </div>
              <p>
                <b>Utoljára módosítva:</b>{" "}
                {new Date(i.updated_at).toLocaleString()}
              </p>
              <Button text="Csere" onClick={() => setItem(i)} />
            </div>
          </div>
        ))
      )}

      <Dialog
        onClose={handleDialogClose}
        title="Kép cseréje"
        usePortal={false}
        isOpen={item}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup helperText="Engedélyezett fájl kiterjesztések: jpg, png, webp.">
            <FileUpload onUpload={handleUpload} isRequired={true} />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Mégse" onClick={handleDialogClose} />

            <Button
              text="Mentés"
              intent="success"
              icon="floppy-disk"
              onClick={handleSave}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const { images, loading } = state.constPageData;
  return { images, loading };
};

export default connect(mapStateToProps, {
  getImages,
  updateItem,
})(StaticImages);
