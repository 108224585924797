import {
  Alignment,
  Button,
  Classes,
  Menu,
  MenuItem,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Popover,
  Position,
} from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { getUser, isAuthorizedForIKK, logout } from "../../utils/auth";
import { ROUTES } from "../../utils/routes";
import { CheckRoles } from "./CheckRoles";
import ThemeSwitcher from "./ThemeSwitcher";

export const TopNavbar = () => {
  return (
    <>
      <Navbar fixedToTop={true} className="menubar">
        <NavbarGroup>
          <Link to={ROUTES.home} title="Kezdőoldalra">
            <NavbarHeading>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src="/logo.svg" alt="" height="30" />
                <b style={{ marginLeft: 10 }}>IKK Admin</b>
              </div>
            </NavbarHeading>
          </Link>

          <NavbarDivider />

          {isAuthorizedForIKK() && (
            <Link to={ROUTES.ikk}>
              <Button className={Classes.MINIMAL} icon="home" text="IKK" />
            </Link>
          )}

          <CheckRoles route={ROUTES.szakkepzes}>
            <Link to={ROUTES.szakkepzes}>
              <Button
                className={Classes.MINIMAL}
                icon="document"
                text="Szakképzés"
              />
            </Link>
          </CheckRoles>

          <CheckRoles route={ROUTES.szakkepesites}>
            <Link to={ROUTES.szakkepesites}>
              <Button
                className={Classes.MINIMAL}
                icon="document"
                text="Szakképesítés"
              />
            </Link>
          </CheckRoles>

          <CheckRoles route={ROUTES.mediaFiles}>
            <Link to={ROUTES.mediaFiles}>
              <Button className={Classes.MINIMAL} icon="media" text="Média" />
            </Link>
          </CheckRoles>

          <CheckRoles route={ROUTES.auth}>
            <Link to={ROUTES.auth}>
              <Button
                className={Classes.MINIMAL}
                icon="key"
                text="Jogosultságok"
              />
            </Link>
          </CheckRoles>

          <CheckRoles route={ROUTES.skillscom}>
            <Link to={ROUTES.skillscom}>
              <Button
                className={Classes.MINIMAL}
                icon="build"
                text="SkillsCom"
              />
            </Link>
          </CheckRoles>

          <CheckRoles route={ROUTES.examcenterCandidates}>
            <Link to={ROUTES.examcenterCandidates}>
              <Button
                className={Classes.MINIMAL}
                icon="lab-test"
                text="Vizsgaközpont"
              />
            </Link>
          </CheckRoles>

          <CheckRoles route={ROUTES.edirSzcAll}>
            <Link to={ROUTES.edirSzcAll}>
              <Button className={Classes.MINIMAL} icon="database" text="EDIR" />
            </Link>
          </CheckRoles>

          <CheckRoles route={ROUTES.project_6110}>
            <Link to={ROUTES.project_6110}>
              <Button className={Classes.MINIMAL} icon="database" text="6110" />
            </Link>
          </CheckRoles>
        </NavbarGroup>

        <NavbarGroup align={Alignment.RIGHT}>
          <ThemeSwitcher />

          <Popover
            position={Position.BOTTOM_RIGHT}
            content={
              <Menu>
                <MenuItem
                  icon="log-out"
                  text="Kilépés"
                  onClick={() => logout()}
                />
              </Menu>
            }
          >
            <Button
              className={Classes.MINIMAL}
              icon="user"
              text={getUser()?.email}
            />
          </Popover>
        </NavbarGroup>
      </Navbar>
    </>
  );
};
