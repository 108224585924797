import React from "react";
import { connect } from "react-redux";
import {
  getAllSpecialisations6110,
  createSpecialisationDocument6110,
} from "../../../redux/6110/rk";
import { ROUTES } from "../../../utils/routes";
import { useEffect } from "react";
import SpecialisationForm from "./SpecialisationForm";

// initial empty state for create form
const emptyState = {
  qualificationId: null,
  mediaId: null,
  fileName: "",
  version: null,
  publishDate: new Date().toISOString().split("T")[0],
};

const SpecialisationCreate6110 = (props) => {
  useEffect(() => {
    props.getAllSpecialisations6110();
  }, []);

  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const data = await props.createSpecialisationDocument6110(
      state.KEORSpecializationId,
      state
    );
    // reset form if submit was successful
    if (data) {
      setState(emptyState);
      props.getAllSpecialisations6110();
      props.history.push(ROUTES.project_6110RK);
    }
  };

  return (
    <SpecialisationForm
      title="Szakirány dokumentum felvitele"
      initialState={emptyState}
      specialization={props.specialization}
      submitBtnText="Létrehoz"
      handleSubmit={handleCreateSubmit}
    />
  );
};

const mapStateToProps = (state) => {
  return { ...state.project6110.rk };
};

export default connect(mapStateToProps, {
  createSpecialisationDocument6110,
  getAllSpecialisations6110,
})(SpecialisationCreate6110);
