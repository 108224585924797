import http from "../../../utils/http";
import { API_BASE_SZAKKEPZES, API_ENDPOINT } from "../../../utils/routes";
import {
  SZAKKEPZES_LOCATIONS_GET_BEGIN,
  SZAKKEPZES_LOCATIONS_GET_FAILURE,
  SZAKKEPZES_LOCATIONS_GET_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";
import { getImagesFailure } from "../ikk/constPageData";

export const getLocations = () => async (dispatch) => {
  dispatch(getItemsBegin());
  try {
    const json = await http.get(API_BASE_SZAKKEPZES + API_ENDPOINT.LOCATION);
    dispatch(getItemsSuccess(json));
    return json;
  } catch (error) {
    dispatch(getImagesFailure(error));
    dispatch(addToastDanger(`Koordináták lekérése sikertelen ${error}`));
  }
};

export const modifyLocation = (id, body) => async (dispatch) => {
  // todo reducer
  try {
    const json = await http.post(
      `${API_BASE_SZAKKEPZES + API_ENDPOINT.LOCATION}/${id}`,
      body
    );
    dispatch(addToastSuccess("Koordináta módosítva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Koordináta módosítás sikertelen: ${error}`));
  }
};

export const refreshLocation = (id) => async (dispatch) => {
  // todo reducer
  try {
    const json = await http.post(
      `${API_BASE_SZAKKEPZES + API_ENDPOINT.LOCATION}/${id}/refresh`
    );
    dispatch(addToastSuccess("Koordináta frissítve"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Koordináta frissítése sikertelen: ${error}`));
  }
};

export const getItemsBegin = () => ({
  type: SZAKKEPZES_LOCATIONS_GET_BEGIN,
});
export const getItemsSuccess = (items) => ({
  type: SZAKKEPZES_LOCATIONS_GET_SUCCESS,
  payload: items,
});
export const getItemsFailure = (err) => ({
  type: SZAKKEPZES_LOCATIONS_GET_FAILURE,
  payload: { err },
});
