import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAllQualification6110,
  updateDocument6110,
  updateQualification6110,
} from "../../../redux/6110/pk";
import { ROUTES } from "../../../utils/routes";
import QualifForm from "./QualifForm";

const QualifEdit6110 = (props) => {
  const [pk, setPk] = useState();
  const { id } = useParams();

  useEffect(() => {
    props.getAllQualification6110();
  }, []);

  useEffect(() => {
    const f = props.qualifications
      .filter((qualif) => qualif.pks.length > 0)
      .map((qualif) => qualif.pks);

    let found;
    if (f) {
      for (let i = 0; i < f.length; i++) {
        for (let j = 0; j < f.length; j++) {
          if (f[i][j]?.id == Number(id)) {
            found = f[i][j];
          }
        }
      }
      setPk(found);
    }
  }, [props.qualifications]);

  const handleEditSubmit = async (state) => {
    const res = await props.updateDocument6110(pk.id, {
      fileName: state.fileName,
      publishDate: state.publishDate,
      archiveDate: state.archiveDate,
    });
    console.log(pk, res);
    if (res) props.history.push(ROUTES.project_6110PK);
  };

  if (!pk) return <Spinner />;

  return (
    <>
      <QualifForm
        title="Dokumentum szerkesztése"
        initialState={pk}
        submitBtnText="Mentés"
        handleSubmit={handleEditSubmit}
        edit={true}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.pk };
};
export default connect(mapStateToProps, {
  getAllQualification6110,
  updateDocument6110,
})(QualifEdit6110);
