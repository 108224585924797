import {
  Button,
  Divider,
  FormGroup,
  Icon,
  InputGroup,
  NumericInput,
  Position,
  Card,
  Checkbox,
} from "@blueprintjs/core";
import "moment/locale/hu";
import React, { useState } from "react";
import FileUpload from "../../common/FileUpload";
import { API_BASE_IKK, API_ENDPOINT } from "../../../utils/routes";
import { LIMIT } from "../../../utils/limits";
import MyCkEditor from "../../common/MyCkEditor";

const DocsForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setState((s) => ({ ...s, [name]: val }));
  };

  const handleNumericInput = (valNum, valStr, inputEl) => {
    setState((s) => ({ ...s, [inputEl.name]: valNum }));
  };

  const handleFileUpload = (media) => {
    setState((s) => ({ ...s, media_id: media.id }));
  };

  return (
    <div style={{ maxWidth: "800px", marginBottom: 100 }}>
      <h2>{props.title}</h2>

      <form onSubmit={handleSubmit}>
        <FormGroup
          label="Cím"
          labelInfo="(kötelező)"
          helperText={`${state.title?.length} / ${LIMIT.DOCUMENT_TITLE_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <InputGroup
            required
            placeholder="Cím..."
            onChange={handleInput}
            name="title"
            value={state?.title}
            intent={
              state.title?.length > LIMIT.DOCUMENT_TITLE_MAX_LEN ? "danger" : ""
            }
          />
        </FormGroup>

        <FormGroup
          label="Alcím"
          labelInfo="(kötelező)"
          helperText={`${state.subtitle?.length} / ${LIMIT.DOCUMENT_SUBTITLE_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <InputGroup
            required
            onChange={handleInput}
            placeholder="Alcím..."
            name="subtitle"
            value={state.subtitle}
            intent={
              state.subtitle?.length > LIMIT.DOCUMENT_SUBTITLE_MAX_LEN
                ? "danger"
                : ""
            }
          />
        </FormGroup>

        <FormGroup
          label="Szöveg"
          labelInfo="(kötelező)"
          helperText={`${state.description?.length} / ${LIMIT.DOCUMENT_TEXT_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <MyCkEditor
            data={state.description}
            onDataChange={(data) =>
              setState((s) => ({ ...s, description: data }))
            }
          />
        </FormGroup>

        <FormGroup
          label="Gomb felirat"
          helperText={`${state.btnLabel?.length} / ${LIMIT.DOCUMENT_BUTTON_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <InputGroup
            fill
            value={state.btn_label}
            onChange={handleInput}
            name="btn_label"
            intent={
              state.btnLabel?.length > LIMIT.DOCUMENT_BUTTON_MAX_LEN
                ? "danger"
                : ""
            }
          />
        </FormGroup>
        <FormGroup
          label="Kategória"
          labelInfo="(kötelező)"
          helperText={`${state.category?.length} / ${LIMIT.DOCUMENT_TITLE_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <InputGroup
            required
            placeholder="Kategória..."
            onChange={handleInput}
            name="category"
            value={state.category}
            intent={
              state.category?.length > LIMIT.DOCUMENT_TITLE_MAX_LEN
                ? "danger"
                : ""
            }
          />
        </FormGroup>
        <Card>
          <FormGroup label="Dokumentum">
            <FileUpload
              isReqired={props.fileRequired}
              onUpload={handleFileUpload}
            />
            <br />
            {state.media_id ? (
              <a
                href={`${API_BASE_IKK + API_ENDPOINT.MEDIA}/${state.media_id}`}
                target="_blank"
              >
                {`${API_BASE_IKK + API_ENDPOINT.MEDIA}/${state.media_id}`}
              </a>
            ) : (
              <p>Nincs fajl feltoltve</p>
            )}
          </FormGroup>
        </Card>
        <br />

        <FormGroup label="Sorrend" labelInfo="(kötelező)">
          <NumericInput
            required
            onValueChange={handleNumericInput}
            name="order"
            value={state.order}
          />
        </FormGroup>
        <FormGroup
          label="Oldal"
          labelInfo="(kötelező)"
          contentClassName="formHelperRight"
        >
          <select onChange={handleInput} name="page">
            <option value="ikk" selected={state.page === "ikk"}>
              IKK.hu
            </option>
            <option value="6110" selected={state.category_id === "6110"}>
              6110
            </option>
          </select>
        </FormGroup>
        <Checkbox
          label="Kiemelt"
          name="featured"
          checked={state.featured}
          onChange={handleInput}
        />

        <br />
        <Button
          text={props.submitBtnText}
          icon="floppy-disk"
          intent="success"
          type="submit"
        />
      </form>
    </div>
  );
};

export default DocsForm;
