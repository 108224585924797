import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getOneCategory,
  updateCategory,
} from "../../../redux/actions/ikk/menu";
import { ROUTES } from "../../../utils/routes";
import MenuCategoryForm from "./MenuCategoryForm";

const MenuCategoryEdit = (props) => {
  const { id } = useParams();
  const [category, setCategory] = useState();

  useEffect(() => {
    async function getData() {
      const res = await props.getOneCategory(id);
      setCategory({ ...res });
    }
    if (id) getData(id);
  }, [id]);

  const handleEditSubmit = async (formstate, setFormstate) => {
    const body = { ...formstate };
    const res = await props.updateCategory(id, body);
    console.log(res);
    if (res) props.history.push(ROUTES.menuCategory);
  };

  if (!category) return <Spinner />;

  return (
    <MenuCategoryForm
      initialState={category}
      submitBtnText="Módosít"
      title="Menü kategória szerkesztése"
      handleSubmit={handleEditSubmit}
    />
  );
};

export default connect(null, { updateCategory, getOneCategory })(
  MenuCategoryEdit
);
