import { LayoutAuth } from "../components/auth/LayoutAuth";
import Users from "../components/auth/UsersAll";
import UsersEdit from "../components/auth/UsersEdit";
import Home from "../components/common/Home";
import { NotFoundPage } from "../components/common/NotFound";
import InstituteAll from "../components/edir/institute/InstituteAll";
import InstituteCreate from "../components/edir/institute/InstituteCreate";
import InstituteEdit from "../components/edir/institute/InstituteEdit";
import { LayoutEDIR } from "../components/edir/common/LayoutEDIR";
import SZCAll from "../components/edir/szc/SZCAll";
import SZCCreate from "../components/edir/szc/SZCCreate";
import SZCEdit from "../components/edir/szc/SZCEdit";
import CandidateEdit from "../components/examcenter/candidates/CandidateEdit";
import CandidatesAll from "../components/examcenter/candidates/CandidatesAll";
import ExamCreate from "../components/examcenter/exams/ExamCreate";
import ExamEdit from "../components/examcenter/exams/ExamEdit";
import ExamsAll from "../components/examcenter/exams/ExamsAll";
import { LayoutExamCenter } from "../components/examcenter/LayoutExamCenter";
import ExamPartnerAll from "../components/examcenter/partners/PartnerAll";
import ExamPartnerCreate from "../components/examcenter/partners/PartnerCreate";
import ExamPartnerEdit from "../components/examcenter/partners/PartnerEdit";
import AboutRowCreate from "../components/ikk/AboutRows/AboutRowCreate";
import AboutRowEdit from "../components/ikk/AboutRows/AboutRowEdit";
import AllAboutRow from "../components/ikk/AboutRows/AllAboutRow";
import ArchiveAll from "../components/ikk/Archive/ArchiveAll";
import ArchiveCreate from "../components/ikk/Archive/ArchiveCreate";

import { PopupAll } from "../components/ikk/Popup/PopupAll";
import { PopupCreate } from "../components/ikk/Popup/PopupCreate";
import { PopupEdit } from "../components/ikk/Popup/PopupEdit";

import CareerCreate from "../components/ikk/Career/CareerCreate";
import CareerEdit from "../components/ikk/Career/CareerEdit";
import CareerAll from "../components/ikk/Career/CareerAll";

import CentrumCreate from "../components/ikk/Centrums/CentrumCreate";
import CentrumEdit from "../components/ikk/Centrums/CentrumEdit";
import CentrumsAll from "../components/ikk/Centrums/CentrumsAll";
import DocsAll from "../components/ikk/Docs/DocsAll";
import DocsCreate from "../components/ikk/Docs/DocsCreate";
import DocsEdit from "../components/ikk/Docs/DocsEdit";
import EventsAll from "../components/ikk/Events/EventAll";
import EventsCreate from "../components/ikk/Events/EventCreate";
import EventEdit from "../components/ikk/Events/EventEdit";
import IkkIndex from "../components/ikk/IkkIndex";
import { LayoutIkk } from "../components/ikk/LayoutIkk/LayoutIkk";
import NewsAll from "../components/ikk/News/NewsAll";
import NewsCreate from "../components/ikk/News/NewsCreate";
import NewsEdit from "../components/ikk/News/NewsEdit";
import AllProject from "../components/ikk/Projects/AllProject";
import ProjectCreate from "../components/ikk/Projects/ProjectCreate";
import ProjectEdit from "../components/ikk/Projects/ProjectEdit";
import PublicInfoAll from "../components/ikk/PublicInfo/PublicInfoAll2";
import PublicInfoCreate from "../components/ikk/PublicInfo/PublicInfoCreate";
import SlidesCreate from "../components/ikk/Slides/SlideCreate";
import SlideEdit from "../components/ikk/Slides/SlideEdit";
import SlidesAll from "../components/ikk/Slides/SlidesAll";
import StaticImages from "../components/ikk/StaticImages";
import StaticTexts from "../components/ikk/StaticTexts";
import TagsAll from "../components/ikk/Tags/TagsAll";
import TagsCreate from "../components/ikk/Tags/TagsCreate";
import TagsEdit from "../components/ikk/Tags/TagsEdit";
import { LayoutMedia } from "../components/mediastore/LayoutMedia";
import MediaFiles from "../components/mediastore/MediaFiles";
import {
  default as MediaImages,
  default as MediaStore,
} from "../components/mediastore/MediaImages";
import SkillsComEditorsAll from "../components/skillsCom/Editors/EditorAll";
import SkillsComEditorsCreate from "../components/skillsCom/Editors/EditorCreate";
import SkillsComEventsAll from "../components/skillsCom/Events/EventAll";
import SkillsComEventsCreate from "../components/skillsCom/Events/EventCreate";
import SkillsComEventsEdit from "../components/skillsCom/Events/EventEdit";
import { LayoutSkillsCom } from "../components/skillsCom/LayoutSkillsCom";
import SkillsComNewsAll from "../components/skillsCom/News/NewsAll";
import SkillsComNewsCreate from "../components/skillsCom/News/NewsCreate";
import SkillsComNewsEdit from "../components/skillsCom/News/NewsEdit";
import SkillsComPostsAll from "../components/skillsCom/Posts/PostsAll";
import SkillsComPostsCreate from "../components/skillsCom/Posts/PostsCreate";
import SkillsComPostsEdit from "../components/skillsCom/Posts/PostsEdit";
import SkillsComCompaniesAll from "../components/skillsCom/Registrations/CompanyAll";
import SkillsComInstitutesAll from "../components/skillsCom/Registrations/InstituteAll";
import SkillsIndex from "../components/skillsCom/SkillsIndex";
import { LayoutSzakkepesites } from "../components/szakkepesites/common/LayoutSzakkepesites";
import AllQualifications from "../components/szakkepesites/pk/AllQualifications";
import QualifDocumentCreate from "../components/szakkepesites/pk/DocumentCreate";
import QualifCreate from "../components/szakkepesites/pk/QualifCreate";
import QualifEdit from "../components/szakkepesites/pk/QualifEdit";
import SzakkepesitesIndex from "../components/szakkepesites/common/SzakkepesitesIndex";
import ExcelExport from "../components/szakkepzes/ExcelExport";
import ExcelImport from "../components/szakkepzes/ExcelImport";
import FaqAll from "../components/szakkepzes/Faq/FaqAll";
import FaqCategoryAll from "../components/szakkepzes/Faq/FaqCategoryAll";
import FaqCategoryCreate from "../components/szakkepzes/Faq/FaqCategoryCreate";
import FaqCategoryEdit from "../components/szakkepzes/Faq/FaqCategoryEdit";
import FaqCreate from "../components/szakkepzes/Faq/FaqCreate";
import FaqEdit from "../components/szakkepzes/Faq/FaqEdit";
import KKK_PTT from "../components/szakkepzes/KKK/KKK";
import KKKCreate from "../components/szakkepzes/KKK/KKKCreate";
import { LayoutSzakkepzes } from "../components/szakkepzes/LayoutSzakkepzes/LayoutSzakkepzes";
import Locations from "../components/szakkepzes/Locations";
import SzakkepzesNotifications from "../components/szakkepzes/Notifications";
import SzakkepzesIndex from "../components/szakkepzes/SzakkepzesIndex";
import ProfessionCardsQualifAll from "../components/szakkepzes/szakmakartyak/ProfessionCardsQualifAll";
import ProfessionCardsQualifEdit from "../components/szakkepzes/szakmakartyak/ProfessionCardsQualifEdit";
import ProfessionCardsSectorsAll from "../components/szakkepzes/szakmakartyak/ProfessionCardsSectorsAll";
import ProfessionCardsSectorsEdit from "../components/szakkepzes/szakmakartyak/ProfessionCardsSectorsEdit";
import AllPKCard from "../components/szakkepesites/pk-card/PkCardAll";
import PkCardCreate from "../components/szakkepesites/pk-card/PkCardCreate";
import PkCardEdit from "../components/szakkepesites/pk-card/PkCardEdit";
import SzakkepesitesStaticTexts from "../components/szakkepesites/SzakkepesitesStaticTexts";
import Stats from "../components/skillsCom/stats";
import JobsCreate from "../components/skillsCom/Jobs/JobsCreate";
import JobsEdit from "../components/skillsCom/Jobs/JobsEdit";
import JobsAll from "../components/skillsCom/Jobs/JobsAll";
import JobApplicants from "../components/skillsCom/Jobs/JobApplicants";
import { ScholarshipTemplatesAll } from "../components/ikk/scholarship/ScholarshipTemplateAll";
import { ScholarshipTemplateCreate } from "../components/ikk/scholarship/ScholarshipTemplateCreate";
import { ScholarshipTemplateEdit } from "../components/ikk/scholarship/ScholarshipTemplateEdit";
import { ScholarshipApplicantsAll } from "../components/ikk/scholarship/ScholarshipAllpicantsAll";
import { ScholarshipApplicantEdit } from "../components/ikk/scholarship/ScholarshipApplicantEdit";
import MenuCategoryAll from "../components/ikk/Menu/MenuCategoryAll";
import MenuCategoryEdit from "../components/ikk/Menu/MenuCategoryEdit";
import MenuItemCreate from "../components/ikk/Menu/MenuItemCreate";
import MenuItemAll from "../components/ikk/Menu/MenuItemAll";
import MenuItemEdit from "../components/ikk/Menu/MenuItemEdit";

import PagesCreate from "../components/ikk/Pages/PagesCreate";
import PagesAll from "../components/ikk/Pages/PagesAll";
import PagesEdit from "../components/ikk/Pages/PagesEdit";
import { Project6110Layout } from "../components/6110/LayoutProject6110";
import { Project6110Index } from "../components/6110";
import AllQualifications6110 from "../components/6110/pk/AllQualifications";
import DocumentCreate6110 from "../components/6110/pk/DocumentCreate";
import QualifEdit6110 from "../components/6110/pk/QualifEdit";
import AllKKK6110 from "../components/6110/kkk/AllKKK";
import KKKCreate6110 from "../components/6110/kkk/KKKCreate";
import AllSpecialisations from "../components/6110/rk/AllSpecialisations";
import SpecialisationCreate from "../components/6110/rk/SpecialisationCreate";
import ExamCenterCreate from "../components/6110/ExamCenter/ExamCenterCreate";
import ExamCenterAll from "../components/6110/ExamCenter/ExamCenterAll";
import ExamCenterEdit from "../components/6110/ExamCenter/ExamCenterEdit";
import StaticTexts6110 from "../components/6110/StaticTexts6110";
import GroupsAll from "../components/ikk/Group/GroupsAll";
import GroupCreate from "../components/ikk/Group/GroupCreate";
import CategoriesAll from "../components/ikk/Category/CategoriesAll";
import CategoriesCreate from "../components/ikk/Category/CategoriesCreate";
import CategoriesEdit from "../components/ikk/Category/CategoriesEdit";
import GroupsEdit from "../components/ikk/Group/GroupsEdit";

// API endpoints
export const API_ENDPOINT = {
  // COMMON API ENDPOINTS
  LOGIN: "/login",
  MEDIA: "/media",

  // IKK API ENDPOINTS
  UPLOAD: "/upload",
  NEWS: "/news",
  EVENTS: "/events",
  TAGS: "/tags",
  GROUPS: "/groups",
  CATEGORIES: "/categories",
  SLIDES: "/carousel",
  CONST_DATA: "/const_data",
  PUBLIC_INFO_CATEGORY: "/public_info/cat",
  PUBLIC_INFO_CATEGORY_POSTION: "/public_info/cat/pos",
  PUBLIC_INFO_ITEM_POSTION: "/public_info/item/pos",
  PUBLIC_INFO_ITEMS: "/public_info/item",
  TRAINING_CENTER: "/training_centers",
  ABOUT: "/about",
  PROJECT: "/project",
  CAREERS: "/career",
  POPUP: "/popup",
  MENU: "/sections",
  EXAM_CENTER: "/exam_center",
  PAGE: "/dynamic_page",

  // SZAKKEPZES API ENDPOINTS
  SZAKKEPZES_NOTIFICATIONS: "/notifications",
  SZAKKEPZES_EXPORT: "/export",
  SZAKKEPZES_INSTITUTES_BULK: "/institutes/bulk",
  SZAKKEPZES_QUALIFICATIONS_CONNECTIONS: "/qualifications/connections",
  LOCATION: "/location",
  KKK: "/profession/attachments",
  FAQ: "/faq/answer",
  FAQ_CATEGORY: "/faq/category",
  DOCUMENTS: "/documents", // downloadable docs on IKK homepage
  SZAKMAKARTYAK_SECTORS: "/sectors",
  SZAKMAKARTYAK_QUALIFICATIONS: "/professions",

  // SZAKKEPESITES API ENDPOINTS
  SZAKKEPESITES_QUALIFICATIONS: "/cms/qualification",
  SZAKKEPESITES_QUALIFICATIONS_DOCUMENT: "/cms/qualification/document",
  SZAKKEPESITES_DOCUMENT: "/cms/document",
  SZAKKEPESITES_CMS_KEOR: "/cms/keor",
  SZAKKEPESITES_KEOR: "/keor",
  SZAKKEPESITES_SPECIALISATION: "/v2/cms/specialization",
  SZAKKEPESITES_SPECIALISATION_DOC: "/v2/cms/specialization/document",
};

// API base without /admin - GET requests
export const API_BASE_IKK = process.env.REACT_APP_IKK_API;
export const API_BASE_SZAKKEPZES = process.env.REACT_APP_SZAKKEPZES_API;
export const API_BASE_SZAKKEPESITES = process.env.REACT_APP_SZAKKEPESITES_API;
export const API_BASE_PROFCARD = process.env.REACT_APP_PROFCARD_API;
export const API_BASE_SKILLSCOM = process.env.REACT_APP_SKILLSCOM_API;
export const API_BASE_EXAM_CENTER = process.env.REACT_APP_EXAM_CENTER_API;
export const API_BASE_CEAM = process.env.REACT_APP_CEAM_API_BASE;
export const API_BASE_QUALIFCARD = process.env.REACT_APP_QUALIFCARD_API;
export const API_BASE_SCHOLARSHIP = process.env.REACT_APP_SCHOLARSHIP_API;

// API base with /admin - POST, PUT, DELETE requests
export const API_BASE_IKK_ADMIN = `${API_BASE_IKK}/admin`;
export const API_BASE_SZAKKEPZES_ADMIN = `${API_BASE_SZAKKEPZES}/admin`;
export const API_BASE_SZAKKEPESITES_ADMIN = `${API_BASE_SZAKKEPESITES}/admin`;
export const API_BASE_PROFCARD_ADMIN = `${API_BASE_PROFCARD}/admin`;

// Frontend urls
export const IKK_URL = process.env.REACT_APP_IKK_URL;
export const SZAKKEPZES_URL = process.env.REACT_APP_SZAKKEPZES_URL;
export const SZAKKEPESITES_URL = process.env.REACT_APP_SZAKKEPESITES_URL;
export const EXAM_CENTER_URL = process.env.REACT_APP_EXAM_CENTER_URL;
export const SKILLSCOM_URL = process.env.REACT_APP_SKILLSCOM_URL;

// PROVIDER URLS
export const API_AUTH_PROVIDER = process.env.REACT_APP_API_AUTH_PROVIDER;

// PROJECT 6110
export const API_BASE_SZAKKEPESITES_6110 = process.env.REACT_APP_SZAKKEPESITES_BASE_API;
export const API_BASE_SZAKKEPZES_6110 = process.env.REACT_APP_SZAKKEPZES_BASE_API;

export const getRouteDataFromRoute = (route) => {
  return routesData.find((r) => r.path === route);
};
// React-router routes
export const ROUTES = {
  home: "/",
  login: "/login",
  ikk: "/ikk",
  szakkepzes: "/szakkepzes",
  szakkepesites: "/szakkepesites",
  skillscom: "/skillscom",

  news: "/news",
  newsCreate: "/news/create",
  newsEdit: "/news/edit/:id",

  tags: "/tags",
  tagsCreate: "/tags/create",
  tagsEdit: "/tags/edit/:id",

  groups: "/groups",
  groupsCreate: "/groups/create",
  groupsEdit: "/groups/edit/:id",

  categories: "/categories",
  categoriesCreate: "/categories/create",
  categoriesEdit: "/categories/edit/:id",

  faq: "/faq",
  faqCreate: "/faq/create",
  faqEdit: "/faq/edit/:id",
  faqCategory: "/faq/category",
  faqCategoryCreate: "/faq/category/create",
  faqCategoryEdit: "/faq/category/edit/:id",

  events: "/events",
  eventsCreate: "/events/create",
  eventsEdit: "/events/edit/:id",

  slides: "/slides",
  slidesCreate: "/slides/create",
  slidesEdit: "/slides/edit/:id",

  centrums: "/centrums",
  centrumsCreate: "/centrums/create",
  centrumsEdit: "/centrums/edit/:id",

  staticTexts: "/static-texts",
  staticImages: "/static-images",

  publicInfo: "/public-info",
  publicInfoCreate: "/public-info/create",

  archive: "/archive",
  archiveCreate: "/archive/create",

  careers: "/careers",
  careerCreate: "/careers/create",
  careerEdit: "/career/:id/edit",

  popup: "/popup",
  popupCreate: "/popup/create",
  popupEdit: "/popup/:id/edit",

  scholarshipTemplates: "/scholarship-templates",
  scholarshipTemplateEdit: "/scholarship-templates/:id/edit",
  scholarshipTemplateCreate: "/scholarship-templates/create",
  scholarshipApplicants: "/scholarship-applicants",
  scholarshipApplicantEdit: "/scholarship-applicants/:id/edit",

  mediaFiles: "/media/files",
  mediaImages: "/media/images",
  mediaUpload: "/media/upload",

  menuCategory: "/menu/category",
  menuCategoryEdit: "/menu/category/:id/edit",
  menuItem: "/menu/category/:id/item",
  menuItemCreate: "/menu/item/create",
  menuItemEdit: "/menu/item/:id/edit",

  pages: "/pages",
  pagesCreate: "/pages/create",
  pagesEdit: "/pages/:id/edit",

  szakkepzesExport: "/szakkepzes/export",
  szakkepzesImport: "/szakkepzes/import",
  szakkepzesNotifications: "/szakkepzes/notifications",

  szakmakartyakSectors: "/profession-cards/sectors",
  szakmakartyakSectorsEdit: "/profession-cards/sectors/edit/:id",
  szakmakartyakQualifications: "/profession-cards/qualifications",
  szakmakartyakQualificationsEdit: "/profession-cards/qualifications/edit/:id",

  szakkepesitesQualif: "/szakkepesites/qualif",
  szakkepesitesQualifCreate: "/szakkepesites/qualif/create",
  szakkepesitesQualifDocCreate: "/szakkepesites/qualif/doc/create",
  szakkepesitesQualifEdit: "/szakkepesites/qualif/edit/:id",

  pkCards: "/szakkepesites/pk-cards",
  pkCardCreate: "/szakkepesites/pk-cards/create",
  pkCardEdit: "/szakkepesites/pk-cards/:id/edit",

  szakkepesitesStaticTexts: "/szakkepesites/static-texts",

  location: "/szakkepzes/location",

  kkk: "/kkk-ptt",
  kkkCreate: "/kkk-ptt/create",
  kkkEdit: "/kkk-ptt/edit/:id",

  documents: "/documents",
  documentsCreate: "/documents/create",
  documentsEdit: "/documents/edit/:id",

  aboutRows: "/about-rows",
  aboutRowsCreate: "/about-rows/create",
  aboutRowsEdit: "/about-rows/edit/:id",

  projects: "/projects",
  projectsCreate: "/projects/create",
  projectsEdit: "/projects/edit/:id",

  auth: "/auth/users",
  authEdit: "/auth/users/:id",

  skillsComNews: "/skillscom/news",
  skillsComNewsCreate: "/skillscom/news/create",
  skillsComNewsEdit: "/skillscom/news/:id/edit",
  skillsComEvents: "/skillscom/events",
  skillsComEventsEdit: "/skillscom/events/:id/edit",
  skillsComEventsCreate: "/skillscom/events/create",
  skillsComPosts: "/skillscom/posts",
  skillsComPostsCreate: "/skillscom/posts/create",
  skillsComPostsEdit: "/skillscom/posts/:id/edit",
  skillsComEditors: "/skillscom/editors",
  skillsComEditorsAdd: "/skillscom/editors/create",
  skillsComStats: "/skillscom/stats",
  skillsComCompanies: "/skillscom/companies",
  skillsComInstitutes: "/skillscom/institutes",
  skillsComJobs: "/skillscom/jobs",
  skillsComJobsCreate: "/skillscom/jobs/create",
  skillsComJobsEdit: "/skillscom/jobs/:id/edit",
  skillscomJobsApplicants: "/skillscom/jobs/:id/applicants",

  examcenterCandidates: "/examcenter/candidates",
  examcenterCandidateEdit: "/examcenter/candidates/edit/:id",
  examsAll: "/examcenter/exams",
  examEdit: "/examcenter/exams/:id/edit",
  examCreate: "/examcenter/exams/create",
  examPartners: "/examcenter/partners",
  examPartnerEdit: "/examcenter/partners/edit/:id",
  examPartnerCreate: "/examcenter/partners/create",

  edirSzcAll: "/edir/szc",
  edirSzcEdit: "/edir/szc/:id/edit",
  edirSzcCreate: "/edir/szc/create",

  edirInstituteAll: "/edir/institute",
  edirInstituteCreate: "/edir/institute/create",
  edirInstituteEdit: "/edir/institute/:id/edit",

  project_6110: "/6110",

  project_6110PK: "/6110/pk",
  project_6110PKCreate: "/6110/pk/create",
  project_6110PKEdit: "/6110/pk/:id/edit",

  project_6110KKK: "/6110/kkk",
  project_6110KKKCreate: "/6110/kkk/create",

  project_6110RK: "/6110/rk",
  project_6110RKCreate: "/6110/rk/create",

  project_6110examCenter: "/6110/akkreditalt-vizsgakozpontok",
  project_6110examCenterCreate: "/6110/akkreditalt-vizsgakozpontok/create",
  project_6110examCenterEdit: "/6110/akkreditalt-vizsgakozpontok/:id/edit",
  project_6110StaticTexts: "/6110/static-texts",
};

// React-router route config
export const routesData = [
  // IKK.hu

  {
    path: ROUTES.home,
    component: Home,
    exact: true,
  },
  {
    path: ROUTES.szakkepzes,
    component: SzakkepzesIndex,
    layout: LayoutSzakkepzes,
    exact: true,
    authNamespace: "profession",
  },
  {
    path: ROUTES.skillscom,
    component: SkillsIndex,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
  },
  {
    path: ROUTES.ikk,
    component: IkkIndex,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
  },
  {
    path: ROUTES.szakkepesites,
    component: SzakkepesitesIndex,
    layout: LayoutSzakkepesites,
    exact: true,
    authNamespace: "qualification",
  },
  {
    path: ROUTES.news,
    component: NewsAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "news:read",
  },
  {
    path: ROUTES.newsCreate,
    component: NewsCreate,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "news:write",
  },
  {
    path: ROUTES.newsEdit,
    component: NewsEdit,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "news:write",
  },
  {
    path: ROUTES.tags,
    component: TagsAll,
    exact: true,
    layout: LayoutIkk,
    // TODO authPermission:
  },

  {
    path: ROUTES.tagsCreate,
    component: TagsCreate,
    layout: LayoutIkk,
    // TODO authPermission:
  },
  {
    path: ROUTES.tagsEdit,
    component: TagsEdit,
    layout: LayoutIkk,
    // TODO authPermission:
  },
  {
    path: ROUTES.groups,
    component: GroupsAll,
    exact: true,
    layout: LayoutIkk,
    // TODO authPermission:
  },
  {
    path: ROUTES.groupsCreate,
    component: GroupCreate,
    exact: true,
    layout: LayoutIkk,
    // TODO authPermission:
  },
  {
    path: ROUTES.groupsEdit,
    component: GroupsEdit,
    exact: true,
    layout: LayoutIkk,
    // TODO authPermission:
  },
  {
    path: ROUTES.categories,
    component: CategoriesAll,
    exact: true,
    layout: LayoutIkk,
    // TODO authPermission:
  },
  {
    path: ROUTES.categoriesCreate,
    component: CategoriesCreate,
    exact: true,
    layout: LayoutIkk,
    // TODO authPermission:
  },
  {
    path: ROUTES.categoriesEdit,
    component: CategoriesEdit,
    exact: true,
    layout: LayoutIkk,
    // TODO authPermission:
  },
  {
    path: ROUTES.slides,
    component: SlidesAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.slidesCreate,
    component: SlidesCreate,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.slidesEdit,
    component: SlideEdit,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.events,
    component: EventsAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "events:read",
  },
  {
    path: ROUTES.eventsCreate,
    component: EventsCreate,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "events:write",
  },
  {
    path: ROUTES.eventsEdit,
    component: EventEdit,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "events:write",
  },
  {
    path: ROUTES.centrums,
    component: CentrumsAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.centrumsCreate,
    component: CentrumCreate,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.centrumsEdit,
    component: CentrumEdit,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.staticTexts,
    component: StaticTexts,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.staticImages,
    component: StaticImages,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.aboutRows,
    component: AllAboutRow,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.aboutRowsCreate,
    component: AboutRowCreate,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.aboutRowsEdit,
    component: AboutRowEdit,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.projects,
    component: AllProject,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "publicData:read",
  },
  {
    path: ROUTES.projectsCreate,
    component: ProjectCreate,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "publicData:write",
  },
  {
    path: ROUTES.projectsEdit,
    component: ProjectEdit,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "publicData:write",
  },
  {
    path: ROUTES.publicInfo,
    component: PublicInfoAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "publicData:read",
  },
  {
    path: ROUTES.publicInfoCreate,
    component: PublicInfoCreate,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "publicData:write",
  },
  {
    path: ROUTES.archive,
    component: ArchiveAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "publicData:read",
  },
  {
    path: ROUTES.archiveCreate,
    component: ArchiveCreate,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "publicData:write",
  },
  {
    path: ROUTES.careers,
    component: CareerAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:read",
  },

  {
    path: ROUTES.careerEdit,
    component: CareerEdit,
    layout: LayoutIkk,
    exact: true,

    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.careerCreate,
    component: CareerCreate,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.popup,
    component: PopupAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.popupEdit,
    component: PopupEdit,
    layout: LayoutIkk,
    exact: true,

    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.popupCreate,
    component: PopupCreate,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },

  {
    path: ROUTES.mediaImages,
    component: MediaImages,
    layout: LayoutMedia,
    exact: true,
    authNamespace: "main",
    authPermission: "media:read",
  },
  {
    path: ROUTES.mediaFiles,
    component: MediaFiles,
    layout: LayoutMedia,
    exact: true,
    authNamespace: "main",
    authPermission: "media:read",
  },
  {
    path: ROUTES.mediaUpload,
    component: MediaStore,
    layout: LayoutMedia,
    exact: true,
    authNamespace: "main",
    authPermission: "media:write",
  },
  {
    path: ROUTES.documents,
    component: DocsAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "media:read",
  },
  {
    path: ROUTES.documentsCreate,
    component: DocsCreate,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "media:write",
  },
  {
    path: ROUTES.documentsEdit,
    component: DocsEdit,
    layout: LayoutIkk,
    authNamespace: "main",
    authPermission: "media:write",
  },
  {
    path: ROUTES.scholarshipTemplates,
    component: ScholarshipTemplatesAll,
    layout: LayoutIkk,
    authNamespace: "scholarship",
    //authPermission: "media:write",
    exact: true,
  },
  {
    path: ROUTES.scholarshipTemplateCreate,
    component: ScholarshipTemplateCreate,
    layout: LayoutIkk,
    authNamespace: "scholarship",
    authPermission: "template:write",
    exact: true,
  },
  {
    path: ROUTES.scholarshipTemplateEdit,
    component: ScholarshipTemplateEdit,
    layout: LayoutIkk,
    authNamespace: "scholarship",
    authPermission: "template:write",
    exact: true,
  },
  {
    path: ROUTES.scholarshipApplicants,
    component: ScholarshipApplicantsAll,
    layout: LayoutIkk,
    authNamespace: "scholarship",
    authPermission: "applicant:read",
    exact: true,
  },
  {
    path: ROUTES.scholarshipApplicantEdit,
    component: ScholarshipApplicantEdit,
    layout: LayoutIkk,
    authNamespace: "scholarship",
    authPermission: "applicant:statusedit",
    exact: true,
  },
  {
    path: ROUTES.menuCategory,
    component: MenuCategoryAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.menuCategoryEdit,
    component: MenuCategoryEdit,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.menuItemCreate,
    component: MenuItemCreate,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.menuItem,
    component: MenuItemAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.menuItemEdit,
    component: MenuItemEdit,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },

  {
    path: ROUTES.pagesCreate,
    component: PagesCreate,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.pages,
    component: PagesAll,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.pagesEdit,
    component: PagesEdit,
    layout: LayoutIkk,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },

  // SZAKKEPZES

  {
    path: ROUTES.szakkepzesExport,
    component: ExcelExport,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.szakkepzesImport,
    component: ExcelImport,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.szakkepzesNotifications,
    component: SzakkepzesNotifications,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.location,
    component: Locations,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.kkk,
    component: KKK_PTT,
    layout: LayoutSzakkepzes,
    exact: true,
  },
  {
    path: ROUTES.kkkCreate,
    component: KKKCreate,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.faq,
    component: FaqAll,
    exact: true,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.faqCreate,
    component: FaqCreate,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.faqEdit,
    component: FaqEdit,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.faqCategory,
    component: FaqCategoryAll,
    exact: true,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.faqCategoryCreate,
    component: FaqCategoryCreate,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.faqCategoryEdit,
    component: FaqCategoryEdit,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.szakmakartyakSectors,
    component: ProfessionCardsSectorsAll,
    layout: LayoutSzakkepzes,
    exact: true,
  },
  {
    path: ROUTES.szakmakartyakSectorsEdit,
    component: ProfessionCardsSectorsEdit,
    layout: LayoutSzakkepzes,
  },
  {
    path: ROUTES.szakmakartyakQualifications,
    component: ProfessionCardsQualifAll,
    layout: LayoutSzakkepzes,
    exact: true,
  },
  {
    path: ROUTES.szakmakartyakQualificationsEdit,
    component: ProfessionCardsQualifEdit,
    layout: LayoutSzakkepzes,
  },

  // SZAKKEPESITES

  {
    path: ROUTES.szakkepesitesQualif,
    component: AllQualifications,
    layout: LayoutSzakkepesites,
    exact: true,
  },
  {
    path: ROUTES.szakkepesitesQualifDocCreate,
    component: QualifDocumentCreate,
    layout: LayoutSzakkepesites,
  },
  {
    path: ROUTES.szakkepesitesQualifCreate,
    component: QualifCreate,
    layout: LayoutSzakkepesites,
  },
  {
    path: ROUTES.szakkepesitesQualifEdit,
    component: QualifEdit,
    layout: LayoutSzakkepesites,
  },
  {
    path: ROUTES.pkCards,
    component: AllPKCard,
    exact: true,
    layout: LayoutSzakkepesites,
    authNamespace: "qualificationcard",
    authPermission: "qualification:read",
  },
  {
    path: ROUTES.pkCardCreate,
    component: PkCardCreate,
    exact: true,
    layout: LayoutSzakkepesites,
    authNamespace: "qualificationcard",
    authPermission: "qualification:write",
  },
  {
    path: ROUTES.pkCardEdit,
    component: PkCardEdit,
    exact: true,
    layout: LayoutSzakkepesites,
    authNamespace: "qualificationcard",
    authPermission: "qualification:write",
  },
  {
    path: ROUTES.szakkepesitesStaticTexts,
    component: SzakkepesitesStaticTexts,
    exact: true,
    layout: LayoutSzakkepesites,
  },

  // AUTH
  {
    path: ROUTES.authEdit,
    component: UsersEdit,
    layout: LayoutAuth,
    authNamespace: "auth",
  },
  {
    path: ROUTES.auth,
    component: Users,
    layout: LayoutAuth,
    authNamespace: "auth",
  },

  //SKILLSCOM

  {
    path: ROUTES.skillsComNews,
    component: SkillsComNewsAll,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "article:read",
  },
  {
    path: ROUTES.skillsComNewsCreate,
    component: SkillsComNewsCreate,
    layout: LayoutSkillsCom,
    authNamespace: "zartrendszer",
    authPermission: "article:create",
  },
  {
    path: ROUTES.skillsComNewsEdit,
    component: SkillsComNewsEdit,
    layout: LayoutSkillsCom,
    authNamespace: "zartrendszer",
    authPermission: "article:update",
  },
  {
    path: ROUTES.skillsComEvents,
    component: SkillsComEventsAll,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "event:read",
  },
  {
    path: ROUTES.skillsComEventsCreate,
    component: SkillsComEventsCreate,
    layout: LayoutSkillsCom,
    authNamespace: "zartrendszer",
    authPermission: "event:create",
  },
  {
    path: ROUTES.skillsComEventsEdit,
    component: SkillsComEventsEdit,
    layout: LayoutSkillsCom,
    authNamespace: "zartrendszer",
    authPermission: "event:create",
  },
  {
    path: ROUTES.skillsComPosts,
    component: SkillsComPostsAll,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "post:read",
  },
  {
    path: ROUTES.skillsComPostsCreate,
    component: SkillsComPostsCreate,
    layout: LayoutSkillsCom,
    authNamespace: "zartrendszer",
    authPermission: "post:create",
  },
  {
    path: ROUTES.skillsComPostsEdit,
    component: SkillsComPostsEdit,
    layout: LayoutSkillsCom,
    authNamespace: "zartrendszer",
    authPermission: "post:update",
  },
  {
    path: ROUTES.skillsComEditors,
    component: SkillsComEditorsAll,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "editor:read",
  },
  {
    path: ROUTES.skillsComEditorsAdd,
    component: SkillsComEditorsCreate,
    layout: LayoutSkillsCom,
    authNamespace: "zartrendszer",
    authPermission: "editor:create",
  },
  {
    path: ROUTES.skillsComCompanies,
    component: SkillsComCompaniesAll,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "company:read",
  },
  {
    path: ROUTES.skillsComInstitutes,
    component: SkillsComInstitutesAll,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "institute:read",
  },
  {
    path: ROUTES.skillsComStats,
    component: Stats,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "statistics:read",
  },
  {
    path: ROUTES.skillsComJobs,
    component: JobsAll,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "",
  },
  {
    path: ROUTES.skillsComJobsCreate,
    component: JobsCreate,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "",
  },
  {
    path: ROUTES.skillsComJobsEdit,
    component: JobsEdit,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "",
  },
  {
    path: ROUTES.skillscomJobsApplicants,
    component: JobApplicants,
    layout: LayoutSkillsCom,
    exact: true,
    authNamespace: "zartrendszer",
    authPermission: "",
  },

  // VIZSGAKOZPONT
  {
    path: ROUTES.examcenterCandidates,
    component: CandidatesAll,
    layout: LayoutExamCenter,
    exact: true,
    authNamespace: "vizsgakozpont",
    authPermission: "candidate:read",
  },
  {
    path: ROUTES.examcenterCandidateEdit,
    component: CandidateEdit,
    layout: LayoutExamCenter,
    exact: true,
    authNamespace: "vizsgakozpont",
    authPermission: "candidate:write",
  },
  {
    path: ROUTES.examsAll,
    component: ExamsAll,
    layout: LayoutExamCenter,
    exact: true,
    authNamespace: "vizsgakozpont",
    authPermission: "exam:read",
  },
  {
    path: ROUTES.examCreate,
    component: ExamCreate,
    layout: LayoutExamCenter,
    exact: true,
    authNamespace: "vizsgakozpont",
    authPermission: "exam:write",
  },
  {
    path: ROUTES.examEdit,
    component: ExamEdit,
    layout: LayoutExamCenter,
    exact: true,
    authNamespace: "vizsgakozpont",
    authPermission: "exam:write",
  },
  {
    path: ROUTES.examPartners,
    component: ExamPartnerAll,
    layout: LayoutExamCenter,
    exact: true,
    authNamespace: "vizsgakozpont",
    authPermission: "exam:write",
  },
  {
    path: ROUTES.examPartnerCreate,
    component: ExamPartnerCreate,
    layout: LayoutExamCenter,
    exact: true,
    authNamespace: "vizsgakozpont",
    authPermission: "exam:write",
  },
  {
    path: ROUTES.examPartnerEdit,
    component: ExamPartnerEdit,
    layout: LayoutExamCenter,
    exact: true,
    authNamespace: "vizsgakozpont",
    authPermission: "exam:write",
  },

  // EDIR
  {
    path: ROUTES.edirSzcAll,
    component: SZCAll,
    layout: LayoutEDIR,
    exact: true,
    authNamespace: "ceam",
    authPermission: "szc:read",
  },
  {
    path: ROUTES.edirSzcCreate,
    component: SZCCreate,
    layout: LayoutEDIR,
    exact: true,
    authNamespace: "ceam",
    authPermission: "szc:write",
  },
  {
    path: ROUTES.edirSzcEdit,
    component: SZCEdit,
    layout: LayoutEDIR,
    exact: true,
    authNamespace: "ceam",
    authPermission: "szc:write",
  },
  {
    path: ROUTES.edirInstituteAll,
    component: InstituteAll,
    layout: LayoutEDIR,
    exact: true,
    authNamespace: "ceam",
    authPermission: "intezmeny:read",
  },
  {
    path: ROUTES.edirInstituteCreate,
    component: InstituteCreate,
    layout: LayoutEDIR,
    exact: true,
    authNamespace: "ceam",
    authPermission: "intezmeny:write",
  },
  {
    path: ROUTES.edirInstituteEdit,
    component: InstituteEdit,
    layout: LayoutEDIR,
    exact: true,
    authNamespace: "ceam",
    authPermission: "intezmeny:write",
  },
  //6110
  {
    path: ROUTES.project_6110,
    component: Project6110Index,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "6110",
    authPermission: "",
  },
  {
    path: ROUTES.project_6110PK,
    component: AllQualifications6110,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "6110",
    authPermission: "pk:write",
  },
  {
    path: ROUTES.project_6110PKCreate,
    component: DocumentCreate6110,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "6110",
    authPermission: "pk:write",
  },
  {
    path: ROUTES.project_6110PKEdit,
    component: QualifEdit6110,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "6110",
    authPermission: "pk:write",
  },
  {
    path: ROUTES.project_6110KKK,
    component: AllKKK6110,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "6110",
    authPermission: "kkk:write",
  },
  {
    path: ROUTES.project_6110KKKCreate,
    component: KKKCreate6110,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "6110",
    authPermission: "kkk:write",
  },
  {
    path: ROUTES.project_6110RK,
    component: AllSpecialisations,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "6110",
    authPermission: "rk:write",
  },
  {
    path: ROUTES.project_6110RKCreate,
    component: SpecialisationCreate,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "6110",
    authPermission: "rk:write",
  },
  {
    path: ROUTES.project_6110examCenterCreate,
    component: ExamCenterCreate,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.project_6110examCenter,
    component: ExamCenterAll,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:read",
  },
  {
    path: ROUTES.project_6110examCenterEdit,
    component: ExamCenterEdit,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  {
    path: ROUTES.project_6110StaticTexts,
    component: StaticTexts6110,
    layout: Project6110Layout,
    exact: true,
    authNamespace: "main",
    authPermission: "constData:write",
  },
  // no match
  {
    path: "*",
    component: NotFoundPage,
  },
];
