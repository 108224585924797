import React from "react";
import { connect } from "react-redux";
import { uploadNews } from "../../../redux/actions/skillscom/news";
import { ROUTES } from "../../../utils/routes";
import NewsForm from "./NewsForm";

const emptyState = {
  title: "",
  lead: "",
  body: "",
  coverImgId: null,
  tags: [],
  sendPush: false,
};

const NewsCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const data = await props.uploadNews(state);
    // reset form is submit was successful
    if (data && data.id) {
      setState(emptyState);
      props.history.push(ROUTES.skillsComNews);
    }
  };

  return (
    <NewsForm
      title="Hír felvitele"
      initialState={emptyState}
      submitBtnText="Létrehoz"
      imageRequired={true}
      onSubmit={handleCreateSubmit}
    />
  );
};

export default connect(null, { uploadNews })(NewsCreate);
