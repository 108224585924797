import {
  Button,
  Checkbox,
  FormGroup,
  Icon,
  InputGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";

const ExamPartnerForm = (props) => {
  const [partner, setPartner] = useState(props.initialState);

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setPartner((s) => ({ ...s, [name]: val }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(partner, setPartner);
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 800, marginBottom: 100 }}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="applications" />
        &nbsp;<span>{props.title}</span>
      </h2>

      <FormGroup label="Név" labelInfo="(kötelező)">
        <InputGroup
          required
          name="name"
          value={partner.name}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Székhely" labelInfo="(kötelező)">
        <InputGroup
          required
          name="hq"
          value={partner.hq}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Adószám" labelInfo="(kötelező)">
        <InputGroup
          required
          name="taxId"
          value={partner.taxId}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Email" labelInfo="(kötelező)">
        <InputGroup
          name="email"
          type="email"
          value={partner.email}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup helperText="Ha nem aktív akkor ez a partner semelyik vizsgánál nem fog megjelenni.">
        <Checkbox
          label="Aktív"
          name="enabled"
          checked={partner.enabled}
          onChange={handleInput}
        />
      </FormGroup>

      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

export default connect(null, {})(ExamPartnerForm);
