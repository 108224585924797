import http from "../../../utils/http";
import { API_AUTH_PROVIDER } from "../../../utils/routes";
import {
  GET_AUTH_USERS_BEGIN,
  GET_AUTH_USERS_FAILURE,
  GET_AUTH_USERS_SUCCESS,
  GET_AUTH_USER_BEGIN,
  GET_AUTH_USER_FAILURE,
  GET_AUTH_USER_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getAllUsers = () => async (dispatch) => {
  dispatch(getAllUsersBegin());
  try {
    const json = await http.get(`${API_AUTH_PROVIDER}/users`);
    dispatch(getAllUsersSuccess(json));
  } catch (error) {
    dispatch(getAllUsersFailure(error));
    dispatch(addToastDanger(`Felhasználók lekérése sikertelen: ${error}`));
  }
};

export const getSingleUser = (id) => async (dispatch) => {
  dispatch(getSingleUserBegin());
  try {
    const json = await http.get(`${API_AUTH_PROVIDER}/users/${id}`);
    dispatch(getSingleUserSuccess(json));
  } catch (error) {
    dispatch(getSingleUserFailure(error));
    dispatch(addToastDanger(`Felhasználók lekérése sikertelen: ${error}`));
  }
};

export const updateUser = (id, data) => async (dispatch) => {
  try {
    const json = await http.put(`${API_AUTH_PROVIDER}/users/${id}`, data);
    dispatch(addToastSuccess(`Jogosultságok módosítva`));
  } catch (error) {
    dispatch(addToastDanger(`Felhasználók frissítése sikertelen: ${error}`));
  }
};

const getAllUsersBegin = () => ({
  type: GET_AUTH_USERS_BEGIN,
});

const getAllUsersSuccess = (data) => ({
  type: GET_AUTH_USERS_SUCCESS,
  payload: data,
});

const getAllUsersFailure = (data) => ({
  type: GET_AUTH_USERS_FAILURE,
  payload: data,
});

const getSingleUserBegin = () => ({
  type: GET_AUTH_USER_BEGIN,
});

const getSingleUserSuccess = (data) => ({
  type: GET_AUTH_USER_SUCCESS,
  payload: data,
});

const getSingleUserFailure = (data) => ({
  type: GET_AUTH_USER_FAILURE,
  payload: data,
});
