import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import React from "react";
import CKEditorUploadAdapter from "../../utils/CKEditorUploadAdapter";

const MyCkEditor = ({
  data, // content to show
  onDataChange, // on content edit callback
  removePlugins = [], // array of plugin names to hide
}) => {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={data}
        onInit={(editor) => {
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );
          editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return new CKEditorUploadAdapter(loader);
          };
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onDataChange(data);
        }}
        config={{
          removePlugins: removePlugins,
          image: {
            styles: ["full", "alignRight", "alignLeft"],
            toolbar: [
              "imageStyle:full",
              "imageStyle:alignRight",
              "imageStyle:alignLeft",
              "|",
              "imageTextAlternative",
            ],
          },
          mediaEmbed: {
            previewsInData: true,
          },
          link: {
            decorators: {
              isExternal: {
                mode: "manual",
                label: "Új oldalon nyílik meg",
                attributes: {
                  target: "_blank",
                },
              },
            },
          },
        }}
      />
    </>
  );
};

export default MyCkEditor;
