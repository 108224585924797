import http from "../../../utils/http";
import { API_BASE_SKILLSCOM } from "../../../utils/routes";
import {
  SKILLSCOM_INSTITUTE_ADD_BEGIN,
  SKILLSCOM_INSTITUTE_ADD_FAILURE,
  SKILLSCOM_INSTITUTE_ADD_SUCCESS,
  SKILLSCOM_INSTITUTE_DELETE_BEGIN,
  SKILLSCOM_INSTITUTE_DELETE_FAILURE,
  SKILLSCOM_INSTITUTE_DELETE_SUCCESS,
  SKILLSCOM_INSTITUTE_GET_BEGIN,
  SKILLSCOM_INSTITUTE_GET_FAILURE,
  SKILLSCOM_INSTITUTE_GET_SUCCESS,
  SKILLSCOM_INSTITUTE_VERIFY_BEGIN,
  SKILLSCOM_INSTITUTE_VERIFY_FAILURE,
  SKILLSCOM_INSTITUTE_VERIFY_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const uploadSKILLSCOM_INSTITUTE = (data) => async (dispatch) => {
  dispatch(uploadSKILLSCOM_INSTITUTEBegin());
  try {
    const json = await http.post(
      API_BASE_SKILLSCOM + "/admin/institutes",
      data
    );
    dispatch(uploadSKILLSCOM_INSTITUTESuccess(json));
    dispatch(addToastSuccess("Intézmény létrehozva"));
    return json;
  } catch (error) {
    dispatch(uploadSKILLSCOM_INSTITUTEFailure(error));
    dispatch(addToastDanger(`Intézmény létrehozása sikertelen: ${error}`));
  }
};

export const verifyInstitute = (id) => async (dispatch) => {
  dispatch(verifyInstituteBegin());
  try {
    const json = await http.post(
      `${API_BASE_SKILLSCOM}/admin/institutes/${id}/verify`
    );
    dispatch(verifyInstituteSuccess(id));
    dispatch(addToastSuccess("Intézmény elfogadva"));
    return json;
  } catch (error) {
    dispatch(verifyInstituteFailure(error));
    dispatch(addToastDanger(`Intézmény elfogadása sikertelen: ${error}`));
  }
};

export const getInstitutes = (offset, limit) => async (dispatch) => {
  dispatch(getSKILLSCOM_INSTITUTEBegin());
  try {
    const params =
      typeof offset === "number" && typeof limit === "number"
        ? `?limit=${limit}&offset=${offset}`
        : "";
    const json = await http.get(
      `${API_BASE_SKILLSCOM}/admin/institutes/${params}`
    );
    dispatch(getSKILLSCOM_INSTITUTESuccess(json));
    return json;
  } catch (error) {
    dispatch(getSKILLSCOM_INSTITUTEFailure(error));
    dispatch(addToastDanger(`Intézmények betöltése sikertelen: ${error}`));
  }
};

export const deleteSKILLSCOM_INSTITUTE = (id) => async (dispatch) => {
  dispatch(deleteSKILLSCOM_INSTITUTEBegin());
  try {
    const json = await http.del(`${API_BASE_SKILLSCOM}/institutes/${id}`);
    dispatch(deleteSKILLSCOM_INSTITUTESuccess(id));
    dispatch(addToastSuccess("Intézmény törölve"));
    return json;
  } catch (error) {
    dispatch(deleteSKILLSCOM_INSTITUTEFailure(error));
    dispatch(addToastDanger(`Intézmény törlése sikertelen: ${error}`));
  }
};

export const verifyInstituteBegin = () => ({
  type: SKILLSCOM_INSTITUTE_VERIFY_BEGIN,
});

export const verifyInstituteSuccess = (id) => ({
  type: SKILLSCOM_INSTITUTE_VERIFY_SUCCESS,
  payload: id,
});

export const verifyInstituteFailure = (error) => ({
  type: SKILLSCOM_INSTITUTE_VERIFY_FAILURE,
  payload: { error },
});

export const uploadSKILLSCOM_INSTITUTEBegin = () => ({
  type: SKILLSCOM_INSTITUTE_ADD_BEGIN,
});

export const uploadSKILLSCOM_INSTITUTESuccess = (SKILLSCOM_INSTITUTE) => ({
  type: SKILLSCOM_INSTITUTE_ADD_SUCCESS,
  payload: { SKILLSCOM_INSTITUTE },
});

export const uploadSKILLSCOM_INSTITUTEFailure = (error) => ({
  type: SKILLSCOM_INSTITUTE_ADD_FAILURE,
  payload: { error },
});

export const getSKILLSCOM_INSTITUTEBegin = () => ({
  type: SKILLSCOM_INSTITUTE_GET_BEGIN,
});

export const getSKILLSCOM_INSTITUTESuccess = (news) => ({
  type: SKILLSCOM_INSTITUTE_GET_SUCCESS,
  payload: news,
});

export const getSKILLSCOM_INSTITUTEFailure = (error) => ({
  type: SKILLSCOM_INSTITUTE_GET_FAILURE,
  payload: { error },
});

export const deleteSKILLSCOM_INSTITUTEBegin = () => ({
  type: SKILLSCOM_INSTITUTE_DELETE_BEGIN,
});

export const deleteSKILLSCOM_INSTITUTESuccess = (id) => ({
  type: SKILLSCOM_INSTITUTE_DELETE_SUCCESS,
  payload: id,
});

export const deleteSKILLSCOM_INSTITUTEFailure = (error) => ({
  type: SKILLSCOM_INSTITUTE_DELETE_FAILURE,
  payload: { error },
});
