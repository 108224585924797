import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import ProjectForm from "./ProjectForm";
import { getProjects, updateProject } from "../../../redux/actions/ikk/project";

const ProjectEdit = (props) => {
  const [project, setProject] = useState();
  const { id } = useParams();

  useEffect(() => {
    getProject();
  }, []);

  const getProject = async () => {
    const rows = await props.getProjects();
    const r = rows.find((r) => r.id === Number(id));
    setProject(r);
  };

  const handleEditSubmit = async (state) => {
    const res = await props.updateProject(project.id, state);
    if (res) props.history.push(ROUTES.projects);
  };

  if (!project) return <></>;

  return (
    <ProjectForm
      initialState={project}
      submitBtnText="Módosít"
      title="Projekt szerkesztése"
      handleSubmit={handleEditSubmit}
      imageRequired={false}
    />
  );
};

export default connect(null, { getProjects, updateProject })(ProjectEdit);
