import { Button, FormGroup, HTMLSelect, Spinner } from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { getToken } from "../../../utils/auth";
import http from "../../../utils/http";
import { API_BASE_EXAM_CENTER, ROUTES } from "../../../utils/routes";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import MomentLocaleUtils from "react-day-picker/moment";
import { objectToQueryString } from "../../../utils/http";

const CandidatesAll = (props) => {
  const [candidates, setCandidates] = useState();
  const [candidateStates, setCandidateStates] = useState(); // map of status label-value pairs
  const [filter, setFilter] = useState({ offset: 0, limit: 100 }); // status,from,till,limit,offset

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    console.log(filter);
    if (filter) {
      // XOR, do not send request if only one date is set from the date range
      if ((filter.from && !filter.till) || (!filter.from && filter.till)) return;
      else {
        setCandidates(null); // reset list before sending filter request
        getAllCandidatesRecursively({ ...filter });
      }
    }
  }, [filter]);

  const getAllCandidatesRecursively = async (allfilter) => {
    const { limit, offset } = allfilter;
    const params = allfilter ? `?${objectToQueryString(allfilter)}` : "";
    const json = await http.get(`${API_BASE_EXAM_CENTER}/candidates${params}`);
    setCandidates((c) => (c ? [...c, ...json] : [...json])); // candidates is null on first render
    if (Array.isArray(json) && json.length === limit) {
      getAllCandidatesRecursively({ ...allfilter, offset: offset + limit, limit: limit });
    }
  };

  // status label map
  const getStates = async () => {
    const json = await http.get(`${API_BASE_EXAM_CENTER}/candidates/states`);
    setCandidateStates(json);
  };

  const handleDateRangeChange = ([from, till]) => {
    setFilter((f) => ({ ...f, from: from?.toISOString(), till: till?.toISOString() }));
  };

  const handleStatusChange = (e) => {
    setFilter((f) => ({ ...f, status: e.target.value }));
  };

  function showFile(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    var newBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    const filename = `vk-export-${new Date().toISOString()}.xlsx`;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }
  const handleExportXLSX = async () => {
    let params = `?${objectToQueryString(filter)}`;
    const r = await fetch(`${API_BASE_EXAM_CENTER}/candidates/excel${params}`, {
      headers: { Authorization: `Bearer ${await getToken()}` },
    });
    const blob = await r.blob();
    showFile(blob);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Név",
        accessor: "fullName",
      },
      {
        Header: "Születési hely",
        accessor: "birthPlace",
      },
      {
        Header: "Születési idő",
        accessor: "birthDay",
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: "Számlázási cím",
        accessor: "billingAddress",
      },
      {
        Header: "Értesítés email",
        accessor: "notificationEmail",
      },
      {
        Header: "Státusz",
        accessor: "status",
        Cell: ({ value }) => (candidateStates[value] ? candidateStates[value] : "-"),
      },
      {
        Header: "Vizsga azonosító",
        accessor: "examIDNumber",
        Cell: ({ value }) => value || "-",
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.examcenterCandidateEdit, {
              id: row.original.id,
            })}
          />
        ),
      },
    ],
    [candidates, candidateStates]
  );

  if (!candidates || !candidateStates) return <Spinner />;

  return (
    <>
      <h2>Összes jelentkező a kiválasztott szűrők szerint</h2>

      <div style={{ display: "flex", gap: 20 }}>
        <FormGroup label="Státusz szűrő">
          <HTMLSelect onChange={handleStatusChange} value={filter.status}>
            <option value="">Nincs</option>
            {Object.keys(candidateStates).map((status) => (
              <option value={status} key={status}>
                {candidateStates[status]}
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>

        <FormGroup label="Jelentkezés intervalluma">
          <DateRangeInput
            locale="hu"
            localeUtils={MomentLocaleUtils}
            highlightCurrentDay
            allowSingleDayRange
            shortcuts={false}
            onChange={handleDateRangeChange}
            formatDate={(date) => date.toLocaleDateString()}
            parseDate={(str) => new Date(str)}
            startInputProps={{ placeholder: "Tól-" }}
            endInputProps={{ placeholder: "-Ig" }}
            value={[
              filter.from ? new Date(filter.from) : undefined,
              filter.till ? new Date(filter.till) : undefined,
            ]}
          />
        </FormGroup>

        <FormGroup label="Eredmények exportálása">
          <Button
            type="submit"
            icon="download"
            intent="primary"
            text="Exportálás XLSX-be"
            onClick={handleExportXLSX}
          />
        </FormGroup>
      </div>

      <TableAdvanced columns={columns} data={candidates} />
    </>
  );
};

export default CandidatesAll;
