import http from "../../../utils/http";
import { API_BASE_SZAKKEPZES, API_ENDPOINT } from "../../../utils/routes";
import {
  GET_FAQS_BEGIN,
  GET_FAQS_FAILURE,
  GET_FAQS_SUCCESS,
  GET_FAQ_CATEGORIES_BEGIN,
  GET_FAQ_CATEGORIES_FAILURE,
  GET_FAQ_CATEGORIES_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getAllFaq = () => async (dispatch) => {
  dispatch(getAllFaqBegin());
  try {
    const json = await http.get(API_BASE_SZAKKEPZES + API_ENDPOINT.FAQ);
    dispatch(getAllFaqSuccess(json));
    return json;
  } catch (error) {
    dispatch(getAllFaqFail(error));
    dispatch(addToastDanger(`Gyakori kérdések betöltése sikertelen: ${error}`));
  }
};

export const getAllFaqCategory = () => async (dispatch) => {
  dispatch(getAllFaqCategoriesBegin());
  try {
    const json = await http.get(
      API_BASE_SZAKKEPZES + API_ENDPOINT.FAQ_CATEGORY
    );
    dispatch(getAllFaqCategoriesSuccess(json));
    return json;
  } catch (error) {
    dispatch(getAllFaqCategoriesFail(error));
    dispatch(addToastDanger(`Kategóriák lekérése sikertelen: ${error}`));
  }
};

export const createFaq = (body) => async (dispatch) => {
  try {
    const json = await http.post(API_BASE_SZAKKEPZES + API_ENDPOINT.FAQ, body);
    dispatch(addToastSuccess("Kérdés létrehozva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Kérdés létrehozása sikertelen: ${error}`));
  }
};

export const createFaqCategory = (body) => async (dispatch) => {
  try {
    const json = await http.post(
      API_BASE_SZAKKEPZES + API_ENDPOINT.FAQ_CATEGORY,
      body
    );
    dispatch(addToastSuccess("Kategória létrehozva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Kategória létrehozása sikertelen: ${error}`));
  }
};

export const deleteFaq = (id) => async (dispatch) => {
  try {
    const json = await http.del(
      `${API_BASE_SZAKKEPZES + API_ENDPOINT.FAQ}/${id}`
    );
    dispatch(addToastSuccess("Kérdés törölve"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Kérdés törlése sikertelen: ${error}`));
  }
};

export const deleteFaqCategory = (id) => async (dispatch) => {
  try {
    const json = await http.del(
      `${API_BASE_SZAKKEPZES + API_ENDPOINT.FAQ_CATEGORY}/${id}`
    );
    dispatch(addToastSuccess("Témakör törölve"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Témakör törlése sikertelen: ${error}`));
  }
};

export const updateFaq = (id, body) => async (dispatch) => {
  try {
    const json = await http.put(
      `${API_BASE_SZAKKEPZES + API_ENDPOINT.FAQ}/${id}`,
      body
    );
    dispatch(addToastSuccess("Gyakori kérdés módosítva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Módosítás sikertelen: ${error}`));
  }
};

export const updateFaqCategory = (id, body) => async (dispatch) => {
  try {
    const json = await http.put(
      `${API_BASE_SZAKKEPZES + API_ENDPOINT.FAQ_CATEGORY}/${id}`,
      body
    );
    dispatch(addToastSuccess("Témakör módosítva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Témakör módosítása sikertelen: ${error}`));
  }
};

const getAllFaqSuccess = (json) => ({
  type: GET_FAQS_SUCCESS,
  payload: json,
});
const getAllFaqBegin = () => ({
  type: GET_FAQS_BEGIN,
});
const getAllFaqFail = (error) => ({
  type: GET_FAQS_FAILURE,
  error: { error },
});
const getAllFaqCategoriesBegin = () => ({
  type: GET_FAQ_CATEGORIES_BEGIN,
});
const getAllFaqCategoriesSuccess = (data) => ({
  type: GET_FAQ_CATEGORIES_SUCCESS,
  payload: data,
});
const getAllFaqCategoriesFail = (error) => ({
  type: GET_FAQ_CATEGORIES_FAILURE,
  payload: { error },
});
