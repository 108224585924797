import { Card, H4 } from "@blueprintjs/core";
import React from "react";
import { SZAKKEPZES_URL } from "../../../utils/routes";
import { LayoutDefault } from "../../LayoutDefault";
import MenuSzakkepzes from "../MenuSzakkepzes/MenuSzakkepzes";

const styleWrap = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "auto 1fr",
  marginTop: "20px",
};

export const LayoutSzakkepzes = ({ children }) => {
  return (
    <LayoutDefault>
      <div style={{ ...styleWrap }}>
        <div>
          <MenuSzakkepzes />
        </div>

        <Card
          elevation={2}
          style={{ height: "calc(100vh - 90px)", overflow: "auto" }}
        >
          <H4>
            <a href={SZAKKEPZES_URL} target="_blank">
              Szakképzés
            </a>
          </H4>
          {children}
        </Card>
      </div>
    </LayoutDefault>
  );
};
