import {
  GROUPS_GET_BEGIN,
  GROUPS_GET_FAIL,
  GROUPS_GET_SUCCESS,
} from "../actionTypes";

const initialState = {
  groups: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GROUPS_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case GROUPS_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        groups: action.payload,
      };
    }
    case GROUPS_GET_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
