import {
  Button,
  Card,
  Colors,
  FormGroup,
  H3,
  H4,
  HTMLSelect,
  Icon,
  InputGroup,
  Spinner,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { getCentrums } from "../../../redux/actions/ikk/traningCenters";
import { getToken } from "../../../utils/auth";
import http from "../../../utils/http";
import { API_BASE_EXAM_CENTER, ROUTES } from "../../../utils/routes";

const CandidateEdit = (props) => {
  const [candidateStates, setCandidateStates] = useState(); // map of states
  const [candidate, setCandidate] = useState();
  const [szcList, setSzcList] = useState([]);

  const { id: candidateId } = useParams();

  useEffect(() => {
    getStates();
    if (candidateId) {
      getCandidate(candidateId);
    }
  }, [candidateId]);
  useEffect(() => {
    async function getSzcList() {
      const list = await props.getCentrums();
      setSzcList(list);
    }
    getSzcList();
  }, []);
  const getCandidate = async (id) => {
    const json = await http.get(`${API_BASE_EXAM_CENTER}/candidates/${id}`);
    setCandidate(json);
  };

  const getStates = async () => {
    const json = await http.get(`${API_BASE_EXAM_CENTER}/candidates/states`);
    setCandidateStates(json);
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    setCandidate((c) => ({ ...c, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const json = await http.put(`${API_BASE_EXAM_CENTER}/candidates/${candidateId}`, {
        ...candidate,
      });
      console.log(json);
      props.history.push(ROUTES.examcenterCandidates);
    } catch (error) {
      console.error(error);
    }
  };

  function showFile(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    var newBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    const filename = `Törzslap-${candidate.fullName}-${new Date().toISOString()}.docx`;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  const handleGenerate = async () => {
    const r = await fetch(`${API_BASE_EXAM_CENTER}/candidates/${candidateId}/generate`, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    const blob = await r.blob();
    showFile(blob);
  };

  if (!candidate || !candidateStates || szcList.legnth < 0) return <Spinner />;

  return (
    <>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="person" />
        &nbsp;&nbsp;<span>Jelentkező szerkesztése </span>&nbsp;&nbsp;
        <Button
          type="submit"
          icon="download"
          intent="primary"
          text="Törzslap generálás"
          onClick={handleGenerate}
        />
      </h2>

      <form onSubmit={handleSubmit} style={{ maxWidth: 800, marginBottom: 100 }}>
        <Card style={{ marginBottom: 20 }}>
          <H3 style={{ color: Colors.BLUE5 }}>A jelentkező adatai</H3>
          <FormGroup label="Teljes név">
            <InputGroup
              name="fullName"
              value={candidate.fullName}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Születési név">
            <InputGroup
              name="birthName"
              value={candidate.birthName}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Születési hely">
            <InputGroup
              name="birthPlace"
              value={candidate.birthPlace}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Születési idő">
            <InputGroup
              type="date"
              name="birthDay"
              value={candidate.birthDay}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Anyja neve">
            <InputGroup
              name="mothersName"
              value={candidate.mothersName}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Azonosító okmány típusa">
            <HTMLSelect
              name="identificationType"
              value={candidate.identificationType}
              onChange={handleInput}
            >
              <option value="ID">Személyi igazolvány</option>
              <option value="PASSPORT">Útlevél</option>
              <option value="DRIVING_LICENSE">Vezetői engedély</option>
            </HTMLSelect>
          </FormGroup>
          <FormGroup label="Azonosító okmány száma">
            <InputGroup
              name="identificationNumber"
              value={candidate.identificationNumber}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Oktatási azonosító száma">
            <InputGroup
              name="educationId"
              value={candidate.educationId}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Képzés elvégzését igazoló tanúsítvány száma">
            <InputGroup
              name="trainingCertificateNumber"
              value={candidate.trainingCertificateNumber}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Tartózkodási hely irányítószám">
            <InputGroup
              name="residencePostalCode"
              value={candidate.residencePostalCode}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Állampolgárság">
            <InputGroup
              name="citizenship"
              value={candidate.citizenship}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Értesítési e-mail cím">
            <InputGroup
              type="email"
              name="notificationEmail"
              value={candidate.notificationEmail}
              onChange={handleInput}
            />
          </FormGroup>

          <H4>Levelezési cím</H4>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: 10,
            }}
          >
            <FormGroup label="Irányítószám">
              <InputGroup
                name="certificateAddressZip"
                value={candidate.certificateAddressZip}
                onChange={handleInput}
              />
            </FormGroup>
            <FormGroup label="Település">
              <InputGroup
                name="certificateAddressCity"
                value={candidate.certificateAddressCity}
                onChange={handleInput}
              />
            </FormGroup>
            <FormGroup label="Utca">
              <InputGroup
                name="certificateAddressStreet"
                value={candidate.certificateAddressStreet}
                onChange={handleInput}
              />
            </FormGroup>
            <FormGroup label="Házszám">
              <InputGroup
                name="certificateAddressHouseNo"
                value={candidate.certificateAddressHouseNo}
                onChange={handleInput}
              />
            </FormGroup>
          </div>
        </Card>

        <Card style={{ marginBottom: 20 }}>
          <H3 style={{ color: Colors.BLUE5 }}>Számlázási adatok</H3>
          <FormGroup label="Számlázási referencia szám">
            <InputGroup
              type="text"
              name="invoiceId"
              value={candidate.invoiceId}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Számlaküldés e-mail címe (Magánszemély)">
            <InputGroup
              type="email"
              name="billingEmail"
              value={candidate.billingEmail}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Számlázási cím (Magánszemély)">
            <InputGroup
              name="billingAddress"
              value={candidate.billingAddress}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Jogi személy - Számlaküldés e-mail címe">
            <InputGroup
              name="billingCompanyEmail"
              value={candidate.billingCompanyEmail}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Jogi személy - Székhely címe">
            <InputGroup
              name="billingCompanyHq"
              value={candidate.billingCompanyHq}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Jogi személy - Cégnév">
            <InputGroup
              name="billingCompanyName"
              value={candidate.billingCompanyName}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Jogi személy - Adószám">
            <InputGroup
              name="billingCompanyTaxId"
              value={candidate.billingCompanyTaxId}
              onChange={handleInput}
            />
          </FormGroup>
        </Card>

        <Card style={{ marginBottom: 20 }}>
          <H3 style={{ color: Colors.BLUE5 }}>Adminiszráció</H3>
          <FormGroup label="Státusz">
            <HTMLSelect name="status" value={candidate.status} onChange={handleInput}>
              <option value=""></option>
              {Object.keys(candidateStates).map((key) => (
                <option key={key} value={key}>
                  {candidateStates[key]}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>
          <FormGroup
            label="Vizsga azonosító"
            labelInfo="(kötelező)"
            helperText="A vizsga egyedi azonosító kódja"
          >
            <InputGroup
              name="examIDNumber"
              value={candidate.examIDNumber}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Fizetés dátuma">
            <InputGroup
              type="date"
              name="paidOnDate"
              value={candidate.paidOnDate?.slice(0, 10)}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Befizetést igazoló készpénzátutalási megbízás sorszáma">
            <InputGroup
              name="paidRefferenceNumber"
              value={candidate.paidRefferenceNumber}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Együttműködő partner">
            <HTMLSelect name="szcId" value={candidate.SZCId} onChange={handleInput}>
              <option value="">Nincsen</option>
              {candidate?.exam?.szcs.map((szc) => (
                <option key={szc.szcId} value={szc.szcId}>
                  {szc.szcId}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>
          <FormGroup label="Vizsga eredménye">
            <InputGroup
              name="examResult"
              value={candidate.examResult}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Vizsga helyszíne">
            <InputGroup
              name="examLocation"
              value={candidate.examLocation}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Vizsgaazonosító szám (külív)">
            <InputGroup
              name="examIDNumber"
              value={candidate.examIDNumber}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="Vizsgatörzslap száma (belív)">
            <InputGroup
              name="examNumber"
              value={candidate.examNumber}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="A kiállított bizonyítvány sorszáma">
            <InputGroup
              name="certificateSerialNumber"
              value={candidate.certificateSerialNumber}
              onChange={handleInput}
            />
          </FormGroup>
          <FormGroup label="A kiállított bizonyítvány sorozatjele">
            <InputGroup
              name="certificateSerialSign"
              value={candidate.certificateSerialSign}
              onChange={handleInput}
            />
          </FormGroup>
        </Card>

        <Button type="submit" icon="floppy-disk" intent="success" text="Mentés" />
      </form>
    </>
  );
};
export default connect(null, { getCentrums })(CandidateEdit);
