import { AnchorButton, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { getAllQualification6110 } from "../../../redux/6110/pk";
import {
  deleteQualification,
  deleteDocument,
} from "../../../redux/actions/szakkepesites/pk";
import { API_BASE_IKK, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import { API_BASE_SZAKKEPESITES_6110 } from "../../../utils/routes";
import http from "../../../utils/http";

const initialstate = {
  fileName: "",
  qualificationId: null,
  version: null,
  mediaId: null,
  publishDate: "",
};

const AllSpecialisations6110 = (props) => {
  // const [editKKK, setEditKKK] = useState(initialstate);
  const [deleteQualif, setDeleteQualif] = useState(initialstate);
  const [deleteDoc, setDeleteDoc] = useState();
  const [editDoc, setEditDoc] = useState();

  useEffect(() => {
    props.getAllQualification6110();
  }, []);

  // opens delete dialog
  const handleDelete = (row) => {
    setDeleteQualif(row);
  };

  const handleDialogClose = () => {
    setEditDoc(null);
  };

  // delete qualification submitted
  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    const res = await props.deleteQualification(deleteQualif.id);
    setDeleteQualif(null);
    props.getAllQualification6110();
  };

  // opens delete dialog
  const handleDeleteDoc = (row, doc) => {
    setDeleteDoc({ ...doc, qualifId: row.id, qualifName: row.name });
  };

  const handleEditDoc = (row, doc) => {
    setEditDoc(doc);
  };

  // deletes a document of a qualification
  const handleDeleteDocSubmit = async (e) => {
    e.preventDefault();
    const res = await http.del(
      `${API_BASE_SZAKKEPESITES_6110}/v2/cms/qualification/document/${deleteDoc.id}`
    );
    setDeleteDoc(null);
    props.getAllQualification6110();
  };

  const handleEditDocSubmit = () => {
    // todo
  };

  const handleDocUpload = (media) => {
    //todo
  };

  console.log(props);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Szakképesítés",
        accessor: "name",
      },
      {
        Header: "Kód",
        accessor: "code",
      },

      {
        Header: "Fájlok",
        accessor: "pks",
        Cell: ({ row, value }) =>
          !value
            ? []
            : value?.map((doc, i) => (
                <div key={i}>
                  <AnchorButton
                    icon="share"
                    small
                    minimal
                    text={`${doc.publishDate} - ${doc.archiveDate} v${doc.version}`}
                    href={`${API_BASE_IKK}/media/${doc.mediaId}`}
                    target="_blank"
                  />
                  <TableActionButtons
                    row={row}
                    editHref={generatePath(ROUTES.project_6110PKEdit, {
                      id: doc.id,
                    })}
                    onDelBtnClick={() => handleDeleteDoc(row.original, doc)}
                  />
                </div>
              )),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2>Összes szakképesítés és programkövetelmény (PK) dokumentumok</h2>

      <TableAdvanced
        data={props.qualifications.filter((q) => q.pks.length > 0)}
        columns={columns}
      />

      {/* delete qualification */}
      <DeleteConfirmation
        isOpen={deleteQualif?.name}
        onCancel={() => setDeleteQualif(null)}
        onDelete={handleDeleteSubmit}
        recordName={`Szakképesítés: ${deleteQualif?.name}`}
      />

      {/* delete document  */}
      <DeleteConfirmation
        isOpen={deleteDoc?.fileName}
        onCancel={() => setDeleteDoc(null)}
        onDelete={handleDeleteDocSubmit}
        recordName={`${deleteDoc?.qualifName} ${deleteDoc?.fileName} dokumentuma`}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.pk };
};
export default connect(mapStateToProps, {
  getAllQualification6110,
  deleteDocument,
  deleteQualification,
})(AllSpecialisations6110);
