import React from "react";
import { showToast } from "../../../utils/helpers";
import { authFetch } from "../../../utils/http";
import { API_BASE_SCHOLARSHIP, ROUTES } from "../../../utils/routes";
import { ScholarshipTemplateForm } from "./ScholarshipTemplateForm";

const emptyState = {
  content: "",
  fileId: null,
  name: "",
};

export const ScholarshipTemplateCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state) => {
    authFetch(`${API_BASE_SCHOLARSHIP}/studentship-template`, {
      method: "POST",
      body: JSON.stringify(state),
    })
      .then((data) => {
        if (data && data.id) {
          props.history.push(ROUTES.scholarshipTemplates);
          showToast({ message: "Sablon feltöltés sikeres" });
        }
      })
      .catch((err) => {
        showToast({ intent: "danger", message: "Sablon feltöltés sikertelen" });
      });

    // const res = await http.post(`${API_BASE_SCHOLARSHIP}/studentship-template`, state);
    // // reset the form if submit was successful
    // if (res && res.id) {
    //   props.history.push(ROUTES.scholarshipTemplates);
    //   showToast({ message: "Sablon feltöltés sikeres" });
    // }
  };

  return (
    <ScholarshipTemplateForm
      title="Új ösztöndíj sablon"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      imageRequired={true}
    />
  );
};
