import http from "../../utils/http";
import { API_BASE_SZAKKEPESITES_6110, API_ENDPOINT } from "../../utils/routes";
import { addToastDanger, addToastSuccess } from "../actions/common/toast";
import {
  SZAKKEPESITES_GET_SPECS_6110_BEGIN,
  SZAKKEPESITES_GET_SPECS_6110_FAIL,
  SZAKKEPESITES_GET_SPECS_6110_SUCCESS,
} from "../actionTypes";

export const getAllSpecialisations6110 = () => async (dispatch) => {
  dispatch(getAllSpecsBegin());
  try {
    const json = await http.get(
      `${API_BASE_SZAKKEPESITES_6110}${API_ENDPOINT.SZAKKEPESITES_SPECIALISATION}`
    );
    dispatch(getAllSpecsSuccess(json));
    return json;
  } catch (error) {
    dispatch(getAllSpecsFail(error));
    dispatch(addToastDanger(`Szakirányok betöltése sikertelen: ${error}`));
  }
};

export const createSpecialisationDocument6110 =
  (id, body) => async (dispatch) => {
    try {
      const json = await http.post(
        `${API_BASE_SZAKKEPESITES_6110}${API_ENDPOINT.SZAKKEPESITES_SPECIALISATION_DOC}`,
        body
      );
      dispatch(addToastSuccess("Dokumentum létrehozva"));
      return json;
    } catch (error) {
      dispatch(addToastDanger(`Dokumentum létrehozása sikertelen: ${error}`));
    }
  };

export const updateSpecialisationDocument6110 =
  (docId, data) => async (dispatch) => {
    try {
      const json = await http.put(
        `${API_BASE_SZAKKEPESITES_6110}${API_ENDPOINT.SZAKKEPESITES_SPECIALISATION_DOC}/${docId}`,
        data
      );
      dispatch(addToastSuccess("Dokumentum módosítása"));
      return json;
    } catch (error) {
      dispatch(addToastDanger(`Dokumentum módosítása sikertelen: ${error}`));
    }
  };

const getAllSpecsBegin = () => ({
  type: SZAKKEPESITES_GET_SPECS_6110_BEGIN,
});
const getAllSpecsSuccess = (json) => ({
  type: SZAKKEPESITES_GET_SPECS_6110_SUCCESS,
  payload: json,
});
const getAllSpecsFail = (error) => ({
  type: SZAKKEPESITES_GET_SPECS_6110_FAIL,
  error: { error },
});
