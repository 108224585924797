import React from "react";
import { connect } from "react-redux";
import { createQualification, getAllFaq } from "../../../redux/actions/szakkepesites/pk";
import { ROUTES } from "../../../utils/routes";
import QualifForm from "./QualifForm";

const emptyState = {
  name: "",
  code: "",
  num: "",
  mkkr: "",
  ekkr: "",
  dkkr: "",
  visible: true,

  // documents can be added after creation
};

const QuaifCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const data = await props.createQualification(state);
    // reset form is submit was successful
    if (data) {
      setState(emptyState);
      props.history.push(ROUTES.szakkepesitesQualif);
    }
  };

  return (
    <QualifForm
      title="Szakképesítés létrehozása"
      initialState={emptyState}
      submitBtnText="Létrehoz"
      handleSubmit={handleCreateSubmit}
    />
  );
};

export default connect(null, { createQualification })(QuaifCreate);
