import { Button, Classes } from "@blueprintjs/core";
import React, { useState } from "react";

function ThemeSwither() {
  const darkIcon = "moon",
    lightIcon = "flash";

  const [icon, setIcon] = useState(lightIcon);

  const changeTheme = () => {
    document.getElementsByClassName("App")[0].classList.toggle(Classes.DARK);
    setIcon((prevIcon) => (prevIcon === darkIcon ? lightIcon : darkIcon));
  };

  return (
    <div onClick={changeTheme}>
      <Button minimal icon={icon} />
    </div>
  );
}

export default ThemeSwither;
