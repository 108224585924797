import http from "../../utils/http";
import {
  API_BASE_SZAKKEPESITES_6110,
  API_BASE_SZAKKEPZES_6110,
  API_ENDPOINT,
} from "../../utils/routes";
import { addToastDanger, addToastSuccess } from "../actions/common/toast";
import {
  SZAKKEPZES_GET_KKK_6110_BEGIN,
  SZAKKEPZES_GET_KKK_6110_FAILURE,
  SZAKKEPZES_GET_KKK_6110_SUCCESS,
} from "../actionTypes";

export const getAllKKK6110 = () => async (dispatch) => {
  dispatch(getAllKKKBegin);
  try {
    const json = await http.get(
      `${API_BASE_SZAKKEPZES_6110}/v2${API_ENDPOINT.KKK}/admin`
    );
    dispatch(getAllKKKSuccess(json));
    return json;
  } catch (error) {
    console.log("lol");
    dispatch(getAllKKKFailure(error));
    dispatch(addToastDanger(`Dokumentumok lekérése sikertelen: ${error}`));
  }
};

export const editKKK6110 = (attachmentId, body) => async (dispatch) => {
  // todo reducers, id
  try {
    const json = await http.put(
      `${API_BASE_SZAKKEPZES_6110}/v2${API_ENDPOINT.KKK}/${attachmentId}`,
      body
    );
    dispatch(addToastSuccess("Dokumentum módosítva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Dokumentum módosítás sikertelen: ${error}`));
  }
};

export const createKKK6110 = (body) => async (dispatch) => {
  // todo reducers, id
  try {
    const json = await http.post(
      API_BASE_SZAKKEPZES_6110 + "/v2" + API_ENDPOINT.KKK,
      body
    );
    dispatch(addToastSuccess("Dokumentum létrehozva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Dokumentum létrehozás sikertelen: ${error}`));
  }
};

const getAllKKKBegin = () => ({
  type: SZAKKEPZES_GET_KKK_6110_BEGIN,
});
const getAllKKKSuccess = (kkks) => ({
  type: SZAKKEPZES_GET_KKK_6110_SUCCESS,
  payload: kkks,
});
const getAllKKKFailure = (error) => ({
  type: SZAKKEPZES_GET_KKK_6110_FAILURE,
  payload: { error },
});
