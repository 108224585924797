import { Button, FormGroup, HTMLSelect, Spinner } from "@blueprintjs/core";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { showToast } from "../../../utils/helpers";
import { authFetch } from "../../../utils/http";
import { API_BASE_SCHOLARSHIP } from "../../../utils/routes";

export const ScholarshipApplicantEdit = () => {
  const { id } = useParams();

  const [applicant, setApplicant] = useState();

  useEffect(() => {
    authFetch(`${API_BASE_SCHOLARSHIP}/applicants/${id}`)
      .then((data) => setApplicant(data))
      .catch((err) => {
        console.error(err);
        showToast({
          intent: "danger",
          message: "Hiba történt az adatok lekérése közben!",
        });
      });
  }, []);

  const handleSubmit = async () => {
    // TODO
  };

  if (!applicant) return <Spinner />;

  return (
    <>
      <h1>
        Jelentkező szerkesztése: <i>{applicant.name}</i>
      </h1>

      <form onSubmit={handleSubmit}>
        <FormGroup label="Státusz" labelInfo="(kötelező)">
          <HTMLSelect>
            <option value="NEW">Új</option>
            <option value="APPROVED">ELFOGADVA</option>
            <option value="REJECTED">ELUTASÍTVA</option>
          </HTMLSelect>
        </FormGroup>

        <Button intent="success" icon="floppy-disk" type="submit">
          Mentés
        </Button>
      </form>
    </>
  );
};
