import { Checkbox, FormGroup, InputGroup } from "@blueprintjs/core";
import { Grid } from "../../../common/LayoutHelpers";

export const linkDefaults = {
  download: false,
  isExternal: false,
  title: "",
  url: "",
};

export const LinkFields = ({ data, onDataChange }) => {
  if (!data) return null;

  return (
    <Grid columns={2}>
      <FormGroup
        label="Link felirat"
        labelInfo="(kötelező)"
        helperText="pl. Megnézem, Letöltés stb."
      >
        <InputGroup
          required
          value={data.title}
          onChange={(e) => onDataChange({ ...data, title: e.target.value })}
        />
      </FormGroup>
      <FormGroup
        label="URL"
        labelInfo="(kötelező)"
        helperText="Relatív URL is használható pl. /terkep"
      >
        <InputGroup
          required
          placeholder="https://..."
          value={data.url}
          onChange={(e) => onDataChange({ ...data, url: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          label="Új lapon nyílik meg"
          checked={data.isExternal}
          onChange={(e) => onDataChange({ ...data, isExternal: e.target.checked })}
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          label="Letöltés megnyitás helyett"
          checked={data.download}
          onChange={(e) => onDataChange({ ...data, download: e.target.checked })}
        />
      </FormGroup>
    </Grid>
  );
};
