import {} from "../../actionTypes";
import {
  ABOUT_ITEM_CREATE_BEGIN,
  ABOUT_ITEM_CREATE_FAILURE,
  ABOUT_ITEM_CREATE_SUCCESS,
  ABOUT_ITEM_DELETE_BEGIN,
  ABOUT_ITEM_DELETE_FAILURE,
  ABOUT_ITEM_DELETE_SUCCESS,
  ABOUT_ITEM_GET_BEGIN,
  ABOUT_ITEM_GET_FAILURE,
  ABOUT_ITEM_GET_SUCCESS,
} from "../../actionTypes";
import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getAboutRows = () => async (dispatch) => {
  dispatch(getAboutRowsBegin());
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.ABOUT);
    dispatch(getAboutRowsSuccess(json));
    return json;
  } catch (error) {
    dispatch(getAboutRowsFail(error));
    dispatch(addToastDanger(`Rolunk szovegek betoltese sikertelen: ${error}`));
  }
};

export const deleteAboutRow = (id) => async (dispatch) => {
  // dispatch(deleteAboutRowsBegin());
  try {
    const json = await http.del(`${API_BASE_IKK_ADMIN + API_ENDPOINT.ABOUT}/${id}`);
    // dispatch(getAboutRowsSuccess(json));
    dispatch(addToastSuccess("Rolunk szoveg torolve"));
    return json;
  } catch (error) {
    // dispatch(getAboutRowsFail(error));
    dispatch(addToastDanger(`Rolunk szoveg torlese sikertelen: ${error}`));
  }
};

export const createAboutRow = (data) => async (dispatch) => {
  // dispatch(deleteAboutRowsBegin());
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.ABOUT, data);
    // dispatch(getAboutRowsSuccess(json));
    dispatch(addToastSuccess("Rolunk szoveg letrehozva"));
    return json;
  } catch (error) {
    // dispatch(getAboutRowsFail(error));
    dispatch(addToastDanger(`Rolunk szoveg letrehozasa sikertelen: ${error}`));
  }
};

export const updateAboutRow = (id, data) => async (dispatch) => {
  // dispatch(deleteAboutRowsBegin());
  try {
    const json = await http.put(`${API_BASE_IKK_ADMIN + API_ENDPOINT.ABOUT}/${id}`, data);
    // dispatch(getAboutRowsSuccess(json));
    dispatch(addToastSuccess("Rolunk szoveg modositva"));
    return json;
  } catch (error) {
    // dispatch(getAboutRowsFail(error));
    dispatch(addToastDanger(`Rolunk szoveg modositasa sikertelen: ${error}`));
  }
};

// export function updateItem(id, data) {
//   return (dispatch) => {
//     const token = localStorage.token;
//     dispatch(getAboutRowsBegin());
//     return fetch(BASE_IKK_URL + ENDPOINT_ADMIN_ABOUT + "/" + id, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     })
//       .then((res) => res.json())
//       .then((json) => {
//         dispatch(getAboutRowsSuccess(json));
//         return json;
//       })
//       .catch((error) => dispatch(getAboutRowsFail(error)));
//   };
// }

// export function createItem(data) {
//   return (dispatch) => {
//     const token = localStorage.token;
//     dispatch(getAboutRowsBegin());
//     return fetch(BASE_IKK_URL + ENDPOINT_ADMIN_ABOUT, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     })
//       .then((res) => res.json())
//       .then((json) => {
//         dispatch(getAboutRowsSuccess(json));
//         return json;
//       })
//       .catch((error) => dispatch(getAboutRowsFail(error)));
//   };
// }
// export function deleteItem(id) {
//   return (dispatch) => {
//     const token = localStorage.token;
//     dispatch(getAboutRowsBegin());
//     return fetch(BASE_IKK_URL + ENDPOINT_ADMIN_ABOUT + "/" + id, {
//       method: "DELETE",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((json) => {
//         dispatch(getAboutRowsSuccess(json));
//         return json;
//       })
//       .catch((error) => dispatch(getAboutRowsFail(error)));
//   };
// }

export const getAboutRowsBegin = () => ({
  type: ABOUT_ITEM_GET_BEGIN,
});
export const getAboutRowsSuccess = (items) => ({
  type: ABOUT_ITEM_GET_SUCCESS,
  payload: items,
});
export const getAboutRowsFail = (err) => ({
  type: ABOUT_ITEM_GET_FAILURE,
  payload: { err },
});
