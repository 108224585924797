import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getCentrums, updateItem } from "../../../redux/actions/ikk/traningCenters";
import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT, ROUTES } from "../../../utils/routes";
import CentrumForm from "./CentrumForm";

const CentrumEdit = (props) => {
  const [centrum, setCentrum] = useState();

  const { id } = useParams();

  useEffect(() => {
    getCentrum();
  }, []);

  async function getCentrum() {
    // there is no api to get a single centrum so we request all
    const r = await http.get(`${API_BASE_IKK_ADMIN}${API_ENDPOINT.TRAINING_CENTER}`);
    const cent = r.find((s) => s.id === Number(id));
    setCentrum(cent);
  }

  const handleEditSubmit = async (state) => {
    const res = await props.updateItem(centrum.id, state);
    if (res) props.history.push(ROUTES.centrums);
  };

  return (
    <>
      {centrum && (
        <CentrumForm
          initialState={centrum}
          submitBtnText="Módosít"
          title="Szakképzési centrum módosítása"
          handleSubmit={handleEditSubmit}
          imageRequired={false}
        />
      )}
    </>
  );
};

export default connect(null, { updateItem, getCentrums })(CentrumEdit);
