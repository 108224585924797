import http from "../../../utils/http";
import { API_BASE_SKILLSCOM } from "../../../utils/routes";
import {
  SKILLSCOM_POST_ADD_BEGIN,
  SKILLSCOM_POST_ADD_FAILURE,
  SKILLSCOM_POST_ADD_SUCCESS,
  SKILLSCOM_POST_DELETE_BEGIN,
  SKILLSCOM_POST_DELETE_SUCCESS,
  SKILLSCOM_POST_GET_BEGIN,
  SKILLSCOM_POST_GET_FAILURE,
  SKILLSCOM_POST_GET_ONE_SUCCESS,
  SKILLSCOM_POST_GET_SUCCESS,
  SKILLSCOM_POST_UPDATE_BEGIN,
  SKILLSCOM_POST_UPDATE_FAILURE,
  SKILLSCOM_POST_UPDATE_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const uploadPost = (data) => async (dispatch) => {
  dispatch(uploadPostBegin());
  try {
    const json = await http.post(`${API_BASE_SKILLSCOM}/posts`, data);
    dispatch(uploadPostSuccess(json));
    dispatch(addToastSuccess("Poszt feltöltés sikeres"));
    return json;
  } catch (error) {
    dispatch(uploadPostFailure(error));
    dispatch(addToastDanger(`Poszt feltöltés sikertelen: ${error}`));
  }
};

export const deletePost = (id) => async (dispatch) => {
  dispatch(postDeleteBegin());
  try {
    const json = await http.del(`${API_BASE_SKILLSCOM}/posts/${id}`);
    dispatch(postDeleteSuccess(id));
    dispatch(addToastSuccess("Törles sikeres"));
    console.log("json:", json);
    return json; // null
  } catch (error) {
    dispatch(addToastDanger(`Törles sikertelen: ${error}`));
  }
};

export const updatePost = (id, data) => async (dispatch) => {
  dispatch(updatePostBegin());
  try {
    const json = await http.put(`${API_BASE_SKILLSCOM}/posts/${id}`, data);
    dispatch(updatePostSuccess(json));
    dispatch(addToastSuccess("Poszt frissítve"));
    return json;
  } catch (error) {
    dispatch(updatePostFailure(error));
    dispatch(addToastDanger(`Poszt frissítése sikertelen: ${error}`));
  }
};

export const getOnePost = (id) => async (dispatch) => {
  dispatch(getPostBegin());
  try {
    const json = await http.get(`${API_BASE_SKILLSCOM}/posts/${id}`);
    dispatch(getOnePostSuccess(json));
    return json;
  } catch (error) {
    dispatch(getPostFailure(error));
    dispatch(addToastDanger(`Poszt betöltése sikertelen: ${error}`));
  }
};

export const getPost = (offset = 0, limit = 30) => async (dispatch) => {
  dispatch(getPostBegin());
  try {
    const json = await http.get(
      `${API_BASE_SKILLSCOM}/posts?offset=${offset}&limit=${limit}`
    );
    dispatch(getPostSuccess(json));
    return json;
  } catch (error) {
    dispatch(getPostFailure(error));
    dispatch(addToastDanger("Posztek betöltése sikertelen."));
  }
};

export const verifyPost = (id) => async (dispatch) => {
  try {
    const json = await http.post(`${API_BASE_SKILLSCOM}/posts/${id}/approve`);
    dispatch(addToastSuccess("Poszt elfogadva"));
    return json;
  } catch (error) {
    dispatch(
      addToastDanger(`Poszt elfogadása sikertelen:`, JSON.stringify(error))
    );
  }
};

export const updatePostBegin = () => ({
  type: SKILLSCOM_POST_UPDATE_BEGIN,
});

export const updatePostSuccess = (post) => ({
  type: SKILLSCOM_POST_UPDATE_SUCCESS,
  payload: { post },
});

export const updatePostFailure = (error) => ({
  type: SKILLSCOM_POST_UPDATE_FAILURE,
  payload: { error },
});

export const uploadPostBegin = () => ({
  type: SKILLSCOM_POST_ADD_BEGIN,
});

export const uploadPostSuccess = (post) => ({
  type: SKILLSCOM_POST_ADD_SUCCESS,
  payload: { post },
});

export const uploadPostFailure = (error) => ({
  type: SKILLSCOM_POST_ADD_FAILURE,
  payload: { error },
});

export const getPostBegin = () => ({
  type: SKILLSCOM_POST_GET_BEGIN,
});

export const getPostSuccess = (posts) => ({
  type: SKILLSCOM_POST_GET_SUCCESS,
  payload: posts,
});

export const getOnePostSuccess = (article) => ({
  type: SKILLSCOM_POST_GET_ONE_SUCCESS,
  payload: article,
});

export const getPostFailure = (error) => ({
  type: SKILLSCOM_POST_GET_FAILURE,
  payload: { error },
});

const postDeleteBegin = () => ({
  type: SKILLSCOM_POST_DELETE_BEGIN,
});

const postDeleteSuccess = (id) => ({
  type: SKILLSCOM_POST_DELETE_SUCCESS,
  payload: { id },
});
