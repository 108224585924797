import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import {
  CONST_DATA_IMAGES_GET_BEGIN,
  CONST_DATA_IMAGES_GET_FAILURE,
  CONST_DATA_IMAGES_GET_SUCCESS,
  CONST_DATA_TEXTS_GET_BEGIN,
  CONST_DATA_TEXTS_GET_FAILURE,
  CONST_DATA_TEXTS_GET_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getTexts = (type) => async (dispatch) => {
  dispatch(getTextsBegin());
  try {
    const json = await http.get(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.CONST_DATA}/${type}`
    );
    dispatch(getTextsSuccess(json));
  } catch (error) {
    dispatch(getTextsFailure(error));
    dispatch(addToastDanger(`Adatok lekérése sikertelen: ${error}`));
  }
};

export const getImages = (page) => async (dispatch) => {
  dispatch(getImagesBegin());
  try {
    const json = await http.get(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.CONST_DATA}/${page}`
    );
    dispatch(getImagesSuccess(json));
  } catch (error) {
    dispatch(getImagesFailure(error));
    dispatch(addToastDanger(`Adatok lekérése sikertelen: ${error}`));
  }
};

export const updateItem = (page, id, value, media_id) => async (dispatch) => {
  //dispatch(getTextsBegin());
  try {
    const json = await http.put(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.CONST_DATA}/${page}/${id}`,
      {
        value,
        media_id,
      }
    );
    //dispatch(getTextsSuccess(json));
    dispatch(addToastSuccess("Mentés sikeres"));
    return json;
  } catch (error) {
    //dispatch(getTextsFailure(error));
    dispatch(addToastDanger(`Mentés sikertelen: ${error}`));
  }
};

export const getTextsBegin = () => ({
  type: CONST_DATA_TEXTS_GET_BEGIN,
});
export const getTextsSuccess = (items) => ({
  type: CONST_DATA_TEXTS_GET_SUCCESS,
  payload: items,
});
export const getTextsFailure = (err) => ({
  type: CONST_DATA_TEXTS_GET_FAILURE,
  payload: { err },
});

export const getImagesBegin = () => ({
  type: CONST_DATA_IMAGES_GET_BEGIN,
});

export const getImagesSuccess = (items) => ({
  type: CONST_DATA_IMAGES_GET_SUCCESS,
  payload: items,
});

export const getImagesFailure = (err) => ({
  type: CONST_DATA_IMAGES_GET_FAILURE,
  payload: { err },
});
