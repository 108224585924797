import {
  Button,
  FormGroup,
  HTMLSelect,
  InputGroup,
  NumericInput,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllFaq, getAllFaqCategory } from "../../../redux/actions/szakkepzes/faq";
import MyCkEditor from "../../common/MyCkEditor";
import { LIMIT } from "../../../utils/limits";

const FaqForm = (props) => {
  const [state, setState] = useState(props.initialState);

  useEffect(() => {
    if (props.categories.length === 0) props.getAllFaqCategory();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };
  const handleNumInput = (val) => {
    setState((s) => ({ ...s, order: val }));
  };
  return (
    <div style={{ maxWidth: "800px" }}>
      <h2>{props.title}</h2>
      <form onSubmit={handleSubmit}>
        <FormGroup
          label="Kérdés"
          helperText={`${state.question?.length} / ${LIMIT.FAQ_QUESTION_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <InputGroup
            required
            onChange={handleInput}
            name="question"
            value={state.question}
            intent={state.question?.length > LIMIT.FAQ_QUESTION_MAX_LEN ? "danger" : ""}
          />
        </FormGroup>

        <FormGroup label="Témakör">
          <HTMLSelect
            required
            onChange={handleInput}
            name="category_id"
            value={state.category_id}
          >
            <option value="" disabled>
              Válasszon
            </option>
            {props.categories.map((cat, i) => (
              <option key={i} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>

        <FormGroup label="Válasz">
          <MyCkEditor
            data={state.answer}
            onDataChange={(data) => setState((s) => ({ ...s, answer: data }))}
          />
        </FormGroup>
        <FormGroup label="Pozíció" labelInfo="(kötelező)">
          <NumericInput
            required
            min={0}
            name="position"
            value={state.order}
            onValueChange={handleNumInput}
          />
        </FormGroup>
        <br />
        <Button
          type="submit"
          text={props.submitBtnText}
          icon="floppy-disk"
          intent="success"
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state.faq };
};
export default connect(mapStateToProps, { getAllFaq, getAllFaqCategory })(FaqForm);
