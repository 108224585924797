import { FormGroup, InputGroup } from "@blueprintjs/core";
import MyCkEditor from "../../../common/MyCkEditor";
import { useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";

export const CollapsibleRichText = ({ initialState, onComponentSave }) => {
  const { state, isDirty, handleSimpleInput } = useInputState(initialState);

  return (
    <>
      <FormGroup label="Gomb felirat" labelInfo="(kötelező)">
        <InputGroup
          required
          name="button"
          value={state.button}
          onChange={handleSimpleInput}
        />
      </FormGroup>
      <FormGroup label="Szöveg" labelInfo="(kötelező)">
        <MyCkEditor
          data={state.richText?.html}
          onDataChange={(data) =>
            handleSimpleInput({ target: { name: "richText", value: { html: data } } })
          }
        />
      </FormGroup>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
