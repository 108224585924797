import http from "../../../utils/http";
import { API_BASE_SZAKKEPESITES, API_ENDPOINT } from "../../../utils/routes";
import {
  SZAKKEPESITES_GET_QUALIFS_BEGIN,
  SZAKKEPESITES_GET_QUALIFS_FAIL,
  SZAKKEPESITES_GET_QUALIFS_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

// export const getKEOR = () => async (dispatch) => {
//   dispatch(getKEORBegin());
//   try {
//     const json = await http.get(API_BASE_SZAKKEPESITES + API_ENDPOINT.SZAKKEPESITES_KEOR);

//     dispatch(getKEORSuccess(json));
//     return json;
//   } catch (error) {
//     dispatch(getKEORFail(error));
//     dispatch(addToastDanger(`KEOR betöltése sikertelen: ${error}`));
//   }
// };

export const getAllQualification = () => async (dispatch) => {
  dispatch(getAllQualifBegin());
  try {
    const json = await http.get(
      API_BASE_SZAKKEPESITES + API_ENDPOINT.SZAKKEPESITES_QUALIFICATIONS
    );
    dispatch(getAllQualifSuccess(json));
    return json;
  } catch (error) {
    dispatch(getAllQualifFail(error));
    dispatch(addToastDanger(`Szakképesítések betöltése sikertelen: ${error}`));
  }
};

export const createQualification = (body) => async (dispatch) => {
  try {
    const json = await http.post(
      API_BASE_SZAKKEPESITES + API_ENDPOINT.SZAKKEPESITES_QUALIFICATIONS,
      { ...body, description: "" }
    );
    dispatch(addToastSuccess("Szakképesítés létrehozva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakképesítés létrehozása sikertelen: ${error}`));
  }
};

export const createQualificationDocument = (id, body) => async (dispatch) => {
  try {
    const json = await http.post(
      API_BASE_SZAKKEPESITES +
        API_ENDPOINT.SZAKKEPESITES_QUALIFICATIONS +
        "/" +
        id +
        "/document",
      body
    );
    dispatch(addToastSuccess("Szakképesítés létrehozva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakképesítés létrehozása sikertelen: ${error}`));
  }
};
export const updateDocument = (docId, data) => async (dispatch) => {
  try {
    const json = await http.put(
      `${
        API_BASE_SZAKKEPESITES + API_ENDPOINT.SZAKKEPESITES_DOCUMENT
      }/${docId}`,
      data
    );
    dispatch(addToastSuccess("Dokumentum módosítása"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Dokumentum módosítása sikertelen: ${error}`));
  }
};
export const deleteDocument = (qualifId, docId) => async (dispatch) => {
  try {
    const json = await http.del(
      `${API_BASE_SZAKKEPESITES + API_ENDPOINT.SZAKKEPESITES_DOCUMENT}/${docId}`
    );
    dispatch(addToastSuccess("Dokumentum törölve"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Dokumentum törlése sikertelen: ${error}`));
  }
};

export const deleteQualification = (id) => async (dispatch) => {
  try {
    const json = await http.del(
      `${
        API_BASE_SZAKKEPESITES + API_ENDPOINT.SZAKKEPESITES_QUALIFICATIONS
      }/${id}`
    );
    dispatch(addToastSuccess("Szakképesítés törölve"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakképesítés törlése sikertelen: ${error}`));
  }
};

export const updateQualification = (id, body) => async (dispatch) => {
  try {
    const json = await http.put(
      `${
        API_BASE_SZAKKEPESITES + API_ENDPOINT.SZAKKEPESITES_QUALIFICATIONS
      }/${id}`,
      body
    );
    dispatch(addToastSuccess("Szakképesítés módosítva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Módosítás sikertelen: ${error}`));
  }
};

const getAllQualifBegin = () => ({
  type: SZAKKEPESITES_GET_QUALIFS_BEGIN,
});
const getAllQualifSuccess = (json) => ({
  type: SZAKKEPESITES_GET_QUALIFS_SUCCESS,
  payload: json,
});
const getAllQualifFail = (error) => ({
  type: SZAKKEPESITES_GET_QUALIFS_FAIL,
  error: { error },
});

// const getKEORBegin = () => ({
//   type: SZAKKEPESITES_GET_KEOR_BEGIN,
// });
// const getKEORSuccess = (json) => ({
//   type: SZAKKEPESITES_GET_KEOR_SUCCESS,
//   payload: json,
// });
// const getKEORFail = (error) => ({
//   type: SZAKKEPESITES_GET_KEOR_FAIL,
//   error: { error },
// });
