import React from "react";
import { connect } from "react-redux";
import { createFaqCategory } from "../../../redux/actions/szakkepzes/faq";
import FaqCategoryForm from "./FaqCategoryForm";
import { ROUTES } from "../../../utils/routes";

const initialState = {
  name: "",
  featured: false,
  media_id: null,
  order:0,
};

const FaqCategoryCreate = (props) => {
  // submit button pressed on create form
  const handleSubmit = async (state, setState, e) => {
    e.preventDefault();
    await props.createFaqCategory(state);
    setState(initialState);
    props.history.push(ROUTES.faqCategory);
  };

  return (
    <FaqCategoryForm
      title="Új gyakori kérdés témakör"
      onSubmit={handleSubmit}
      initialState={initialState}
      imageRequired={true}
    />
  );
};

export default connect(null, { createFaqCategory })(FaqCategoryCreate);
