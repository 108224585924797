import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllFaq, updateFaq } from "../../../redux/actions/szakkepzes/faq";
import { ROUTES } from "../../../utils/routes";
import FaqForm from "./FaqForm";

const FaqEdit = (props) => {
  const [faq, setFaq] = useState();

  const { id } = useParams();

  useEffect(() => {
    if (props.faqs.length === 0) props.getAllFaq();
  }, []);

  useEffect(() => {
    const f = props.faqs.find((f) => f.id === Number(id));
    f && setFaq({ ...f, category_id: f.category.id });
  }, [props.faqs]);

  const handleEditSubmit = async (state) => {
    const res = await props.updateFaq(faq.id, state);
    if (res) props.history.push(ROUTES.faq);
  };

  if (!faq) return <Spinner />;

  return (
    <>
      <FaqForm
        title="Gyakori kérdés szerkesztése"
        initialState={faq}
        submitBtnText="Mentés"
        handleSubmit={handleEditSubmit}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.faq };
};
export default connect(mapStateToProps, { getAllFaq, updateFaq })(FaqEdit);
