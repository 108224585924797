import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  TextArea,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCategory, modifyCategory } from "../../../redux/actions/ikk/publicInfo";

const ArchiveEditCategoryDialog = (props) => {
  const [category, setCategory] = useState();

  // the category is initially received from the props and then managed in local state afterwards
  useEffect(() => {
    setCategory(props.category);
  }, [props]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setCategory((c) => ({ ...c, [name]: value }));
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setCategory((c) => ({ ...c, [name]: checked }));
  };

  const handleDialogClose = () => {
    props.setCategory(null);
  };

  const handleSave = async () => {
    await props.modifyCategory(
      category.id,
      category.name,
      category.visible,
      category.deep_link
    );
    props.setCategory(null);
    props.getCategory();
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      title="Kategória módosítása"
      isOpen={!!category}
      usePortal={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup label="Név">
          <TextArea
            fill
            growVertically
            name="name"
            value={category?.name}
            onChange={handleInput}
            style={{ resize: "none" }}
          />
        </FormGroup>

        <FormGroup>
          <Checkbox
            label="Látható"
            name="visible"
            checked={category?.visible}
            onChange={handleCheckbox}
          />
          <Checkbox
            label="Deep link"
            name="deep_link"
            checked={category?.deep_link}
            onChange={handleCheckbox}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Mégse" onClick={handleDialogClose} />

          <Button
            text="Mentés"
            intent="success"
            icon="floppy-disk"
            onClick={handleSave}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default connect(null, { modifyCategory, getCategory })(ArchiveEditCategoryDialog);
