import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAllQualification,
  updateQualification,
  updateDocument,
} from "../../../redux/actions/szakkepesites/pk";
import { ROUTES } from "../../../utils/routes";
import QualifForm from "./QualifForm";

const QualifEdit = (props) => {
  const [qualif, setQualif] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (props.qualifications.length === 0) props.getAllQualification();
  }, []);

  useEffect(() => {
    const f = props.qualifications.find((f) => f.id === Number(id));
    f && setQualif({ ...f });
  }, [props.qualifications]);

  const handleEditSubmit = async (state) => {
    const res = await props.updateQualification(qualif.id, state);
    if (state.documents) {
      for (const doc of state.documents)
        await props.updateDocument(doc.id, doc);
    }
    if (res) props.history.push(ROUTES.szakkepesitesQualif);
  };

  if (!qualif) return <Spinner />;

  return (
    <>
      <QualifForm
        title="Szakképesítés szerkesztése"
        initialState={qualif}
        submitBtnText="Mentés"
        handleSubmit={handleEditSubmit}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.pk };
};
export default connect(mapStateToProps, {
  getAllQualification,
  updateQualification,
  updateDocument,
})(QualifEdit);
