export const HStack = ({ children, ...rest }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 20,
        ...rest,
      }}
    >
      {children}
    </div>
  );
};

export const Stack = ({ children, ...rest }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, ...rest }}>
      {children}
    </div>
  );
};

export const Grid = ({ children, columns, ...rest }) => {
  return (
    <div
      style={{
        display: "grid",
        gap: 20,
        gridTemplateColumns: `repeat(${columns}, minmax(0,1fr))`,
        ...rest,
      }}
    >
      {children}
    </div>
  );
};
