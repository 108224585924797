import {
  Button,
  Callout,
  Divider,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { createTag } from "../../../redux/actions/ikk/tags";
import SuggestQualification from "../../common/SuggestQualification";

const TagsEdit = (props) => {
  const [tag, setTag] = useState();

  const onTagSelect = (morphType) => (item, e) => {
    setTag({
      name: item.name,
      morph_to: morphType,
      morph_to_id: item.id,
    });
  };

  const submitTag = async () => {
    props.createTag(tag);
    setTag(null); // reset input
  };

  return (
    <>
      <h2>TODO Tag szerkesztése</h2>

      <Callout
        icon="info-sign"
        intent="primary"
        style={{ marginBottom: "20px" }}
      >
        A "kapcsolat" tagek nevét nem célszerű változtatni a könnyen
        kezelhetőség érdekében.
      </Callout>

      <FormGroup label="Normal tag" helperText="Uj szabad szoveges cimke">
        <InputGroup
          fill={false}
          onFocus={() => setTag(null)}
          onChange={(e) => setTag({ name: e.target.value })}
        />
      </FormGroup>
      <Button intent="success" onClick={submitTag}>
        Mentes
      </Button>

      <Divider style={{ margin: "20px 0" }} />

      <FormGroup
        label="Szakma kapcsolat"
        helperText="Ha egy hirhez/esemenyhez egy szakmat akarunk hozzarendelni"
      >
        <SuggestQualification onSelect={onTagSelect("sector")} />
      </FormGroup>
      <Button intent="success" onClick={submitTag}>
        Mentes
      </Button>

      <Divider style={{ margin: "20px 0" }} />

      <FormGroup
        label="Agazat kapcsolat"
        helperText="Ha egy hirhez/esemenyhez egy teljes agazatot akarunk hozzarendelni"
      >
        <SuggestQualification onSelect={onTagSelect("profession")} />
      </FormGroup>
      <Button
        intent="success"
        style={{ marginBottom: "100px" }}
        onClick={submitTag}
      >
        Mentes
      </Button>
    </>
  );
};

const mapStateToProps = (state) => {
  //
};

export default connect(null, {
  createTag,
})(TagsEdit);
