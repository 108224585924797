import http from "../../utils/http";
import {
  API_BASE_SZAKKEPESITES,
  API_ENDPOINT,
  API_BASE_SZAKKEPESITES_6110,
} from "../../utils/routes";
import {
  SZAKKEPESITES_GET_QUALIFS_BEGIN,
  SZAKKEPESITES_GET_QUALIFS_FAIL,
  SZAKKEPESITES_GET_QUALIFS_SUCCESS,
} from "../actionTypes";
import { addToastDanger, addToastSuccess } from "../actions/common/toast";

export const getAllQualification6110 = () => async (dispatch) => {
  dispatch(getAllQualifBegin());
  try {
    const json = await http.get(
      `${API_BASE_SZAKKEPESITES_6110}/v2${API_ENDPOINT.SZAKKEPESITES_QUALIFICATIONS}`
    );
    dispatch(getAllQualifSuccess(json));
    return json;
  } catch (error) {
    dispatch(getAllQualifFail(error));
    dispatch(addToastDanger(`Szakképesítések betöltése sikertelen: ${error}`));
  }
};

export const createQualificationDocument6110 =
  (id, body) => async (dispatch) => {
    try {
      const json = await http.post(
        `${API_BASE_SZAKKEPESITES_6110}/v2${API_ENDPOINT.SZAKKEPESITES_QUALIFICATIONS_DOCUMENT}`,
        body
      );
      dispatch(addToastSuccess("Szakképesítés létrehozva"));
      return json;
    } catch (error) {
      dispatch(
        addToastDanger(`Szakképesítés létrehozása sikertelen: ${error}`)
      );
    }
  };

export const updateDocument6110 = (docId, data) => async (dispatch) => {
  try {
    const json = await http.put(
      `${API_BASE_SZAKKEPESITES_6110}/v2${API_ENDPOINT.SZAKKEPESITES_QUALIFICATIONS_DOCUMENT}/${docId}`,
      data
    );
    dispatch(addToastSuccess("Dokumentum módosítása"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Dokumentum módosítása sikertelen: ${error}`));
  }
};

export const deleteDocument6110 = (qualifId, docId) => async (dispatch) => {
  try {
    const json = await http.del(
      `${
        API_BASE_SZAKKEPESITES_6110 + API_ENDPOINT.SZAKKEPESITES_DOCUMENT
      }/${docId}`
    );
    dispatch(addToastSuccess("Dokumentum törölve"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Dokumentum törlése sikertelen: ${error}`));
  }
};

const getAllQualifBegin = () => ({
  type: SZAKKEPESITES_GET_QUALIFS_BEGIN,
});
const getAllQualifSuccess = (json) => ({
  type: SZAKKEPESITES_GET_QUALIFS_SUCCESS,
  payload: json,
});
const getAllQualifFail = (error) => ({
  type: SZAKKEPESITES_GET_QUALIFS_FAIL,
  error: { error },
});
