import {
  CONST_PUBLIC_INFO_CATEGORY_GET_BEGIN,
  CONST_PUBLIC_INFO_CATEGORY_GET_FAILURE,
  CONST_PUBLIC_INFO_CATEGORY_GET_SUCCESS,
  CONST_PUBLIC_INFO_CATEGORY_POSITION_BEGIN,
  CONST_PUBLIC_INFO_CATEGORY_POSITION_FAILURE,
  CONST_PUBLIC_INFO_CATEGORY_POSITION_SUCCESS,
  CONST_PUBLIC_INFO_ITEM_GET_BEGIN,
  CONST_PUBLIC_INFO_ITEM_GET_FAILURE,
  CONST_PUBLIC_INFO_ITEM_GET_SUCCESS,
} from "../actionTypes";

const initialState = {
  const_data: [],
  loading: false,
  error: null,
  categories: [],
  flat_categories: [],
  items: [],
};

function recursiveChild(tree, all) {
  let newTree = tree.map((cat) => {
    let r = cat;
    r["children"] = all.filter((cat) => cat.parent_cat === r.id);
    r.children = recursiveChild(r.children, all);
    return r;
  });
  return newTree;
}

function recursiveFlat(tree, depth, flat) {
  tree.forEach((cat) => {
    flat.push({ ...cat, depth });
    flat = recursiveFlat(cat.children, depth + 1, flat);
  });
  return flat;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CONST_PUBLIC_INFO_CATEGORY_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case CONST_PUBLIC_INFO_CATEGORY_GET_SUCCESS: {
      let root = action.payload
        .map((cat) => {
          if (cat.parent_cat == null) return cat;
          return null;
        })
        .filter((cat) => cat !== null);
      let tree = recursiveChild(root, action.payload);
      let flat = recursiveFlat(tree, 0, []);
      console.log({ flat, tree });
      return {
        ...state,
        loading: false,
        error: null,
        categories: tree,
        flat_categories: flat,
      };
    }
    case CONST_PUBLIC_INFO_CATEGORY_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case CONST_PUBLIC_INFO_ITEM_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case CONST_PUBLIC_INFO_ITEM_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload,
      };
    }
    case CONST_PUBLIC_INFO_ITEM_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case CONST_PUBLIC_INFO_CATEGORY_POSITION_BEGIN:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CONST_PUBLIC_INFO_CATEGORY_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CONST_PUBLIC_INFO_CATEGORY_POSITION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
