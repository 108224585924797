import { Button, Classes, Code, Dialog, Intent } from "@blueprintjs/core";
import React, { useState } from "react";

export const DeleteConfirmation = ({
  isOpen,
  onCancel,
  onDelete,
  recordName, // highlighted text
  text, // extra text to display in the dialog
}) => {
  const [step, setStep] = useState(1);

  const handleStep = () => {
    setStep(2);
  };

  const handleCancel = () => {
    setStep(1);
    onCancel();
  };

  return (
    <Dialog
      title="Törlés megerősítése"
      icon="warning-sign"
      usePortal={false} // otherwise global dark/light theme is not respected for some reason
      onClose={handleCancel}
      /* className={Classes.DARK} */
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        {step === 1 ? (
          <p>
            Biztosan törli az alábbi rekordo(ka)t? A művelet nem visszavonható.{" "}
            {text}
          </p>
        ) : (
          <h2>Biztosan törli?</h2>
        )}
        <p>
          <Code>{recordName}</Code>
        </p>
      </div>
      <br />
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleCancel}>Mégse</Button>

          <Button
            icon="trash"
            intent={Intent.DANGER}
            onClick={step === 1 ? handleStep : onDelete}
          >
            Törlés
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
