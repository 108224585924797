import http from "../../../utils/http";
import { API_BASE_SZAKKEPZES, API_ENDPOINT } from "../../../utils/routes";
import {
  SZAKKEPZES_CONNECTIONS_IMPORT_BEGIN,
  SZAKKEPZES_CONNECTIONS_IMPORT_FAILURE,
  SZAKKEPZES_CONNECTIONS_IMPORT_SUCCESS,
  SZAKKEPZES_INSTITUTES_IMPORT_BEGIN,
  SZAKKEPZES_INSTITUTES_IMPORT_FAILURE,
  SZAKKEPZES_INSTITUTES_IMPORT_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const importInstitutesFromExcel = (file, type) => async (dispatch) => {
  dispatch(importInstitutesBegin());
  try {
    var form = new FormData();
    form.append("file", file);
    form.append("type", type);
    const json = await http.uploadFile(
      API_BASE_SZAKKEPZES + API_ENDPOINT.SZAKKEPZES_INSTITUTES_BULK,
      form
    );
    dispatch(importInstitutesSuccess(json));
    dispatch(addToastSuccess("Import sikeres"));
    return json;
  } catch (error) {
    dispatch(importInstitutesFailure(error));
    dispatch(addToastDanger(`Intézmények importálása sikertelen: ${error}`));
  }
};

export const importConnectionsFromExcel = (file, type) => async (dispatch) => {
  dispatch(importConnectionsBegin());
  try {
    var form = new FormData();
    form.append("file", file);
    form.append("type", type);
    const json = await http.uploadFile(
      API_BASE_SZAKKEPZES + API_ENDPOINT.SZAKKEPZES_QUALIFICATIONS_CONNECTIONS,
      form
    );
    dispatch(importConnectionsSuccess(json));
    dispatch(addToastSuccess("Import sikeres"));
    return json;
  } catch (error) {
    dispatch(importConnectionsFailure(error));
    dispatch(
      addToastDanger(`Összerendelések importálása sikertelen: ${error}`)
    );
  }
};

const importInstitutesBegin = () => ({
  type: SZAKKEPZES_INSTITUTES_IMPORT_BEGIN,
});

const importInstitutesSuccess = (items) => ({
  type: SZAKKEPZES_INSTITUTES_IMPORT_SUCCESS,
  payload: items,
});

const importInstitutesFailure = (err) => {
  console.log(err);
  return {
    type: SZAKKEPZES_INSTITUTES_IMPORT_FAILURE,
    payload: { err },
  };
};

const importConnectionsBegin = () => ({
  type: SZAKKEPZES_CONNECTIONS_IMPORT_BEGIN,
});

const importConnectionsSuccess = (items) => ({
  type: SZAKKEPZES_CONNECTIONS_IMPORT_SUCCESS,
  payload: items,
});

const importConnectionsFailure = (err) => {
  console.log(err);
  return {
    type: SZAKKEPZES_CONNECTIONS_IMPORT_FAILURE,
    payload: { err },
  };
};
