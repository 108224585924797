import http from "../../../utils/http";
import {
  API_BASE_IKK,
  API_BASE_IKK_ADMIN,
  API_ENDPOINT,
} from "../../../utils/routes";
import {
  GROUPS_GET_BEGIN,
  GROUPS_GET_FAIL,
  GROUPS_GET_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getAllGroups = () => async (dispatch) => {
  dispatch(getAllGroupBegin());
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.GROUPS);

    dispatch(getAllGroupSuccess(json));
  } catch (error) {
    dispatch(getAllGroupFail(error));
    dispatch(addToastDanger(`Kategóriák betöltése sikertelen: ${error}`));
  }
};

export const createGroup = (data) => async (dispatch) => {
  try {
    const json = await http.post(
      API_BASE_IKK_ADMIN + API_ENDPOINT.GROUPS,
      data
    );
    dispatch(addToastSuccess("Group létrehozva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Group létrehozás sikertelen: ${error}`));
  }
};

const getAllGroupSuccess = (json) => ({
  type: GROUPS_GET_SUCCESS,
  payload: json,
});
const getAllGroupBegin = () => ({
  type: GROUPS_GET_BEGIN,
});
const getAllGroupFail = (error) => ({
  type: GROUPS_GET_FAIL,
  error: { error },
});
