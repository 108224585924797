import { Spinner, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { deleteEvent, getEvent } from "../../../redux/actions/ikk/event";
import { ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const EventsAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);

  useEffect(() => {
    props.getEvent();
  }, []);

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = async () => {
    setRowToDelete(null);
    await props.deleteEvent(rowToDelete.id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Esemény neve",
        accessor: "title",
      },
      {
        Header: "Kezdés",
        accessor: "from",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Vége",
        accessor: "till",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Megjelenés ideje",
        accessor: "published_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Link",
        accessor: "link",
        Cell: ({ value }) => (
          <a href={value} target="_blank" style={{ wordBreak: "break-all" }}>
            {value}
          </a>
        ),
      },
      {
        Header: "Helyszín",
        accessor: "location",
        Cell: ({ value }) => (
          <a
            href={value.link}
            target="_blank"
            style={{ wordBreak: "break-all" }}
          >
            {value.name}
          </a>
        ),
      },
      {
        Header: "Tagek",
        accessor: "tags",
        Cell: ({ value }) => {
          return value.map((tag) => (
            <Tag round minimal key={tag.id} style={{ margin: "2px" }}>
              {tag.name}
            </Tag>
          ));
        },
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.eventsEdit, { id: row.original.id })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes esemény</h2>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.events} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { events, loading } = state.events;
  return { events, loading };
};
export default connect(mapStateToProps, { getEvent, deleteEvent })(EventsAll);
