import { Card } from "@blueprintjs/core";

export const Details = ({ summary, children }) => {
  return (
    <details>
      <summary
        style={{
          outline: "none",
          userSelect: "none",
          margin: 10,
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        {summary}
      </summary>
      <Card style={{ margin: `0 20px` }}>{children}</Card>
    </details>
  );
};
