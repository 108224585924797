import { Classes, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import { CheckRoles } from "../../common/CheckRoles";

const MenuSzakkepesites = () => {
  return (
    <Menu className={Classes.ELEVATION_2}>
      <MenuDivider title="Szakképesítés" />
      <MenuItem icon="document" text="Programkövetelmények">
        <MenuItem
          icon="plus"
          text="Új szakképesítés"
          tagName={Link}
          to={ROUTES.szakkepesitesQualifCreate}
        />
        <MenuItem
          icon="plus"
          text="Új PK"
          tagName={Link}
          to={ROUTES.szakkepesitesQualifDocCreate}
        />
        <MenuItem
          icon="edit"
          text="Összes PK"
          tagName={Link}
          to={ROUTES.szakkepesitesQualif}
        />
      </MenuItem>

      <CheckRoles route={ROUTES.pkCards}>
        <MenuItem icon="document" text="PK kártyák">
          <MenuItem
            icon="plus"
            text="Új PK kártya"
            tagName={Link}
            to={ROUTES.pkCardCreate}
          />
          <MenuItem
            icon="edit"
            text="Összes PK kártya"
            tagName={Link}
            to={ROUTES.pkCards}
          />
        </MenuItem>
      </CheckRoles>

      <MenuDivider title="Statikus tartalom" />

      <MenuItem
        icon="align-left"
        tagName={Link}
        to={ROUTES.szakkepesitesStaticTexts}
        text="Szövegek"
      />

    </Menu>
  );
};

export default MenuSzakkepesites;
