import { MenuItem, Position } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { API_BASE_PROFCARD } from "../../utils/routes";
import style from "../szakkepzes/KKK/KKK.module.scss";

const SuggestSector = ({
  onSelect, // callback, signature: (item, event) => {}
}) => {
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    getSectors();
  }, []);

  const getSectors = async () => {
    fetch(API_BASE_PROFCARD + "/sectors")
      .then((res) => res.json())
      .then((jsonarr) => {
        setSectors(jsonarr);
      });
  };

  const itemRenderer = (item, { handleClick, modifiers, query }) => (
    <MenuItem
      key={item.id}
      text={item.name}
      onClick={handleClick}
      active={modifiers.active}
    />
  );

  const filterSectors = (query, qualif, _index, exactMatch) => {
    const normalizedName = qualif.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedName === normalizedQuery;
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0;
    }
  };

  return (
    <Suggest
      items={sectors}
      itemRenderer={itemRenderer}
      inputValueRenderer={(item) => item.name}
      noResults={<MenuItem disabled={true} text="Nincs találat" />}
      onItemSelect={onSelect}
      popoverProps={{
        popoverClassName: style.qualifselectPopover,
        position: Position.BOTTOM_LEFT,
      }}
      itemPredicate={filterSectors}
      inputProps={{ required: true, placeholder: "Válasszon..." }}
    />
  );
};

export default SuggestSector;
