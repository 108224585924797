import { AnchorButton, Spinner } from "@blueprintjs/core";
import truncate from "html-truncate";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { showToast } from "../../../utils/helpers";
import { authFetch } from "../../../utils/http";
import { API_BASE_SCHOLARSHIP, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

export const ScholarshipTemplatesAll = () => {
  const [rowDelete, setRowDelete] = useState();
  const [templates, setTemplates] = useState();

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = () => {
    authFetch(`${API_BASE_SCHOLARSHIP}/studentship-template`)
      .then((data) => setTemplates(data))
      .catch((err) => {
        console.error(err);
        showToast({
          intent: "danger",
          message: "Hiba történt az adatok lekérése közben!",
        });
      });
  };

  const handleDeleteSubmit = async () => {
    authFetch(`${API_BASE_SCHOLARSHIP}/studentship-template/${rowDelete.id}`, {
      method: "DELETE",
    })
      .then(() => {
        showToast({ message: "Törlés sikeres" });
        getTemplates();
      })
      .catch((err) => {
        console.error(err);
        showToast({
          intent: "danger",
          message: "Hiba történt a törlés közben!",
        });
      })
      .finally(() => setRowDelete(null));
  };

  const handleSort = ({ oldIndex, newIndex, sortedData }) => {
    console.log(sortedData);
    // TODO PUT
    const body = {
      tenderTemplateIdsInOrder: sortedData.map((d) => d.id),
    };
    authFetch(`${API_BASE_SCHOLARSHIP}/studentship-template/order`, {
      method: "PUT",
      body: JSON.stringify(body),
    })
      .then(() => {
        showToast({ message: "Sorrend módosítás sikeres!" });
      })
      .catch((err) => {
        console.error(err);
        showToast({
          intent: "danger",
          message: "Hiba történt a sorrend módosítás közben!",
        });
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Név",
        accessor: "name",
      },
      {
        Header: "Leírás",
        accessor: "content",
        Cell: ({ value }) => (
          <div dangerouslySetInnerHTML={{ __html: truncate(value, 100) }} />
        ),
      },
      {
        Header: "Fájl",
        accessor: "fileUrl",
        Cell: ({ value }) =>
          !value ? (
            "Nincs"
          ) : (
            <AnchorButton
              href={`${API_BASE_SCHOLARSHIP.replace(`/api`, "")}${value}`}
              target="_blank"
              small
              icon="document-open"
              text="Megnyit"
            />
          ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.scholarshipTemplateEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={() => setRowDelete(row.original)}
          />
        ),
      },
    ],
    []
  );

  if (!templates) return <Spinner />;

  return (
    <>
      <h1>Összes ösztöndíj sablon</h1>

      <DeleteConfirmation
        isOpen={!!rowDelete}
        onCancel={() => setRowDelete(null)}
        onDelete={handleDeleteSubmit}
        recordName={`Sablon: ${rowDelete?.name}`}
      />

      <TableAdvanced
        columns={columns}
        data={templates}
        allowSort
        onSortChange={handleSort}
      />
    </>
  );
};
