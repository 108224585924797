import http from "../../../utils/http";
import { API_BASE_SKILLSCOM } from "../../../utils/routes";
import {
  SKILLSCOM_EDITOR_ADD_BEGIN,
  SKILLSCOM_EDITOR_ADD_FAILURE,
  SKILLSCOM_EDITOR_ADD_SUCCESS,
  SKILLSCOM_EDITOR_DELETE_BEGIN,
  SKILLSCOM_EDITOR_DELETE_SUCCESS,
  SKILLSCOM_EDITOR_GET_BEGIN,
  SKILLSCOM_EDITOR_GET_FAILURE,
  SKILLSCOM_EDITOR_GET_ONE_SUCCESS,
  SKILLSCOM_EDITOR_GET_SUCCESS,
  SKILLSCOM_EDITOR_UPDATE_BEGIN,
  SKILLSCOM_EDITOR_UPDATE_FAILURE,
  SKILLSCOM_EDITOR_UPDATE_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const uploadEditor = (data) => async (dispatch) => {
  dispatch(uploadEditorBegin());
  try {
    const json = await http.post(`${API_BASE_SKILLSCOM}/admin/editors`, data);
    dispatch(uploadEditorSuccess(json));
    dispatch(addToastSuccess("Szerkesztő hozzáadaása sikeres"));
    return json;
  } catch (error) {
    dispatch(uploadEditorFailure(error));
    dispatch(addToastDanger(`Szerkesztő hozzáadása sikertelen: ${error}`));
  }
};

export const deleteEditor = (id) => async (dispatch) => {
  dispatch(editorDeleteBegin());
  try {
    const json = await http.del(`${API_BASE_SKILLSCOM}/admin/editors/${id}`);
    dispatch(editorDeleteSuccess(id));
    dispatch(addToastSuccess("Törles sikeres"));
    console.log("json:", json);
    return json; // null
  } catch (error) {
    dispatch(addToastDanger(`Törles sikertelen: ${error}`));
  }
};

export const updateEditor = (id, data) => async (dispatch) => {
  dispatch(updateEditorBegin());
  try {
    const json = await http.put(
      `${API_BASE_SKILLSCOM}/admin/editors/${id}`,
      data
    );
    dispatch(updateEditorSuccess(json));
    dispatch(addToastSuccess("Szerkesztő frissítve"));
    return json;
  } catch (error) {
    dispatch(updateEditorFailure(error));
    dispatch(addToastDanger(`Szerkesztő frissítése sikertelen: ${error}`));
  }
};

export const getOneEditor = (id) => async (dispatch) => {
  dispatch(getEditorBegin());
  try {
    const json = await http.get(`${API_BASE_SKILLSCOM}/admin/editors/${id}`);
    dispatch(getOneEditorSuccess(json));
    return json;
  } catch (error) {
    dispatch(getEditorFailure(error));
    dispatch(addToastDanger(`Szerkesztő betöltése sikertelen: ${error}`));
  }
};

export const getEditor = (offset = 0, limit = 30) => async (dispatch) => {
  dispatch(getEditorBegin());
  try {
    const json = await http.get(
      `${API_BASE_SKILLSCOM}/admin/editors?offset=${offset}&limit=${limit}`
    );
    dispatch(getEditorSuccess(json));
    return json;
  } catch (error) {
    dispatch(getEditorFailure(error));
    dispatch(addToastDanger("Szerkesztő betöltése sikertelen."));
  }
};

export const updateEditorBegin = () => ({
  type: SKILLSCOM_EDITOR_UPDATE_BEGIN,
});

export const updateEditorSuccess = (editor) => ({
  type: SKILLSCOM_EDITOR_UPDATE_SUCCESS,
  payload: { editor },
});

export const updateEditorFailure = (error) => ({
  type: SKILLSCOM_EDITOR_UPDATE_FAILURE,
  payload: { error },
});

export const uploadEditorBegin = () => ({
  type: SKILLSCOM_EDITOR_ADD_BEGIN,
});

export const uploadEditorSuccess = (editor) => ({
  type: SKILLSCOM_EDITOR_ADD_SUCCESS,
  payload: { editor },
});

export const uploadEditorFailure = (error) => ({
  type: SKILLSCOM_EDITOR_ADD_FAILURE,
  payload: { error },
});

export const getEditorBegin = () => ({
  type: SKILLSCOM_EDITOR_GET_BEGIN,
});

export const getEditorSuccess = (editor) => ({
  type: SKILLSCOM_EDITOR_GET_SUCCESS,
  payload: editor,
});

export const getOneEditorSuccess = (article) => ({
  type: SKILLSCOM_EDITOR_GET_ONE_SUCCESS,
  payload: article,
});

export const getEditorFailure = (error) => ({
  type: SKILLSCOM_EDITOR_GET_FAILURE,
  payload: { error },
});

const editorDeleteBegin = () => ({
  type: SKILLSCOM_EDITOR_DELETE_BEGIN,
});

const editorDeleteSuccess = (id) => ({
  type: SKILLSCOM_EDITOR_DELETE_SUCCESS,
  payload: { id },
});
