import { SZAKKEPZES_GET_KKK_BEGIN, SZAKKEPZES_GET_KKK_FAILURE, SZAKKEPZES_GET_KKK_SUCCESS, SZAKKEPZES_GET_SINGLE_KKK } from "../../actionTypes";

const initialState = {
  items: [],
  loading: false,
  error: null,
  singleItem: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SZAKKEPZES_GET_KKK_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SZAKKEPZES_GET_KKK_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload,
      };
    }
    case SZAKKEPZES_GET_KKK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        items: [],
      };
    }
    case SZAKKEPZES_GET_SINGLE_KKK: {
        return {
            ...state,
            loading: false,
            error: false,
            singleItem: state.items.find(i=>i.id===action.payload)
        }
    }
    default:
      return state;
  }
}
