import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleUser, updateUser } from "../../redux/actions/auth/users";
import { ROUTES } from "../../utils/routes";
import UsersForm from "./UsersForm";

const UsersEdit = (props) => {
  const { id } = useParams();
  const [editUser, setEditUser] = useState();

  useEffect(() => {
    async function getSingleUser() {
      const res = await props.getSingleUser(id);

      setEditUser({ ...res});
    }
    getSingleUser();
  }, []);

  const handleEditSubmit = async (state) => {
      console.log(state);
      props.updateUser(state.id,state);
  };

  if (props.loading) return <Spinner intent="primary" />;
  return (
    <>
      {props.user && (
        <UsersForm
          initialState={props.user}
          submitBtnText="Módosít"
          title={`${props.user.email} szerkesztése`}
          onSubmit={handleEditSubmit}
          imageRequired={false}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  console.log(state.user)
  const { user, loading } = state.user;
  return { user, loading };
};
export default connect(mapStateToProps, { getSingleUser, updateUser })(UsersEdit);
