import { FormGroup, HTMLSelect, InputGroup } from "@blueprintjs/core";
import { HStack } from "../../../common/LayoutHelpers";
import { colors, icons, useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";

export const SectionHeading = ({ initialState, onComponentSave }) => {
  const { state, isDirty, handleSimpleInput } = useInputState(initialState);

  return (
    <>
      <HStack>
        <FormGroup label="Cím" labelInfo="(kötelező)" style={{ flexGrow: 1 }}>
          <InputGroup
            required
            name="title"
            value={state.title}
            onChange={handleSimpleInput}
          />
        </FormGroup>

        <FormGroup label="Ikon">
          <HTMLSelect value={state.icon} name="icon" onChange={handleSimpleInput}>
            <option value="" disabled>
              Válasszon...
            </option>
            {icons.map((c, idx) => (
              <option key={idx} value={c}>
                {c}
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>
        <FormGroup label="Szín">
          <HTMLSelect value={state.color} name="color" onChange={handleSimpleInput}>
            <option value="" disabled>
              Válasszon...
            </option>
            {colors.map((c, idx) => (
              <option key={idx} value={c}>
                {c}
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>
      </HStack>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
