import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import {
  CAROUSEL_CREATE_BEGIN,
  CAROUSEL_CREATE_FAILURE,
  CAROUSEL_CREATE_SUCCESS,
  CAROUSEL_GET_BEGIN,
  CAROUSEL_GET_FAILURE,
  CAROUSEL_GET_SUCCESS,
  CAROUSEL_UPDATE_BEGIN,
  CAROUSEL_UPDATE_FAILURE,
  CAROUSEL_UPDATE_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getItems = () => async (dispatch) => {
  dispatch({ type: CAROUSEL_GET_BEGIN });
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.SLIDES);
    dispatch({ type: CAROUSEL_GET_SUCCESS, payload: json });

    return json;
  } catch (error) {
    dispatch({ type: CAROUSEL_GET_FAILURE, payload: error });
    dispatch(addToastDanger("Slide-ok betöltése sikertelen"));
  }
};

export const getOneSlide = (id) => async (dispatch) => {
  try {
    const json = await http.get(`${API_BASE_IKK_ADMIN}${API_ENDPOINT.SLIDES}/${id}`);
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Slide lekérése sikertelen: ${error}`));
  }
};

export const createSlide = (data) => async (dispatch) => {
  dispatch({ type: CAROUSEL_CREATE_BEGIN });
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.SLIDES, data);
    dispatch({ type: CAROUSEL_CREATE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Slide létrehozva"));
    return json;
  } catch (error) {
    dispatch({ type: CAROUSEL_CREATE_FAILURE, payload: error });
    dispatch(addToastDanger(`Slide létrehozása sikertelen: ${error}`));
  }
};

export const updateSlide = (id, data) => async (dispatch) => {
  dispatch({ type: CAROUSEL_UPDATE_BEGIN });
  try {
    const json = await http.put(
      API_BASE_IKK_ADMIN + API_ENDPOINT.SLIDES + "/" + id,
      data
    );
    dispatch({ type: CAROUSEL_UPDATE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Slide módosítás sikeres"));
    return json;
  } catch (error) {
    dispatch({ type: CAROUSEL_UPDATE_FAILURE, payload: error });
    dispatch(addToastDanger(`Slide módosítás sikertelen: ${error}`));
  }
};

export const deleteSlide = (id) => async (dispatch) => {
  try {
    const json = await http.del(`${API_BASE_IKK_ADMIN}${API_ENDPOINT.SLIDES}/${id}`);
    dispatch(addToastSuccess("Slide törölve"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Slide törlése sikertelen: ${error}`));
  }
};
