import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import {
  DOCUMENT_ADD_BEGIN,
  DOCUMENT_ADD_FAILURE,
  DOCUMENT_ADD_SUCCESS,
  DOCUMENT_DELETE_BEGIN,
  DOCUMENT_DELETE_FAILURE,
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_GET_BEGIN,
  DOCUMENT_GET_FAILURE,
  DOCUMENT_GET_SUCCESS,
  DOCUMENT_UPDATE_BEGIN,
  DOCUMENT_UPDATE_FAILURE,
  DOCUMENT_UPDATE_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const createDocument = (data) => async (dispatch) => {
  dispatch(createDocumentBegin());
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.DOCUMENTS, data);
    dispatch(createDocumentSuccess(json));
    dispatch(addToastSuccess("Dokumentum létrehozva"));
    return json;
  } catch (error) {
    dispatch(createDocumentFailure(error));
    dispatch(addToastDanger(`Dokumentum létrehozása sikertelen: ${error}`));
  }
};

export const updateDocument = (id, data) => async (dispatch) => {
  dispatch(updateDocumentBegin());
  try {
    const json = await http.put(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.DOCUMENTS}/${id}`,
      data
    );
    dispatch(updateDocumentSuccess(json));
    dispatch(addToastSuccess("Dokumentum módosítva"));
    return json;
  } catch (error) {
    dispatch(updateDocumentFailure(error));
    dispatch(addToastDanger(`Dokumentum módosítása sikertelen: ${error}`));
  }
};

export const getDocument = (id = false) => async (dispatch) => {
  dispatch(getDocumentBegin());
  try {
    const json = await http.get(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.DOCUMENTS}/${id ? id : ""}`
    );
    dispatch(getDocumentSuccess(json));
    return json;
  } catch (error) {
    dispatch(getDocumentFailure(error));
    dispatch(addToastDanger(`Dokumentumok betöltése sikertelen: ${error}`));
  }
};

export const deleteDocument = (id) => async (dispatch) => {
  dispatch(deleteDocumentBegin());
  try {
    const json = await http.del(`${API_BASE_IKK_ADMIN}${API_ENDPOINT.DOCUMENTS}/${id}`);
    dispatch(deleteDocumentSuccess(id));
    dispatch(addToastSuccess("Dokumentum törölve"));
    return json;
  } catch (error) {
    dispatch(deleteDocumentFailure(error));
    dispatch(addToastDanger(`Dokumentum törlése sikertelen: ${error}`));
  }
};

export const updateDocumentBegin = () => ({
  type: DOCUMENT_UPDATE_BEGIN,
});

export const updateDocumentSuccess = (event) => ({
  type: DOCUMENT_UPDATE_SUCCESS,
  payload: { event },
});

export const updateDocumentFailure = (error) => ({
  type: DOCUMENT_UPDATE_FAILURE,
  payload: { error },
});

export const createDocumentBegin = () => ({
  type: DOCUMENT_ADD_BEGIN,
});

export const createDocumentSuccess = (event) => ({
  type: DOCUMENT_ADD_SUCCESS,
  payload: { event },
});

export const createDocumentFailure = (error) => ({
  type: DOCUMENT_ADD_FAILURE,
  payload: { error },
});

export const getDocumentBegin = () => ({
  type: DOCUMENT_GET_BEGIN,
});

export const getDocumentSuccess = (news) => ({
  type: DOCUMENT_GET_SUCCESS,
  payload: news,
});

export const getDocumentFailure = (error) => ({
  type: DOCUMENT_GET_FAILURE,
  payload: { error },
});

export const deleteDocumentBegin = () => ({
  type: DOCUMENT_DELETE_BEGIN,
});

export const deleteDocumentSuccess = (id) => ({
  type: DOCUMENT_DELETE_SUCCESS,
  payload: id,
});

export const deleteDocumentFailure = (error) => ({
  type: DOCUMENT_DELETE_FAILURE,
  payload: { error },
});
