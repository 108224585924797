const initialState = {
  me: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SKILLSCOM_ME_SUCCESS": {
      return {
        me: action.payload,
      };
    }
    case "SKILLSCOM_ME_FAILED": {
      return {
        me: null,
      };
    }
    default:
      return state;
  }
}
