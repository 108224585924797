export const MEDIA_ADD_BEGIN = "MEDIA_ADD_BEGIN";
export const MEDIA_ADD_SUCCESS = "MEDIA_ADD_SUCCESS";
export const MEDIA_ADD_FAILURE = "MEDIA_ADD_FAILURE";

export const MEDIA_GET_ALL_IMAGE_BEGIN = "MEDIA_GET_ALL_IMAGE_BEGIN";
export const MEDIA_GET_ALL_IMAGE_SUCCESS = "MEDIA_GET_ALL_IMAGE_SUCCESS";
export const MEDIA_GET_ALL_IMAGE_FAILURE = "MEDIA_GET_ALL_IMAGE_FAILURE";

export const MEDIA_GET_ALL_FILE_BEGIN = "MEDIA_GET_ALL_FILE_BEGIN";
export const MEDIA_GET_ALL_FILE_SUCCESS = "MEDIA_GET_ALL_FILE_SUCCESS";
export const MEDIA_GET_ALL_FILE_FAILURE = "MEDIA_GET_ALL_FILE_FAILURE";

export const NEWS_ADD_BEGIN = "NEWS_ADD_BEGIN";
export const NEWS_ADD_SUCCESS = "NEWS_ADD_SUCCESS";
export const NEWS_ADD_FAILURE = "NEWS_ADD_FAILURE";

export const NEWS_GET_BEGIN = "NEWS_GET_BEGIN";
export const NEWS_GET_SUCCESS = "NEWS_GET_SUCCESS";
export const NEWS_GET_FAILURE = "NEWS_GET_FAILURE";
export const NEWS_GET_ONE_SUCCESS = "NEWS_GET_ONE_SUCCESS";

export const NEWS_UPDATE_BEGIN = "NEWS_UPDATE_BEGIN";
export const NEWS_UPDATE_SUCCESS = "NEWS_UPDATE_SUCCESS";
export const NEWS_UPDATE_FAILURE = "NEWS_UPDATE_FAILURE";

export const NEWS_DELETE_BEGIN = "NEWS_DELETE_BEGIN";
export const NEWS_DELETE_SUCCESS = "NEWS_DELETE_SUCCESS";
export const NEWS_DELETE_FAILURE = "NEWS_DELETE_FAILURE";

//SKILLSCOM
export const SKILLSCOM_INSTITUTE_ADD_BEGIN = "SKILLSCOM_INSTITUTE_ADD_BEGIN";
export const SKILLSCOM_INSTITUTE_ADD_SUCCESS =
  "SKILLSCOM_INSTITUTE_ADD_SUCCESS";
export const SKILLSCOM_INSTITUTE_ADD_FAILURE =
  "SKILLSCOM_INSTITUTE_ADD_FAILURE";

export const SKILLSCOM_INSTITUTE_GET_BEGIN = "SKILLSCOM_INSTITUTE_GET_BEGIN";
export const SKILLSCOM_INSTITUTE_GET_SUCCESS =
  "SKILLSCOM_INSTITUTE_GET_SUCCESS";
export const SKILLSCOM_INSTITUTE_GET_FAILURE =
  "SKILLSCOM_INSTITUTE_GET_FAILURE";
export const SKILLSCOM_INSTITUTE_GET_ONE_SUCCESS =
  "SKILLSCOM_INSTITUTE_GET_ONE_SUCCESS";

export const SKILLSCOM_INSTITUTE_VERIFY_BEGIN =
  "SKILLSCOM_INSTITUTE_VERIFY_BEGIN";
export const SKILLSCOM_INSTITUTE_VERIFY_SUCCESS =
  "SKILLSCOM_INSTITUTE_VERIFY_SUCCESS";
export const SKILLSCOM_INSTITUTE_VERIFY_FAILURE =
  "SKILLSCOM_INSTITUTE_VERIFY_FAILURE";

export const SKILLSCOM_INSTITUTE_DELETE_BEGIN =
  "SKILLSCOM_INSTITUTE_DELETE_BEGIN";
export const SKILLSCOM_INSTITUTE_DELETE_SUCCESS =
  "SKILLSCOM_INSTITUTE_DELETE_SUCCESS";
export const SKILLSCOM_INSTITUTE_DELETE_FAILURE =
  "SKILLSCOM_INSTITUTE_DELETE_FAILURE";

export const SKILLSCOM_COMPANY_ADD_BEGIN = "SKILLSCOM_COMPANY_ADD_BEGIN";
export const SKILLSCOM_COMPANY_ADD_SUCCESS = "SKILLSCOM_COMPANY_ADD_SUCCESS";
export const SKILLSCOM_COMPANY_ADD_FAILURE = "SKILLSCOM_COMPANY_ADD_FAILURE";

export const SKILLSCOM_COMPANY_GET_BEGIN = "SKILLSCOM_COMPANY_GET_BEGIN";
export const SKILLSCOM_COMPANY_GET_SUCCESS = "SKILLSCOM_COMPANY_GET_SUCCESS";
export const SKILLSCOM_COMPANY_GET_FAILURE = "SKILLSCOM_COMPANY_GET_FAILURE";
export const SKILLSCOM_COMPANY_GET_ONE_SUCCESS =
  "SKILLSCOM_COMPANY_GET_ONE_SUCCESS";

export const SKILLSCOM_COMPANY_VERIFY_BEGIN = "SKILLSCOM_COMPANY_VERIFY_BEGIN";
export const SKILLSCOM_COMPANY_VERIFY_SUCCESS =
  "SKILLSCOM_COMPANY_VERIFY_SUCCESS";
export const SKILLSCOM_COMPANY_VERIFY_FAILURE =
  "SKILLSCOM_COMPANY_VERIFY_FAILURE";

export const SKILLSCOM_COMPANY_DELETE_BEGIN = "SKILLSCOM_COMPANY_DELETE_BEGIN";
export const SKILLSCOM_COMPANY_DELETE_SUCCESS =
  "SKILLSCOM_COMPANY_DELETE_SUCCESS";
export const SKILLSCOM_COMPANY_DELETE_FAILURE =
  "SKILLSCOM_COMPANY_DELETE_FAILURE";

export const SKILLSCOM_EDITOR_ADD_BEGIN = "SKILLSCOM_EDITOR_ADD_BEGIN";
export const SKILLSCOM_EDITOR_ADD_SUCCESS = "SKILLSCOM_EDITOR_ADD_SUCCESS";
export const SKILLSCOM_EDITOR_ADD_FAILURE = "SKILLSCOM_EDITOR_ADD_FAILURE";

export const SKILLSCOM_EDITOR_GET_BEGIN = "SKILLSCOM_EDITOR_GET_BEGIN";
export const SKILLSCOM_EDITOR_GET_SUCCESS = "SKILLSCOM_EDITOR_GET_SUCCESS";
export const SKILLSCOM_EDITOR_GET_FAILURE = "SKILLSCOM_EDITOR_GET_FAILURE";
export const SKILLSCOM_EDITOR_GET_ONE_SUCCESS =
  "SKILLSCOM_EDITOR_GET_ONE_SUCCESS";

export const SKILLSCOM_EDITOR_UPDATE_BEGIN = "SKILLSCOM_EDITOR_UPDATE_BEGIN";
export const SKILLSCOM_EDITOR_UPDATE_SUCCESS =
  "SKILLSCOM_EDITOR_UPDATE_SUCCESS";
export const SKILLSCOM_EDITOR_UPDATE_FAILURE =
  "SKILLSCOM_EDITOR_UPDATE_FAILURE";

export const SKILLSCOM_EDITOR_DELETE_BEGIN = "SKILLSCOM_EDITOR_DELETE_BEGIN";
export const SKILLSCOM_EDITOR_DELETE_SUCCESS =
  "SKILLSCOM_EDITOR_DELETE_SUCCESS";
export const SKILLSCOM_EDITOR_DELETE_FAILURE =
  "SKILLSCOM_EDITOR_DELETE_FAILURE";

export const SKILLSCOM_NEWS_ADD_BEGIN = "SKILLSCOM_NEWS_ADD_BEGIN";
export const SKILLSCOM_NEWS_ADD_SUCCESS = "SKILLSCOM_NEWS_ADD_SUCCESS";
export const SKILLSCOM_NEWS_ADD_FAILURE = "SKILLSCOM_NEWS_ADD_FAILURE";

export const SKILLSCOM_NEWS_GET_BEGIN = "SKILLSCOM_NEWS_GET_BEGIN";
export const SKILLSCOM_NEWS_GET_SUCCESS = "SKILLSCOM_NEWS_GET_SUCCESS";
export const SKILLSCOM_NEWS_GET_FAILURE = "SKILLSCOM_NEWS_GET_FAILURE";
export const SKILLSCOM_NEWS_GET_ONE_SUCCESS = "SKILLSCOM_NEWS_GET_ONE_SUCCESS";

export const SKILLSCOM_NEWS_UPDATE_BEGIN = "SKILLSCOM_NEWS_UPDATE_BEGIN";
export const SKILLSCOM_NEWS_UPDATE_SUCCESS = "SKILLSCOM_NEWS_UPDATE_SUCCESS";
export const SKILLSCOM_NEWS_UPDATE_FAILURE = "SKILLSCOM_NEWS_UPDATE_FAILURE";

export const SKILLSCOM_NEWS_DELETE_BEGIN = "SKILLSCOM_NEWS_DELETE_BEGIN";
export const SKILLSCOM_NEWS_DELETE_SUCCESS = "SKILLSCOM_NEWS_DELETE_SUCCESS";
export const SKILLSCOM_NEWS_DELETE_FAILURE = "SKILLSCOM_NEWS_DELETE_FAILURE";

export const SKILLSCOM_POST_ADD_BEGIN = "SKILLSCOM_POST_ADD_BEGIN";
export const SKILLSCOM_POST_ADD_SUCCESS = "SKILLSCOM_POST_ADD_SUCCESS";
export const SKILLSCOM_POST_ADD_FAILURE = "SKILLSCOM_POST_ADD_FAILURE";

export const SKILLSCOM_POST_GET_BEGIN = "SKILLSCOM_POST_GET_BEGIN";
export const SKILLSCOM_POST_GET_SUCCESS = "SKILLSCOM_POST_GET_SUCCESS";
export const SKILLSCOM_POST_GET_FAILURE = "SKILLSCOM_POST_GET_FAILURE";
export const SKILLSCOM_POST_GET_ONE_SUCCESS = "SKILLSCOM_POST_GET_ONE_SUCCESS";

export const SKILLSCOM_POST_UPDATE_BEGIN = "SKILLSCOM_POST_UPDATE_BEGIN";
export const SKILLSCOM_POST_UPDATE_SUCCESS = "SKILLSCOM_POST_UPDATE_SUCCESS";
export const SKILLSCOM_POST_UPDATE_FAILURE = "SKILLSCOM_POST_UPDATE_FAILURE";

export const SKILLSCOM_POST_DELETE_BEGIN = "SKILLSCOM_POST_DELETE_BEGIN";
export const SKILLSCOM_POST_DELETE_SUCCESS = "SKILLSCOM_POST_DELETE_SUCCESS";
export const SKILLSCOM_POST_DELETE_FAILURE = "SKILLSCOM_POST_DELETE_FAILURE";
//SKILLSCOM
export const TAGS_GET_SUCCESS = "TAGS_GET_SUCCESS";
export const TAGS_GET_FAIL = "TAGS_GET_FAIL";
export const TAGS_GET_BEGIN = "TAGS_GET_BEGIN";

export const GROUPS_GET_SUCCESS = "GROUPS_GET_SUCCESS";
export const GROUPS_GET_FAIL = "GROUPS_GET_FAIL";
export const GROUPS_GET_BEGIN = "GROUPS_GET_BEGIN";

export const CAROUSEL_GET_BEGIN = "CAROUSEL_GET_BEGIN";
export const CAROUSEL_GET_SUCCESS = "CAROUSEL_GET_SUCCESS";
export const CAROUSEL_GET_FAILURE = "CAROUSEL_GET_FAILURE";

export const CAROUSEL_CREATE_BEGIN = "CAROUSEL_CREATE_BEGIN";
export const CAROUSEL_CREATE_SUCCESS = "CAROUSEL_CREATE_SUCCESS";
export const CAROUSEL_CREATE_FAILURE = "CAROUSEL_CREATE_FAILURE";

export const CAROUSEL_UPDATE_BEGIN = "CAROUSEL_UPDATE_BEGIN";
export const CAROUSEL_UPDATE_SUCCESS = "CAROUSEL_UPDATE_SUCCESS";
export const CAROUSEL_UPDATE_FAILURE = "CAROUSEL_UPDATE_FAILURE";

export const EVENT_ADD_BEGIN = "EVENT_ADD_BEGIN";
export const EVENT_ADD_SUCCESS = "EVENT_ADD_SUCCESS";
export const EVENT_ADD_FAILURE = "EVENT_ADD_FAILURE";

export const EVENT_GET_BEGIN = "EVENT_GET_BEGIN";
export const EVENT_GET_SUCCESS = "EVENT_GET_SUCCESS";
export const EVENT_GET_FAILURE = "EVENT_GET_FAILURE";

export const EVENT_UPDATE_BEGIN = "EVENT_UPDATE_BEGIN";
export const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS";
export const EVENT_UPDATE_FAILURE = "EVENT_UPDATE_FAILURE";

export const EVENT_DELETE_BEGIN = "EVENT_DELETE_BEGIN";
export const EVENT_DELETE_SUCCESS = "EVENT_DELETE_SUCCESS";
export const EVENT_DELETE_FAILURE = "EVENT_DELETE_FAILURE";

export const SKILLSCOM_EVENT_ADD_BEGIN = "SKILLSCOM_EVENT_ADD_BEGIN";
export const SKILLSCOM_EVENT_ADD_SUCCESS = "SKILLSCOM_EVENT_ADD_SUCCESS";
export const SKILLSCOM_EVENT_ADD_FAILURE = "SKILLSCOM_EVENT_ADD_FAILURE";

export const SKILLSCOM_EVENT_GET_BEGIN = "SKILLSCOM_EVENT_GET_BEGIN";
export const SKILLSCOM_EVENT_GET_SUCCESS = "SKILLSCOM_EVENT_GET_SUCCESS";
export const SKILLSCOM_EVENT_GET_FAILURE = "SKILLSCOM_EVENT_GET_FAILURE";

export const SKILLSCOM_EVENT_UPDATE_BEGIN = "SKILLSCOM_EVENT_UPDATE_BEGIN";
export const SKILLSCOM_EVENT_UPDATE_SUCCESS = "SKILLSCOM_EVENT_UPDATE_SUCCESS";
export const SKILLSCOM_EVENT_UPDATE_FAILURE = "SKILLSCOM_EVENT_UPDATE_FAILURE";

export const SKILLSCOM_EVENT_DELETE_BEGIN = "SKILLSCOM_EVENT_DELETE_BEGIN";
export const SKILLSCOM_EVENT_DELETE_SUCCESS = "SKILLSCOM_EVENT_DELETE_SUCCESS";
export const SKILLSCOM_EVENT_DELETE_FAILURE = "SKILLSCOM_EVENT_DELETE_FAILURE";

export const ABOUT_ITEM_GET_BEGIN = "ABOUT_ITEM_GET_BEGIN";
export const ABOUT_ITEM_GET_SUCCESS = "ABOUT_ITEM_GET_SUCCESS";
export const ABOUT_ITEM_GET_FAILURE = "ABOUT_ITEM_GET_FAILURE";

export const ABOUT_ITEM_CREATE_BEGIN = "ABOUT_ITEM_CREATE_BEGIN";
export const ABOUT_ITEM_CREATE_SUCCESS = "ABOUT_ITEM_CREATE_SUCCESS";
export const ABOUT_ITEM_CREATE_FAILURE = "ABOUT_ITEM_CREATE_FAILURE";

export const ABOUT_ITEM_DELETE_BEGIN = "ABOUT_ITEM_DELETE_BEGIN";
export const ABOUT_ITEM_DELETE_SUCCESS = "ABOUT_ITEM_DELETE_SUCCESS";
export const ABOUT_ITEM_DELETE_FAILURE = "ABOUT_ITEM_DELETE_FAILURE";

export const PROJECT_GET_BEGIN = "PROJECT_GET_BEGIN";
export const PROJECT_GET_SUCCESS = "PROJECT_GET_SUCCESS";
export const PROJECT_GET_FAILURE = "PROJECT_GET_FAILURE";

export const PROJECT_CREATE_BEGIN = "PROJECT_CREATE_BEGIN";
export const PROJECT_CREATE_SUCCESS = "PROJECT_CREATE_SUCCESS";
export const PROJECT_CREATE_FAILURE = "PROJECT_CREATE_FAILURE";

export const PROJECT_DELETE_BEGIN = "PROJECT_DELETE_BEGIN";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const PROJECT_DELETE_FAILURE = "PROJECT_DELETE_FAILURE";

export const TRAINING_CENTER_ITEM_GET_BEGIN = "TRAINING_CENTER_ITEM_GET_BEGIN";
export const TRAINING_CENTER_ITEM_GET_SUCCESS =
  "TRAINING_CENTER_ITEM_GET_SUCCESS";
export const TRAINING_CENTER_ITEM_GET_FAILURE =
  "TRAINING_CENTER_ITEM_GET_FAILURE";

export const TRAINING_CENTER_ITEM_CREATE_BEGIN =
  "TRAINING_CENTER_ITEM_CREATE_BEGIN";
export const TRAINING_CENTER_ITEM_CREATE_SUCCESS =
  "TRAINING_CENTER_ITEM_CREATE_SUCCESS";
export const TRAINING_CENTER_ITEM_CREATE_FAILURE =
  "TRAINING_CENTER_ITEM_CREATE_FAILURE";

export const TRAINING_CENTER_ITEM_DELETE_BEGIN =
  "TRAINING_CENTER_ITEM_DELETE_BEGIN";
export const TRAINING_CENTER_ITEM_DELETE_SUCCESS =
  "TRAINING_CENTER_ITEM_DELETE_SUCCESS";
export const TRAINING_CENTER_ITEM_DELETE_FAILURE =
  "TRAINING_CENTER_ITEM_DELETE_FAILURE";

export const CONST_DATA_TEXTS_GET_BEGIN = "CONST_DATA_TEXTS_GET_BEGIN";
export const CONST_DATA_TEXTS_GET_SUCCESS = "CONST_DATA_TEXTS_GET_SUCCESS";
export const CONST_DATA_TEXTS_GET_FAILURE = "CONST_DATA_TEXTS_GET_FAILURE";

export const CONST_DATA_IMAGES_GET_BEGIN = "CONST_DATA_IMAGES_GET_BEGIN";
export const CONST_DATA_IMAGES_GET_SUCCESS = "CONST_DATA_IMAGES_GET_SUCCESS";
export const CONST_DATA_IMAGES_GET_FAILURE = "CONST_DATA_IMAGES_GET_FAILURE";

export const CONST_PUBLIC_INFO_ITEM_GET_BEGIN =
  "CONST_PUBLIC_INFO_ITEM_GET_BEGIN";
export const CONST_PUBLIC_INFO_ITEM_GET_SUCCESS =
  "CONST_PUBLIC_INFO_ITEM_GET_SUCCESS";
export const CONST_PUBLIC_INFO_ITEM_GET_FAILURE =
  "CONST_PUBLIC_INFO_ITEM_GET_FAILURE";

export const CONST_PUBLIC_INFO_ITEM_CREATE_BEGIN =
  "CONST_PUBLIC_INFO_ITEM_CREATE_BEGIN";
export const CONST_PUBLIC_INFO_ITEM_CREATE_SUCCESS =
  "CONST_PUBLIC_INFO_ITEM_CREATE_SUCCESS";
export const CONST_PUBLIC_INFO_ITEM_CREATE_FAILURE =
  "CONST_PUBLIC_INFO_ITEM_CREATE_FAILURE";

export const CONST_PUBLIC_INFO_ITEM_DELETE_BEGIN =
  "CONST_PUBLIC_INFO_CATEGORY_DELETE_BEGIN";
export const CONST_PUBLIC_INFO_ITEM_DELETE_SUCCESS =
  "CONST_PUBLIC_INFO_CATEGORY_DELETE_SUCCESS";
export const CONST_PUBLIC_INFO_ITEM_DELETE_FAILURE =
  "CONST_PUBLIC_INFO_CATEGORY_DELETE_FAILURE";

export const CONST_PUBLIC_INFO_CATEGORY_GET_BEGIN =
  "CONST_PUBLIC_INFO_CATEGORY_GET_BEGIN";
export const CONST_PUBLIC_INFO_CATEGORY_GET_SUCCESS =
  "CONST_PUBLIC_INFO_CATEGORY_GET_SUCCESS";
export const CONST_PUBLIC_INFO_CATEGORY_GET_FAILURE =
  "CONST_PUBLIC_INFO_CATEGORY_GET_FAILURE";

export const CONST_PUBLIC_INFO_CATEGORY_CREATE_BEGIN =
  "CONST_PUBLIC_INFO_CATEGORY_CREATE_BEGIN";
export const CONST_PUBLIC_INFO_CATEGORY_CREATE_SUCCESS =
  "CONST_PUBLIC_INFO_CATEGORY_CREATE_SUCCESS";
export const CONST_PUBLIC_INFO_CATEGORY_CREATE_FAILURE =
  "CONST_PUBLIC_INFO_CATEGORY_CREATE_FAILURE";

export const CONST_PUBLIC_INFO_CATEGORY_DELETE_BEGIN =
  "CONST_PUBLIC_INFO_CATEGORY_DELETE_BEGIN";
export const CONST_PUBLIC_INFO_CATEGORY_DELETE_SUCCESS =
  "CONST_PUBLIC_INFO_CATEGORY_DELETE_SUCCESS";
export const CONST_PUBLIC_INFO_CATEGORY_DELETE_FAILURE =
  "CONST_PUBLIC_INFO_CATEGORY_DELETE_FAILURE";

export const CONST_PUBLIC_INFO_CATEGORY_POSITION_BEGIN =
  "CONST_PUBLIC_INFO_CATEGORY_POSITION_BEGIN";
export const CONST_PUBLIC_INFO_CATEGORY_POSITION_SUCCESS =
  "CONST_PUBLIC_INFO_CATEGORY_POSITION_SUCCESS";
export const CONST_PUBLIC_INFO_CATEGORY_POSITION_FAILURE =
  "CONST_PUBLIC_INFO_CATEGORY_POSITION_FAILURE";

export const CONST_PUBLIC_INFO_ITEM_POSITION_BEGIN =
  "CONST_PUBLIC_INFO_ITEM_POSITION_BEGIN";
export const CONST_PUBLIC_INFO_ITEM_POSITION_SUCCESS =
  "CONST_PUBLIC_INFO_ITEM_POSITION_SUCCESS";
export const CONST_PUBLIC_INFO_ITEM_POSITION_FAILURE =
  "CONST_PUBLIC_INFO_ITEM_POSITION_FAILURE";

export const DOCUMENT_ADD_BEGIN = "DOCUMENT_ADD_BEGIN";
export const DOCUMENT_ADD_FAILURE = "DOCUMENT_ADD_FAILURE";
export const DOCUMENT_ADD_SUCCESS = "DOCUMENT_ADD_SUCCESS";
export const DOCUMENT_DELETE_BEGIN = "DOCUMENT_DELETE_BEGIN";
export const DOCUMENT_DELETE_FAILURE = "DOCUMENT_DELETE_FAILURE";
export const DOCUMENT_DELETE_SUCCESS = "DOCUMENT_DELETE_SUCCESS";
export const DOCUMENT_GET_BEGIN = "DOCUMENT_GET_BEGIN";
export const DOCUMENT_GET_FAILURE = "DOCUMENT_GET_FAILURE";
export const DOCUMENT_GET_SUCCESS = "DOCUMENT_GET_SUCCESS";
export const DOCUMENT_UPDATE_BEGIN = "DOCUMENT_UPDATE_BEGIN";
export const DOCUMENT_UPDATE_FAILURE = "DOCUMENT_UPDATE_FAILURE";
export const DOCUMENT_UPDATE_SUCCESS = "DOCUMENT_UPDATE_SUCCESS";

export const SZAKKEPZES_NOTIFICATIONS_GET_BEGIN =
  "SZAKKEPZES_NOTIFICATIONS_GET_BEGIN";
export const SZAKKEPZES_NOTIFICATIONS_GET_SUCCESS =
  "SZAKKEPZES_NOTIFICATIONS_GET_SUCCESS";
export const SZAKKEPZES_NOTIFICATIONS_GET_FAILURE =
  "SZAKKEPZES_NOTIFICATIONS_GET_FAILURE";

export const SZAKKEPZES_INSTITUTES_IMPORT_BEGIN =
  "SZAKKEPZES_INSTITUTES_IMPORT_BEGIN";
export const SZAKKEPZES_INSTITUTES_IMPORT_SUCCESS =
  "SZAKKEPZES_INSTITUTES_IMPORT_SUCCESS";
export const SZAKKEPZES_INSTITUTES_IMPORT_FAILURE =
  "SZAKKEPZES_INSTITUTES_IMPORT_FAILURE";

export const SZAKKEPZES_CONNECTIONS_IMPORT_BEGIN =
  "SZAKKEPZES_CONNECTIONS_IMPORT_BEGIN";
export const SZAKKEPZES_CONNECTIONS_IMPORT_SUCCESS =
  "SZAKKEPZES_CONNECTIONS_IMPORT_SUCCESS";
export const SZAKKEPZES_CONNECTIONS_IMPORT_FAILURE =
  "SZAKKEPZES_CONNECTIONS_IMPORT_FAILURE";

export const SZAKKEPZES_LOCATIONS_GET_BEGIN = "SZAKKEPZES_LOCATIONS_GET_BEGIN";
export const SZAKKEPZES_LOCATIONS_GET_SUCCESS =
  "SZAKKEPZES_LOCATIONS_GET_SUCCESS";
export const SZAKKEPZES_LOCATIONS_GET_FAILURE =
  "SZAKKEPZES_LOCATIONS_GET_FAILURE";

export const SZAKKEPZES_EXPORT_BEGIN = "SZAKKEPZES_EXPORT_BEGIN";
export const SZAKKEPZES_EXPORT_SUCCESS = "SZAKKEPZES_EXPORT_SUCCESS";
export const SZAKKEPZES_EXPORT_FAILURE = "SZAKKEPZES_EXPORT_FAILURE";

export const SZAKKEPZES_GET_KKK_BEGIN = "SZAKKEPZES_GET_KKK_BEGIN";
export const SZAKKEPZES_GET_KKK_SUCCESS = "SZAKKEPZES_GET_KKK_SUCCESS";
export const SZAKKEPZES_GET_KKK_FAILURE = "SZAKKEPZES_GET_KKK_FAILURE";

export const SZAKKEPZES_GET_SINGLE_KKK_SUCCESS =
  "SZAKKEPZES_GET_SINGLE_KKK_SUCCESS";
export const SZAKKEPZES_GET_SINGLE_KKK_BEGIN =
  "SZAKKEPZES_GET_SINGLE_KKK_BEGIN";
export const SZAKKEPZES_GET_SINGLE_KKK_FAILURE =
  "SZAKKEPZES_GET_SINGLE_KKK_FAILURE";
export const SZAKKEPZES_GET_SINGLE_KKK = "SZAKKEPZES_GET_SINGLE_KKK";

export const SZAKKEPESITES_GET_QUALIFS_BEGIN =
  "SZAKKEPESITES_GET_QUALIFS_BEGIN";
export const SZAKKEPESITES_GET_QUALIFS_SUCCESS =
  "SZAKKEPESITES_GET_QUALIFS_SUCCESS";
export const SZAKKEPESITES_GET_QUALIFS_FAIL = "SZAKKEPESITES_GET_QUALIFS_FAIL";

export const SZAKKEPESITES_CREATE_QUALIF_BEGIN =
  "SZAKKEPESITES_CREATE_QUALIF_BEGIN";
export const SZAKKEPESITES_CREATE_QUALIF_SUCCESS =
  "SZAKKEPESITES_CREATE_QUALIF_SUCCESS";
export const SZAKKEPESITES_CREATE_QUALIF_FAIL =
  "SZAKKEPESITES_CREATE_QUALIF_FAIL";

// export const SZAKKEPESITES_GET_KEOR_BEGIN = 'SZAKKEPESITES_GET_KEOR_BEGIN';
// export const SZAKKEPESITES_GET_KEOR_SUCCESS = 'SZAKKEPESITES_GET_KEOR_SUCCESS';
// export const SZAKKEPESITES_GET_KEOR_FAIL = 'SZAKKEPESITES_GET_KEOR_FAIL';

export const GET_FAQS_BEGIN = "GET_FAQS_BEGIN";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_FAILURE = "GET_FAQS_FAILURE";

export const GET_FAQ_CATEGORIES_BEGIN = "GET_FAQ_CATEGORIES_BEGIN";
export const GET_FAQ_CATEGORIES_SUCCESS = "GET_FAQ_CATEGORIES_SUCCESS";
export const GET_FAQ_CATEGORIES_FAILURE = "GET_FAQ_CATEGORIES_FAILURE";

export const ADD_TOAST_SUCCESS = "ADD_TOAST_SUCCESS";
export const ADD_TOAST_DANGER = "ADD_TOAST_DANGER";
export const REMOVE_TOAST = "REMOVE_TOAST";

export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SHOW_MEDIA = "SHOW_MEDIA";

export const GET_AUTH_USERS_BEGIN = "GET_AUTH_USERS_BEGIN";
export const GET_AUTH_USERS_SUCCESS = "GET_AUTH_USERS_SUCCESS";
export const GET_AUTH_USERS_FAILURE = "GET_AUTH_USERS_FAILURE";

export const GET_AUTH_USER_BEGIN = "GET_AUTH_USER_BEGIN";
export const GET_AUTH_USER_SUCCESS = "GET_AUTH_USER_SUCCESS";
export const GET_AUTH_USER_FAILURE = "GET_AUTH_USER_FAILURE";

export const MENU_CATEGORY_GET_BEGIN = "MENU_CATEGORY_GET_BEGIN";
export const MENU_CATEGORY_GET_SUCCESS = "MENU_CATEGORY_GET_SUCCESS";
export const MENU_CATEGORY_GET_FAILURE = "MENU_CATEGORY_GET_FAILURE";

export const MENU_CATEGORY_UPDATE_BEGIN = "MENU_CATEGORY_UPDATE_BEGIN";
export const MENU_CATEGORY_UPDATE_SUCCESS = "MENU_CATEGORY_UPDATE_SUCCESS";
export const MENU_CATEGORY_UPDATE_FAILURE = "MENU_CATEGORY_UPDATE_FAILURE";

export const MENU_ITEM_CREATE_BEGIN = "MENU_ITEM_CREATE_BEGIN";
export const MENU_ITEM_CREATE_SUCCESS = "MENU_ITEM_CREATE_SUCCESS";
export const MENU_ITEM_CREATE_FAILURE = "MENU_ITEM_CREATE_FAILURE";

export const MENU_ITEM_DELETE_BEGIN = "MENU_ITEM_DELETE_BEGIN";
export const MENU_ITEM_DELETE_SUCCESS = "MENU_ITEM_DELETE_SUCCESS";
export const MENU_ITEM_DELETE_FAILURE = "MENU_ITEM_DELETE_FAILURE";

export const MENU_ITEM_UPDATE_BEGIN = "MENU_ITEM_UPDATE_BEGIN";
export const MENU_ITEM_UPDATE_SUCCESS = "MENU_ITEM_UPDATE_SUCCESS";
export const MENU_ITEM_UPDATE_FAILURE = "MENU_ITEM_UPDATE_FAILURE";

export const MENU_ONE_CATEGORY_GET_SUCCESS = "MENU_CATEGORY_GET_SUCCESS";

export const EXAM_CENTER_GET_BEGIN = "EXAM_CENTER_GET_BEGIN";
export const EXAM_CENTER_GET_SUCCESS = "EXAM_CENTER_GET_SUCCESS";
export const EXAM_CENTER_GET_FAILURE = "EXAM_CENTER_GET_FAILURE";

export const EXAM_CENTER_UPDATE_BEGIN = "EXAM_CENTER_UPDATE_BEGIN";
export const EXAM_CENTER_UPDATE_SUCCESS = "EXAM_CENTER_UPDATE_SUCCESS";
export const EXAM_CENTER_UPDATE_FAILURE = "EXAM_CENTER_UPDATE_FAILURE";

export const EXAM_CENTER_CREATE_BEGIN = "EXAM_CENTER_CREATE_BEGIN";
export const EXAM_CENTER_CREATE_SUCCESS = "EXAM_CENTER_CREATE_SUCCESS";
export const EXAM_CENTER_CREATE_FAILURE = "EXAM_CENTER_CREATE_FAILURE";

export const EXAM_CENTER_DELETE_BEGIN = "EXAM_CENTER_DELETE_BEGIN";
export const EXAM_CENTER_DELETE_SUCCESS = "EXAM_CENTER_DELETE_SUCCESS";
export const EXAM_CENTER_DELETE_FAILURE = "EXAM_CENTER_DELETE_FAILURE";

export const PAGE_GET_BEGIN = "PAGE_GET_BEGIN";
export const PAGE_GET_SUCCESS = "PAGE_GET_SUCCESS";
export const PAGE_GET_FAILURE = "PAGE_GET_FAILURE";

export const PAGE_UPDATE_BEGIN = "PAGE_UPDATE_BEGIN";
export const PAGE_UPDATE_SUCCESS = "PAGE_UPDATE_SUCCESS";
export const PAGE_UPDATE_FAILURE = "PAGE_UPDATE_FAILURE";

export const PAGE_CREATE_BEGIN = "PAGE_CREATE_BEGIN";
export const PAGE_CREATE_SUCCESS = "PAGE_CREATE_SUCCESS";
export const PAGE_CREATE_FAILURE = "PAGE_CREATE_FAILURE";

export const PAGE_DELETE_BEGIN = "PAGE_DELETE_BEGIN";
export const PAGE_DELETE_SUCCESS = "PAGE_DELETE_SUCCESS";
export const PAGE_DELETE_FAILURE = "PAGE_DELETE_FAILURE";

export const SZAKKEPESITES_GET_QUALIFS_6110_BEGIN =
  "SZAKKEPESITES_GET_QUALIFS_6110_BEGIN";
export const SZAKKEPESITES_GET_QUALIFS_6110_SUCCESS =
  "SZAKKEPESITES_GET_QUALIFS_6110_SUCCESS";
export const SZAKKEPESITES_GET_QUALIFS_6110_FAIL =
  "SZAKKEPESITES_GET_QUALIFS_6110_FAIL";

export const SZAKKEPESITES_GET_SPECS_6110_BEGIN =
  "SZAKKEPESITES_GET_SPECS_6110_BEGIN";
export const SZAKKEPESITES_GET_SPECS_6110_SUCCESS =
  "SZAKKEPESITES_GET_SPECS_6110_SUCCESS";
export const SZAKKEPESITES_GET_SPECS_6110_FAIL =
  "SZAKKEPESITES_GET_SPECS_6110_FAIL";

export const SZAKKEPZES_GET_KKK_6110_BEGIN = "SZAKKEPZES_GET_KKK_6110_BEGIN";
export const SZAKKEPZES_GET_KKK_6110_SUCCESS =
  "SZAKKEPZES_GET_KKK_6110_SUCCESS";
export const SZAKKEPZES_GET_KKK_6110_FAILURE =
  "SZAKKEPZES_GET_KKK_6110_FAILURE";
export const SZAKKEPZES_GET_SINGLE_6110_KKK = "SZAKKEPZES_GET_SINGLE_6110_KKK";
