import {
  MEDIA_ADD_BEGIN,
  MEDIA_ADD_FAILURE,
  MEDIA_ADD_SUCCESS,
  MEDIA_GET_ALL_FILE_BEGIN,
  MEDIA_GET_ALL_FILE_FAILURE,
  MEDIA_GET_ALL_FILE_SUCCESS,
  MEDIA_GET_ALL_IMAGE_BEGIN,
  MEDIA_GET_ALL_IMAGE_FAILURE,
  MEDIA_GET_ALL_IMAGE_SUCCESS,
} from "../actionTypes";

const initialState = {
  media: null,
  loading: false,
  error: null,
  files: [],
  images: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MEDIA_ADD_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case MEDIA_ADD_SUCCESS: {
      console.log("pl",action.payload)
      let additionals=null;
      if(action.payload.media.type==="image"){
        const tmp_im=[...state.images];
        tmp_im.push(action.payload.media);
        additionals={
          images:tmp_im
        }
      }else{
        const tmp_f=[...state.files];
        tmp_f.push(action.payload.media);
        additionals={
          files:tmp_f
        }
      }
      return {
        ...state,
        ...additionals,
        loading: false,
        error: null,
        media: action.payload,

      };
    }
    case MEDIA_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        media: null,
      };
    }

    case MEDIA_GET_ALL_IMAGE_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case MEDIA_GET_ALL_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        images: action.payload.filter((m) => m.type === "image"),
      };
    }

    case MEDIA_GET_ALL_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case MEDIA_GET_ALL_FILE_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case MEDIA_GET_ALL_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        files: action.payload.filter((media) => media.type === "file"),
      };

    case MEDIA_GET_ALL_FILE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
}
