import {
  ADD_TOAST_DANGER,
  ADD_TOAST_SUCCESS,
  REMOVE_TOAST,
} from "../../actionTypes";

export const addToastSuccess = (message) => {
  return {
    type: ADD_TOAST_SUCCESS,
    message,
  };
};

export const addToastDanger = (message) => {
  return {
    type: ADD_TOAST_DANGER,
    message,
  };
};

export const removeToast = () => {
  return {
    type: REMOVE_TOAST,
  };
};
