import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  Spinner,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  deletePost,
  getOnePost,
  getPost,
  verifyPost,
} from "../../../redux/actions/skillscom/post";
import { isAuthorized } from "../../../utils/auth";
import { API_BASE_IKK, ROUTES, SKILLSCOM_URL } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const PostsAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);
  const [rowToApprove, setRowToApprove] = useState();

  useEffect(() => {
    getAllPostsRecursively(0, 100);
  }, []);

  const getAllPostsRecursively = async (offset, limit) => {
    const j = await props.getPost(offset, limit);
    if (Array.isArray(j) && j.length === limit) {
      getAllPostsRecursively(offset + limit, limit);
    }
  };

  // opens delete dialog
  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  // send delete request and reset dialogs
  const handleDelete = () => {
    props.deletePost(rowToDelete.id);
    setRowToDelete(null);
    setRowToApprove(null);
  };

  // sends approve request
  const handleApprove = async (id) => {
    await props.verifyPost(id);
    getAllPostsRecursively(0, 100); // TODO should be handled in redux
  };

  // opens approve dialog
  const handleApproveDialog = (id) => async () => {
    const post = await props.getOnePost(id);
    setRowToApprove(post);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Posztolás dátum",
        accessor: "createdAt",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },

      {
        Header: "Utoljára módosítva",
        accessor: "updatedAt",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Tartalom",
        accessor: "body",
        Cell: ({ value }) =>
          value.length > 30 ? `${value.slice(0, 30)}...` : value,
      },
      {
        Header: "Kép",
        accessor: "coverImgId",
        Cell: ({ value }) => {
          if (value)
            return (
              <img
                width="100"
                src={`${API_BASE_IKK}/media/${value}/sm`}
                loading="lazy"
              />
            );
          return "-";
        },
      },
      {
        Header: "Elfogadva",
        accessor: "approved",
        Cell: ({ row, value }) => {
          if (
            isAuthorized({
              authNamespace: "zartrendszer",
              authPermission: "post:approve",
            }) &&
            !value
          ) {
            return (
              <Button
                intent="primary"
                onClick={handleApproveDialog(row.original.id)}
              >
                Előnézet
              </Button>
            );
          } else return <p>{value ? "Igen" : "Nem"}</p>;
        },
      },
      {
        Header: "Web",
        Cell: ({ row }) => (
          <AnchorButton
            small
            minimal
            icon="document-open"
            text="Megnyit"
            target="_blank"
            href={`${SKILLSCOM_URL}/post/${row.original.id}`}
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={
              isAuthorized({
                namespace: "zartrendszer",
                authPermission: "post:update",
              })
                ? generatePath(ROUTES.skillsComPostsEdit, {
                    id: row.original.id,
                  })
                : null
            }
            onDelBtnClick={
              isAuthorized({
                namespace: "zartrendszer",
                authPermission: "post:delete",
              })
                ? handleDeleteDialog
                : null
            }
          />
        ),
      },
    ],
    []
  );
  return (
    <>
      <h2>Összes poszt</h2>

      <ApproveDialog
        isOpen={!!rowToApprove}
        post={rowToApprove}
        onApprove={handleApprove}
        onDelete={handleDeleteDialog}
        onCancel={() => setRowToApprove(null)}
      />

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={`${rowToDelete?.body.slice(0, 30)}...`}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.posts} />
      )}
    </>
  );
};

const ApproveDialog = ({ isOpen, post, onCancel, onApprove, onDelete }) => {
  if (!isOpen || !post) return null;
  return (
    <Dialog
      icon="info-sign"
      isOpen={isOpen}
      onClose={onCancel}
      onApprove={() => onApprove(post.id)}
      usePortal={false}
      title="Poszt bejegyzés elbírálásra vár"
    >
      <div className={Classes.DIALOG_BODY}>
        <div dangerouslySetInnerHTML={{ __html: post.body }} />
        <img
          src={`${API_BASE_IKK}/media/${post.coverImgId}/sm`}
          style={{
            marginTop: 10,
            width: "100%",
            display: post.coverImgId ? "block" : "none",
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent="danger" onClick={() => onDelete(post)}>
            Töröl
          </Button>
          <Button onClick={onCancel}>Mégse</Button>
          <Button intent="primary" icon="tick-circle">
            Elfogad
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { posts, loading } = state.skillsComPosts;
  return { posts, loading };
};

export default connect(mapStateToProps, {
  verifyPost,
  getPost,
  getOnePost,
  deletePost,
})(PostsAll);
