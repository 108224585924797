import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { deleteSlide, getItems } from "../../../redux/actions/ikk/carousel";
import { ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const SlidesAll = ({ getItems, deleteSlide, slides, loading }) => {
  const [rowToDelete, setRowToDelete] = useState(null);

  useEffect(() => {
    getItems();
  }, []);

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = async () => {
    await deleteSlide(rowToDelete.id);
    setRowToDelete(null);
    getItems();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Cím",
        accessor: "title",
      },
      {
        Header: "Link",
        accessor: "link",
        Cell: ({ value }) => (
          <a href={value} target="_blank" style={{ wordBreak: "break-all" }}>
            {value}
          </a>
        ),
      },
      {
        Header: "Törzs",
        accessor: "body",
      },
      {
        Header: "Pozíció",
        accessor: "position",
      },
      {
        Header: "Aktív",
        accessor: "visible",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Kép",
        accessor: "cover",
        Cell: ({ value }) => (
          <img
            width="100"
            src={value.uri.find((u) => u.size === "xs").uri}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.slidesEdit, { id: row.original.id })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes slide</h2>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      {loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={slides} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { slides, loading } = state.carousel;
  return { slides, loading };
};

export default connect(mapStateToProps, { getItems, deleteSlide })(SlidesAll);
