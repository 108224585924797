import { Button, HTMLSelect, HTMLTable, InputGroup } from "@blueprintjs/core";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { forwardRef, useState } from "react";
import { useEffect } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

export const TableAdvanced = ({
  columns, // react-table column data
  data, // react-table row data
  allowSort = false, // show a drag handler if `true`
  onSortChange = null, // callback when sorting is finished, `oldIndex`, `newIndex`, `sortedData` is passed as parameters
  idField = "id", // key of the uniquie identifier attribute on a record in the table
}) => {
  const sensors = useSensors(useSensor(PointerSensor));
  const [controlledData, setControlledData] = useState(data);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: controlledData,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    // since we use a derived state from props, we need to update that too when the props change,
    // to avoid being stuck with an empty (initial state) table
    setControlledData(data);
  }, [data]);

  // one of the columns is being dragged by the user
  const handleDragStart = (event) => {
    //
  };

  // drag action finised, fire callback
  const handleDragEnd = (event) => {
    const { active, over, ...rest } = event;
    console.log({ active, over, rest });
    if (active.id !== over.id) {
      const oldIndex = controlledData.findIndex(
        (item) => item[idField] === active.id
      );
      const newIndex = controlledData.findIndex(
        (item) => item[idField] === over.id
      );
      const sortedData = arrayMove(controlledData, oldIndex, newIndex);
      if (onSortChange) {
        onSortChange({ oldIndex, newIndex, sortedData });
        setControlledData(sortedData);
      }
    }
  };

  console.log(page);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
        <Button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          icon="double-chevron-left"
        />
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          icon="chevron-left"
        />
        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          icon="chevron-right"
        />
        <Button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          icon="double-chevron-right"
        />
        <span style={{ padding: "0 20px" }}>
          <strong>{`${pageIndex + 1}. / ${pageOptions.length}. oldal`}</strong>
        </span>

        <HTMLSelect
          value={pageSize}
          options={[10, 20, 50, 100, 500, 1000]}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        />

        <div style={{ marginLeft: "auto" }}>
          <InputGroup
            type="search"
            placeholder="Keresés..."
            leftIcon="search"
            onChange={(e) => setGlobalFilter(e.target.value || undefined)} // Set undefined to remove the filter entirely
          />
        </div>
      </div>

      <HTMLTable striped {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting.
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? "▼" : "▲") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={page.map(
              (r) => (r?.original[idField])
            )}
            strategy={verticalListSortingStrategy}
          >
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return allowSort && onSortChange ? (
                  <SortableRow key={row[idField]} idField={idField} row={row} />
                ) : (
                  <Row key={row[idField]} row={row} />
                );
              })}
            </tbody>
          </SortableContext>
        </DndContext>
      </HTMLTable>
    </>
  );
};

const SortableRow = ({ row, idField }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: row.original[idField],
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Row
      row={row}
      ref={setNodeRef}
      style={style}
      listeners={listeners}
      allowSort
      {...attributes}
    />
  );
};

const Row = forwardRef(({ row, listeners, allowSort, ...props }, ref) => {
  return (
    <tr {...row.getRowProps()} {...props} ref={ref}>
      {row.cells.map((cell) => {
        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
      })}
      {allowSort && <Handle {...listeners} />}
    </tr>
  );
});

const Handle = (props) => {
  return (
    <td>
      <Button
        style={{ cursor: "grab" }}
        icon="drag-handle-vertical"
        minimal
        title="Sorrend módosítás (drag & drop)"
        {...props}
      />
    </td>
  );
};
