import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  deleteCentrum,
  getCentrums,
} from "../../../redux/actions/ikk/traningCenters";
import { ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const CentrumsAll = (props) => {
  const [centrums, setCentrums] = useState([]);
  const [rowToDelete, setRowToDelete] = useState(null);

  useEffect(() => {
    props.getCentrums().then((res) => {
      setCentrums(res);
    });
  }, []);

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = () => {
    props.deleteCentrum(rowToDelete.id);
    setRowToDelete(null);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Megye",
        accessor: "state",
      },
      {
        Header: "Szakképzési Centrum",
        accessor: "training_center",
      },
      {
        Header: "Irányítószám",
        accessor: "post_code",
      },
      {
        Header: "Település",
        accessor: "location",
      },
      {
        Header: "Utca, házszám",
        accessor: "address",
      },
      {
        Header: "Postázási cím",
        accessor: "post_address",
      },
      {
        Header: "Telefon",
        accessor: "telephone",
      },
      {
        Header: "Honlap",
        accessor: "website",
        Cell: ({ value }) => (
          <a href={value} target="_blank" style={{ wordBreak: "break-all" }}>
            {value}
          </a>
        ),
      },
      {
        Header: "Utoljára módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.centrumsEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2 style={{ marginBottom: 0 }}>Összes szakképzési centrum</h2>
      <small>
        Figyelem! Ez az intézmény lista nem azonos a szakképzés.ikk.hu oldalon
        fellelhető intézményekkel.
      </small>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.training_center}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      {centrums && <TableAdvanced data={centrums} columns={columns} />}
    </>
  );
};

export default connect(null, { getCentrums, deleteCentrum })(CentrumsAll);
