import http from "./http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "./routes";

export default class CKEditorUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(async (file) => {
      var form = new FormData();
      form.append("file", file);

      const res = await http.uploadFile(API_BASE_IKK_ADMIN + API_ENDPOINT.UPLOAD, form);
      console.log(res);

      return {
        default: res.uri.find((o) => o.size === "xl").uri,
        "160": res.uri.find((o) => o.size === "xs").uri,
        "500": res.uri.find((o) => o.size === "sm").uri,
        "720": res.uri.find((o) => o.size === "md").uri,
        "1280": res.uri.find((o) => o.size === "lg").uri,
      };
    });
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
  }
}
