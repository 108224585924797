import {
  Button,
  Checkbox,
  FormGroup,
  HTMLSelect,
  Icon,
  InputGroup,
  MenuItem,
  NumericInput,
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { uploadMedia } from "../../../redux/actions/common/media";
import { getCentrums } from "../../../redux/actions/ikk/traningCenters";
import { API_BASE_EXAM_CENTER } from "../../../utils/routes";
import MyCkEditor from "../../common/MyCkEditor";
import { SuggestKeorQualif } from "./SuggestKeorQualif";

const ExamForm = (props) => {
  const [exam, setExam] = useState(props.initialState);
  const [szcList, setSzcList] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    getPartners();
    getSzcList();
  }, []);

  const getPartners = async () => {
    const res = await fetch(`${API_BASE_EXAM_CENTER}/partners`);
    const data = await res.json();
    if (data.success) setPartners(data.data);
    else alert("Hiba történt!");
  };

  const getSzcList = async () => {
    const list = await props.getCentrums();
    setSzcList(list);
  };

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setExam((s) => ({ ...s, [name]: val }));
  };

  const handleNumberInput = (valueAsNumber, valueAsString, element) => {
    setExam((s) => ({ ...s, [element.name]: valueAsNumber }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(exam, setExam);
  };

  const handleQualifSelect = (qualif) => {
    setExam((s) => ({ ...s, qualificationId: qualif.id }));
  };

  // SZC was clicked on the dropdown list
  const handleSzcSelect = (item) => {
    const id = item.id;
    setExam((prevState) => {
      let szcarr = [...prevState.szcs];
      if (szcarr.indexOf(id) > -1) {
        szcarr = szcarr.filter((sid) => sid !== id); // remove
      } else {
        szcarr = [...szcarr, id]; // append
      }
      return { ...prevState, szcs: szcarr };
    });
  };

  // SZC was removed from the tag input field (x)
  const handleSzcRemove = (value) => {
    setExam((s) => {
      const ids = [...s.szcs];
      const idx = ids.findIndex((id) => id === value.id);
      ids.splice(idx, 1);
      return { ...s, szcs: ids };
    });
  };

  const handlePartnerSelect = (item) => {
    const id = item.id;
    setExam((prevState) => {
      let ps = prevState.partners ? [...prevState.partners] : [];

      if (ps.indexOf(id) > -1) {
        ps = ps.filter((pid) => pid !== id); // remove
      } else {
        ps = [...ps, id]; // append
      }
      return { ...prevState, partners: ps };
    });
  };

  const handlePartnerRemove = (value) => {
    setExam((e) => {
      const ids = [...e.partners];
      const idx = ids.findIndex((id) => id === value.id);
      ids.splice(idx, 1);
      return { ...e, partners: ids };
    });
  };

  console.log("partners", partners);

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 800, marginBottom: 100 }}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="applications" />
        &nbsp;<span>{props.title}</span>
      </h2>

      <FormGroup
        label="Név"
        labelInfo="(kötelező)"
        helperText="Egy olyan név ami alapján egyertelműen beazonosítható a vizsga (adminisztrácios célokból)"
      >
        <InputGroup required name="name" value={exam.name} onChange={handleInput} />
      </FormGroup>

      <FormGroup
        label="Szakképesítés"
        labelInfo="(kötelező)"
        helperText="A listában csak azok a szakképesítések jelennek meg melyekhez van PK hozzárendelve"
      >
        <SuggestKeorQualif
          selectedItem={exam.qualificationId}
          onSelect={(q) => handleQualifSelect(q)}
        />
      </FormGroup>

      <FormGroup label="Nyelv" labelInfo="(kötelező)">
        <HTMLSelect required name="language" value={exam.language} onChange={handleInput}>
          <option value="" disabled></option>
          <option value="Magyar">Magyar</option>
          <option value="Angol">Angol</option>
          <option value="Német">Német</option>
        </HTMLSelect>
      </FormGroup>

      <FormGroup label="Időpont" helperText="">
        <InputGroup
          name="timeframe"
          value={exam.timeframe ?? ""}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Helyszín" helperText="">
        <InputGroup name="location" value={exam.location ?? ""} onChange={handleInput} />
      </FormGroup>

      <FormGroup label="Max létszám" helperText="">
        <NumericInput
          required
          name="maxCandidates"
          min={0}
          value={exam.maxCandidates}
          onValueChange={handleNumberInput}
        />
      </FormGroup>

      <FormGroup
        label="Partnerek"
        helperText="(kötelező ha a vizsgadíj rendezett - 0Ft)"
        helperText="Partnerek akiket ki lehet ennél a vizsgánál választani a jelentkezés formon."
      >
        <MultiSelect
          fill
          items={partners.filter((p) => p.enabled)}
          selectedItems={partners.filter((p) => exam.partners.includes(p.id))}
          onItemSelect={handlePartnerSelect}
          onRemove={handlePartnerRemove}
          tagRenderer={(item) => item.name}
          popoverProps={{ minimal: true, popoverClassName: "popover" }}
          itemPredicate={(query, item) =>
            item.name.toLowerCase().indexOf(query.toLowerCase() >= 0)
          }
          itemRenderer={(item, { modifiers, handleClick }) => {
            if (!modifiers.matchesPredicate) {
              return null;
            }
            return (
              <MenuItem
                active={modifiers.active}
                icon={exam.partners.includes(item.id) ? "tick" : "blank"}
                key={item.id}
                onClick={handleClick}
                text={item.name}
              />
            );
          }}
        />
      </FormGroup>

      <FormGroup
        label="SZC-k"
        helperText="Azok a szakképzési centrumok melyeknél lehet ilyen vizsgát tenni"
      >
        <MultiSelect
          fill
          items={szcList}
          selectedItems={szcList.filter((szc) => exam.szcs.includes(szc.id))}
          onRemove={handleSzcRemove}
          onItemSelect={handleSzcSelect}
          tagRenderer={(item) => item.training_center}
          popoverProps={{ minimal: true, popoverClassName: "popover" }}
          itemPredicate={(query, item) =>
            item.training_center.toLowerCase().indexOf(query.toLowerCase() >= 0)
          }
          itemRenderer={(item, { modifiers, handleClick }) => {
            if (!modifiers.matchesPredicate) {
              return null;
            }
            return (
              <MenuItem
                active={modifiers.active}
                icon={exam.szcs.includes(item.id) ? "tick" : "blank"}
                key={item.training_center}
                onClick={handleClick}
                label={item.post_address}
                text={item.training_center}
              />
            );
          }}
        />
      </FormGroup>

      <FormGroup label="Vizsgadíj" labelInfo="(Ft, kötelező)">
        <NumericInput
          required
          name="price"
          value={exam.price}
          onValueChange={handleNumberInput}
        />
      </FormGroup>

      <FormGroup
        label="Tájékoztató szöveg"
        helperText="Felugró ablakban megjelenő szöveg (akkor jelenik meg ha nem aktív a vizsga)"
      >
        <MyCkEditor
          data={exam.description}
          onDataChange={(d) => setExam((e) => ({ ...e, description: d }))}
        />
      </FormGroup>

      <FormGroup helperText="Ha be van pipálva megjelenik a Vizsgajelentkezés gomb a PK mellett">
        <Checkbox
          name="visible"
          label="Látható legyen az oldalon"
          checked={exam.visible}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup helperText="Ha aktív akkor átirányít a vizsga jelenkeztető oldalra a gomb, ha nem akkor megjelenik a felugró ablak a tájékoztató szöveggel.">
        <Checkbox
          label="Aktiv"
          name="enabled"
          checked={exam.enabled}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup>
        <Checkbox
          label="Online vizsga"
          name="online"
          checked={exam.online}
          onChange={handleInput}
        />
      </FormGroup>

      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

export default connect(null, { getCentrums, uploadMedia })(ExamForm);
