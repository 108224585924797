import {
  Button,
  Callout,
  Checkbox,
  FormGroup,
  HTMLSelect,
  InputGroup,
  MenuItem,
  NumericInput,
  TagInput,
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { RandomPasswordButton } from "../common/RandomPasswordButton";
import { ToggleVisibilityButton } from "../common/ToggleVisibilityButton";
import { getAllInstitute } from "../institute/InstituteAll";

const SZCForm = (props) => {
  const [szc, setSzc] = useState(props.initialState);
  const [institutes, setInstitutes] = useState([]); // all institute
  const [instituteIds, setInstituteIds] = useState([]); // selected institutes from from dropdown list

  useEffect(() => {
    // set all institutes
    getAllInstitute().then((data) => setInstitutes(data));
    // set initial state for institute tag selector
    setInstituteIds(props.initialState.relatedIntezmenys.map((i) => i.naturalId));
  }, []);

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setSzc((s) => ({ ...s, [name]: val }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit({ szc, setSzc, instituteIds });
  };

  const RightElement = () => {
    return (
      <span>
        <RandomPasswordButton handleInput={handleInput} isDisabled={props.isEditing} />
        <ToggleVisibilityButton />
      </span>
    );
  };

  const handleInstituteSelect = (value) => {
    setInstituteIds((ids) => [...ids, value.naturalId]);
  };

  const handleInstituteRemove = (value) => {
    // TODO
    setInstituteIds((ids) => {
      return ids.filter((id) => id !== value.naturalId);
    });
  };

  const handleDomainRemove = (val, index) => {
    const newDomains = szc.realDomains.filter((p) => p !== val);
    handleInput({ target: { name: "realDomains", value: newDomains } });
  };

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={() => {}}
      onKeyPress={() => {}}
      style={{ maxWidth: 800, marginBottom: 100 }}
      autoComplete="off"
    >
      <h2>{props.title}</h2>

      {/* show a warning, since there is no test API for CEAM */}
      {process.env.REACT_APP_CMS_ENV !== "live" && (
        <Callout title="Figyelem!" intent="warning">
          Az EDIR hozzáférés kezelővel az alábbiakban az <b>éles</b> adatokat szerkeszti!
        </Callout>
      )}
      <br />

      <FormGroup label="Név" labelInfo="(kötelező)">
        <InputGroup
          required
          name="name"
          placeholder="SZC teljes neve"
          value={szc.name}
          onChange={handleInput}
        />
      </FormGroup>
      <FormGroup label="DB ID" labelInfo="(kötelező)">
        <NumericInput
          name="dbId"
          disabled={props.isEditing}
          value={szc.dbId}
          min={0}
          onValueChange={(num) => handleInput({ target: { name: "dbId", value: num } })}
        />
      </FormGroup>
      <FormGroup label="Tárhely" labelInfo="(GB, kötelező)">
        <NumericInput
          name="storage"
          disabled={props.isEditing}
          value={szc.storage}
          onChange={handleInput}
          min={0}
          onValueChange={(num) =>
            handleInput({ target: { name: "storage", value: num } })
          }
        />
      </FormGroup>
      <FormGroup label="Frontend EDIR domain" labelInfo="(kötelező)">
        <InputGroup
          required
          disabled={props.isEditing}
          name="frontendEdirDomain"
          placeholder="*.www.szc.edir.hu"
          value={szc.frontendEdirDomain}
          onChange={handleInput}
        />
      </FormGroup>
      <FormGroup label="CMS EDIR domain" labelInfo="(kötelező)">
        <InputGroup
          required
          disabled={props.isEditing}
          name="backendEdirDomain"
          placeholder="*.cms.szc.edir.hu"
          value={szc.backendEdirDomain}
          onChange={handleInput}
        />
      </FormGroup>
      <FormGroup
        label="Igazi domain(ek)"
        labelInfo="(kötelező)"
        helperText="A www kezdetű domaineket is külön fel kell vinni ha van."
      >
        <TagInput
          fill
          addOnBlur
          placeholder="Vesszővel elválasztott domain nevek"
          onChange={(values) =>
            handleInput({ target: { name: "realDomains", value: values } })
          }
          onKeyDown={(e) => console.log(e.code)}
          values={szc.realDomains}
          onRemove={handleDomainRemove}
          separator={/[,\n\r\s]/}
        />
      </FormGroup>

      <FormGroup label="Main CMS domain" labelInfo="(kötelező)">
        <InputGroup
          required
          disabled={props.isEditing}
          name="mainCMSDomain"
          value={szc.mainCMSDomain}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Intézmények (kötelező)">
        <MultiSelect
          fill
          items={institutes.sort((i1, i2) => i1.name.localeCompare(i2.name, "hu"))}
          selectedItems={institutes.filter((i) => instituteIds.includes(i.naturalId))}
          onItemSelect={handleInstituteSelect}
          onRemove={handleInstituteRemove}
          tagRenderer={(item) => item.name}
          popoverProps={{ minimal: true, popoverClassName: "popover" }}
          itemPredicate={(query, item) =>
            item.name.toLowerCase().indexOf(query.toLowerCase() >= 0)
          }
          itemRenderer={(item, { modifiers, handleClick }) => {
            if (!modifiers.matchesPredicate) {
              return null;
            }
            return (
              <MenuItem
                active={modifiers.active}
                icon={instituteIds.includes(item.naturalId) ? "tick" : "blank"}
                key={item.naturalId}
                onClick={handleClick}
                text={item.name}
                label={`(${item.frontendEdirDomain})`}
              />
            );
          }}
        />
      </FormGroup>

      <FormGroup label="Admin user" labelInfo="(kötelező)">
        <InputGroup
          name="username"
          type="email"
          disabled={props.isEditing}
          value={szc.username}
          placeholder="IKK admin email"
          onChange={handleInput}
        />
      </FormGroup>
      <FormGroup label="Admin jelszó" labelInfo="(kötelező)">
        <InputGroup
          required
          name="password"
          type="password"
          disabled={props.isEditing}
          value={szc.password}
          onChange={handleInput}
          minLength={8}
          rightElement={<RightElement />}
        />
      </FormGroup>
      <FormGroup label="Tag" labelInfo="(kötelező)">
        <HTMLSelect
          name="tag"
          value={szc.tag}
          onChange={handleInput}
          disabled={props.isEditing}
        >
          <option value="prod">prod</option>
          <option value="dev">dev</option>
        </HTMLSelect>
      </FormGroup>

      <Checkbox
        label="Cert létrehozása"
        name="createdCertForEdirDomain"
        disabled={props.isEditing}
        onChange={handleInput}
        checked={szc.createdCertForEdirDomain}
      />

      <FormGroup label="Verzió" labelInfo="">
        <NumericInput
          name="version"
          disabled={props.isEditing}
          min={0}
          onValueChange={(n) => handleInput({ target: { name: "version", value: n } })}
          value={szc.version}
        />
      </FormGroup>

      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

export default SZCForm;
