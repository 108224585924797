import { getToken } from "./auth";
import { checkTestUrl } from "./helpers";

async function request(url, params, method, extraOptions = {}) {
  checkTestUrl(params);

  const options = {
    method,

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + (await getToken()),
    },
    mode: "cors", // no-cors, cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, same-origin, omit
    redirect: "follow", // manual, follow, error
    referrerPolicy: "no-referrer", // no-referrer, client
    ...extraOptions,
  };

  if (params) {
    if (method === "GET") {
      url += "?" + objectToQueryString(params);
    } else {
      options.body = extraOptions.noStringify ? params : JSON.stringify(params);
    }
  }

  const response = await fetch(url, options);
  let err = "";
  // checking status code
  if (!response.ok) {
    try {
      const jsonresp = await response.json();
      if (jsonresp.error) {
        err = jsonresp.error;
      }
    } catch (e) {
      err = `Unexpected status: ${response.status} ${response.statusText}`;
    } finally {
      throw new Error(err);
    }
  }

  // checking json error message
  // delete request sometimes has empty body
  const result = response.status === 204 ? { success: true } : await response.json();
  if (!result.success) {
    throw new Error(`${result.error}`);
  }

  return result.data;
}

export function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
}

function get(url, params, extraOptions) {
  return request(url, params, "GET", extraOptions);
}

function post(url, params, extraOptions) {
  return request(url, params, "POST", extraOptions);
}

function put(url, params, extraOptions) {
  return request(url, params, "PUT", extraOptions);
}

function del(url, params, extraOptions) {
  return request(url, params, "DELETE", extraOptions);
}

async function uploadFile(url, file, extraOptions) {
  return request(url, file, "POST", {
    // when uploading a file, content-type header must not be set
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + (await getToken()),
    },
    noStringify: true, // prevent strinigifying payload
    ...extraOptions,
  });
}

/**
 * Alternative method to `request`. Returns the JSON response as is.
 *
 */
export const authFetch = async (url, options = {}) => {
  const { headers = {}, ...otheroptions } = options;
  const opts = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + (await getToken()),
      ...headers,
    },
    ...otheroptions,
  };

  // for file uploads the Content-Type header must be omitted
  //if(!Object.keys(headers).map(h=>h.toLowerCase()).includes)
  if (headers["Content-Type"] === null) {
    delete opts.headers["Content-Type"];
  }

  const res = await fetch(url, opts);
  if (!res.ok) {
    throw Error(`Unexpedced status code: ${res.statusText}`);
  }

  // check if response is json format and return the parsed object
  const contentType = res.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    const data = await res.json();
    return data;
  }

  return null;
};

export default {
  get,
  post,
  del,
  put,
  uploadFile,
};
