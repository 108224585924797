import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  Spinner,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteKKK,
  editKKK,
  getAllKKK,
} from "../../../redux/actions/szakkepzes/kkk";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import FileUpload from "../../common/FileUpload";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const initialstate = {
  qualification_id: "",
  qualification_name: "",
  name: "",
  media_id: "",
  attachment_id: "",
};

const KKK_PTT = (props) => {
  const [editKKK, setEditKKK] = useState(initialstate);
  const [deleteKKK, setDeleteKKK] = useState(initialstate);

  useEffect(() => {
    props.getAllKKK();
  }, []);

  // opens edit dialog
  const handleEdit = (row, attachment) => {
    setEditKKK((kkk) => ({
      ...kkk,
      qualification_id: row.id,
      name: attachment.name,
      media_id: attachment.media_id,
      attachment_id: attachment.id,
    }));
  };

  // opens delete dialog
  const handleDelete = (row, attachment) => {
    setDeleteKKK((kkk) => ({
      ...kkk,
      qualification_name: row.name,
      qualification_id: row.id,
      name: attachment.name,
      media_id: attachment.media_id,
      attachment_id: attachment.id,
    }));
  };

  const handleDialogClose = () => {
    setEditKKK(initialstate);
  };

  const handleUpload = (media) => {
    setEditKKK((kkk) => ({ ...kkk, media_id: media.id }));
  };

  const handleEditSubmit = async (e) => {
    //todo
    e.preventDefault();
    const res = await props.editKKK(editKKK.attachment_id, {
      name: editKKK.name,
      media_id: editKKK.media_id,
    });
    setEditKKK(initialstate);
  };

  const handleDeleteSubmit = async (e) => {
    //todo
    e.preventDefault();
    const res = await props.deleteKKK(deleteKKK.attachment_id);
    setDeleteKKK(initialstate);
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setEditKKK((kkk) => ({ ...kkk, name: value }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Szakma",
        accessor: "name",
      },
      {
        Header: "Ágazat",
        accessor: "sector.name",
      },
      {
        Header: "Fájlok",
        accessor: "attachments",
        Cell: ({ row, value }) =>
          value.map((attachment, i) => (
            <div key={i}>
              <AnchorButton
                icon="import"
                small
                minimal
                text={attachment.name}
                href={attachment.media}
                target="_blank"
              />
              <TableActionButtons
                row={row}
                onEditBtnClick={() => handleEdit(row.original, attachment)}
                onDelBtnClick={() => handleDelete(row.original, attachment)}
              />
            </div>
          )),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2>KKK és PTT dokumentumok</h2>

      <TableAdvanced data={props.items} columns={columns} />

      <DeleteConfirmation
        isOpen={deleteKKK.attachment_id}
        onCancel={() => setDeleteKKK(initialstate)}
        onDelete={handleDeleteSubmit}
        recordName={`${deleteKKK.qualification_name} - ${deleteKKK.name}`}
      />

      <Dialog
        title="Dokumentum szerkesztése"
        isOpen={editKKK.attachment_id}
        onClose={handleDialogClose}
        usePortal={false}
      >
        <form onSubmit={handleEditSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Dokumentum típusa">
              <HTMLSelect value={editKKK.name} onChange={handleInput}>
                <option value="KKK">KKK</option>
                <option value="PTT">PTT</option>
              </HTMLSelect>
            </FormGroup>

            <FormGroup label="Dokumentum cseréje">
              <FileUpload onUpload={handleUpload} isRequired />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="Mégsem" onClick={handleDialogClose} />
              <Button
                intent="success"
                type="submit"
                text="Mentés"
                icon="floppy-disk"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.kkk };
};
export default connect(mapStateToProps, {
  getAllKKK,
  editKKK,
  deleteKKK,
})(KKK_PTT);
