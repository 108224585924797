import React from "react";
import http from "../../../utils/http";
import { API_BASE_CEAM, ROUTES } from "../../../utils/routes";
import SZCForm from "./SZCForm";

const emptyState = {
  name: "",
  password: "",
  realDomains: [],
  username: "admin@ikk.hu",
  dbId: "",
  frontendEdirDomain: "",
  backendEdirDomain: "",
  mainCMSDomain: "cms.szc.edir.hu",
  relatedIntezmenys: [],
  tag: "prod",
  createdCertForEdirDomain: false,
  version: 2,
  storage: 1,
};

const SZCCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async ({ szc, setSzc, instituteIds }) => {
    try {
      // save SZC data
      const resp = await http.post(`${API_BASE_CEAM}/api/szc`, szc);

      if (resp.naturalId) {
        // save related institutes
        await http.put(`${API_BASE_CEAM}/api/szc/${resp.naturalId}/intezmenys`, {
          intezmenyNaturalIds: instituteIds,
        });

        // reset the form if submit was successful
        setSzc(emptyState);
        props.history.push(ROUTES.edirSzcAll);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SZCForm
      title="Új EDIR SZC"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
      imageRequired={true}
    />
  );
};

export default SZCCreate;
