import { Button, FormGroup, Checkbox } from "@blueprintjs/core";
import { Details } from "../../../common/Details";
import { useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";
import { ImageFields, imageDefaults } from "../fields/ImageFields";
import { DeleteButton } from "../DeleteButton";

export const ImageGrid = ({ initialState, onComponentSave }) => {
  const { state, isDirty, setState, handleSimpleInput } = useInputState(initialState);

  const onAddNewItem = () => {
    setState((s) => ({ ...s, images: [...s.images, { ...imageDefaults }] }));
  };

  const onRemoveItem = (index) => {
    const temp = structuredClone(state.images);
    temp.splice(index, 1);
    setState((s) => ({ ...s, images: temp }));
  };

  const onImageChange = (index) => (key, value) => {
    const temp = structuredClone(state);
    temp.images[index][key] = value;
    setState(temp);
  };

  return (
    <>
      <Checkbox
        label="Középre igazítás"
        name="centered"
        checked={state.centered}
        onChange={handleSimpleInput}
      />

      <Checkbox
        label="Kattintható"
        name="clickable"
        checked={state.clickable}
        onChange={handleSimpleInput}
      />

      <FormGroup label="Képek">
        {state.images?.map((image, i) => (
          <Details key={i} summary={`${i + 1}. ${image.caption || "Kép"}`}>
            <ImageFields data={image} onDataChange={onImageChange(i)} />
            <DeleteButton onClick={() => onRemoveItem(i)}>Kép törlése</DeleteButton>
          </Details>
        ))}

        <Button minimal icon="plus" intent="primary" onClick={onAddNewItem}>
          Új kép
        </Button>
      </FormGroup>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
