import React from "react";
import { connect } from "react-redux";
import http from "../../../utils/http";
import { API_BASE_EXAM_CENTER, ROUTES } from "../../../utils/routes";
import ExamPartnerForm from "./PartnerForm";

const emptyState = {
  name: "",
  hq: "",
  taxId: "",
  email: "",
  enabled: true,
};

const ExamPartnerCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    try {
      const json = await http.post(`${API_BASE_EXAM_CENTER}/partners`, state);

      // reset the form if submit was successful
      setState(emptyState);
      props.history.push(ROUTES.examPartners);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ExamPartnerForm
      title="Új partner"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
      imageRequired={true}
    />
  );
};

export default connect(null, {})(ExamPartnerCreate);
