import {
  Button,
  Callout,
  Classes,
  Dialog,
  FileInput,
  InputGroup,
  Spinner,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import { getAllImage, uploadMedia } from "../../redux/actions/common/media";

const FileUpload = ({
  uploadMedia, // redux action
  onUpload, // callback with uploaded media as parameter
  isRequired, // file input required attribute

  images, // redux mediastore state
  loading, // redux mediastore state
  getAllImage, // redux mediastore action

  separateFileName=false,
}) => {
  const [showUploadFeedback, setShowUploadFeedback] = useState(false);
  const [fileName, setFileName] = useState("");
  const [inputLabel, setInputLabel] = useState("Fájl feltöltése...");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(); // selected image in mediastore dialog
  const fileInput = useRef();

  useEffect(() => {
    if (isDialogOpen && images.length === 0) getAllImage();
  }, [isDialogOpen]);

  const handleUpload = async () => {
    const file = fileInput.current.files[0];
    if (!file) return;
    console.log("file", file);
    const res = await uploadMedia(file, fileName);
    console.log("fileupload response:", res);
    setShowUploadFeedback(res && res.id);
    // callback
    if (res && onUpload) onUpload(res);
  };

  const handleFileInputChange = () => {
    setInputLabel(fileInput.current.files[0].name);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    setShowUploadFeedback(false); // reset before selecting new image
  };

  const handleMediaSelect = () => {
    // same callback as after uploading,
    // making it irrelevant for the parent component whether the file was newly uploaded or selected
    if (onUpload) onUpload(selectedImage);
    setShowUploadFeedback(true);
    setDialogOpen(false);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        {!separateFileName && (
          <InputGroup
            required={isRequired}
            placeholder="Fájl címe"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
        )}

        <FileInput
          text={inputLabel}
          buttonText="Tallóz"
          style={{ margin: "0 10px" }}
          onInputChange={handleFileInputChange}
          inputProps={{
            ref: fileInput,
            required: isRequired,
            style: { maxWidth: "230px", overflow: "hidden" },
          }}
        />

        <Button
          icon="upload"
          text="Feltölt"
          onClick={handleUpload}
          intent="primary"
        />

        <span style={{ margin: "0 10px" }}>VAGY</span>

        <Button
          text="Médiatár"
          icon="folder-close"
          onClick={handleDialogOpen}
          title="Médiatárból választás"
        />
      </div>

      {showUploadFeedback && (
        <Callout
          style={{ marginTop: "5px", maxWidth: "200px" }}
          intent="success"
        >
          Fájl feltöltve
        </Callout>
      )}

      {/* image browser dialog */}
      <Dialog
        isOpen={isDialogOpen}
        title="Kép választása médiatárból"
        icon="media"
        onClose={handleDialogClose}
        usePortal={false}
        style={{ width: 800 }}
      >
        <div
          className={Classes.DIALOG_BODY}
          style={{ maxHeight: "80vh", overflow: "auto" }}
        >
          {loading ? (
            <Spinner intent="primary" />
          ) : (
            <div
              style={{
                display: "grid",
                gridGap: "1rem",
                gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
              }}
            >
              {images.map((m, i) => (
                <LazyLoadImage
                  key={m.id}
                  src={m.uri.find((u) => u.size === "xs")?.uri}
                  onClick={() => setSelectedImage(m)}
                  effect="blur"
                  style={{
                    objectFit: "cover",
                    height: "120px",
                    width: "100%",
                    border:
                      selectedImage?.id === m.id ? "4px solid orange" : "none",
                  }}
                />
              ))}
            </div>
          )}
        </div>
        <br />

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={handleDialogClose}>Mégse</Button>

            <Button
              icon="floppy-disk"
              intent="primary"
              onClick={handleMediaSelect}
            >
              Mentés
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const { images, loading } = state.media;
  return { images, loading };
};

export default connect(mapStateToProps, { getAllImage, uploadMedia })(
  FileUpload
);
