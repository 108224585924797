import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import http, { authFetch } from "../../../utils/http";
import { API_BASE_QUALIFCARD, ROUTES } from "../../../utils/routes";
import PkCardForm from "./PkCardForm";

const PkCardEdit = (props) => {
  const [cardData, setCardData] = useState();

  const { id } = useParams();

  useEffect(() => {
    getPkData();
  }, []);

  const getPkData = async () => {
    try {
      const data = await http.get(`${API_BASE_QUALIFCARD}/cms/qualifications/${id}`);
      setCardData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditSubmit = async (state) => {
    try {
      await http.put(`${API_BASE_QUALIFCARD}/cms/qualifications/${id}`, state);
      props.history.push(ROUTES.pkCards);
    } catch (error) {
      console.error(error);
    }
  };

  if (!cardData) return <Spinner />;

  return (
    <PkCardForm
      initialState={cardData}
      submitBtnText="Módosít"
      title="PK kártya szerkesztése"
      handleSubmit={handleEditSubmit}
      imageRequired={false}
    />
  );
};

export default PkCardEdit;
