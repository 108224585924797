import http from "../../../utils/http";
import { API_BASE_PROFCARD_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getAllProfCardSector = () => async (dispatch) => {
  try {
    const json = await http.get(
      API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_SECTORS
    );
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Ágazatok lekérese sikertelen ${error}`));
  }
};

export const getAllProfCardQualif = () => async (dispatch) => {
  try {
    const json = await http.get(
      API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_QUALIFICATIONS
    );
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakmák lekérése sikertelen ${error}`));
  }
};

export const getProfCardSectorCardData = (id) => async (dispatch) => {
  try {
    const json = await http.get(
      `${API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_SECTORS}/${id}`
    );
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakmakártya adatok lekerese sikertelen ${error}`));
  }
};

export const getProfQualifCardData = (id) => async (dispatch) => {
  try {
    const json = await http.get(
      `${API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_QUALIFICATIONS}/${id}`
    );
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakmakártya adatok lekerese sikertelen ${error}`));
  }
};

export const createProfCardQualifData = (cardData) => async (dispatch) => {
  try {
    const json = await http.post(
      `${API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_QUALIFICATIONS}`,
      cardData
    );
    dispatch(addToastSuccess("Szakmakártya letrehozva!"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakmakártya letrehozasa sikertelen ${error}`));
  }
};

export const createProfCardSectorData = (cardData) => async (dispatch) => {
  try {
    const json = await http.post(
      `${API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_SECTORS}`,
      cardData
    );
    dispatch(addToastSuccess("Szakmakártya letrehozva!"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakmakártya letrehozasa sikertelen ${error}`));
  }
};

export const editProfCardSectorData = (id, cardData) => async (dispatch) => {
  try {
    const json = await http.put(
      `${API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_SECTORS}/${id}`,
      cardData
    );
    dispatch(addToastSuccess("Szektor szerkesztése sikeres!"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szektor szerkesztése sikertelen ${error}`));
  }
};

export const editProfCardQualifData = (id, cardData) => async (dispatch) => {
  try {
    const json = await http.put(
      `${API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_QUALIFICATIONS}/${id}`,
      cardData
    );
    dispatch(addToastSuccess("Szakma szerkesztése sikeres!"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakma szerkesztése sikertelen ${error}`));
  }
};

// deleted cardData
export const deleteProfCardSectorData = (id) => async (dispatch) => {
  try {
    const json = await http.del(
      `${API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_SECTORS}/${id}`
    );
    dispatch(addToastSuccess("Szakmakartya adatok torolve!"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakmakartya adatok torlese sikertelen: ${error}`));
  }
};

// deleted cardData
export const deleteProfCardQualifData = (id) => async (dispatch) => {
  try {
    const json = await http.del(
      `${API_BASE_PROFCARD_ADMIN + API_ENDPOINT.SZAKMAKARTYAK_QUALIFICATIONS}/${id}`
    );
    dispatch(addToastSuccess("Szakmakartya adatok torolve!"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Szakmakartya adatok torlese sikertelen: ${error}`));
  }
};
