import { Callout, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import http from "../../../utils/http";
import {
  API_BASE_IKK,
  API_BASE_IKK_ADMIN,
  API_ENDPOINT,
  ROUTES,
} from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

export const PopupAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);
  const [popups, setPopups] = useState([]);

  useEffect(() => {
    getPopups();
  }, []);

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = async () => {
    await http.del(`${API_BASE_IKK_ADMIN + API_ENDPOINT.POPUP}/${rowToDelete.id}`);
    setRowToDelete(null);
    getPopups();
  };

  const getPopups = async () => {
    const data = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.POPUP);
    setPopups(data);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Cím",
        accessor: "title",
      },
      {
        Header: "Aktív",
        accessor: "enabled",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Létrehozva",
        accessor: "created_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Kép",
        accessor: "img_media_id",
        Cell: ({ value }) => (
          <img
            alt=""
            width="100"
            src={`${API_BASE_IKK}/media/${value}/xs`}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.popupEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2>Összes popup</h2>

      <Callout title="Figyelem!" intent="warning">
        Győződjünk meg róla, hogy csak egy popup van aktív státuszban, mert az oldalon
        mindegyik aktív megjelenik!
      </Callout>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      <TableAdvanced columns={columns} data={popups} />
    </>
  );
};
