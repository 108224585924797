import React from "react";
import { connect } from "react-redux";
import { createProject } from "../../../redux/actions/ikk/project";
import { ROUTES } from "../../../utils/routes";
import ProjectForm from "./ProjectForm";

const emptyState = {
  title: "",
  body: "",
  order: 1,
  category: "",
};

const ProjectCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const res = await props.createProject(state);
    // reset the form if submit was successful
    if (res && res.id) {
      // setState(emptyState);
      props.history.push(ROUTES.projects);
    }
  };

  return (
    <ProjectForm
      title="Új Projekt blokk"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
      imageRequired={true}
    />
  );
};

export default connect(null, { createProject })(ProjectCreate);
