import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import http from "../../../utils/http";
import { API_BASE_EXAM_CENTER, ROUTES } from "../../../utils/routes";
import ExamPartnerForm from "./PartnerForm";

const ExamPartnerEdit = (props) => {
  const [partner, setPartner] = useState();

  const { id } = useParams();

  useEffect(() => {
    getExam();
  }, []);

  const getExam = async () => {
    const data = await http.get(`${API_BASE_EXAM_CENTER}/partners/${id}`);
    const { name, hq, taxId, email, enabled } = data;
    setPartner({ name, hq, taxId, email, enabled });
  };

  const handleEditSubmit = async (state) => {
    const data = await http.put(`${API_BASE_EXAM_CENTER}/partners/${id}`, state);
    if (data) props.history.push(ROUTES.examPartners);
  };

  if (!partner) return <Spinner />;

  return (
    <ExamPartnerForm
      initialState={partner}
      submitBtnText="Módosít"
      title="Partner szerkesztése"
      handleSubmit={handleEditSubmit}
      imageRequired={false}
    />
  );
};

export default connect(null, {})(ExamPartnerEdit);
