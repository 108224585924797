import { Classes, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";

const MenuIkk = () => {
  return (
    <Menu className={Classes.ELEVATION_2}>
      <MenuDivider title="Dinamikus tartalom" />

      <MenuItem icon="menu" text="Menü">
        <MenuItem
          tagName={Link}
          to={ROUTES.menuItemCreate}
          icon="plus"
          text="Új menü item"
        />
        <MenuItem
          tagName={Link}
          to={ROUTES.menuCategory}
          icon="edit"
          text="Összes menü kategória"
        />
      </MenuItem>

      <MenuItem icon="manual" text="Hírek">
        <MenuItem
          tagName={Link}
          to={ROUTES.newsCreate}
          icon="plus"
          text="Új hír"
        />
        <MenuItem
          tagName={Link}
          to={ROUTES.news}
          icon="edit"
          text="Összes hír"
        />
      </MenuItem>

      <MenuItem icon="tag" text="Tagek">
        <MenuItem
          tagName={Link}
          to={ROUTES.tagsCreate}
          icon="plus"
          text="Új tag"
        />
        <MenuItem
          tagName={Link}
          to={ROUTES.tags}
          icon="edit"
          text="Összes tag"
        />
      </MenuItem>
      <MenuItem icon="tag" text="Hír Csoportok">
        <MenuItem
          tagName={Link}
          to={ROUTES.groupsCreate}
          icon="plus"
          text="Új csoport"
        />
        <MenuItem
          tagName={Link}
          to={ROUTES.groups}
          icon="edit"
          text="Összes csopot"
        />
      </MenuItem> 
      <MenuItem icon="tag" text="Hír Kategóriák">
        <MenuItem
          tagName={Link}
          to={ROUTES.categoriesCreate}
          icon="plus"
          text="Új kategória"
        />
        <MenuItem
          tagName={Link}
          to={ROUTES.categories}
          icon="edit"
          text="Összes kategória"
        />
      </MenuItem>

      <MenuItem icon="multi-select" text="Slider">
        <MenuItem
          tagName={Link}
          to={ROUTES.slidesCreate}
          icon="plus"
          text="Új slide"
        />
        <MenuItem
          tagName={Link}
          to={ROUTES.slides}
          icon="edit"
          text="Összes slide"
        />
      </MenuItem>

      <MenuItem icon="timeline-events" text="Események">
        <MenuItem
          tagName={Link}
          to={ROUTES.eventsCreate}
          icon="plus"
          text="Új esemény"
        />
        <MenuItem
          icon="edit"
          tagName={Link}
          to={ROUTES.events}
          text="Összes esemény"
        />
      </MenuItem>

      <MenuItem icon="office" text="Szakképzés centrumok">
        <MenuItem
          icon="plus"
          tagName={Link}
          to={ROUTES.centrumsCreate}
          text="Új szakképzési centrum"
        />
        <MenuItem
          icon="edit"
          tagName={Link}
          to={ROUTES.centrums}
          text="Összes szakképzési centrum"
        />
      </MenuItem>

      <MenuItem icon="database" text="Közérdekű adatok">
        <MenuItem
          icon="plus"
          text="Új közérdekű adat"
          tagName={Link}
          to={ROUTES.publicInfoCreate}
        />
        <MenuItem
          icon="edit"
          text="Összes közérdekű adat"
          tagName={Link}
          to={ROUTES.publicInfo}
        />
      </MenuItem>

      <MenuItem icon="archive" text="Archívum">
        <MenuItem
          icon="plus"
          text="Új archív adat"
          tagName={Link}
          to={ROUTES.archiveCreate}
        />
        <MenuItem
          icon="edit"
          text="Összes archív adat"
          tagName={Link}
          to={ROUTES.archive}
        />
      </MenuItem>

      <MenuItem icon="document" text="Dokumentumok">
        <MenuItem
          icon="plus"
          text="Új dokumentum"
          tagName={Link}
          to={ROUTES.documentsCreate}
        />
        <MenuItem
          icon="edit"
          text="Összes dokumentum"
          tagName={Link}
          to={ROUTES.documents}
        />
      </MenuItem>

      <MenuItem icon="briefcase" text="Karrier">
        <MenuItem
          icon="plus"
          text="Új álláshirdetés"
          tagName={Link}
          to={ROUTES.careerCreate}
        />
        <MenuItem
          icon="edit"
          text="Összes álláshirdetés"
          tagName={Link}
          to={ROUTES.careers}
        />
      </MenuItem>

      <MenuItem icon="star" text="Popup">
        <MenuItem
          icon="plus"
          text="Új Popup"
          tagName={Link}
          to={ROUTES.popupCreate}
        />
        <MenuItem
          icon="edit"
          text="Összes Popup"
          tagName={Link}
          to={ROUTES.popup}
        />
      </MenuItem>

      <MenuItem icon="euro" text="Ösztöndíjak, pályázatok">
        <MenuItem
          icon="plus"
          text="Új ösztöndíj sablon"
          tagName={Link}
          to={ROUTES.scholarshipTemplateCreate}
        />
        <MenuItem
          icon="edit"
          text="Összes ösztöndíj sablon"
          tagName={Link}
          to={ROUTES.scholarshipTemplates}
        />
        <MenuItem
          icon="people"
          text="Jelentkezők"
          tagName={Link}
          to={ROUTES.scholarshipApplicants}
        />
      </MenuItem>

      <MenuItem icon="projects" text="Projektek">
        <MenuItem
          icon="plus"
          text="Új Projekt"
          tagName={Link}
          to={ROUTES.projectsCreate}
        />
        <MenuItem
          icon="edit"
          tagName={Link}
          to={ROUTES.projects}
          text="Összes Projekt"
        />
      </MenuItem>

      <MenuItem icon="page-layout" text="Egyedi oldalak">
        <MenuItem
          icon="plus"
          tagName={Link}
          to={ROUTES.pagesCreate}
          text="Új oldal"
        />
        <MenuItem
          icon="edit"
          tagName={Link}
          to={ROUTES.pages}
          text="Összes oldal"
        />
      </MenuItem>

      <MenuDivider title="Statikus tartalom" />

      <MenuItem
        icon="align-left"
        tagName={Link}
        to={ROUTES.staticTexts}
        text="Szövegek"
      />

      <MenuItem icon="align-left" text="Rólunk szövegek">
        <MenuItem
          icon="plus"
          text="Új Rólunk szöveg blokk"
          tagName={Link}
          to={ROUTES.aboutRowsCreate}
        />
        <MenuItem
          icon="edit"
          tagName={Link}
          to={ROUTES.aboutRows}
          text="Összes Rólunk szöveg blokk"
        />
      </MenuItem>
      <MenuItem
        icon="media"
        tagName={Link}
        to={ROUTES.staticImages}
        text="Képek"
      />
    </Menu>
  );
};

export default MenuIkk;
