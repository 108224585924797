import { Spinner } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { getAllTags } from "../../../redux/actions/ikk/tags";
import { ROUTES } from "../../../utils/routes";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const TagsAll = (props) => {
  useEffect(() => {
    props.getAllTags();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Tag nev",
        accessor: "name",
      },
      {
        Header: "Kapcsolat (ID)",
        accessor: "morph_to",
        Cell: ({ row, value }) =>
          value ? `${value} (${row.original.morph_to_id})` : "-",
      },
      {
        Header: "Utoljára módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.tagsEdit, { id: row.original.id })}
            // onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  if (!props.tags.length) return <Spinner />;

  return (
    <>
      <h2>Összes tag</h2>

      <TableAdvanced data={props.tags} columns={columns} />
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.tags };
};
export default connect(mapStateToProps, {
  getAllTags,
})(TagsAll);
