import { Classes, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/routes";

const MenuMedia = () => {
  return (
    <Menu className={Classes.ELEVATION_2}>
      <MenuItem
        icon="document"
        text="Dokumentumok"
        tagName={Link}
        to={ROUTES.mediaFiles}
      />
      <MenuItem
        icon="media"
        text="Képek"
        tagName={Link}
        to={ROUTES.mediaImages}
      />

      <MenuDivider />

      <MenuItem
        icon="export"
        text="Feltöltés"
        tagName={Link}
        to={ROUTES.mediaUpload}
      />
    </Menu>
  );
};

export default MenuMedia;
