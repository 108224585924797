import { Button, FormGroup, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  createProfCardSectorData,
  editProfCardSectorData,
  getProfCardSectorCardData,
} from "../../../redux/actions/szakkepzes/profCards";
import { LIMIT } from "../../../utils/limits";
import { API_BASE_IKK, ROUTES } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import MyCkEditor from "../../common/MyCkEditor";

const initialState = {
  body: "",
  medias: [],
};

const ProfessionCardsSectorsEdit = (props) => {
  const [sector, setSector] = useState(); // original sector as returned from the API
  const [sectorData, setSectorData] = useState(initialState); // cardData to be created/edited
  const [loaded, setLoaded] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const handleInput = (key, val) => {
    setSectorData((d) => ({ ...d, [key]: val }));
  };

  useEffect(() => {
    getSectorData();
  }, []);

  const getSectorData = async () => {
    const res = await props.getProfCardSectorCardData(id);
    console.log(res);
    setSector(res);
    if (res.cardData) setSectorData({ ...res.cardData });
    setLoaded(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...sectorData,
      body: sectorData.body.replaceAll("&nbsp;", " "), // to prevent bugs caused by non-breaking
      sectorId: Number(id),
    };
    if (!sector.cardData) {
      // cardData not set, create new
      const res = await props.createProfCardSectorData(data);
    } else {
      // edit existing cardData
      const res = await props.editProfCardSectorData(id, data);
    }
    history.push(ROUTES.szakmakartyakSectors);
  };

  const handleFileUpload = (type) => (media) => {
    const newMed = { mediaId: media.id, type };
    const found = sectorData.medias.find((m) => m.type === type);
    const newMedias = found
      ? sectorData.medias.map((m) => (m.type === type ? newMed : m)) // replacing existing media
      : [...sectorData.medias, newMed]; // adding new media item
    setSectorData((d) => ({ ...d, medias: newMedias }));
  };

  const mediaCover = sectorData.medias.find((m) => m.type === "COVER");
  const mediaImg1 = sectorData.medias.find((m) => m.type === "IMG1");
  const mediaImg2 = sectorData.medias.find((m) => m.type === "IMG2");
  const mediaImg3 = sectorData.medias.find((m) => m.type === "IMG3");

  if (!sector || !loaded) return <Spinner intent="primary" />;

  return (
    <>
      <h1>Szakmakártya ágazat szerkesztése: {sector.name}</h1>

      <form onSubmit={handleSubmit} style={{ maxWidth: "800px", paddingBottom: 100 }}>
        <FormGroup
          label="Szövegtörzs"
          helperText={`${sectorData.body.length} / ${LIMIT.PROFCARD_SECTOR_BODY_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <MyCkEditor
            data={sectorData?.body}
            onDataChange={(data) => handleInput("body", data)}
          />
        </FormGroup>

        <FormGroup label="Borító kép ">
          <FileUpload onUpload={handleFileUpload("COVER")} />
        </FormGroup>
        <FormGroup>
          {!!mediaCover ? (
            <img src={`${API_BASE_IKK}/media/${mediaCover.mediaId}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        <FormGroup label="Kép 1 ">
          <FileUpload onUpload={handleFileUpload("IMG1")} />
        </FormGroup>
        <FormGroup>
          {!!mediaImg1 ? (
            <img src={`${API_BASE_IKK}/media/${mediaImg1.mediaId}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        <FormGroup label="Kép 2 ">
          <FileUpload onUpload={handleFileUpload("IMG2")} />
        </FormGroup>
        <FormGroup>
          {!!mediaImg2 ? (
            <img src={`${API_BASE_IKK}/media/${mediaImg2.mediaId}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        <FormGroup label="Kép 3">
          <FileUpload onUpload={handleFileUpload("IMG3")} />
        </FormGroup>
        <FormGroup>
          {!!mediaImg3 ? (
            <img src={`${API_BASE_IKK}/media/${mediaImg3.mediaId}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        <FormGroup label="Video" labelInfo="(opcionális)">
          <FileUpload onUpload={handleFileUpload("VIDEO")} />
        </FormGroup>

        <br />

        <Button intent="success" type="submit" icon="floppy-disk">
          Mentés
        </Button>
      </form>
    </>
  );
};

export default connect(null, {
  getProfCardSectorCardData,
  editProfCardSectorData,
  createProfCardSectorData,
})(ProfessionCardsSectorsEdit);
