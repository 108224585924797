import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT, ROUTES } from "../../../utils/routes";
import PopupForm from "./PopupForm";
import { showToast } from "../../../utils/helpers";

export const PopupEdit = (props) => {
  const [popup, setPopup] = useState();

  const { id } = useParams();

  useEffect(() => {
    fetchPopups();
  }, []);

  async function fetchPopups() {
    const pData = await getPopupById(id);
    setPopup(pData);
  }

  /**
   * Make a request to get the popup data
   * @param {string} id The ID of the popup
   * @returns The popup data
   */
  const getPopupById = async (id) => {
    const res = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.POPUP + "/" + id);
    return res;
  };

  const handleEditSubmit = async (formState, setFormState) => {
    try {
      await http.put(API_BASE_IKK_ADMIN + API_ENDPOINT.POPUP + "/" + id, formState);
      showToast({ intent: "success", message: "Popup módosítva!" });
      props.history.push(ROUTES.popup);
    } catch (error) {
      showToast({ intent: "danger", message: `Hiba történt! ${error.message}` });
    }
  };

  return (
    <>
      {popup && (
        <PopupForm
          initialState={popup}
          submitBtnText="Módosít"
          title="Popup módosítása"
          handleSubmit={handleEditSubmit}
        />
      )}
    </>
  );
};
