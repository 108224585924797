import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  H3,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  approveNews,
  deleteNews,
  getNews,
  getOneNews,
} from "../../../redux/actions/skillscom/news";
import { isAuthorized } from "../../../utils/auth";
import { API_BASE_IKK, ROUTES, SKILLSCOM_URL } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const NewsAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);
  const [rowToApprove, setRowToApprove] = useState();

  useEffect(() => {
    getAllNewsRecursively(0, 100);
  }, []);

  const getAllNewsRecursively = async (offset, limit) => {
    const j = await props.getNews(offset, limit);
    if (Array.isArray(j) && j.length === limit)
      getAllNewsRecursively(offset + limit, limit);
  };

  // opens delete dialog
  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  // sends delete request
  const handleDelete = () => {
    props.deleteNews(rowToDelete.id);
    setRowToDelete(null);
    setRowToApprove(null);
  };

  // sends approve request
  const handleApprove = async (id) => {
    await props.approveNews(id);
    getAllNewsRecursively(0, 100); // TODO should be handled in redux
  };

  // opens approve dialog
  const handleApproveDialog = (id) => async () => {
    const article = await props.getOneNews(id);
    setRowToApprove(article);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Posztolás dátum",
        accessor: "createdAt",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },

      {
        Header: "Utoljára módosítva",
        accessor: "updatedAt",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Cím",
        accessor: "title",
      },
      // {
      //   Header: "Tagek",
      //   accessor: "tags",
      //   Cell: ({ value }) => {
      //     return value?.map((tag) => (
      //       <Tag round minimal key={tag.id} style={{ margin: "2px" }}>
      //         {tag.name}
      //       </Tag>
      //     ));
      //   },
      // },

      {
        Header: "Kép",
        accessor: "coverImgId",
        Cell: ({ value }) => (
          <img
            width="100"
            src={`${API_BASE_IKK}/media/${value}/sm`}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Elfogadva",
        accessor: "approved",
        Cell: ({ row, value }) => {
          if (
            isAuthorized({
              authNamespace: "zartrendszer",
              authPermission: "post:approve",
            }) &&
            !value
          ) {
            return (
              <Button
                intent="primary"
                onClick={handleApproveDialog(row.original.id)}
              >
                Előnézet
              </Button>
            );
          } else return <p>{value ? "Igen" : "Nem"}</p>;
        },
      },
      {
        Header: "Web",
        Cell: ({ row }) => (
          <AnchorButton
            small
            minimal
            icon="document-open"
            text="Megnyit"
            target="_blank"
            href={`${SKILLSCOM_URL}/hirek/${row.original.id}`}
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={
              isAuthorized({
                namespace: "zartrendszer",
                authPermission: "article:update",
              })
                ? generatePath(ROUTES.skillsComNewsEdit, {
                    id: row.original.id,
                  })
                : null
            }
            onDelBtnClick={
              isAuthorized({
                namespace: "zartrendszer",
                authPermission: "article:delete",
              })
                ? handleDeleteDialog
                : null
            }
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes hír</h2>

      <ApproveDialog
        isOpen={!!rowToApprove}
        article={rowToApprove}
        onApprove={handleApprove}
        onDelete={handleDeleteDialog}
        onCancel={() => setRowToApprove(null)}
      />

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.news} />
      )}
    </>
  );
};

const ApproveDialog = ({ isOpen, article, onCancel, onApprove, onDelete }) => {
  if (!isOpen || !article) return null;
  return (
    <Dialog
      icon="info-sign"
      isOpen={isOpen}
      onClose={onCancel}
      onApprove={() => onApprove(article.id)}
      usePortal={false}
      title="Hír bejegyzés elbírálásra vár"
      style={{ width: "900px" }}
    >
      <div
        className={Classes.DIALOG_BODY}
        style={{ maxHeight: "60vh", overflowY: "auto" }}
      >
        <H3>{article.title}</H3>
        <time>{new Date(article.postedAt).toLocaleString()}</time>
        {article.tags.map((tag) => (
          <Tag key={tag.name} style={{ marginLeft: 10 }}>
            {tag.name}
          </Tag>
        ))}
        <p style={{ fontWeight: "bold", marginTop: 10 }}>{article.lead}</p>
        <div dangerouslySetInnerHTML={{ __html: article.body }} />
        <img
          src={`${API_BASE_IKK}/media/${article.coverImgId}/lg`}
          style={{
            marginTop: 10,
            width: "100%",
            display: article.coverImgId ? "block" : "none",
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent="danger" onClick={() => onDelete(article)}>
            Töröl
          </Button>
          <Button onClick={onCancel}>Mégse</Button>
          <Button intent="primary" icon="tick-circle">
            Elfogad
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { news, loading } = state.skillsComNews;
  return { news, loading };
};

export default connect(mapStateToProps, {
  getNews,
  getOneNews,
  deleteNews,
  approveNews,
})(NewsAll);
