import React, { useEffect } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { getAllUsers } from "../../redux/actions/auth/users";
import { ROUTES } from "../../utils/routes";
import TableActionButtons from "../common/TableActionButtons";
import { TableAdvanced } from "../common/TableAdvanced";

const Users = (props) => {
  useEffect(() => {
    const getUsers = async () => {
      props.getAllUsers();
    };
    if (props.users.length === 0) getUsers();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) =>
          value.length > 20 ? `${value.slice(0, 20)}...` : value,
      },
      {
        Header: "Provider",
        accessor: "provider",
      },
      {
        Header: "Verified",
        accessor: "verified",
        Cell: ({ value }) => (value ? "True" : "False"),
      },
      {
        Header: "Roles",
        accessor: "roles",
        Cell: ({ value }) => (
          <table style={{ borderCollapse: "collapse" }}>
            <tbody>
              {Object.keys(value).map((val) => (
                <tr key={val}>
                  <td style={{ background: "transparent", boxShadow: "none" }}>
                    <b>{val}:</b>
                  </td>
                  <td style={{ background: "transparent", boxShadow: "none" }}>
                    {value[val].sort().join(", ")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ),
      },
      {
        Header: "Művelet",
        accessor: (row) => row,
        Cell: ({ value }) => (
          <TableActionButtons
            row={value}
            editHref={generatePath(ROUTES.authEdit, { id: value.id })}
            onDelBtnClick={() => {
              console.log("lol");
            }}
          />
        ),
      },
    ],
    []
  );
  return (
    <>
      <h2>Összes felhasználó</h2>

      <TableAdvanced data={props.users} columns={columns} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { users } = state.users;
  return { users };
};
export default connect(mapStateToProps, { getAllUsers })(Users);
