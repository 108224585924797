import {
    SZAKKEPESITES_GET_SPECS_6110_BEGIN,
    SZAKKEPESITES_GET_SPECS_6110_FAIL,
    SZAKKEPESITES_GET_SPECS_6110_SUCCESS,
  } from "../../actionTypes";
  
  const initialState = {
    specialization: [],
    loading: false,
    error: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case SZAKKEPESITES_GET_SPECS_6110_BEGIN: {
        return {
          ...state,
          loading: true,
          error: null,
        };
      }
      case SZAKKEPESITES_GET_SPECS_6110_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: null,
          specialization: action.payload,
        };
      }
      case SZAKKEPESITES_GET_SPECS_6110_FAIL: {
        return {
          ...state,
          loading: false,
          error: action.payload,
          specialization: [],
        };
      }
  
      default:
        return state;
    }
  }
  