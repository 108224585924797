import { LayoutDefault } from "../LayoutDefault";
import Menu6110 from "./Menu6110";
import { Callout, Card, H4 } from "@blueprintjs/core";
import React from "react";
const styleWrap = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "auto 1fr",
  marginTop: "20px",
};
export const Project6110Layout = ({ children }) => {
  return (
    <LayoutDefault>
      <div style={{ ...styleWrap }}>
        <div>
          <Menu6110 />
        </div>

        <Card
          elevation={2}
          style={{ height: "calc(100vh - 90px)", overflow: "auto" }}
        >
          <H4>
            <a href={"#"} target="_blank">
              6110
            </a>
          </H4>

          {children}
        </Card>
      </div>
    </LayoutDefault>
  );
};
