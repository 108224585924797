import React from "react";
import { connect } from "react-redux";
import { uploadMedia } from "../../../redux/actions/common/media";
import { uploadEvent } from "../../../redux/actions/ikk/event";
import { ROUTES } from "../../../utils/routes";
import EventForm from "./EventForm";

const emptyState = {
  type: "Általános",
  title: "",
  description: "",
  from: null,
  till: null,
  published_at: null,
  link: "",
  comment: "",
  tags: [],
  location: {
    link: "",
    name: "",
    lat: "",
    lng: "",
  },
};

const EventCreate = (props) => {
  const handleCreateSubmit = async (state, setState) => {
    const res = await props.uploadEvent(state);
    if (res && res.id) props.history.push(ROUTES.events);
  };

  return (
    <EventForm
      title="Új esemény"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
    />
  );
};

export default connect(null, { uploadEvent, uploadMedia })(EventCreate);
