import { useEffect } from "react";
import { connect } from "react-redux";
import { getMe } from "../../redux/actions/skillscom/me";

/**
 * A wrapper component to guard it's children based on the account type of a user in SkillsCom.
 * If the provided `types` array includes the current user's account type,
 * the `children` is rendered as is, otherwise it will be hidden.
 */
const CheckSkillscomAccountType = ({
  me, // redux state
  getMe, // redux action
  type, // same as `types` prop but for a single string
  types = [], // array of Skillscom account types to allow (student, teacher, institute, company)
  children, // React children
}) => {
  useEffect(() => {
    if (!me) getMe();
  }, []);

  const typesProvided = (types.length === 0 && type ? [type] : types).map((t) =>
    t.toUpperCase()
  );

  if (!me || !typesProvided.includes(me.type.toUpperCase())) return null;

  return children;
};

const mapStateToProps = (state) => {
  const { me } = state.skillsComMe;
  return { me };
};
export default connect(mapStateToProps, { getMe })(CheckSkillscomAccountType);
