import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getEvent, updateEvent } from "../../../redux/actions/ikk/event";
import { ROUTES } from "../../../utils/routes";
import EventForm from "./EventForm";

const EventEdit = (props) => {
  const [event, setEvent] = useState();

  const { id } = useParams();

  useEffect(() => {
    fetchEvent();
  }, []);

  async function fetchEvent() {
    const allevent = await props.getEvent();
    const ev = allevent.find((e) => e.id === Number(id));
    setEvent(ev);
  }

  const handleEditSubmit = async (state) => {
    const res = await props.updateEvent(event.id, state);
    if (res) props.history.push(ROUTES.events);
  };

  return (
    <>
      {event && (
        <EventForm
          initialState={event}
          submitBtnText="Módosít"
          title="Esemény módosítása"
          handleSubmit={handleEditSubmit}
        />
      )}
    </>
  );
};

export default connect(null, { getEvent, updateEvent })(EventEdit);
