import { Classes, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { isAuthorized } from "../../utils/auth";
import { getRouteDataFromRoute, ROUTES } from "../../utils/routes";
import { CheckRoles } from "../common/CheckRoles";
import CheckSkillscomAccountType from "../common/CheckSkillscomAccountType";

const MenuSkillsCom = () => {
  return (
    <Menu className={Classes.ELEVATION_2}>
      <MenuDivider title="Feed" />
      <MenuItem icon="manual" text="Hírek">
        <CheckRoles route={ROUTES.skillsComNewsCreate}>
          <MenuItem
            tagName={Link}
            to={ROUTES.skillsComNewsCreate}
            icon="plus"
            text="Új hír"
          />
        </CheckRoles>
        <CheckRoles route={ROUTES.skillsComNews}>
          <MenuItem tagName={Link} to={ROUTES.skillsComNews} icon="list" text="Híreim" />
        </CheckRoles>
      </MenuItem>
      <MenuItem icon="timeline-events" text="Események">
        <CheckRoles route={ROUTES.skillsComEventsCreate}>
          <MenuItem
            tagName={Link}
            to={ROUTES.skillsComEventsCreate}
            icon="plus"
            text="Új esemény"
          />
        </CheckRoles>
        <CheckRoles route={ROUTES.skillsComEvents}>
          <MenuItem
            icon="list"
            tagName={Link}
            to={ROUTES.skillsComEvents}
            text="Eseményeim"
          />
        </CheckRoles>
      </MenuItem>

      <MenuItem icon="label" text="Posztok">
        <CheckRoles route={ROUTES.skillsComPostsCreate}>
          <MenuItem
            tagName={Link}
            to={ROUTES.skillsComPostsCreate}
            icon="plus"
            text="Új poszt"
          />
        </CheckRoles>
        <CheckRoles route={ROUTES.skillsComPosts}>
          <MenuItem
            icon="list"
            tagName={Link}
            to={ROUTES.skillsComPosts}
            text="Posztjaim"
          />
        </CheckRoles>
      </MenuItem>

      <CheckSkillscomAccountType types={["company"]}>
        <MenuItem icon="briefcase" text="Álláshirdetések">
          <MenuItem
            tagName={Link}
            to={ROUTES.skillsComJobsCreate}
            icon="plus"
            text="Új Álláshirdetés"
          />
          <MenuItem
            icon="list"
            tagName={Link}
            to={ROUTES.skillsComJobs}
            text="Álláshirdetéseim"
          />
        </MenuItem>
      </CheckSkillscomAccountType>

      {(isAuthorized(getRouteDataFromRoute(ROUTES.skillsComEditors)) ||
        isAuthorized(getRouteDataFromRoute(ROUTES.skillsComEditorsAdd))) && (
        <>
          <MenuDivider title="Fiókok" />
          <MenuItem icon="user" text="Szerkesztők (tanárok)">
            <CheckRoles route={ROUTES.skillsComEditorsAdd}>
              <MenuItem
                tagName={Link}
                to={ROUTES.skillsComEditorsAdd}
                icon="plus"
                text="Új szerkesztő"
              />
            </CheckRoles>
            <CheckRoles route={ROUTES.skillsComEditors}>
              <MenuItem
                icon="list"
                tagName={Link}
                to={ROUTES.skillsComEditors}
                text="Összes szerkesztő"
              />
            </CheckRoles>
          </MenuItem>
        </>
      )}

      <MenuDivider title="Admin" />
      {(isAuthorized(getRouteDataFromRoute(ROUTES.skillsComCompanies)) ||
        isAuthorized(getRouteDataFromRoute(ROUTES.skillsComInstitutes))) && (
        <MenuItem icon="people" text="Regisztrációk">
          <CheckRoles route={ROUTES.skillsComCompanies}>
            <MenuItem
              tagName={Link}
              to={ROUTES.skillsComCompanies}
              icon="briefcase"
              text="Cégek"
            />
          </CheckRoles>
          <CheckRoles route={ROUTES.skillsComInstitutes}>
            <MenuItem
              tagName={Link}
              to={ROUTES.skillsComInstitutes}
              icon="office"
              text="Intézmények"
            />
          </CheckRoles>
        </MenuItem>
      )}

      <CheckRoles route={ROUTES.skillsComStats}>
        <MenuItem
          tagName={Link}
          to={ROUTES.skillsComStats}
          icon="timeline-bar-chart"
          text="Statisztika"
        />
      </CheckRoles>

      {/* <MenuItem icon="tag" text="Tagek">
        <MenuItem
          tagName={Link}
          to={ROUTES.tagsCreate}
          icon="edit"
          text="Új tag"
        />
        <MenuItem
          tagName={Link}
          to={ROUTES.tags}
          icon="list"
          text="Összes tag"
        />
      </MenuItem> */}
    </Menu>
  );
};

export default MenuSkillsCom;
