import {
  Button,
  FormGroup,
  Icon,
  InputGroup,
  NumericInput,
  MenuItem,
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";

import React, { useState } from "react";
import MyCkEditor from "../../common/MyCkEditor";
import { ControlledTagInput } from "../../common/TagInput";
import FileUpload from "../../common/FileUpload";
import {
  API_BASE_IKK,
  API_BASE_SZAKKEPESITES_6110,
  API_ENDPOINT,
} from "../../../utils/routes";
import http from "../../../utils/http";
const getQualifications = async () => {
  const json = await http.get(
    `${API_BASE_SZAKKEPESITES_6110}/v2/cms/specialization`
  );
  return json;
};
const ExamCenterForm = (props) => {
  const [state, setState] = useState(props.initialState);
  const [qualifs, setQualifs] = useState([]);

  useState(() => {
    getQualifications().then((t) => {
      setQualifs(t);
    });
  }, []);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };
  const handleLogoUpload = (media) => {
    setState((s) => ({ ...s, logo_id: media.id }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.keyCode == 13) return;
    props.handleSubmit(state, setState);
  };

  const handleTagChange = (name, values) => {
    setState((s) => ({ ...s, [name]: [...values] }));
  };
  const handleKeorSelect = (value) => {
    // setInstituteIds((ids) => [...ids, value.naturalId]);
    setState((s) => {
      if (s.qualified_exams)
        return { ...s, qualified_exams: [...s.qualified_exams, value.id] };
      return { ...s, qualified_exams: [value.id] };
    });
  };

  const handleKeorRemove = (value) => {
    // setInstituteIds((ids) => {
    //   return ids.filter((id) => id !== value.naturalId);
    // });
    setState((s) => {
      if (s.qualified_exams)
        return {
          ...s,
          qualified_exams: s.qualified_exams.filter((id) => id !== value.id),
        };
    });
  };

  return (
    <>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="office" />
        &nbsp;<span>{props.title}</span>
      </h2>
      <form
        onSubmit={handleSubmit}
        onKeyPress={(e) => e.key == "Enter" && e.preventDefault()}
        style={{ maxWidth: "550px" }}
      >
        <FormGroup label="Név" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="Név..."
            value={state.title}
            name="title"
            onChange={handleInput}
          />
        </FormGroup>

        <FormGroup label="Leírás" labelInfo="(kötelező)">
          <MyCkEditor
            data={state.text}
            onDataChange={(text) => setState((s) => ({ ...s, text }))}
          />
        </FormGroup>

        <FormGroup label="Cím" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="Cím..."
            value={state.address}
            name="address"
            onChange={handleInput}
          />
        </FormGroup>

        <FormGroup
          label="Telefon"
          helperText="Tobb szam rogzitese Enter billenyuvel lehetseges"
        >
          <ControlledTagInput
            placeholder="+36..."
            onChange={(values) => handleTagChange("phone", values)}
            values={state.phone}
          />
        </FormGroup>

        <FormGroup
          label="E-mail"
          helperText="Tobb e-mail cim rogzitese Enter billenyuvel lehetseges"
        >
          <ControlledTagInput
            placeholder="@"
            values={state.email}
            onChange={(values) => handleTagChange("email", values)}
          />
        </FormGroup>

        <FormGroup label="Honlap">
          <InputGroup
            placeholder="https://..."
            value={state.url}
            name="url"
            type="url"
            onChange={handleInput}
          />
        </FormGroup>
        <FormGroup label="Vizsga jelentkezés">
          <InputGroup
            placeholder="https://..."
            value={state.exam_software_url}
            name="exam_software_url"
            type="url"
            onChange={handleInput}
          />
        </FormGroup>
        <FormGroup
          label="Logo"
          helperText="Engedélyezett formátumok: jpg, png, webp."
        >
          <FileUpload onUpload={handleLogoUpload} separateFileName={true} />
        </FormGroup>
        <FormGroup>
          {state.logo_id ? (
            <img src={`${API_BASE_IKK}/media/${state.logo_id}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>
        <FormGroup label="Vizsgáztatható KEOR-ok">
          <MultiSelect
            fill
            items={qualifs.sort((i1, i2) =>
              i1.name.localeCompare(i2.name, "hu")
            )}
            selectedItems={qualifs.filter((i) =>
              state.qualified_exams?.includes(i.id)
            )}
            onItemSelect={handleKeorSelect}
            onRemove={handleKeorRemove}
            tagRenderer={(item) => item.name}
            popoverProps={{ minimal: true, popoverClassName: "popover" }}
            itemPredicate={(query, item) =>
              item.name.toLowerCase().indexOf(query.toLowerCase() >= 0)
            }
            itemRenderer={(item, { modifiers, handleClick }) => {
              if (!modifiers.matchesPredicate) {
                return null;
              }
              return (
                <MenuItem
                  active={modifiers.active}
                  icon={
                    state.qualified_exams?.includes(item.id) ? "tick" : "blank"
                  }
                  key={item.id}
                  onClick={handleClick}
                  text={item.name}
                  label={`(${item.code})`}
                />
              );
            }}
          />
        </FormGroup>
        <FormGroup label="Sorrend" labelInfo="(kötelező)">
          <NumericInput
            required
            min={1}
            name="order"
            value={state.order}
            onValueChange={(e) =>
              handleInput({ target: { name: "order", value: e } })
            }
          />
        </FormGroup>

        <br />

        <Button
          type="submit"
          text={props.submitBtnText}
          icon="floppy-disk"
          intent="success"
        />
      </form>
    </>
  );
};

export default ExamCenterForm;
