import {
  EVENT_ADD_BEGIN,
  EVENT_ADD_FAILURE,
  EVENT_ADD_SUCCESS,
  EVENT_DELETE_BEGIN,
  EVENT_DELETE_SUCCESS,
  EVENT_GET_BEGIN,
  EVENT_GET_FAILURE,
  EVENT_GET_SUCCESS,
} from "../actionTypes";

const initialState = {
  events: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EVENT_ADD_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case EVENT_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        events: state.events,
      };
    }
    case EVENT_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case EVENT_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case EVENT_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case EVENT_GET_SUCCESS: {
      // let events = state.events;

      // if (!Array.isArray(events)) {
      //   events = [];
      // }
      // events = events.concat(action.payload);
      // events = events.filter((obj, pos, arr) => {
      //   return arr.map((mapObj) => mapObj["id"]).indexOf(obj["id"]) === pos;
      // });

      return {
        ...state,
        loading: false,
        error: null,
        events: action.payload, //events,
      };
    }
    case EVENT_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case EVENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        events: state.events.filter((e) => e.id !== action.payload),
      };
    default:
      return state;
  }
}
