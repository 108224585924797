import { AnchorButton, Button } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllFile } from "../../redux/actions/common/media";
import { copyToClipboard } from "../../utils/helpers";
import { TableAdvanced } from "../common/TableAdvanced";

const MediaFiles = (props) => {
  useEffect(() => {
    const getFiles = async () => {
      props.getAllFile();
    };
    if (props.files.length === 0) getFiles();
  }, []);

  const getFileName = (uri) => {
    return uri.replace(/^.*[\\\/]/, "");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Fájlnév",
        accessor: (row) => row.uri[0].uri,
        Cell: ({ value }) => getFileName(value),
      },
      {
        Header: "Létrehozva",
        accessor: "created_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Utoljára módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },

      {
        Header: "Művelet",
        accessor: (row) => row.uri[0].uri,
        Cell: ({ value }) => (
          <>
            <Button
              minimal
              small
              icon="link"
              text="Link"
              onClick={() => copyToClipboard(value, "Vágólapra másolva")}
            />
            <Button
              small
              minimal
              icon="document-open"
              text="Megnyit"
              onClick={() => window.open(value, "_blank")}
            />
            <AnchorButton
              href={value}
              download={getFileName(value)}
              minimal
              small
              icon="import"
              text="Letölt"
            />
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes feltöltött dokumentum</h2>

      <TableAdvanced data={props.files} columns={columns} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { files } = state.media;
  return { files };
};
export default connect(mapStateToProps, { getAllFile })(MediaFiles);
