import {
  MENU_CATEGORY_GET_BEGIN,
  MENU_CATEGORY_GET_SUCCESS,
  MENU_CATEGORY_GET_FAILURE,
  MENU_CATEGORY_UPDATE_BEGIN,
  MENU_CATEGORY_UPDATE_SUCCESS,
  MENU_CATEGORY_UPDATE_FAILURE,
  MENU_ITEM_CREATE_BEGIN,
  MENU_ITEM_CREATE_SUCCESS,
  MENU_ITEM_CREATE_FAILURE,
  MENU_ITEM_DELETE_BEGIN,
  MENU_ITEM_DELETE_SUCCESS,
  MENU_ITEM_DELETE_FAILURE,
  MENU_ITEM_UPDATE_BEGIN,
  MENU_ITEM_UPDATE_SUCCESS,
  MENU_ITEM_UPDATE_FAILURE,
} from "../actionTypes";

const initialState = {
  categories: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MENU_CATEGORY_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case MENU_CATEGORY_GET_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
        loading: false,
        error: null,
      };
    }
    case MENU_CATEGORY_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case MENU_CATEGORY_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case MENU_CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case MENU_CATEGORY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case MENU_ITEM_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MENU_ITEM_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case MENU_ITEM_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case MENU_ITEM_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MENU_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case MENU_ITEM_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case MENU_ITEM_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MENU_ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case MENU_ITEM_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
