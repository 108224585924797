import React from "react";
import { showToast } from "../../../utils/helpers";
import http from "../../../utils/http";
import { API_BASE_SKILLSCOM, ROUTES } from "../../../utils/routes";
import JobsForm from "./JobsForm";

const emptyState = {
  title: "",
  workingHours: "",
  location: "",
  description: "",
};

const JobsCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state) => {
    try {
      const data = await http.post(`${API_BASE_SKILLSCOM}/jobs`, state);
      // reset form is submit was successful
      if (data && data.id) {
        props.history.push(ROUTES.skillsComJobs);
        showToast({ message: "Mentés sikeres!" });
      }
    } catch (error) {
      console.error(error);
      showToast({ intent: "danger", message: "Hiba történt a mentés közben!" });
    }
  };

  return (
    <JobsForm
      title="Álláshirdetés felvitele"
      initialState={emptyState}
      submitBtnText="Létrehoz"
      imageRequired={true}
      onSubmit={handleCreateSubmit}
    />
  );
};

export default JobsCreate;
