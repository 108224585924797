import http from "../../../utils/http";
import {
  API_BASE_IKK,
  API_BASE_IKK_ADMIN,
  API_ENDPOINT,
} from "../../../utils/routes";
import {
  TAGS_GET_BEGIN,
  TAGS_GET_FAIL,
  TAGS_GET_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getAllTags = () => async (dispatch) => {
  dispatch(getAllTagBegin());
  try {
    const json = await (
      await fetch(API_BASE_IKK + API_ENDPOINT.TAGS + "?limit=600")
    ).json();
    dispatch(getAllTagSuccess(json));
  } catch (error) {
    dispatch(getAllTagFail(error));
    dispatch(addToastDanger(`Kategóriák betöltése sikertelen: ${error}`));
  }
};

export const createTag = (data) => async (dispatch) => {
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.TAGS, data);
    dispatch(addToastSuccess("Tag létrehozva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Tag létrehozás sikertelen: ${error}`));
  }
};

const getAllTagSuccess = (json) => ({
  type: TAGS_GET_SUCCESS,
  payload: json,
});
const getAllTagBegin = () => ({
  type: TAGS_GET_BEGIN,
});
const getAllTagFail = (error) => ({
  type: TAGS_GET_FAIL,
  error: { error },
});
