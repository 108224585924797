import {
  Button,
  FormGroup,
  Icon,
  InputGroup,
  Position,
  TextArea,
} from "@blueprintjs/core";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import "moment/locale/hu";
import React, { useState } from "react";
import { useEffect } from "react";
import MomentLocaleUtils from "react-day-picker/moment";
import CKEditorUploadAdapter from "../../../utils/CKEditorUploadAdapter";
import { LIMIT } from "../../../utils/limits";
import { API_BASE_IKK, API_ENDPOINT, API_BASE_IKK_ADMIN } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import TagsMultiSelect from "../../common/TagsMultiSelect";
import http from "../../../utils/http";

const NewsForm = ({
  initialState,
  title,
  submitBtnText,
  onSubmit,
  imageRequired,
}) => {
  const [state, setState] = useState({ ...initialState });
  const [categories, setCategories] = useState([]);

  const [step, setStep] = useState(0);
  
  useEffect(() => {
    getCategories();
  }, []);
  

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const getCategories = async () => {
    const res = await http.get(`${API_BASE_IKK}/admin/categories`);

    setCategories(res);
  };

  const handleInputDate = (name) => (date, isUser) => {
    setState((s) => ({ ...s, [name]: date ? date.toISOString() : null }));
  };

  const handleCoverUpload = (media) => {
    setState((s) => ({ ...s, cover_id: media.id }));
  };

  const handleTagsChange = (newTags) => {
    console.log("handleTagsChange", newTags);
    const tagIds = newTags.map((t) => t.id);
    console.log(tagIds)
    setState((s) => ({ ...s, tags: [...tagIds] }));
  };
  const preSubmit = (state, setState) => {
    const s = { ...state };
    if (s.tags.length > 0 && s.tags[0].hasOwnProperty("id")) {
      s.tags = s.tags.map((t) => t.id);
    }
    onSubmit(s, setState);
  };
  return (
    <div style={{ maxWidth: "800px" }}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="manual" />
        &nbsp;&nbsp;<span>{title}</span>
      </h2>

      <div style={{ display: step === 1 ? "none" : "" }}>
        <FormGroup
          label="Cím"
          labelInfo="(kötelező)"
          helperText={`${state.title?.length} / ${LIMIT.NEWS_TITLE_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <InputGroup
            placeholder="Hír címe..."
            required
            value={state.title}
            onChange={handleInput}
            name="title"
            intent={
              state.title.length > LIMIT.EVENT_TITLE_MAX_LEN ? "danger" : ""
            }
          />
        </FormGroup>

        <FormGroup
          label="Összefoglaló"
          labelInfo="(kötelező)"
          helperText={`${state.short_description?.length} / ${LIMIT.NEWS_LEAD_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <TextArea
            fill
            growVertically
            placeholder="Rövid összefoglaló..."
            style={{ resize: "none" }}
            name="short_description"
            value={state.short_description}
            onChange={handleInput}
            intent={
              state.short_description.length > LIMIT.NEWS_LEAD_MAX_LEN
                ? "danger"
                : ""
            }
          />
        </FormGroup>
        <FormGroup
          label="Kategória"
          labelInfo="(kötelező)"
          helperText="Hír kategóriája"
          contentClassName="formHelperRight"
        >
          <select onChange={handleInput} name="category_id">
            <option value="" selected={state.category_id === null} disabled>
              none
            </option>
            {categories.map((cat) => (
              <option value={cat.id} selected={state.category_id === cat.id}>
                {cat.name} - {cat.group_id}
              </option>
            ))}
          </select>
        </FormGroup>     
        <FormGroup
          label="Tagek"
          helperText="Az itt felvitt összerendelések szerint lesz látható a hír a szakmakártyák oldalain. A listaban csak a mar korabban a Tagek menuben felvitt tagek lathatoak."
        >
          <TagsMultiSelect
            initialTags={state.tags}
            onChange={handleTagsChange}
          />
        </FormGroup>

        <FormGroup label="Publikálás ideje" labelInfo="(kötelező)">
          <DateInput
            showActionsBar
            todayButtonText="Most"
            clearButtonText="Töröl"
            locale="hu"
            localeUtils={MomentLocaleUtils}
            timePrecision={TimePrecision.MINUTE}
            formatDate={(date) => date.toLocaleString()}
            parseDate={(str) => new Date(str)}
            placeholder={"ÉÉÉÉ-HH-NN"}
            popoverProps={{ position: Position.TOP }}
            highlightCurrentDay
            value={state.published_at && new Date(state.published_at)}
            inputProps={{ leftIcon: "calendar" }}
            onChange={handleInputDate("published_at")}
          />
        </FormGroup>

        <FormGroup
          helperText="Előre szúrható vele egy régebben felvitt hír."
          label="Prioritás dátum"
        >
          <DateInput
            showActionsBar
            todayButtonText="Most"
            clearButtonText="Töröl"
            timePrecision={TimePrecision.MINUTE}
            highlightCurrentDay
            locale="hu"
            localeUtils={MomentLocaleUtils}
            formatDate={(date) => date.toLocaleString()}
            parseDate={(str) => new Date(str)}
            placeholder={"ÉÉÉÉ-HH-NN"}
            popoverProps={{ position: Position.TOP }}
            value={state.priority_date && new Date(state.priority_date)}
            inputProps={{ leftIcon: "calendar" }}
            onChange={handleInputDate("priority_date")}
          />
        </FormGroup>

        <FormGroup
          label="Borítókép"
          labelInfo={imageRequired && "(kötelező)"}
          helperText="Engedélyezett formátumok: jpg, png, webp."
        >
          <FileUpload onUpload={handleCoverUpload} isRequired={imageRequired} />
        </FormGroup>

        <FormGroup>
          {state.cover_id ? (
            <img src={`${API_BASE_IKK}/media/${state.cover_id}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        <br />

        <Button
          onClick={() => setStep(1)}
          text="Tovább"
          intent="primary"
          rightIcon="chevron-right"
        />
      </div>
      <div style={{ display: step === 0 && "none" }}>
        <CKEditor
          editor={ClassicEditor}
          data={state.body}
          onInit={(editor) => {
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              );
            editor.plugins.get("FileRepository").createUploadAdapter = (
              loader
            ) => {
              return new CKEditorUploadAdapter(loader);
            };
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setState((s) => ({ ...s, body: data }));
          }}
          config={{
            image: {
              styles: ["full", "alignRight", "alignLeft"],
              toolbar: [
                "imageStyle:full",
                "imageStyle:alignRight",
                "imageStyle:alignLeft",
                "|",
                "imageTextAlternative",
              ],
            },
            mediaEmbed: {
              previewsInData: true,
            },
            link: {
              decorators: {
                isExternal: {
                  mode: "manual",
                  label: "Új oldalon nyílik meg",
                  attributes: {
                    target: "_blank",
                  },
                },
              },
            },
          }}
        />

        <br />
        <Button
          text="Vissza"
          icon="chevron-left"
          style={{ marginRight: "20px" }}
          onClick={() => setStep(0)}
        />
        <Button
          intent="success"
          text={submitBtnText}
          icon="floppy-disk"
          onClick={() => preSubmit(state, setState)}
        />
      </div>
      <br />
    </div>
  );
};

export default NewsForm;
