import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { Details } from "../../../common/Details";
import MyCkEditor from "../../../common/MyCkEditor";
import { linkDefaults, LinkFields } from "../fields/LinkFields";
import { useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";

export const Documents = ({ initialState, onComponentSave }) => {
  const { state, isDirty, setState } = useInputState(initialState);

  const onAddNew = () => {
    setState((s) => ({
      ...s,
      documents: [
        ...s.documents,
        { title: "Title", description: "", link: { ...linkDefaults } },
      ],
    }));
  };

  const onDocChange = (index, key, value) => {
    const temp = structuredClone(state);
    temp.documents[index][key] = value;
    setState(temp);
  };

  const onRemoveItem = (index) => {
    const temp = structuredClone(state.documents);
    temp.splice(index, 1);
    setState((s) => ({ ...s, documents: temp }));
  };

  return (
    <>
      <FormGroup label="Dokumentumok">
        {state.documents.map((doc, docIndex) => (
          <Details key={docIndex} summary={`${docIndex + 1}. ${doc.title}`}>
            <FormGroup label="Cím" labelInfo="(kötelező)">
              <InputGroup
                required
                value={doc.title}
                onChange={(e) => onDocChange(docIndex, "title", e.target.value)}
              />
            </FormGroup>
            <FormGroup label="Rovid leiras">
              <MyCkEditor
                data={doc.description}
                onDataChange={(d) => onDocChange(docIndex, "description", d)}
              />
            </FormGroup>
            <LinkFields
              data={doc.link}
              onDataChange={(data) => onDocChange(docIndex, "link", data)}
            />
            <Button
              small
              minimal
              icon="trash"
              intent="danger"
              onClick={() => onRemoveItem(docIndex)}
            >
              Dokumentum törlése
            </Button>
          </Details>
        ))}

        <Button minimal icon="plus" intent="primary" onClick={onAddNew}>
          Új dokumentum
        </Button>
      </FormGroup>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
