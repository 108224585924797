import { Position, Toast, Toaster } from "@blueprintjs/core";
import React from "react";
import { connect } from "react-redux";
import { removeToast } from "../../redux/actions/common/toast";

const ToastNotification = ({ toasts, removeToast }) => {
  const getTimeout = (intent) => (intent === "success" ? 4000 : 10000);

  const getIcon = (intent) => {
    return intent === "danger"
      ? "error"
      : intent === "warning"
      ? "warning-sign"
      : "tick";
  };

  return (
    <>
      <Toaster position={Position.BOTTOM}>
        {toasts.map((t, i) => (
          <Toast
            key={i}
            icon={getIcon(t.intent)}
            intent={t.intent}
            message={t.message}
            onDismiss={() => removeToast()}
            timeout={getTimeout(t.intent)}
          />
        ))}
      </Toaster>
    </>
  );
};

const mapStateToProps = (state) => {
  const { toasts } = state.toast;
  return { toasts };
};

export default connect(mapStateToProps, { removeToast })(ToastNotification);
