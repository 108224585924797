import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import http from "../../../utils/http";
import { API_BASE_CEAM, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

export const getAllSZc = async () => {
  try {
    const json = await http.get(`${API_BASE_CEAM}/api/szc`);
    return json;
  } catch (error) {
    console.error(error);
  }
};

const SZCAll = () => {
  const [szcs, setSzcs] = useState();
  const [rowToDelete, setRowToDelete] = useState();

  useEffect(() => {
    getAllSZc().then((data) => setSzcs(data));
  }, []);

  const handleDelete = async () => {
    const json = await http.del(`${API_BASE_CEAM}/api/szc/${rowToDelete.naturalId}`);
    setRowToDelete(null);
    getAllSZc().then((data) => setSzcs(data));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "naturalId",
      },
      {
        Header: "Név",
        accessor: "name",
      },
      {
        Header: "Domain",
        accessor: "realDomains",
        Cell: ({ value }) =>
          value.map((domain) => (
            <a
              key={domain}
              style={{ display: "block" }}
              href={`https://${domain}`}
              target="_blank"
            >
              {domain}
            </a>
          )),
      },
      {
        Header: "EDIR domain",
        accessor: "frontendEdirDomain",
        Cell: ({ value }) => (
          <a href={`https://${value}`} target="_blank">
            {value}
          </a>
        ),
      },
      {
        Header: "Tag",
        accessor: "tag",
      },

      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            onDelBtnClick={() => setRowToDelete(row.original)}
            editHref={generatePath(ROUTES.edirSzcEdit, {
              id: row.original.naturalId,
            })}
          />
        ),
      },
    ],
    []
  );

  if (!szcs) return <Spinner />;

  return (
    <>
      <DeleteConfirmation
        isOpen={!!rowToDelete}
        recordName={rowToDelete?.name}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      <h2>Összes EDIR SZC</h2>
      <TableAdvanced columns={columns} data={szcs} idField="naturalId" />
    </>
  );
};

export default SZCAll;
