import { Classes, Divider, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/routes";

export const MenuExamCenter = () => {
  return (
    <Menu className={Classes.ELEVATION_2}>
      <MenuDivider title="Vizsga jelentkezés" />

      <MenuItem
        icon="people"
        text="Jelentkezők"
        tagName={Link}
        to={ROUTES.examcenterCandidates}
      />
      <MenuItem text="Vizsgák" icon="applications">
        <MenuItem icon="plus" text="Új vizsga" tagName={Link} to={ROUTES.examCreate} />
        <MenuItem icon="edit" text="Összes vizsga" tagName={Link} to={ROUTES.examsAll} />
      </MenuItem>

      <MenuItem text="Partnerek" icon="office">
        <MenuItem
          icon="plus"
          text="Új partner"
          tagName={Link}
          to={ROUTES.examPartnerCreate}
        />
        <MenuItem
          icon="edit"
          text="Összes partner"
          tagName={Link}
          to={ROUTES.examPartners}
        />
      </MenuItem>
    </Menu>
  );
};
