import { Button, Callout, Colors, Icon, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
  changeCategoryPosition,
  changeItemPosition,
  deleteCategory,
  deleteItem,
  getCategory,
  getItems,
} from "../../../redux/actions/ikk/publicInfo";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import PublicInfoEditCategoryDialog from "./PublicInfoEditCategoryDialog";
import PublicInfoEditDataDialog from "./PublicInfoEditDataDialog";

const sortableStyle = (data) => ({
  userSelect: "none",
  cursor: "move",
  color: data.deep_link ? Colors.ORANGE3 : !data.visible ? Colors.RED3 : "",
});

const PublicInfoAll = (props) => {
  const [editItem, setEditItem] = useState(); // item passed to edit popup, set when edit clicked
  const [editCategory, setEditCategory] = useState(); // category passed to edit popup, set when edit clicked
  const [deleteData, setDeleteData] = useState(); // item or category, set when delete button clicked

  let sortingId = null; // the currently grabbed item/category id, storing it in state would cause rerenders that break drag&drop functionality
  const pressDelay = 500; // ms, allow button to be clicked before node becomes draggable

  useEffect(() => {
    props.getItems();
    props.getCategory();
  }, []);

  const handleEdit = (data, type) => {
    if (type === "category") {
      setEditCategory(data);
    } else if (type === "item") {
      setEditItem(data);
    }
  };

  const handleDelete = async () => {
    // if data has category_id treat it as an item
    if (deleteData.category_id) {
      await props.deleteItem(deleteData.id);
      props.getItems();
    } else {
      await props.deleteCategory(deleteData.id);
      props.getCategory();
    }
    setDeleteData(null);
  };

  const onSortStart = (data) => {
    const val = Number(data.node.getAttribute("value"));
    console.log("onSortStart", val);
    sortingId = val;
  };

  const onSortEndItems = async ({ oldIndex, newIndex }) => {
    console.log("onSortEndItems", oldIndex, newIndex, sortingId);
    await props.changeItemPosition(sortingId, newIndex);
    props.getItems();
  };

  const onSortEndCategories = async ({ oldIndex, newIndex }) => {
    console.log("onSortEndCategories", oldIndex, newIndex, sortingId);
    await props.changeCategoryPosition(sortingId, newIndex);
    props.getCategory();
  };

  // receives a single item from SortableListOfItems
  const SortableItem = SortableElement(({ item }) => (
    <li value={item.id} style={sortableStyle(item)}>
      <span style={{ display: "flex" }}>
        {`[ID: ${item.id}] [O: ${item.order}] ${item.name}`}
        <Button minimal small icon="edit" onClick={() => handleEdit(item, "item")} />
        <Button minimal small icon="trash" onClick={() => setDeleteData(item)} />
      </span>
    </li>
  ));

  // receives a list of items in a category from SortableCategory
  const SortableListOfItems = SortableContainer(({ items }) => {
    return (
      <ul>
        {items.map((item, i) => (
          <SortableItem key={i} index={i} item={item} />
        ))}
      </ul>
    );
  });

  // receives one category and its corresponding items from SortableListOfCategories
  const SortableCategory = SortableElement(({ category, items }) => (
    <li value={category.id}>
      <b style={sortableStyle(category)}>
        {`[ID: ${category.id}] [O: ${category.order}] ${category.name}`}
      </b>
      <Button minimal small icon="cog" onClick={() => handleEdit(category, "category")} />
      <Button minimal small icon="trash" onClick={() => setDeleteData(category)} />
      <SortableListOfItems
        pressDelay={pressDelay}
        lockAxis="y"
        items={items}
        onSortStart={onSortStart}
        onSortEnd={onSortEndItems}
      />
    </li>
  ));

  // receives all categories and items from PublicInfoAll props (this component)
  const SortableListOfCategories = SortableContainer(({ categories }) => {
    return (
      <ul>
        {categories.map((cat, i) => (
          <SortableCategory
            key={i}
            index={i}
            category={cat}
            items={props.items.filter((it) => it.category_id === cat.id)}
          />
        ))}
      </ul>
    );
  });

  if (props.loading || props.categories.length === 0 || props.items.length === 0)
    return <Spinner intent="primary" />;

  return (
    <>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="book" />
        &nbsp;&nbsp;<span>Összes közérdekű adat</span>
      </h2>

      <Callout intent="primary">
        Az adatok egy kategórián belül és a kategóriák sorrendje drag & drop módszerrel
        módosítható, {pressDelay / 1000} másodperc nyomva tartás után. &nbsp;
        <br /> Jelmagyarázat:&nbsp;
        <b style={{ color: Colors.RED3 }}>Piros: </b>Nem látható &nbsp;
        <b style={{ color: Colors.ORANGE3 }}>Narancs:</b> Deep link
      </Callout>

      <SortableListOfCategories
        pressDelay={pressDelay}
        lockAxis="y"
        categories={props.categories}
        items={props.items}
        onSortStart={onSortStart}
        onSortEnd={onSortEndCategories}
      />

      <PublicInfoEditCategoryDialog
        category={editCategory}
        setCategory={setEditCategory}
      />

      <PublicInfoEditDataDialog item={editItem} setItem={setEditItem} />

      <DeleteConfirmation
        isOpen={!!deleteData}
        recordName={deleteData?.name}
        onDelete={handleDelete}
        onCancel={() => setDeleteData(null)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { loading, items, categories } = state.publicInfo;
  const publicDataCategories = categories.filter((c) => c.type === "PUBLIC_DATA");
  return { loading, items, categories: publicDataCategories };
};

export default connect(mapStateToProps, {
  getItems,
  getCategory,
  changeItemPosition,
  deleteCategory,
  deleteItem,
  changeCategoryPosition,
})(PublicInfoAll);
