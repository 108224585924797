import {
  Button,
  Card,
  Collapse,
  FileInput,
  FormGroup,
  HTMLSelect,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  importConnectionsFromExcel,
  importInstitutesFromExcel,
} from "../../redux/actions/szakkepzes/import";

const initialState = {
  // full sync upload
  excel_sync_file: null,
  // partial upload
  excel_file: null,
  selected_type: "",
  selected_import: "",
};

const ExcelImport = (props) => {
  console.log("props:", props);
  const [hint, setHint] = useState(false);
  const [state, setState] = useState(initialState);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleFileInput = (e) => {
    const { name, files } = e.target;
    console.log("fileinput target:", name, files[0]);
    setState((s) => ({ ...s, [name]: files[0] }));
  };

  const handelFullSyncUpload = async (e) => {
    e.preventDefault();
    const file = state.excel_sync_file;
    if (!file) return;

    await props.importInstitutesFromExcel(file, "overwrite");
    await props.importConnectionsFromExcel(file, "overwrite");
    setState(initialState);
  };

  const handelUpload = async (e) => {
    e.preventDefault();

    const file = state.excel_file;
    const type = state.selected_type;

    switch (state.selected_import) {
      case "institute":
        props.importInstitutesFromExcel(file, type);
        break;
      case "connection":
        props.importConnectionsFromExcel(file, type);
        break;
      case "both":
        await props.importInstitutesFromExcel(file, type);
        await props.importConnectionsFromExcel(file, type);
        break;
      default:
        console.log("invalid selected_import");
        break;
    }
    setState(initialState);
  };

  return (
    <div style={{ maxWidth: "600px" }}>
      <h2>Excel fájl importálás</h2>

      <Button text="Segítség" icon="help" onClick={() => setHint((h) => !h)} />
      <Collapse isOpen={hint}>
        <b>Feltöltés előtt ellenőrizendő:</b>
        <ul>
          <li>Első Munkalapon az Intézmények találhatóak</li>
          <li>Második Munkalapon a Szakma - Intézmény hozzárendelés</li>
          <li>
            Intézmények Fejléce:
            <ul>
              <li>ID (egész szám)</li>
              <li>Intézmény neve (szöveg)</li>
              <li>Irányító szám (szöveg/szám)</li>
              <li>Település (szöveg)</li>
              <li>Utca, házszám (szöveg)</li>
              <li>Vonalas Telefon (szöveg)</li>
              <li>Honlap (szöveg - URL)</li>
              <li>ÁLLAMI/NEM ÁLLAMI (ÁLLAMI / NEM ÁLLAMI)</li>
            </ul>
          </li>
          <li>
            Szakma - intézmény fejléc:
            <ul>
              <li>ID (szám)</li>
              <li>Intézmény neve (szöveg)</li>
              <li>OKJ SZÁM (Szöveg/szám)</li>
              <li>Szakképesítés megnevezése (Szöveg)</li>
            </ul>
          </li>
        </ul>
      </Collapse>

      <br />

      <Card>
        <form onSubmit={handelFullSyncUpload}>
          <h3 style={{ marginTop: 0 }}>Teljes újratöltés</h3>

          <FormGroup label="Szakma-intézmény kapcsolat és intézmények">
            <FileInput
              onInputChange={handleFileInput}
              inputProps={{ name: "excel_sync_file", required: true }}
              text={state.excel_sync_file?.name || "Fájl választása"}
              hasSelection={!!state.excel_sync_file}
              buttonText="Tallóz"
            />
          </FormGroup>
          <Button
            type="submit"
            text="Feltöltés"
            icon="upload"
            intent="primary"
            loading={
              (props.instLoading || props.connLoading) && state.excel_sync_file
            }
          />
        </form>
      </Card>
      <br />

      <Card>
        <form onSubmit={handelUpload}>
          <h3 style={{ marginTop: 0 }}>Részleges újratöltés</h3>

          <FormGroup label="Feltöltendő adatok">
            <HTMLSelect
              required
              onChange={handleInput}
              name="selected_import"
              value={state.selected_import}
            >
              <option value="" disabled>
                Válasszon...
              </option>
              <option value="institute">Intézmények</option>
              <option value="connection">{"Szakma ⟷ Intézmény"}</option>
              <option value="both">Minden</option>
            </HTMLSelect>
          </FormGroup>

          <RadioGroup
            inline
            label="Művelet"
            name="selected_type"
            onChange={handleInput}
            selectedValue={state.selected_type}
          >
            <Radio required label="Töröl" value="delete" />
            <Radio label="Hozzáad" value="append" />
            <Radio label="Módosít" value="modify" />
          </RadioGroup>
          <br />

          <FormGroup>
            <FileInput
              onInputChange={handleFileInput}
              hasSelection={!!state.excel_file}
              inputProps={{ name: "excel_file", required: true }}
              text={state.excel_file?.name || "Fájl választása"}
              buttonText="Tallóz"
            />
          </FormGroup>

          <Button
            type="submit"
            text="Feltöltés"
            icon="upload"
            intent="primary"
            loading={
              (props.instLoading || props.connLoading) && state.excel_file
            }
          />
        </form>
      </Card>
      <br />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { importInstitutes, importConnections } = state;
  return {
    instLoading: importInstitutes.loading,
    connLoading: importConnections.loading,
  };
};

export default connect(mapStateToProps, {
  importInstitutesFromExcel,
  importConnectionsFromExcel,
})(ExcelImport);
