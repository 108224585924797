import { AnchorButton, Spinner } from "@blueprintjs/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { generatePath } from "react-router-dom";
import { showToast } from "../../../utils/helpers";
import { authFetch } from "../../../utils/http";
import { API_BASE_SCHOLARSHIP, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import { EnvAlertWrapper } from "../../common/EnvAlertWrapper";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

export const ScholarshipApplicantsAll = () => {
  const [rowDelete, setRowDelete] = useState();
  const [applicants, setApplicants] = useState();

  useEffect(() => {
    getApplicants();
  }, []);

  const getApplicants = () => {
    authFetch(`${API_BASE_SCHOLARSHIP}/applicants?size=1000`)
      .then((data) => setApplicants(data))
      .catch((err) => {
        console.error(err);
        showToast({
          intent: "danger",
          message: "Hiba történt az adatok lekérése közben!",
        });
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Név",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Státusz",
        accessor: "status",
      },
      {
        Header: "Feltöltött fájl",
        accessor: "fileUrl",
        Cell: ({ value }) => (
          <AnchorButton
            href={`${API_BASE_SCHOLARSHIP.replace(`/api`, "")}${value}`}
            target="_blank"
            small
            icon="document-open"
            text="Megnyit"
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.scholarshipApplicantEdit, {
              id: row.original.id,
            })}
          />
        ),
      },
    ],
    []
  );

  if (!applicants) return <Spinner />;

  return (
    <>
      <h1>Összes ösztöndíjra jelentkező</h1>

      <TableAdvanced columns={columns} data={applicants.content} />
    </>
  );
};
