import { InputGroup, Spinner } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { connect } from "react-redux";
import { getAllImage } from "../../redux/actions/common/media";
import FileUpload from "../common/FileUpload";

const MediaImages = (props) => {
  useEffect(() => {
    if (props.images.length === 0) props.getAllImage();
  }, []);

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2>Összes feltöltött kép</h2>
      <div
        style={{
          display: "flex",
          margin: "20px 0",
          justifyContent: "space-between",
        }}
      >
        <FileUpload />

        <InputGroup type="search" leftIcon="search" placeholder="Keresés..." />
      </div>

      <div
        style={{
          display: "grid",
          gridGap: "1rem",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
        }}
      >
        {props.images.map((m, i) => (
          <LazyLoadImage
            key={m.id}
            src={m.uri.find((u) => u.size === "sm")?.uri}
            effect="blur"
            style={{
              objectFit: "cover",
              maxHeight: "140px",
              width: "100%",
            }}
          />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { images, loading } = state.media;
  return { images, loading };
};

export default connect(mapStateToProps, { getAllImage })(MediaImages);
