import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneNews, updateNews } from "../../../redux/actions/skillscom/news";
import { ROUTES } from "../../../utils/routes";
import NewsForm from "./NewsForm";

const NewsEdit = (props) => {
  const { id } = useParams();
  //const [article, setArticle] = useState();

  console.log("edit props:", props);

  useEffect(() => {
    props.getOneNews(id);
    //delete art.cover;
    //console.log("article:", art);
    //setArticle(art);
  }, []);

  const handleEditSubmit = async (state) => {
    const realTags = [];
    if (state.hasOwnProperty("tags")) {
      for (const tag of state.tags) {
        if (Number.isInteger(tag)) {
          realTags.push(tag);
        } else if (tag?.hasOwnProperty("id")) {
          realTags.push(tag.id);
        }
      }
    }
    const res = await props.updateNews(props.article.id, {
      ...state,
      tags: realTags,
    });
    if (res) props.history.push(ROUTES.skillsComNews);
  };

  if (props.loading) return <Spinner intent="primary" />;

  if (!props.article) return null;

  return (
    <NewsForm
      initialState={props.article}
      submitBtnText="Módosít"
      title="Hír szerkesztése"
      onSubmit={handleEditSubmit}
      imageRequired={false}
    />
  );
};

const mapStateToProps = (state) => {
  const { loading, article } = state.skillsComNews;
  return { loading, article };
};
export default connect(mapStateToProps, { getOneNews, updateNews })(NewsEdit);
