import {
  AnchorButton,
  Button,
  Card,
  Checkbox,
  Divider,
  FormGroup,
  H4,
  InputGroup,
  MenuItem,
  Position,
} from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deleteDocument } from "../../../redux/actions/szakkepesites/pk";
import { API_BASE_IKK, API_BASE_SZAKKEPESITES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import FileUpload from "../../common/FileUpload";
import kkkstyle from "../../szakkepzes/KKK/KKK.module.scss";

const QualifForm = (props) => {
  const [state, setState] = useState(props.initialState);
  //const [docDelete, setDocDelete] = useState();
  const [specializations, setSpecializations] = useState([]);

  useEffect(() => {
    //   if (props.categories.length === 0) props.getAllFaqCategory();
    getKEOR();
  }, []);

  const getKEOR = async () => {
    const res = await fetch(`${API_BASE_SZAKKEPESITES}/keor`);
    const data = await res.json();
    const allSpec = data
      .flatMap((k) => k.directions)
      .flatMap((d) => d.specializations)
      .sort((a, b) => a.name.localeCompare(b.name, "hu"));
    setSpecializations(allSpec);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  const handleInput = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setState((s) => ({ ...s, [name]: val }));
  };

  // const handleDeleteDocument = async () => {
  //   const res = await props.deleteDocument(state.id, docDelete.id);
  //   setDocDelete(null);
  // };

  const handleDocumentChange = (index) => (e) => {
    const { name, value } = e.target;
    setState((s) => {
      const arr = [...s.documents];
      const doc = { ...arr[index], [name]: value };
      arr.splice(index, 1, doc);

      return {
        ...s,
        documents: arr,
      };
    });
  };

  const handleMediaUpload = (media) => {
    //TODO
    console.log("handleMediaUpload:", media);
  };

  // for Suggest input
  const SpecializationItemRenderer = (item, { handleClick, modifiers, query }) => (
    <MenuItem
      key={item.id}
      text={item.name}
      onClick={handleClick}
      active={modifiers.active}
    />
  );

  const filterSpecializations = (query, qualif, _index, exactMatch) => {
    const normalizedName = qualif.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedName === normalizedQuery;
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0;
    }
  };

  console.log("qualifform state:", state);

  return (
    <>
      {/* <DeleteConfirmation
        isOpen={docDelete?.name}
        onCancel={() => setDocDelete(null)}
        onDelete={handleDeleteDocument}
        recordName={`${state.name} dokumentum: ${docDelete?.name}`}
      /> */}

      <div style={{ maxWidth: "800px", marginBottom: 100 }}>
        <h2>{props.title}</h2>
        <form onSubmit={handleSubmit}>
          <FormGroup label="Szakképesítés neve">
            <InputGroup
              required
              onChange={handleInput}
              name="name"
              value={state.name}
              placeholder="pl. Artista I. (Bohóc)"
            />
          </FormGroup>

          <FormGroup label="Specializáció">
            <Suggest
              items={specializations}
              itemRenderer={SpecializationItemRenderer}
              inputValueRenderer={(item) => item.name}
              selectedItem={specializations.find(
                (s) => s.id === state.KEORSpecializationId
              )}
              noResults={<MenuItem disabled={true} text="Nincs találat" />}
              onItemSelect={(item, e) =>
                setState((s) => ({ ...s, KEORSpecializationId: item.id }))
              }
              popoverProps={{
                popoverClassName: kkkstyle.qualifselectPopover,
                position: Position.BOTTOM_LEFT,
              }}
              itemPredicate={filterSpecializations}
              inputProps={{ required: true, placeholder: "Válasszon..." }}
            />
          </FormGroup>

          <FormGroup label="KEOR teljes kód (keor + mkkr + sorszám)">
            <InputGroup
              required
              onChange={handleInput}
              name="code"
              value={state.code}
              placeholder="pl. 02155008"
            />
          </FormGroup>

          <FormGroup label="Sorszám">
            <InputGroup
              required
              onChange={handleInput}
              name="num"
              value={state.num}
              placeholder="pl. 008"
            />
          </FormGroup>

          <FormGroup label="MKKR">
            <InputGroup
              required
              onChange={handleInput}
              name="mkkr"
              value={state.mkkr}
              placeholder="pl. 5"
            />
          </FormGroup>

          <FormGroup label="EKKR">
            <InputGroup onChange={handleInput} name="ekkr" value={state.ekkr || ""} />
          </FormGroup>

          <FormGroup label="DKKR">
            <InputGroup onChange={handleInput} name="dkkr" value={state.dkkr || ""} />
          </FormGroup>

          <Checkbox
            label="Látható az oldalon"
            name="visible"
            checked={state.visible}
            onChange={handleInput}
          />
          <br />

          {state.documents && (
            <>
              <H4>Dokumentumok ({state.documents.length})</H4>
              {state.documents?.map((doc, i) => (
                <Card key={i} style={{ marginBottom: 20 }}>
                  <FormGroup label="Dokumentum neve">
                    <InputGroup
                      required
                      name="name"
                      value={doc.name}
                      onChange={handleDocumentChange(i)}
                    />
                  </FormGroup>
                  <FormGroup label="Fajl">
                    <FileUpload
                      isRequired
                      onUpload={handleMediaUpload}
                      isRequired={false}
                    />
                    <p>
                      <a
                        target="_blank"
                        href={`${API_BASE_IKK}/media/${doc.mediaId}`}
                      >{`${API_BASE_IKK}/media/${doc.mediaId}`}</a>
                    </p>
                  </FormGroup>
                  <FormGroup
                    label="Archivalva ekkor"
                    labelInfo="(Csak ha archivalni szeretnenk!)"
                  >
                    <InputGroup
                      type="date"
                      name="archiveDate"
                      value={doc.archiveDate || ""}
                      onChange={handleDocumentChange(i)}
                    />
                  </FormGroup>
                </Card>
              ))}
            </>
          )}

          <br />
          <Button
            type="submit"
            text={props.submitBtnText}
            icon="floppy-disk"
            intent="success"
          />
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.pk };
};
export default connect(mapStateToProps, { deleteDocument })(QualifForm);
