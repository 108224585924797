import { FormGroup, InputGroup, NumericInput } from "@blueprintjs/core";
import FileUpload from "../../../common/FileUpload";
import { API_BASE_IKK } from "../../../../utils/routes";
import { Grid } from "../../../common/LayoutHelpers";

export const imageDefaults = {
  caption: "",
  width: null,
  height: null,
  mediaId: null,
};

export const ImageFields = ({ data, onDataChange }) => {
  return (
    <>
      <FormGroup label="Cím vagy leírás">
        <InputGroup
          value={data.caption}
          onChange={(e) => onDataChange("caption", e.target.value)}
        />
      </FormGroup>
      <Grid columns={2}>
        <FormGroup label="Kép szélessége" labelInfo="(px)">
          <NumericInput
            fill
            min={0}
            leftIcon="arrows-horizontal"
            value={data.width}
            onValueChange={(val) => onDataChange("width", val)}
          />
        </FormGroup>
        <FormGroup label="Kép magassága" labelInfo="(px)">
          <NumericInput
            min={0}
            leftIcon="arrows-vertical"
            fill
            value={data.height}
            onValueChange={(val) => onDataChange("height", val)}
          />
        </FormGroup>
      </Grid>
      <FormGroup label="Kép">
        <FileUpload onUpload={(media) => onDataChange("mediaId", media.id)} />
      </FormGroup>
      <FormGroup>
        {data?.mediaId ? (
          <img src={`${API_BASE_IKK}/media/${data.mediaId}`} width="200" alt="" />
        ) : (
          <p>Nincs kép feltöltve</p>
        )}
      </FormGroup>
    </>
  );
};
