import { Callout, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getNotifications,
  deleteNotifications,
} from "../../redux/actions/szakkepzes/notifications";
import { TableAdvanced } from "../common/TableAdvanced";
import { Button } from "@blueprintjs/core";
import { DeleteConfirmation } from "../common/DeleteConfirmation";

const Notifications = (props) => {
  useEffect(() => {
    const getNotifications = async () => {
      props.getNotifications();
    };
    if (props.notifications.length === 0) getNotifications();
  }, []);
  const [deleteData, setDeleteData] = useState();

  const handleDelete = () => {
    setDeleteData(null);
    props.deleteNotifications().then(props.getNotifications());
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Intézmény neve",
        accessor: (row) => row.training_center,
        Cell: ({ value }) => value.name,
      },
      {
        Header: "URL",
        accessor: (row) => row.training_center,
        Cell: ({ value }) => (
          <a target="_blank" href={value.website}>
            {value.website}
          </a>
        ),
      },
      {
        Header: "Message",
        Cell: ({ row }) => (
          <Callout intent={row.original.severity > 3 ? "danger" : "warning"}>
            {row.original.message}
          </Callout>
        ),
      },
      {
        Header: "Dátum",
        accessor: "createdAt",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2>Intézmények weblapjainak vizsgálatának értesítései</h2>
      <Button
        autoFocus
        intent="danger"
        text="Törlés"
        rightIcon="arrow-right"
        onClick={() => setDeleteData(true)}
      />
      <DeleteConfirmation
        isOpen={!!deleteData}
        recordName={"Minden értesítés"}
        onDelete={handleDelete}
        onCancel={() => setDeleteData(null)}
      />
      <TableAdvanced data={props.notifications} columns={columns} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { notifications } = state;
  return { ...notifications };
};
export default connect(mapStateToProps, { getNotifications, deleteNotifications })(
  Notifications
);
