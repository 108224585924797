import {
  SZAKKEPZES_NOTIFICATIONS_GET_BEGIN,
  SZAKKEPZES_NOTIFICATIONS_GET_FAILURE,
  SZAKKEPZES_NOTIFICATIONS_GET_SUCCESS,
} from "../../actionTypes";

const initialState = {
  notifications: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SZAKKEPZES_NOTIFICATIONS_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SZAKKEPZES_NOTIFICATIONS_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        notifications: action.payload,
      };
    }
    case SZAKKEPZES_NOTIFICATIONS_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        notifications: [],
      };
    }
    default:
      return state;
  }
}
