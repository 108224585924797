import { Spinner, Icon } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import { API_BASE_IKK } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import { deleteItem, getOneCategory } from "../../../redux/actions/ikk/menu";
import { useParams } from "react-router-dom";
import truncate from "html-truncate";

const MenuItemAll = (props) => {
  const { id } = useParams();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [category, setCategory] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const data = await props.getOneCategory(id);
    setCategory(data);
  };

  const handleDeleteDialog = (row) => {
    setItemToDelete(row);
  };

  const handleDelete = async () => {
    props.deleteItem(itemToDelete.id);
    setItemToDelete(null);
    getData();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Cím",
        accessor: "name",
      },
      {
        Header: "Leírás",
        accessor: "body",
        Cell: ({ value }) => (
          <div
            dangerouslySetInnerHTML={{ __html: truncate(value, 120) }}
          />
        ),
      },
      {
        Header: "Link",
        accessor: "link",
        Cell: ({ value }) => (
          <a href={value} target="_blank">
            {value} <Icon icon="share" />
          </a>
        ),
      },
      {
        Header: "Külső link",
        accessor: "isExternal",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Sorrend",
        accessor: "order",
      },
      {
        Header: "Kép",
        accessor: "cover_id",
        Cell: ({ value }) => (
          <img
            width="100"
            src={`${API_BASE_IKK}/media/${value}/xs`}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.menuItemEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes menü item ({category?.name})</h2>

      <DeleteConfirmation
        isOpen={itemToDelete !== null}
        recordName={itemToDelete?.name}
        onDelete={handleDelete}
        onCancel={() => setItemToDelete(null)}
      />

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={category?.items ?? []} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { category, loading } = state.menu;
  return { category, loading };
};

export default connect(mapStateToProps, { deleteItem, getOneCategory })(MenuItemAll);
