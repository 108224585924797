import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  Spinner,
  InputGroup,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAllSpecialisations6110,
  updateSpecialisationDocument6110,
} from "../../../redux/6110/rk";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import FileUpload from "../../common/FileUpload";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import { API_BASE_IKK, ROUTES } from "../../../utils/routes";
import http from "../../../utils/http";
import {
  API_BASE_SZAKKEPESITES_6110,
  API_ENDPOINT,
} from "../../../utils/routes";

const initialstate = {
  archiveDate: "",
  publishDate: "",
  fileName: "",
};

const AllSpecialisations6110 = (props) => {
  const [editRK, seteditRK] = useState(initialstate);
  const [deleteRK, setdeleteRK] = useState(initialstate);

  useEffect(() => {
    props.getAllSpecialisations6110();
  }, []);

  // opens edit dialog
  const handleEdit = (row, attachment) => {
    seteditRK((rk) => ({
      ...rk,
      attachment_id: attachment.id,
      archiveDate: attachment.archiveDate,
      publishDate: attachment.publishDate,
      fileName: attachment.fileName,
      version: attachment.version,
    }));
  };

  // opens delete dialog
  const handleDelete = (row, attachment) => {
    setdeleteRK((rk) => ({
      ...rk,
      ...attachment,
      id: attachment.id,
    }));
  };

  const handleDialogClose = () => {
    seteditRK(initialstate);
  };

  const handleUpload = (media) => {
    seteditRK((kkk) => ({ ...kkk, media_id: media.id }));
  };

  const handleEditSubmit = async (e) => {
    //todo
    e.preventDefault();
    const res = await props.updateSpecialisationDocument6110(
      editRK.attachment_id,
      {
        fileName: editRK.fileName,
        archiveDate: editRK.archiveDate,
        publishDate: editRK.publishDate,
        version: editRK.version,
      }
    );
    seteditRK(initialstate);
    res && props.getAllSpecialisations6110();
  };

  const handleDeleteSubmit = async (e) => {
    //todo
    e.preventDefault();
    const res = await http.del(
      `${API_BASE_SZAKKEPESITES_6110}/v2/cms/specialization/document/${deleteRK.id}`
    );
    setdeleteRK(initialstate);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    seteditRK((rk) => ({ ...rk, [name]: value }));
  };

  console.log(deleteRK);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "KEOR kód",
        accessor: "code",
      },
      {
        Header: "Szakirány",
        accessor: "name",
      },
      {
        Header: "Fájlok",
        accessor: "documents",
        Cell: ({ row, value }) =>
          value.map((attachment, i) => (
            <div key={i}>
              <AnchorButton
                icon="share"
                small
                minimal
                text={`${attachment.publishDate} - ${attachment.archiveDate} v${attachment.version}`}
                href={`${API_BASE_IKK}/media/${attachment.mediaId}`}
                target="_blank"
              />
              <TableActionButtons
                row={row}
                onEditBtnClick={() => handleEdit(row.original, attachment)}
                onDelBtnClick={() => handleDelete(row.original, attachment)}
              />
            </div>
          )),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2>Szakirány dokumentumok</h2>

      <TableAdvanced
        data={props.specialization.filter((s) => s.documents.length > 0)}
        columns={columns}
      />

      <DeleteConfirmation
        isOpen={deleteRK.id}
        onCancel={() => setdeleteRK(initialstate)}
        onDelete={handleDeleteSubmit}
        recordName={`${deleteRK.fileName} - ${deleteRK.version}`}
      />

      <Dialog
        title="Dokumentum szerkesztése"
        isOpen={editRK.attachment_id}
        onClose={handleDialogClose}
        usePortal={false}
      >
        <form onSubmit={handleEditSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Fájl címe" labelInfo="(kötelező)">
              <InputGroup
                isRequired
                name="fileName"
                placeholder="Fájl címe"
                value={editRK.fileName}
                onChange={handleInput}
              />
            </FormGroup>

            <FormGroup label="Verzió" labelInfo="(kötelező)">
              <InputGroup
                type="text"
                name="version"
                value={editRK.version}
                onChange={handleInput}
              />
            </FormGroup>
            <FormGroup label="Publikálva ekkor" labelInfo="(kötelező)">
              <InputGroup
                type="date"
                name="publishDate"
                value={editRK.publishDate}
                onChange={handleInput}
              />
            </FormGroup>
            <FormGroup
              label="Archiválva ekkor"
              labelInfo="(Csak ha archiválni szeretnénk!)"
            >
              <InputGroup
                type="date"
                name="archiveDate"
                value={editRK.archiveDate}
                onChange={handleInput}
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="Mégsem" onClick={handleDialogClose} />
              <Button
                intent="success"
                type="submit"
                text="Mentés"
                icon="floppy-disk"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.project6110.rk };
};
export default connect(mapStateToProps, {
  getAllSpecialisations6110,
  updateSpecialisationDocument6110,
})(AllSpecialisations6110);
