import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { showToast } from "../../../utils/helpers";
import http from "../../../utils/http";
import { API_BASE_SKILLSCOM, ROUTES } from "../../../utils/routes";
import JobsForm from "./JobsForm";

const JobsEdit = (props) => {
  const [job, setJob] = useState();
  const { id } = useParams();

  useEffect(() => {
    getJob();
  }, [id]);

  const getJob = async () => {
    try {
      const data = await http.get(`${API_BASE_SKILLSCOM}/jobs/${id}`);
      if (data.id) setJob(data);
    } catch (error) {
      console.error(error);
      showToast({
        intent: "danger",
        message: "Hiba történt az álláshirdetés betöltésekor",
      });
    }
  };

  const handleEditSubmit = async (state) => {
    try {
      const data = await http.put(`${API_BASE_SKILLSCOM}/jobs/${state.id}`, state);
      if (data) props.history.push(ROUTES.skillsComJobs);
    } catch (error) {
      console.error(error);
      showToast({
        intent: "danger",
        message: "Hiba történt az álláshirdetés mentésekor!",
      });
    }
  };

  if (!job) return <Spinner />;

  return (
    <JobsForm
      initialState={job}
      submitBtnText="Módosít"
      title="Álláshirdetés szerkesztése"
      onSubmit={handleEditSubmit}
      imageRequired={false}
    />
  );
};

export default JobsEdit;
