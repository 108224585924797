import React from "react";
import { connect } from "react-redux";
import {
  createQualificationDocument,
  getAllQualification,
} from "../../../redux/actions/szakkepesites/pk";
import { ROUTES } from "../../../utils/routes";
import QualifForm from "./QualifForm";
import DocumentForm from "./DocumentForm";
import { useEffect } from "react";

// initial empty state for create form
const emptyState = {
  qualificationId: null,
  name: "",
  mediaId: null,
};

const DocumentCreate = (props) => {
  useEffect(() => {
    props.getAllQualification();
  }, []);

  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const data = await props.createQualificationDocument(state.qualificationId, state);
    // reset form if submit was successful
    if (data) {
      setState(emptyState);
      props.getAllQualification();
      props.history.push(ROUTES.szakkepesitesQualif);
    }
  };

  return (
    <DocumentForm
      title="Programkövetelmény (PK) felvitele"
      initialState={emptyState}
      qualifications={props.qualifications}
      submitBtnText="Létrehoz"
      handleSubmit={handleCreateSubmit}
    />
  );
};

const mapStateToProps = (state) => {
  return { ...state.pk };
};

export default connect(mapStateToProps, {
  createQualificationDocument,
  getAllQualification,
})(DocumentCreate);
