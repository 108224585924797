import { Button, MenuItem } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllTags } from "../../redux/actions/ikk/tags";

const translater = {
  sector: "Ágazat",
  profession: "Szakma",
};
const TagMultiSelect = ({
  getAllTags, // redux action (api request)
  tags, // redux state
  initialTags, // pre-selected tags for edit form
  //onSelectCallback, // (tag) => {}
  //onRemoveCallback, // (tag) => {}
  onChange, // (selectedTags) => {}
}) => {
  const [selectedTags, setSelectedTags] = useState([...initialTags]);

  useEffect(() => {
    getAllTags();
  }, []);

  const handleClear = () => setSelectedTags([]);

  const clearButton =
    selectedTags.length > 0 ? (
      <Button icon="cross" minimal={true} onClick={handleClear} />
    ) : undefined;

  const filterTag = (query, tag, _index, exactMatch) => {
    const normalizedTitle = tag.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${normalizedTitle} `.indexOf(normalizedQuery) >= 0;
    }
  };

  const arrayContainsTag = (tags, tagToFind) => {
    return tags.some((t) => t.id === tagToFind.id);
  };

  const getSelectedTagIndex = (tag) => {
    return selectedTags.indexOf(tag);
  };

  const isTagSelected = (tag) => {
    return getSelectedTagIndex(tag) !== -1;
  };

  const handleTagSelect = (tag) => {
    if (!isTagSelected(tag)) {
      selectTag(tag);
    } else {
      deselectTag(getSelectedTagIndex(tag));
    }
  };

  const handleTagRemove = (_tag, index) => {
    deselectTag(index);
  };

  const renderTag = (tag) => tag.name;

  function areTagsEqual(tagA, tagB) {
    return tagA.id === tagB.id;
  }

  const selectTag = (tag) => {
    selectTags([tag]);
  };

  const selectTags = (tagsToSelect) => {
    let nextTags;
    tagsToSelect.forEach((tag) => {
      nextTags = !arrayContainsTag(selectedTags, tag)
        ? [...selectedTags, tag]
        : selectedTags;
    });
    setSelectedTags(nextTags);
    if (onChange) onChange(nextTags); // notify parent component via the provided callback
  };

  const deselectTag = (index) => {
    const newTags = selectedTags.filter((t, i) => i !== index);
    setSelectedTags(newTags);
    if (onChange) onChange(newTags); // notify parent component via the provided callback
  };

  // NOTE: not using Films.itemRenderer here so we can set icons.
  const renderTagOption = (tag, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        icon={isTagSelected(tag) ? "tick" : "blank"}
        key={tag.id}
        label={translater[tag.morph_to] ?? "Általános"}
        onClick={handleClick}
        text={tag.name}
        shouldDismissPopover={false}
      />
    );
  };

  return (
    <MultiSelect
      itemPredicate={filterTag}
      itemRenderer={renderTagOption}
      items={tags}
      itemsEqual={areTagsEqual}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={handleTagSelect}
      tagRenderer={renderTag}
      tagInputProps={{
        onRemove: handleTagRemove,
        rightElement: clearButton,
        placeholder: "Válasszon...",
      }}
      selectedItems={selectedTags}
      popoverProps={{ minimal: true }}
    />
  );
};

const mapStateToProps = (state) => {
  return { ...state.tags };
};
export default connect(mapStateToProps, {
  getAllTags,
})(TagMultiSelect);
