import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import AboutRowForm from "./AboutRowForm";
import { getAboutRows, updateAboutRow } from "../../../redux/actions/ikk/aboutRow";

const AboutRowEdit = (props) => {
  const [row, setRow] = useState();

  const { id } = useParams();

  useEffect(() => {
    getAboutRow();
  }, []);

  const getAboutRow = async () => {
    const rows = await props.getAboutRows();
    const r = rows.find((r) => r.id === Number(id));
    setRow(r);
  };

  const handleEditSubmit = async (state) => {
    const res = await props.updateAboutRow(row.id, state);
    if (res) props.history.push(ROUTES.aboutRows);
  };

  return (
    <>
      {row && (
        <AboutRowForm
          initialState={row}
          submitBtnText="Módosít"
          title="Rolunk szoveg szerkesztése"
          handleSubmit={handleEditSubmit}
          imageRequired={false}
        />
      )}
    </>
  );
};

export default connect(null, { getAboutRows, updateAboutRow })(AboutRowEdit);
