import { MenuItem, Position } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { API_BASE_SZAKKEPESITES } from "../../../utils/routes";
//import style from "../szakkepzes/KKK/KKK.module.scss";

/**
 * Lists all KEOR qualifications with autocomplete support.
 * Works in a controlled manner.
 */
export const SuggestKeorQualif = ({
  selectedItem, // id of the selected item
  onSelect, // callback, signature: (item, event) => {}
}) => {
  const [keorData, setKeorData] = useState([]);

  const qualifications = keorData
    ?.flatMap((k) => k.directions)
    .flatMap((d) => d.specializations)
    .flatMap((s) => s.qualifications)
    .filter((q) => q.documents.length > 0);

  useEffect(() => {
    getKeorData();
  }, []);

  const getKeorData = async () => {
    const res = await fetch(`${API_BASE_SZAKKEPESITES}/keor`);
    const data = await res.json();
    setKeorData(data);
  };

  const itemRenderer = (item, { handleClick, modifiers, query }) => (
    <MenuItem
      key={item.id}
      text={item.name}
      onClick={handleClick}
      active={modifiers.active}
    />
  );

  const filterQualifications = (query, qualif, _index, exactMatch) => {
    const normalizedName = qualif.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedName === normalizedQuery;
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0;
    }
  };

  return (
    <Suggest
      selectedItem={
        selectedItem ? qualifications.find((q) => q.id === selectedItem) : undefined
      }
      fill
      items={qualifications}
      itemRenderer={itemRenderer}
      inputValueRenderer={(item) => item.name}
      noResults={<MenuItem disabled={true} text="Nincs találat" />}
      onItemSelect={onSelect}
      popoverProps={{
        //popoverClassName: style.qualifselectPopover,
        popoverClassName: "popover",
        position: Position.BOTTOM_LEFT,
      }}
      itemPredicate={filterQualifications}
      inputProps={{ required: true, placeholder: "Válasszon..." }}
    />
  );
};
