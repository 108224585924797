import React from "react";
import { connect } from "react-redux";
import { createPage } from "../../../redux/actions/ikk/pages";
import { ROUTES } from "../../../utils/routes";
import { generatePath } from "react-router-dom";
import PagesForm from "./PagesForm";

const emptyState = {
  title: "",
  subtitle: "",
  slug: "",
  media_id: null,
  components: [],
  domain: "ikk",
};

const PagesCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state) => {
    const res = await props.createPage(state);
    if (res && res.id) {
      props.history.push(ROUTES.pages);
    }
  };

  return (
    <PagesForm
      title="Új oldal"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
    />
  );
};

export default connect(null, { createPage })(PagesCreate);
