import { Callout } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  deleteFaqCategory,
  getAllFaqCategory,
} from "../../../redux/actions/szakkepzes/faq";
import { API_BASE_IKK, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const FaqCategoryAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState();

  useEffect(() => {
    props.getAllFaqCategory();
  }, []);

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = async () => {
    await props.deleteFaqCategory(rowToDelete.id);
    setRowToDelete(null);
    props.getAllFaqCategory();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Témakör neve",
        accessor: "name",
      },
      {
        Header: "Pozició",
        accessor: "order",
      },
      {
        Header: "Kiemelt",
        accessor: "featured",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Kép",
        Cell: ({ value, row }) => {
          if (row.original.media_id)
            return (
              <img src={`${API_BASE_IKK}/media/${row.original.media_id}`} height="70" />
            );
          return <p>Nincs kép</p>;
        },
      },
      {
        Header: "Művelet",
        Cell: ({ value, row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.faqCategoryEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes témakör</h2>

      <Callout intent="primary">
        Törölni csak olyan témakört lehet amihez nincs kérdés rendelve.
      </Callout>

      <TableAdvanced
        columns={columns}
        data={props.categories.sort((a, b) => a.order - b.order)}
      />

      <DeleteConfirmation
        isOpen={!!rowToDelete}
        recordName={rowToDelete?.name}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.faq };
};
export default connect(mapStateToProps, {
  getAllFaqCategory,
  deleteFaqCategory,
})(FaqCategoryAll);
