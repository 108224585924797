import {
  Button,
  Classes,
  Dialog,
  HTMLSelect,
  Spinner,
  TextArea,
} from "@blueprintjs/core";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getProjects, deleteProject } from "../../../redux/actions/ikk/project";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import truncate from "html-truncate";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import { generatePath } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";

const AllProject = (props) => {
  // const [texts, setTexts] = useState([]); // all static text
  const [rowEditing, setRowEditing] = useState(null); // selected row to edit
  const [rowDelete, setRowDelete] = useState();

  useEffect(() => {
    props.getProjects();
  }, []);

  const handleDelete = (row) => {
    setRowDelete(row);
  };

  const handleDeleteSubmit = async () => {
    await props.deleteProject(rowDelete.id);
    setRowDelete(null);
    props.getProjects();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Sorrend",
        accessor: "order",
      },
      {
        Header: "Cim",
        accessor: "title",
      },
      {
        Header: "Kategoria",
        accessor: "category",
        Cell: ({ value }) => value ?? "-",
      },
      {
        Header: "Szöveg",
        accessor: "body",
        Cell: ({ value }) => (
          <div
            style={{ maxWidth: "700px" }}
            dangerouslySetInnerHTML={{ __html: truncate(value, 300) }}
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.projectsEdit, { id: row.original.id })}
            onDelBtnClick={() => handleDelete(row.original)}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes Projektek</h2>

      <DeleteConfirmation
        isOpen={!!rowDelete}
        onCancel={() => setRowDelete(null)}
        onDelete={handleDeleteSubmit}
        recordName={`Rolunk szoveg blokk: ${rowDelete?.title}`}
      />

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.projects} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.projects };
};

export default connect(mapStateToProps, { getProjects, deleteProject })(AllProject);
