import {
    LOGIN_FAILURE,
    LOGIN_BEGIN,
    LOGIN_SUCCESS
} from "../actionTypes";

const initialState = {
    user:null,
    loading:false,
    error:null,
    logged:false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOGIN_BEGIN: {
            return {
                ...state,
                loading: true,
                error: null,
                logged:false,
            };

        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                user:action.payload,
                logged:true,
            };

        }
        case LOGIN_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                user:null,
                logged:false,
            };

        }
        default:
            return state;
    }
}
