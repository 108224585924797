import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import http from "../../../utils/http";
import { API_BASE_EXAM_CENTER, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const ExamPartnerAll = () => {
  const [rowToDelete, setRowToDelete] = useState();
  const [partners, setPartners] = useState();

  const handleDelete = async () => {
    const json = await http.del(`${API_BASE_EXAM_CENTER}/partners/${rowToDelete.id}`);
    setRowToDelete(null);
    getPartners();
  };

  const getPartners = async () => {
    const res = await fetch(`${API_BASE_EXAM_CENTER}/partners`);
    const data = await res.json();
    if (data.success) setPartners(data.data);
    else alert("Hiba történt!");
  };

  useEffect(() => {
    getPartners();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Név",
        accessor: "name",
      },
      {
        Header: "Székhely",
        accessor: "hq",
      },
      {
        Header: "Adószám",
        accessor: "taxId",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Aktív",
        accessor: "enabled",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            onDelBtnClick={() => setRowToDelete(row.original)}
            editHref={generatePath(ROUTES.examPartnerEdit, {
              id: row.original.id,
            })}
          />
        ),
      },
    ],
    []
  );

  console.log("partners", partners);
  if (!partners) return <Spinner />;

  return (
    <>
      <DeleteConfirmation
        isOpen={!!rowToDelete}
        recordName={rowToDelete?.name}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      <h2>Összes partner</h2>
      <TableAdvanced columns={columns} data={partners} />
    </>
  );
};

export default ExamPartnerAll;
