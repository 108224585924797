import http from "../../../utils/http";
import { API_BASE_SZAKKEPZES, API_ENDPOINT } from "../../../utils/routes";
import {
  SZAKKEPZES_NOTIFICATIONS_GET_BEGIN,
  SZAKKEPZES_NOTIFICATIONS_GET_FAILURE,
  SZAKKEPZES_NOTIFICATIONS_GET_SUCCESS,
} from "../../actionTypes";
import {addToastDanger, addToastSuccess} from "../common/toast";

export const getNotifications = () => async (dispatch) => {
  dispatch(getItemsBegin());
  try {
    const json = await http.get(
      API_BASE_SZAKKEPZES + API_ENDPOINT.SZAKKEPZES_NOTIFICATIONS,
      {
        size: 300,
        sort: "id,desc",
      }
    );
    dispatch(getItemsSuccess(json));
    return json;
  } catch (error) {
    dispatch(getItemsFailure(error));
    dispatch(addToastDanger(`Értesítések betöltése sikertelen: ${error}`));
  }
};
export const deleteNotifications = () => async (dispatch) => {
  try {
    const json = await http.del(
        API_BASE_SZAKKEPZES + API_ENDPOINT.SZAKKEPZES_NOTIFICATIONS
    );
   dispatch(addToastSuccess("Értesítések törölve!"));
    return json;
  } catch (error) {

    dispatch(addToastDanger(`Értesítéseket nem sikerült törölni: ${error}`));
  }
};
export const getItemsBegin = () => ({
  type: SZAKKEPZES_NOTIFICATIONS_GET_BEGIN,
});
export const getItemsSuccess = (items) => ({
  type: SZAKKEPZES_NOTIFICATIONS_GET_SUCCESS,
  payload: items,
});
export const getItemsFailure = (err) => ({
  type: SZAKKEPZES_NOTIFICATIONS_GET_FAILURE,
  payload: { err },
});
