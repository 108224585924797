import React from "react";
import { connect } from "react-redux";
import { uploadMedia } from "../../../redux/actions/common/media";
import { createSlide, updateSlide } from "../../../redux/actions/ikk/carousel";
import http from "../../../utils/http";
import { API_BASE_EXAM_CENTER, ROUTES } from "../../../utils/routes";
import ExamForm from "./ExamForm";

const emptyState = {
  name: "",
  price: 0,
  language: "",
  qualificationId: null,
  description: "",
  enabled: true,
  visible: true,
  partners: [],
  szcs: [], // szakkepesi centrumok
  online: false,
};

const ExamCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    try {
      const json = await http.post(`${API_BASE_EXAM_CENTER}/exams`, state);

      // reset the form if submit was successful
      setState(emptyState);
      props.history.push(ROUTES.examsAll);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ExamForm
      title="Új vizsga"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
      imageRequired={true}
    />
  );
};

export default connect(null, {})(ExamCreate);
