import {
  Button,
  Callout,
  Divider,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { createGroup } from "../../../redux/actions/ikk/groups";
import SuggestQualification from "../../common/SuggestQualification";
import SuggestSector from "../../common/SuggestSector";

const GroupsCreate = (props) => {
  const [group, setGroup] = useState();


  const submitGroup = async () => {
    console.log(group)
    props.createGroup(group);
    setGroup(null); // reset input
  };

  return (
    <>
      <h2>Új Hír csoport</h2>

      <Callout
        icon="info-sign"
        intent="primary"
        style={{ marginBottom: "20px" }}
      >
        Egy hír csoport létrehozásával tudsz egy új csoportot létrehozni, amelyhez híreket tudsz majd rendelni.
      </Callout>

      <FormGroup label="Normal tag" helperText="Uj szabad szoveges cimke">
        <InputGroup
          fill={false}
          onFocus={() => setGroup(null)}
          onChange={(e) => setGroup({ name: e.target.value })}
        />
      </FormGroup>
      <Button intent="success" onClick={submitGroup}>
        Mentes
      </Button>


    </>
  );
};

export default connect(null, {
  createGroup,
})(GroupsCreate);
