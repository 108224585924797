import {
  SKILLSCOM_COMPANY_ADD_BEGIN,
  SKILLSCOM_COMPANY_ADD_FAILURE,
  SKILLSCOM_COMPANY_ADD_SUCCESS,
  SKILLSCOM_COMPANY_DELETE_BEGIN,
  SKILLSCOM_COMPANY_DELETE_SUCCESS,
  SKILLSCOM_COMPANY_GET_BEGIN,
  SKILLSCOM_COMPANY_GET_FAILURE,
  SKILLSCOM_COMPANY_GET_SUCCESS,
  SKILLSCOM_COMPANY_VERIFY_SUCCESS,
} from "../../actionTypes";

const initialState = {
  companies: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SKILLSCOM_COMPANY_ADD_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SKILLSCOM_COMPANY_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        companies: state.companies,
      };
    }
    case SKILLSCOM_COMPANY_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case SKILLSCOM_COMPANY_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SKILLSCOM_COMPANY_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case SKILLSCOM_COMPANY_GET_SUCCESS: {
      let companies = state.companies;

      if (!Array.isArray(companies)) {
        companies = [];
      }
      companies = companies.concat(action.payload);
      companies = companies.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj["id"]).indexOf(obj["id"]) === pos;
      }); // unique

      return {
        ...state,
        loading: false,
        error: null,
        companies: companies,
      };
    }
    case SKILLSCOM_COMPANY_VERIFY_SUCCESS: {
      const idx = state.companies.findIndex((c) => c.id === action.payload);
      const comp = {
        ...state.companies[idx],
        verified: new Date().toISOString(),
      };
      const newComps = [...state.companies];
      newComps.splice(idx, 1, comp);
      return {
        ...state,
        companies: newComps,
      };
    }

    case SKILLSCOM_COMPANY_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SKILLSCOM_COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        companies: state.companies.filter((e) => e.id !== action.payload),
      };
    default:
      return state;
  }
}
