import {
    CAROUSEL_CREATE_BEGIN,
    CAROUSEL_CREATE_FAILURE,
    CAROUSEL_CREATE_SUCCESS,
    CAROUSEL_GET_BEGIN,
    CAROUSEL_GET_FAILURE,
    CAROUSEL_GET_SUCCESS,
    CAROUSEL_UPDATE_BEGIN,
    CAROUSEL_UPDATE_FAILURE,
    CAROUSEL_UPDATE_SUCCESS
} from "../actionTypes";

const initialState = {
    slides:[],
    loading:false,
    error:null,
    updating:false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CAROUSEL_GET_BEGIN: {
            return {
                ...state,
                loading: true,
                error: null
            };

        }
        case CAROUSEL_GET_SUCCESS: {
            return {
                slides:action.payload,
                loading: false,
                error: null
            };

        }
        case CAROUSEL_GET_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        }
        case CAROUSEL_UPDATE_BEGIN: {
            return {
                ...state,
                loading: true,
            };

        }
        case CAROUSEL_UPDATE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };

        }
        case CAROUSEL_UPDATE_FAILURE: {

            return {
                ...state,
                loading: false,
                error: action.payload
            };

        }



        default:
            return state;
    }
}
