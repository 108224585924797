import {
  Button,
  Checkbox,
  NumericInput,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import "moment/locale/hu";
import React, { useState } from "react";
import MyCkEditor from "../../common/MyCkEditor";

const CareerForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setState((s) => ({ ...s, [name]: val }));
  };

  return (
    <div style={{ maxWidth: "800px", marginBottom: 100 }}>
      <h2>{props.title}</h2>

      <form onSubmit={handleSubmit}>
        <FormGroup label="Cím" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="Cím..."
            onChange={handleInput}
            name="title"
            value={state.title}
          />
        </FormGroup>

        <FormGroup label="Szöveg" labelInfo="(kötelező)">
          <MyCkEditor
            data={state.body}
            onDataChange={(data) => setState((s) => ({ ...s, body: data }))}
          />
        </FormGroup>
        <FormGroup label="Pozíció" labelInfo="(kötelező)">
          <NumericInput
            required
            min={0}
            name="order"
            value={state.order}
            onValueChange={(e) =>
              handleInput({
                target: { name: "order", type: "number", value: new Number(e) },
              })
            }
          />
        </FormGroup>
        <br />
        <Button
          text={props.submitBtnText}
          icon="floppy-disk"
          intent="success"
          type="submit"
        />
      </form>
    </div>
  );
};

export default CareerForm;
