import { Icon, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  getAllProfCardQualif,
  deleteProfCardQualifData,
} from "../../../redux/actions/szakkepzes/profCards";
import { ROUTES } from "../../../utils/routes";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";

const ProfessionCardsQualifAll = (props) => {
  const [qualifs, setQualifs] = useState([]);
  const [rowToDelete, setRowToDelete] = useState(); // set when clicking on delete button

  useEffect(() => {
    getQualifs();
  }, []);

  const getQualifs = async () => {
    const data = await props.getAllProfCardQualif();
    setQualifs(data);
  };

  const handleDelete = async () => {
    await props.deleteProfCardQualifData(rowToDelete.id);
    setRowToDelete(null);
    getQualifs();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Szakma neve",
        accessor: "name",
      },
      {
        Header: "Ágazat",
        accessor: "sector.name",
      },
      {
        Header: "Szakmajegyzék száma",
        accessor: "okjId",
      },
      {
        Header: "Tanítják",
        accessor: "institutes",
        Cell: ({ value }) => `${value.length} helyen`,
      },

      {
        Header: "Képzés időtartama",
        accessor: "minTime",
        Cell: ({ value }) => (value ? `${value} év` : "-"),
      },
      {
        Header: "Szakképzés/technum",
        accessor: "asd", // todo
        Cell: ({ value }) => "-",
      },
      {
        Header: "Szakmakártya adatok kitöltve?",
        accessor: (row) => !!row.cardData,
        Cell: ({ value }) =>
          value ? (
            <>
              <Icon icon="tick-circle" intent="success" /> Igen
            </>
          ) : (
            <>
              <Icon icon="error" intent="danger" /> Nem
            </>
          ),
        sortType: (rowA, rowB) =>
          rowA.original.cardData !== null && rowB.original.cardData === null ? -1 : 1,
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            onDelBtnClick={() => setRowToDelete(row.original)}
            editHref={generatePath(ROUTES.szakmakartyakQualificationsEdit, {
              id: row.original.id,
            })}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes szakmakártya szakma </h2>

      <DeleteConfirmation
        isOpen={!!rowToDelete}
        recordName={`${rowToDelete?.name} szakma szakmakartya adatai`}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      {!qualifs.length ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced data={qualifs} columns={columns} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.profCards };
};

export default connect(null, {
  getAllProfCardQualif,
  deleteProfCardQualifData,
})(ProfessionCardsQualifAll);
