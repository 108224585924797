import {
  PAGE_GET_BEGIN,
  PAGE_GET_SUCCESS,
  PAGE_GET_FAILURE,
  PAGE_UPDATE_BEGIN,
  PAGE_UPDATE_SUCCESS,
  PAGE_UPDATE_FAILURE,
  PAGE_CREATE_BEGIN,
  PAGE_CREATE_SUCCESS,
  PAGE_CREATE_FAILURE,
  PAGE_DELETE_BEGIN,
  PAGE_DELETE_SUCCESS,
  PAGE_DELETE_FAILURE,
} from "../actionTypes";

const initialState = {
  pages: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PAGE_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case PAGE_GET_SUCCESS: {
      return {
        ...state,
        pages: action.payload,
        loading: false,
        error: null,
      };
    }
    case PAGE_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case PAGE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case PAGE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case PAGE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PAGE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PAGE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case PAGE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PAGE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PAGE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case PAGE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
