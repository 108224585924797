import { Spinner, AnchorButton } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { showToast } from "../../../utils/helpers";
import http from "../../../utils/http";
import { API_BASE_SKILLSCOM, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const JobsAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);
  const [jobs, setJobs] = useState();

  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = async () => {
    try {
      const data = await http.get(`${API_BASE_SKILLSCOM}/jobs/me`);
      setJobs(data);
    } catch (error) {
      console.error(error);
      showToast({ intent: "danger", message: "Hiba történt az adatok lekérésekor" });
    }
  };

  // opens delete dialog
  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  // sends delete request
  const handleDelete = async () => {
    try {
      await http.del(`${API_BASE_SKILLSCOM}/jobs/${rowToDelete.id}`);
      setRowToDelete(null);
      getJobs();
      showToast({ message: "Törlés sikeres!" });
    } catch (error) {
      console.error(error);
      showToast({ intent: "danger", message: "Hiba történt a törlés során!" });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Cím",
        accessor: "title",
      },
      {
        Header: "Helyszín",
        accessor: "location",
      },
      {
        Header: "Munkaidő",
        accessor: "workingHours",
      },
      {
        Header: "Jelenkezők",
        Cell: ({ row }) => (
          <AnchorButton
            href={generatePath(ROUTES.skillscomJobsApplicants, { id: row.original.id })}
          >
            Jelenkezők
          </AnchorButton>
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.skillsComJobsEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  if (!jobs) return <Spinner />;

  return (
    <>
      <h2>Összes álláshirdetésem</h2>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      <TableAdvanced columns={columns} data={jobs} />
    </>
  );
};

export default JobsAll;
