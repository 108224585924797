import { Classes, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import { CheckRoles } from "../../common/CheckRoles";

const MenuEDIR = () => {
  return (
    <Menu className={Classes.ELEVATION_2}>
      <MenuDivider title="EDIR Manager" />

      <MenuItem icon="database" text="SZC-k">
        <CheckRoles route={ROUTES.edirSzcCreate}>
          <MenuItem
            tagName={Link}
            to={ROUTES.edirSzcCreate}
            icon="plus"
            text="Új EDIR SZC"
          />
        </CheckRoles>

        <CheckRoles route={ROUTES.edirSzcAll}>
          <MenuItem
            tagName={Link}
            to={ROUTES.edirSzcAll}
            icon="edit"
            text="Összes EDIR SZC"
          />
        </CheckRoles>
      </MenuItem>

      <MenuItem icon="database" text="Intézmények">
        <CheckRoles route={ROUTES.edirInstituteCreate}>
          <MenuItem
            tagName={Link}
            to={ROUTES.edirInstituteCreate}
            icon="plus"
            text="Új EDIR Intézmény"
          />
        </CheckRoles>

        <CheckRoles route={ROUTES.edirInstituteAll}>
          <MenuItem
            tagName={Link}
            to={ROUTES.edirInstituteAll}
            icon="edit"
            text="Összes EDIR Intézmény"
          />
        </CheckRoles>
      </MenuItem>
    </Menu>
  );
};

export default MenuEDIR;
