import { Button, Checkbox, FormGroup, InputGroup, NumericInput } from "@blueprintjs/core";
import React, { useState } from "react";
import { API_BASE_IKK } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";

const FaqCategoryForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleInput = (e) => {
    const { name, value, checked, type } = e.target;
    const val = type === "checkbox" ? checked : value;
    setState((s) => ({ ...s, [name]: val }));
  };
  const handleNumInput = (val) => {
    setState((s) => ({ ...s, order: val }));
  };
  const handleCoverUpload = (media) => {
    setState((s) => ({ ...s, media_id: media.id }));
  };

  return (
    <div style={{ maxWidth: "600px" }}>
      <h2>{props.title}</h2>
      <br />
      <form onSubmit={(e) => props.onSubmit(state, setState, e)}>
        <FormGroup label="Témakör neve">
          <InputGroup name="name" value={state.name} onChange={handleInput} />
        </FormGroup>

        <FormGroup helperText="Ha be van pipálva a témakör linkje megjelenik az ikk.hu oldalon is">
          <Checkbox
            label="Kiemelt témakör"
            name="featured"
            checked={state.featured}
            onChange={handleInput}
          />
        </FormGroup>

        <br />
        <FormGroup label="Pozíció" labelInfo="(kötelező)">
          <NumericInput
            required
            min={0}
            name="position"
            value={state.order}
            onValueChange={handleNumInput}
          />
        </FormGroup>
        <br />
        <FormGroup label="Borító kép">
          <FileUpload isReqired={props.imageRequired} onUpload={handleCoverUpload} />
        </FormGroup>

        <FormGroup>
          {state.media_id ? (
            <img src={`${API_BASE_IKK}/media/${state.media_id}`} width="200" />
          ) : (
            <p>Nincs kép feltöltve</p>
          )}
        </FormGroup>

        <FormGroup>
          <Button type="submit" text="Mentés" icon="floppy-disk" intent="success" />
        </FormGroup>
      </form>
    </div>
  );
};

export default FaqCategoryForm;
