import { Button, FormGroup, InputGroup, HTMLSelect, Checkbox } from "@blueprintjs/core";
import "moment/locale/hu";
import React, { useState } from "react";
import MyCkEditor from "../../common/MyCkEditor";
import { API_BASE_IKK } from "../../../utils/routes";
import { uploadMedia } from "../../../redux/actions/common/media";
import FileUpload from "../../common/FileUpload";
import { connect } from "react-redux";
import { showToast } from "../../../utils/helpers";

const PopupForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  const handleMediaUpload = (media) => {
    console.log("media:", media);
    setState((s) => ({ ...s, img_media_id: media.id }));
  };

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setState((s) => ({ ...s, [name]: val }));
  };

  const handlePageInput = (index, e) => {
    setState((s) => {
      const newArr = [...s.show_on_pages];
      newArr[index] = e.target.value.trim();
      return { ...s, show_on_pages: newArr };
    });
  };

  const handleAddPage = () => {
    setState((s) => ({ ...s, show_on_pages: [...s.show_on_pages, "/hirek"] }));
  };

  const handleDeletePage = (index) => {
    setState((s) => {
      const tempPages = [...s.show_on_pages];
      tempPages.splice(index, 1);
      if (tempPages.length === 0) {
        showToast({ intent: "danger", message: "Minimum egy oldal megadása kötelező" });
        return s;
      }
      return { ...s, show_on_pages: tempPages };
    });
  };

  return (
    <div style={{ maxWidth: "800px", marginBottom: 100 }}>
      <h2>{props.title}</h2>

      <form onSubmit={handleSubmit}>
        <FormGroup label="Cím" labelInfo="(kötelező)">
          <InputGroup required onChange={handleInput} name="title" value={state.title} />
        </FormGroup>

        <FormGroup label="Gomb link">
          <InputGroup onChange={handleInput} name="url" type="url" value={state.url} />
        </FormGroup>
        <FormGroup label="Gomb felirat">
          <InputGroup onChange={handleInput} name="url_title" value={state.url_title} />
        </FormGroup>
        <FormGroup label="Működés" labelInfo="(kötelező)">
          <HTMLSelect required onChange={handleInput} name="type" value={state.type}>
            <option value="ONCE">Egyszer jelenik meg</option>
            <option value="ALWAYS">Minden alkalommal megjelenik</option>
          </HTMLSelect>
        </FormGroup>
        <FormGroup label="Szöveg" labelInfo="(kötelező)">
          <MyCkEditor
            data={state.body}
            onDataChange={(data) => setState((s) => ({ ...s, body: data }))}
          />
        </FormGroup>
        <FormGroup label="Aktív">
          <Checkbox checked={state.enabled} onChange={handleInput} name="enabled" />
        </FormGroup>
        <FormGroup
          label="Aloldalak ahol megjelenik"
          helperText="Per jellel ('/') kezdődő relatív URL vagy regex. Pl. minden oldal jelölése: .*"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 10,
            }}
          >
            {state.show_on_pages.map((pattern, i) => (
              <div key={i} style={{ display: "flex", gap: 4 }}>
                <InputGroup
                  onChange={(e) => handlePageInput(i, e)}
                  name="show_on_pages"
                  value={state.show_on_pages[i]}
                  required
                />
                <Button
                  type="button"
                  intent="danger"
                  icon="trash"
                  minimal
                  onClick={() => handleDeletePage(i)}
                />
              </div>
            ))}
            <Button type="button" icon="add" onClick={handleAddPage} />
          </div>
        </FormGroup>
        <FormGroup label="Kép" labelInfo="(kötelező)">
          <FileUpload onUpload={handleMediaUpload} isRequired={props.imageRequired} />
        </FormGroup>
        <FormGroup>
          {state.img_media_id ? (
            <img alt="" src={`${API_BASE_IKK}/media/${state.img_media_id}`} width="200" />
          ) : (
            <p>Nincs kép feltöltve</p>
          )}
        </FormGroup>

        <br />
        <Button
          text={props.submitBtnText}
          icon="floppy-disk"
          intent="success"
          type="submit"
        />
      </form>
    </div>
  );
};

export default connect(null, { uploadMedia })(PopupForm);
