import {
  ADD_TOAST_DANGER,
  ADD_TOAST_SUCCESS,
  REMOVE_TOAST,
} from "../actionTypes";

const initialState = {
  toasts: [],
};

export default function toast(state = initialState, action) {
  switch (action.type) {
    case ADD_TOAST_SUCCESS: {
      let newToasts = [...state.toasts];
      newToasts.push({ message: action.message, intent: "success" });

      return {
        ...state,
        toasts: newToasts,
      };
    }

    case ADD_TOAST_DANGER: {
      let newToasts = [...state.toasts];
      newToasts.push({ message: action.message, intent: "danger" });
      return {
        ...state,
        toasts: newToasts,
      };
    }

    case REMOVE_TOAST:
      return initialState;

    default:
      return state;
  }
}
