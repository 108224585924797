import { LayoutDefault } from "../LayoutDefault";
import { Callout, Card, H4 } from "@blueprintjs/core";
import React from "react";
const styleWrap = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "auto 1fr",
  marginTop: "20px",
};
export const Project6110Index = ({ children }) => {
  return <></>;
};
