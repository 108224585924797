import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { uploadMedia } from "../../../redux/actions/common/media";
import { getOneSlide, updateSlide } from "../../../redux/actions/ikk/carousel";
import http from "../../../utils/http";
import { API_BASE_EXAM_CENTER, ROUTES } from "../../../utils/routes";
import ExamForm from "./ExamForm";

const ExamEdit = (props) => {
  const [exam, setExam] = useState();

  const { id } = useParams();

  useEffect(() => {
    getExam();
  }, []);

  const getExam = async () => {
    const data = await http.get(`${API_BASE_EXAM_CENTER}/exams/${id}`);
    // transform partners property to array of IDs
    const transformed = {
      ...data,
      partners: data.partners ? data.partners.map((p) => p.id) : [],
    };
    setExam(transformed);
  };

  const handleEditSubmit = async (state) => {
    const data = await http.put(`${API_BASE_EXAM_CENTER}/exams/${id}`, state);
    if (data) props.history.push(ROUTES.examsAll);
  };

  if (!exam) return <Spinner />;
  console.log("exam:", exam);

  return (
    <ExamForm
      initialState={exam}
      submitBtnText="Módosít"
      title="Vizsga szerkesztése"
      handleSubmit={handleEditSubmit}
      imageRequired={false}
    />
  );
};

export default connect(null, {})(ExamEdit);
