import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import {
  TRAINING_CENTER_ITEM_GET_BEGIN,
  TRAINING_CENTER_ITEM_GET_FAILURE,
  TRAINING_CENTER_ITEM_GET_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getCentrums = () => async (dispatch) => {
  dispatch(getItemsBegin());
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.TRAINING_CENTER);
    dispatch(getItemsSuccess(json));
    return json;
  } catch (error) {
    dispatch(getItemsFailure(error));
    dispatch(addToastDanger(`Intézmények betöltése sikertelen: ${error}`));
  }
};

export const updateItem = (id, data) => async (dispatch) => {
  dispatch(getItemsBegin());
  try {
    const json = await http.put(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.TRAINING_CENTER}/${id}`,
      data
    );
    dispatch(getItemsSuccess(json));
    dispatch(addToastSuccess("Intézmény módosítása sikeres"));
    return json;
  } catch (error) {
    dispatch(getItemsFailure(error));
    dispatch(addToastDanger(`Intézmény módosítása sikertelen ${error}`));
  }
};

export const createItem = (data) => async (dispatch) => {
  dispatch(getItemsBegin());
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.TRAINING_CENTER, data);
    dispatch(getItemsSuccess(json));
    dispatch(addToastSuccess("Intézmény létrehozva"));
    return json;
  } catch (error) {
    dispatch(getItemsFailure(error));
    dispatch(addToastDanger(`Intézmény létrehozása sikertelen: ${error}`));
  }
};

export const deleteCentrum = (id) => async (dispatch) => {
  dispatch(getItemsBegin());
  try {
    const json = await http.del(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.TRAINING_CENTER}/${id}`
    );
    dispatch(getItemsSuccess(json));
    dispatch(addToastSuccess("Intézmény törölve"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Törlés sikertelen: ${error}`));
    dispatch(getItemsFailure(error));
  }
};

// TODO reducer missing

export const getItemsBegin = () => ({
  type: TRAINING_CENTER_ITEM_GET_BEGIN,
});
export const getItemsSuccess = (items) => ({
  type: TRAINING_CENTER_ITEM_GET_SUCCESS,
  payload: items,
});
export const getItemsFailure = (err) => ({
  type: TRAINING_CENTER_ITEM_GET_FAILURE,
  payload: { err },
});
