import { Spinner, Button } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { getCategories } from "../../../redux/actions/ikk/menu";
import { ROUTES } from "../../../utils/routes";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import { Link } from "react-router-dom";
import truncate from "html-truncate";

const MenuCategoryAll = (props) => {
  useEffect(() => {
    props.getCategories();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Cím",
        accessor: "name",
      },
      {
        Header: "Alcím",
        accessor: "subtitle",
      },
      {
        Header: "Leírás",
        accessor: "body",
        Cell: ({ value }) => (
          <div
            dangerouslySetInnerHTML={{ __html: truncate(value, 120) }}
          />
        ),
      },
      {
        Header: "Sorrend",
        accessor: "order",
      },
      {
        Header: "Menü itemek",
        Cell: ({ row }) => (
          <Link
            to={generatePath(ROUTES.menuItem, {
              id: row.original.id,
            })}
          >
            <Button text="Menü itemek" />
          </Link>
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.menuCategoryEdit, {
              id: row.original.id,
            })}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes menü kategória</h2>

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.categories} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { categories, loading } = state.menu;
  return { categories, loading };
};

export default connect(mapStateToProps, { getCategories })(MenuCategoryAll);
