import {
  Button,
  FormGroup,
  Icon,
  InputGroup,
  NumericInput,
} from "@blueprintjs/core";
import React, { useState } from "react";

const CentrumForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleNumInput = (val) => {
    setState((s) => ({ ...s, post_code: val }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  return (
    <>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="office" />
        &nbsp;<span>{props.title}</span>
      </h2>
      <form onSubmit={handleSubmit} style={{ maxWidth: "550px" }}>
        <FormGroup label="Intézmény neve" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="Név..."
            value={state.training_center}
            name="training_center"
            onChange={handleInput}
          />
        </FormGroup>

        <FormGroup label="Megye" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="Megye..."
            value={state.state}
            name="state"
            onChange={handleInput}
          />
        </FormGroup>

        <FormGroup label="Település" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="Település..."
            value={state.location}
            name="location"
            onChange={handleInput}
          />
        </FormGroup>

        <FormGroup label="Irányítószám" labelInfo="(kötelező)">
          <NumericInput
            required
            placeholder="Irányítószám..."
            min={1000}
            max={9999}
            value={state.post_code}
            name="post_code"
            onValueChange={handleNumInput}
          />
        </FormGroup>

        <FormGroup label="Utca, házszám" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="Utca, házszám..."
            value={state.address}
            name="address"
            onChange={handleInput}
          />
        </FormGroup>

        <FormGroup label="Postázási cím" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="Postázási cím..."
            value={state.post_address}
            name="post_address"
            onChange={handleInput}
          />
        </FormGroup>

        <FormGroup label="Telefon" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="+36..."
            value={state.telephone}
            name="telephone"
            type="tel"
            onChange={handleInput}
          />
        </FormGroup>

        <FormGroup label="Honlap" labelInfo="(kötelező)">
          <InputGroup
            required
            placeholder="https://..."
            value={state.website}
            name="website"
            type="url"
            onChange={handleInput}
          />
        </FormGroup>
        <br />

        <Button
          type="submit"
          text={props.submitBtnText}
          icon="floppy-disk"
          intent="success"
        />
      </form>
    </>
  );
};

export default CentrumForm;
