import {
  GET_FAQS_BEGIN,
  GET_FAQS_FAILURE,
  GET_FAQS_SUCCESS,
  GET_FAQ_CATEGORIES_BEGIN,
  GET_FAQ_CATEGORIES_FAILURE,
  GET_FAQ_CATEGORIES_SUCCESS,
} from "../../actionTypes";

const initialState = {
  faqs: [],
  categories: [],
  loading: false,
  error: null,
  singleItem: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FAQS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case GET_FAQS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        faqs: action.payload,
      };
    }
    case GET_FAQS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        faqs: [],
      };
    }
    case GET_FAQ_CATEGORIES_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_FAQ_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        categories: action.payload,
      };
    case GET_FAQ_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        categories: [],
      };
    default:
      return state;
  }
}
