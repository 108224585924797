import { Button, FormGroup, InputGroup, Checkbox } from "@blueprintjs/core";
import { Details } from "../../../common/Details";
import { useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";
import { ImageFields, imageDefaults } from "../fields/ImageFields";
import MyCkEditor from "../../../common/MyCkEditor";
import { DeleteButton } from "../DeleteButton";

export const GalleryWithPreview = ({ initialState, onComponentSave }) => {
  const { state, isDirty, setState, handleSimpleInput } = useInputState(initialState);

  const onAddNewItem = () => {
    setState((s) => ({ ...s, images: [...s.images, { ...imageDefaults }] }));
  };

  const onRemoveItem = (index) => {
    setState((s) => {
      const temp = structuredClone(s);
      // remove from previews
      const mediaId = temp.images[index].mediaId;
      const previewIndex = temp.previewImages.findIndex((id) => id === mediaId);
      if (previewIndex > -1) temp.previewImages.splice(previewIndex, 1);
      // remove from images
      temp.images.splice(index, 1);
      return temp;
    });
  };

  const onImageChange = (index) => (key, value) => {
    const temp = structuredClone(state);
    temp.images[index][key] = value;
    setState(temp);
  };

  const handleCheck = (e) => {
    const mediaId = parseInt(e.target.value);
    if (e.target.checked) {
      // adding it to previews
      setState((s) => ({ ...s, previewImages: [...s.previewImages, mediaId] }));
    } else {
      // remove from previews
      setState((s) => ({
        ...s,
        previewImages: s.previewImages.filter((id) => id !== mediaId),
      }));
    }
  };

  console.log(state);

  return (
    <>
      <FormGroup label="Galéria címe" labelInfo="(kötelező)">
        <InputGroup
          required
          name="title"
          value={state.title}
          onChange={handleSimpleInput}
        />
      </FormGroup>
      <FormGroup label="Galéria rövid leírása">
        <MyCkEditor
          data={state.description}
          onDataChange={(description) => setState((s) => ({ ...s, description }))}
        />
      </FormGroup>
      {state.images?.map((image, i) => (
        <Details key={i} summary={`${i + 1}. ${image.caption || "Kép"}`}>
          <Checkbox
            label="Előnézeti kép"
            value={image.mediaId ?? ""}
            checked={state.previewImages.includes(image.mediaId)}
            onChange={handleCheck}
          />
          <ImageFields data={image} onDataChange={onImageChange(i)} />
          <DeleteButton onClick={() => onRemoveItem(i)}>Kép törlése</DeleteButton>
        </Details>
      ))}

      <Button minimal icon="plus" intent="primary" onClick={onAddNewItem}>
        Új kép
      </Button>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
