import { Button, Spinner } from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { exportFile } from "../../redux/actions/szakkepzes/export";

const ExcelExport = (props) => {
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);
    await props.exportFile();
    setLoading(false);
  };

  return (
    <>
      <h2>Excel fájl exportálása</h2>

      {loading ? (
        <Spinner intent="primary" />
      ) : (
        <Button
          text="Letöltés"
          intent="primary"
          icon="import"
          onClick={handleExport}
        />
      )}
    </>
  );
};

export default connect(null, { exportFile })(ExcelExport);
