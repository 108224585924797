import React from "react";
import { connect } from "react-redux";
import { createItem } from "../../../redux/actions/ikk/traningCenters";
import { ROUTES } from "../../../utils/routes";
import CentrumForm from "./CentrumForm";

const emptyState = {
  state: "",
  training_center: "",
  post_code: "",
  location: "",
  address: "",
  post_address: "",
  telephone: "",
  website: "",
};

const CentrumCreate = (props) => {
  // handle form submit button
  const handleCreateSubmit = async (state, setState) => {
    const res = await props.createItem(state);
    // reset form if submit was successful
    if (res && res.id) {
      setState(emptyState);
      props.history.push(ROUTES.centrums);
    }
  };

  return (
    <CentrumForm
      title="Új szakképzési centrum"
      submitBtnText="Létrehoz"
      handleSubmit={handleCreateSubmit}
      initialState={emptyState}
    />
  );
};

export default connect(null, { createItem })(CentrumCreate);
