import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  getExamCenters,
  deleteExamCenter,
} from "../../../redux/actions/ikk/examCenters";
import { ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import truncate from "html-truncate";

const ExamCenterAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);

  useEffect(() => {
    props.getExamCenters();
  }, []);

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = () => {
    props.deleteExamCenter(rowToDelete.id);
    setRowToDelete(null);
    props.getExamCenters();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Név",
        accessor: "title",
      },
      {
        Header: "Leírás",
        accessor: "text",
        Cell: ({ value }) => (
          <div dangerouslySetInnerHTML={{ __html: truncate(value, 100) }} />
        ),
      },
      {
        Header: "Cím",
        accessor: "address",
      },
      {
        Header: "Telefon",
        accessor: "phone",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Honlap",
        accessor: "url",
        Cell: ({ value }) => (
          <a href={value} target="_blank" style={{ wordBreak: "break-all" }}>
            {value}
          </a>
        ),
      },
      {
        Header: "Sorrend",
        accessor: "order",
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.project_6110examCenterEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2 style={{ marginBottom: 0 }}>Összes akkreditált vizsgaközpont</h2>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />
      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced data={props.centers} columns={columns} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { centers, loading } = state.examCenters;
  return { centers, loading };
};

export default connect(mapStateToProps, { getExamCenters, deleteExamCenter })(
  ExamCenterAll
);
