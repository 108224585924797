import { Button } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";

const TableActionButtons = ({
  row,
  onDelBtnClick,
  delParam, // parameter passed to onDelBtnClick
  editHref,
  onEditBtnClick,
  editParam, // parameter passed to onEditBtnClick
}) => {
  return (
    <>
      {(editHref || onEditBtnClick) && (
        <Link to={editHref || "#"}>
          <Button
            minimal
            icon="edit"
            title="Szerkeszt"
            onClick={() => onEditBtnClick && onEditBtnClick(editParam || row.original)}
          />
        </Link>
      )}

      {onDelBtnClick && (
        <Button
          title="Törlés"
          icon="trash"
          minimal
          intent="danger"
          onClick={() => onDelBtnClick && onDelBtnClick(delParam || row.original)}
        />
      )}
    </>
  );
};

export default TableActionButtons;
