import {
  Button,
  ButtonGroup,
  Callout,
  Classes,
  Colors,
  Icon,
  Spinner,
  UL,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
  changeCategoryPosition,
  changeItemPosition,
  deleteCategory,
  deleteItem,
  getCategory,
  getItems,
} from "../../../redux/actions/ikk/publicInfo";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import ArchiveEditCategoryDialog from "./ArchiveEditCategoryDialog";
import ArchiveEditDataDialog from "./ArchiveEditDataDialog";
// import PublicInfoEditCategoryDialog from "./PublicInfoEditCategoryDialog";
// import PublicInfoEditDataDialog from "./PublicInfoEditDataDialog";

const sortableStyle = (data) => {
  const isItem = !data.children;
  return {
    userSelect: "none",
    cursor: "move",
    border: "2px solid",
    borderColor: data.deep_link ? Colors.ORANGE3 : "transparent",
    background: isItem ? "" : "rgba(200,200,250,0.2)",
    display: "flex",
    gap: 20,
    alignItems: "center",
    //width: "max-content",
    justifyContent: isItem ? "auto" : "space-between",
    borderRadius: 10,
    padding: "6px 20px",
    margin: isItem ? 0 : "0 0 6px 0",
    opacity: !data.visible ? 0.5 : 1,
  };
};

const DELAY = 300; // ms, allow button to be clicked before node becomes draggable

const ArchiveAll = (props) => {
  const [editItem, setEditItem] = useState(); // item passed to edit popup, set when edit clicked
  const [editCategory, setEditCategory] = useState(); // category passed to edit popup, set when edit clicked
  const [deleteData, setDeleteData] = useState(); // item or category, set when delete button clicked

  let sortingId = null; // the currently grabbed item/category id, storing it in state would cause rerenders that break drag&drop functionality

  useEffect(() => {
    props.getItems();
    props.getCategory();
  }, []);

  const handleEdit = (data, type) => {
    if (type === "category") {
      setEditCategory(data);
    } else if (type === "item") {
      setEditItem(data);
    }
  };

  const handleDelete = async () => {
    // if data has category_id treat it as an item
    if (deleteData.category_id) {
      await props.deleteItem(deleteData.id);
      props.getItems();
    } else {
      await props.deleteCategory(deleteData.id);
      props.getCategory();
    }
    setDeleteData(null);
  };

  const onSortStart = (data) => {
    const val = Number(data.node.getAttribute("value"));
    console.log("onSortStart", val);
    sortingId = val;
  };

  const onSortEndItems = async ({ oldIndex, newIndex }) => {
    console.log("onSortEndItems", oldIndex, newIndex, sortingId);
    await props.changeItemPosition(sortingId, newIndex);
    props.getItems();
  };

  const onSortEndCategories = async ({ oldIndex, newIndex }) => {
    console.log("onSortEndCategories", oldIndex, newIndex, sortingId);
    await props.changeCategoryPosition(sortingId, newIndex);
    props.getCategory();
  };

  // receives a single item from SortableListOfItems
  const SortableItem = SortableElement(({ item }) => (
    <li value={item.id}>
      <div style={sortableStyle(item)}>
        <span>{item.name}</span>
        <ButtonGroup>
          <Button small icon="edit" onClick={() => handleEdit(item, "item")} />
          <Button small icon="trash" onClick={() => setDeleteData(item)} />
        </ButtonGroup>
      </div>
    </li>
  ));

  // receives a list of items in a category from SortableCategory
  const SortableListOfItems = SortableContainer(({ items }) => {
    return (
      <ul>
        {items.map((item, i) => (
          <SortableItem key={i} index={i} item={item} />
        ))}
      </ul>
    );
  });

  // receives one category and its corresponding items from SortableListOfCategories
  const SortableCategory = SortableElement(
    ({ category, items = [], subCategories = [] }) => {
      return (
        <li value={category.id}>
          <div style={{ ...sortableStyle(category), fontSize: "1.1em" }}>
            <b>{category.name}</b>
            <ButtonGroup>
              <Button small icon="cog" onClick={() => handleEdit(category, "category")} />
              <Button small icon="trash" onClick={() => setDeleteData(category)} />
            </ButtonGroup>
          </div>

          {subCategories.length > 0 && (
            <ul>
              {subCategories.map((childCategory, i) => (
                <SortableCategory
                  index={i}
                  key={childCategory.id}
                  pressDelay={DELAY}
                  lockAxis="y"
                  category={childCategory}
                  items={props.items.filter((it) => it.category_id === childCategory.id)}
                  subCategories={childCategory.children}
                  onSortStart={onSortStart}
                  onSortEnd={onSortEndCategories}
                />
              ))}
            </ul>
          )}

          <SortableListOfItems
            pressDelay={DELAY}
            lockAxis="y"
            items={items}
            onSortStart={onSortStart}
            onSortEnd={onSortEndItems}
          />
        </li>
      );
    }
  );

  // receives all categories and items from ArchiveAll props (this component)
  const SortableListOfCategories = SortableContainer(({ categories }) => {
    return (
      <ul>
        {categories.map((cat, i) => (
          <SortableCategory
            key={cat.id}
            index={i}
            category={cat}
            items={props.items.filter((it) => it.category_id === cat.id)}
            subCategories={cat.children}
          />
        ))}
      </ul>
    );
  });

  if (props.loading || props.categories.length === 0 || props.items.length === 0)
    return <Spinner intent="primary" />;

  console.log("cats:", props.categories);

  return (
    <>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="archive" />
        &nbsp;&nbsp;<span>Összes archív adat</span>
      </h2>

      <Callout intent="primary">
        Az adatok egy kategórián belül és a kategóriák sorrendje drag & drop módszerrel
        módosítható, {DELAY / 1000} másodperc nyomva tartás után. &nbsp;
        <br /> Jelmagyarázat:&nbsp;
        <b style={{ color: Colors.RED3 }}>■</b>: Nem látható &nbsp;
        <b style={{ color: Colors.ORANGE3 }}>■</b>: Deep link
      </Callout>

      <SortableListOfCategories
        pressDelay={DELAY}
        lockAxis="y"
        categories={props.categories}
        onSortStart={onSortStart}
        onSortEnd={onSortEndCategories}
      />

      <ArchiveEditCategoryDialog category={editCategory} setCategory={setEditCategory} />

      <ArchiveEditDataDialog item={editItem} setItem={setEditItem} />

      <DeleteConfirmation
        isOpen={!!deleteData}
        recordName={deleteData?.name}
        onDelete={handleDelete}
        onCancel={() => setDeleteData(null)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { loading, items, categories, flat_categories } = state.publicInfo;
  const archiveCategories = categories.filter((c) => c.type === "ARCHIVE_DATA");
  return { loading, items, categories: archiveCategories };
};

export default connect(mapStateToProps, {
  getItems,
  getCategory,
  changeItemPosition,
  deleteCategory,
  deleteItem,
  changeCategoryPosition,
})(ArchiveAll);
