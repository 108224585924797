import http from "../../../utils/http";
import { API_BASE_SKILLSCOM } from "../../../utils/routes";
import {
  SKILLSCOM_COMPANY_ADD_BEGIN,
  SKILLSCOM_COMPANY_ADD_FAILURE,
  SKILLSCOM_COMPANY_ADD_SUCCESS,
  SKILLSCOM_COMPANY_DELETE_BEGIN,
  SKILLSCOM_COMPANY_DELETE_FAILURE,
  SKILLSCOM_COMPANY_DELETE_SUCCESS,
  SKILLSCOM_COMPANY_GET_BEGIN,
  SKILLSCOM_COMPANY_GET_FAILURE,
  SKILLSCOM_COMPANY_GET_SUCCESS,
  SKILLSCOM_COMPANY_VERIFY_BEGIN,
  SKILLSCOM_COMPANY_VERIFY_FAILURE,
  SKILLSCOM_COMPANY_VERIFY_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const uploadSKILLSCOM_COMPANY = (data) => async (dispatch) => {
  dispatch(uploadSKILLSCOM_COMPANYBegin());
  try {
    const json = await http.post(API_BASE_SKILLSCOM + "/admin/companies", data);
    dispatch(uploadSKILLSCOM_COMPANYSuccess(json));
    dispatch(addToastSuccess("Cég létrehozva"));
    return json;
  } catch (error) {
    dispatch(uploadSKILLSCOM_COMPANYFailure(error));
    dispatch(addToastDanger(`Cég létrehozása sikertelen: ${error}`));
  }
};

export const verifyCompany = (id) => async (dispatch) => {
  dispatch(verifyCompanyBegin());
  try {
    const json = await http.post(
      `${API_BASE_SKILLSCOM}/admin/companies/${id}/verify`
    );
    dispatch(verifyCompanySuccess(id));
    dispatch(addToastSuccess("Cég elfogadva"));
    return json;
  } catch (error) {
    dispatch(verifyCompanyFailure(error));
    dispatch(addToastDanger(`Cég elfogadása sikertelen: ${error}`));
  }
};

export const getCompanies = (id = null, { offset, limit }) => async (
  dispatch
) => {
  dispatch(getSKILLSCOM_COMPANYBegin());
  try {
    const params =
      typeof offset === "number" && typeof limit === "number"
        ? `?limit=${limit}&offset=${offset}`
        : "";
    const json = await http.get(
      `${API_BASE_SKILLSCOM}/admin/companies/${id || ""}${params}`
    );
    dispatch(getSKILLSCOM_COMPANYSuccess(json));
    return json;
  } catch (error) {
    dispatch(getSKILLSCOM_COMPANYFailure(error));
    dispatch(addToastDanger(`Cégek betöltése sikertelen: ${error}`));
  }
};

export const deleteSKILLSCOM_COMPANY = (id) => async (dispatch) => {
  dispatch(deleteSKILLSCOM_COMPANYBegin());
  try {
    const json = await http.del(`${API_BASE_SKILLSCOM}/companies/${id}`);
    dispatch(deleteSKILLSCOM_COMPANYSuccess(id));
    dispatch(addToastSuccess("Cég törölve"));
    return json;
  } catch (error) {
    dispatch(deleteSKILLSCOM_COMPANYFailure(error));
    dispatch(addToastDanger(`Cég törlése sikertelen: ${error}`));
  }
};

export const verifyCompanyBegin = () => ({
  type: SKILLSCOM_COMPANY_VERIFY_BEGIN,
});

export const verifyCompanySuccess = (id) => ({
  type: SKILLSCOM_COMPANY_VERIFY_SUCCESS,
  payload: id,
});

export const verifyCompanyFailure = (error) => ({
  type: SKILLSCOM_COMPANY_VERIFY_FAILURE,
  payload: { error },
});

export const uploadSKILLSCOM_COMPANYBegin = () => ({
  type: SKILLSCOM_COMPANY_ADD_BEGIN,
});

export const uploadSKILLSCOM_COMPANYSuccess = (SKILLSCOM_COMPANY) => ({
  type: SKILLSCOM_COMPANY_ADD_SUCCESS,
  payload: { SKILLSCOM_COMPANY },
});

export const uploadSKILLSCOM_COMPANYFailure = (error) => ({
  type: SKILLSCOM_COMPANY_ADD_FAILURE,
  payload: { error },
});

export const getSKILLSCOM_COMPANYBegin = () => ({
  type: SKILLSCOM_COMPANY_GET_BEGIN,
});

export const getSKILLSCOM_COMPANYSuccess = (news) => ({
  type: SKILLSCOM_COMPANY_GET_SUCCESS,
  payload: news,
});

export const getSKILLSCOM_COMPANYFailure = (error) => ({
  type: SKILLSCOM_COMPANY_GET_FAILURE,
  payload: { error },
});

export const deleteSKILLSCOM_COMPANYBegin = () => ({
  type: SKILLSCOM_COMPANY_DELETE_BEGIN,
});

export const deleteSKILLSCOM_COMPANYSuccess = (id) => ({
  type: SKILLSCOM_COMPANY_DELETE_SUCCESS,
  payload: id,
});

export const deleteSKILLSCOM_COMPANYFailure = (error) => ({
  type: SKILLSCOM_COMPANY_DELETE_FAILURE,
  payload: { error },
});
