import React from "react";
import { connect } from "react-redux";
import {
  createQualificationDocument6110,
  getAllQualification6110,
} from "../../../redux/6110/pk";
import { ROUTES } from "../../../utils/routes";
import DocumentForm from "./DocumentForm";
import { useEffect } from "react";

// initial empty state for create form
const emptyState = {
  qualificationId: null,
  mediaId: null,
  fileName: "",
  version: null,
  publishDate: new Date().toISOString().split("T")[0],
};

const DocumentCreate6110 = (props) => {
  useEffect(() => {
    props.getAllQualification6110();
  }, []);

  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const data = await props.createQualificationDocument6110(
      state.qualificationId,
      state
    );
    // reset form if submit was successful
    if (data) {
      setState(emptyState);
      props.getAllQualification6110();
      props.history.push(ROUTES.project_6110PK);
    }
  };

  return (
    <DocumentForm
      title="Programkövetelmény (PK) felvitele"
      initialState={emptyState}
      qualifications={props.qualifications}
      submitBtnText="Létrehoz"
      handleSubmit={handleCreateSubmit}
    />
  );
};

const mapStateToProps = (state) => {
  return { ...state.pk };
};

export default connect(mapStateToProps, {
  createQualificationDocument6110,
  getAllQualification6110,
})(DocumentCreate6110);
