import { combineReducers } from "redux";
import aboutRows from "./aboutRows";
import authUser from "./auth/user";
import authUsers from "./auth/users";
import carousel from "./carousel";
import constPageData from "./constPageData";
import documents from "./documents";
import events from "./events";
import login from "./login";
import media from "./media";
import news from "./news";
import projects from "./projects";
import publicInfo from "./publicInfo";
import pk from "./szakkepesites/pk";
import faq from "./szakkepzes/faq";
import importConnections from "./szakkepzes/importConnections";
import importInstitutes from "./szakkepzes/importInstitutes";
import kkk from "./szakkepzes/kkk";
import locations from "./szakkepzes/locations";
import notifications from "./szakkepzes/notifications";
import tags from "./tags";
import groups from "./groups";
import toast from "./toast";
import skillsComNews from "./skillscom/news";
import skillsComEvents from "./skillscom/events";
import skillsComPosts from "./skillscom/posts";
import skillsComEditors from "./skillscom/editor";
import skillsComCompanies from "./skillscom/companies";
import skillsComInstitutes from "./skillscom/institutes";
import skillsComMe from "./skillscom/me";
import menu from "./menu";
import examCenters from "./examCenters";
import pages from "./pages";
import kkk6110 from "./6110/kkk";
import rk6110 from "./6110/rk";

export default combineReducers({
  login,
  media,
  news,
  events,
  publicInfo,
  constPageData,
  importInstitutes,
  importConnections,
  toast,
  carousel,
  notifications, // cms only
  locations,
  kkk, // kepzesi kimeneti kovetelmenyek
  faq,
  documents, // homepage docs
  menu, // menu categories and items
  examCenters, // akkreditált vizsgaközpontok
  pages, // custom pages
  pk, // programkovetelmenyek
  aboutRows, // ikk about page blocks
  projects,
  users: authUsers,
  user: authUser,
  tags, // for events/news - sector/profession connection
  groups,
  skillsComNews,
  skillsComEvents,
  skillsComPosts,
  skillsComEditors,
  skillsComCompanies,
  skillsComInstitutes,
  skillsComMe,
  project6110: combineReducers({
    kkk: kkk6110,
    rk: rk6110,
  }),
});
