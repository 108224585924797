import { Callout, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import http from "../../../utils/http";
import { API_BASE_QUALIFCARD, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const PkCardAll = () => {
  const [rowToDelete, setRowToDelete] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    getAllPKCard();
  }, []);

  const getAllPKCard = async () => {
    const res = await fetch(`${API_BASE_QUALIFCARD}/qualifications`);
    const json = await res.json();
    if (res.ok) setData(json);
  };

  // opens delete dialog
  const handleDelete = (row) => {
    setRowToDelete(row);
  };

  // delete qualification submitted
  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    await http.del(`${API_BASE_QUALIFCARD}/cms/qualifications/${rowToDelete.id}`);
    setRowToDelete(null);
    getAllPKCard();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Szakképesítés neve",
        accessor: "name",
      },
      {
        Header: "KEOR kód",
        accessor: "code",
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.pkCardEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={() => handleDelete(row.original)}
          />
        ),
      },
    ],
    []
  );

  if (process.env.REACT_APP_CMS_ENV !== "live")
    return (
      <Callout intent="primary">
        A szakképesítés kártyák csak éles környezetben érhetőek el.
      </Callout>
    );

  if (!data) return <Spinner intent="primary" />;

  return (
    <>
      <h2>Összes szakképesítés (PK) kártya</h2>

      {/* delete PK card modal */}
      <DeleteConfirmation
        isOpen={rowToDelete?.name}
        onCancel={() => setRowToDelete(null)}
        onDelete={handleDeleteSubmit}
        recordName={`Szakképesítés: ${rowToDelete?.name}`}
      />

      <TableAdvanced data={data} columns={columns} />
    </>
  );
};

export default PkCardAll;
