import {
  AnchorButton,
  Button,
  FileInput,
  FormGroup,
  InputGroup,
  Spinner,
} from "@blueprintjs/core";
import { useRef, useState } from "react";
import { showToast } from "../../../utils/helpers";
import { authFetch } from "../../../utils/http";
import { API_BASE_SCHOLARSHIP } from "../../../utils/routes";
import { EnvAlertWrapper } from "../../common/EnvAlertWrapper";
import MyCkEditor from "../../common/MyCkEditor";

export const ScholarshipTemplateForm = (props) => {
  const [state, setState] = useState(props.initialState);
  const [isUploading, setUploading] = useState(false);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.handleSubmit(state);
  };

  const handleDeleteFile = () => {
    setState((s) => ({ ...s, fileId: null, fileUrl: null }));
  };

  // upload public template file
  const handleFileUpload = async (e) => {
    setUploading(true);
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    authFetch(`${API_BASE_SCHOLARSHIP}/file/public`, {
      method: "POST",
      body: formdata,
      headers: { "Content-Type": null }, // omit content-type header entirely
    })
      .then((data) => {
        showToast({ message: "Fájl feltöltés sikeres" });
        setState((s) => ({ ...s, fileId: data.fileId, fileUrl: data.fileUrl }));
      })
      .catch((e) => {
        console.error(e);
        showToast({ intent: "danger", message: "Fájl feltöltés sikertelen" });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ maxWidth: 800 }}>
        <h2>{props.title}</h2>

        <FormGroup label="Név" labelInfo="(kötelező)">
          <InputGroup required name="name" value={state.name} onChange={handleInput} />
        </FormGroup>

        <FormGroup label="Leírás" labelInfo="(kötelező)">
          <MyCkEditor
            data={state.content}
            onDataChange={(d) => handleInput({ target: { value: d, name: "content" } })}
          />
        </FormGroup>

        <FormGroup label="Fájl" labelInfo="">
          <FileInput onInputChange={handleFileUpload} />
          <Button
            icon="trash"
            intent="danger"
            minimal
            onClick={handleDeleteFile}
            title="Fájl törlése"
          />
          {isUploading && (
            <div style={{ maxWidth: 30, marginTop: 10 }}>
              <Spinner size={20} />
            </div>
          )}
          {state.fileUrl && (
            <p style={{ marginTop: 10 }}>
              Jelenleg feltöltve:{" "}
              <a
                href={`${API_BASE_SCHOLARSHIP.replace("/api", "")}${state.fileUrl}`}
                target="_blank"
              >
                {state.fileUrl}
              </a>
            </p>
          )}
        </FormGroup>
        <br />

        <Button intent="success" icon="floppy-disk" type="submit">
          Mentés
        </Button>
      </form>
    </>
  );
};
