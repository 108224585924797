import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAllFaqCategory,
  updateFaqCategory,
} from "../../../redux/actions/szakkepzes/faq";
import { ROUTES } from "../../../utils/routes";
import FaqCategoryForm from "./FaqCategoryForm";

const FaqCategoryEdit = (props) => {
  const [category, setCategory] = useState();

  const { id } = useParams();

  useEffect(() => {
    if (props.categories.length === 0) props.getAllFaqCategory();
  }, []);

  useEffect(() => {
    const c = props.categories.find((c) => c.id === Number(id));
    c && setCategory({ ...c });
  }, [props.categories]);

  const handleSubmit = async (state, setState, e) => {
    e.preventDefault();
    const res = await props.updateFaqCategory(category.id, state);
    if (res) props.history.push(ROUTES.faqCategory);
  };

  if (!category) return <Spinner />;

  return (
    <FaqCategoryForm
      title="Gyakori kérdés témakör szerkesztése"
      initialState={category}
      onSubmit={handleSubmit}
      imageRequired={false}
    />
  );
};

const mapStateToProps = (state) => {
  return { ...state.faq };
};
export default connect(mapStateToProps, {
  getAllFaqCategory,
  updateFaqCategory,
})(FaqCategoryEdit);
