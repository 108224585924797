import React from "react";
import { connect } from "react-redux";
import { createExamCenter } from "../../../redux/actions/ikk/examCenters";
import { ROUTES } from "../../../utils/routes";
import ExamCenterForm from "./ExamCenterForm";

const emptyState = {
  title: "",
  text: "",
  address: "",
  phone: [],
  email: [],
  url: "",
  order: 1,
  exam_software_url: "",
  qualified_exams: null,
};

const ExamCenterCreate = (props) => {
  // handle form submit button
  const handleCreateSubmit = async (state, setState) => {
    const res = await props.createExamCenter(state);
    // reset form if submit was successful
    if (res && res.id) {
      setState(emptyState);
      props.history.push(ROUTES.examCenter);
    }
  };

  return (
    <ExamCenterForm
      title="Új akkreditált vizsgaközpont"
      submitBtnText="Létrehoz"
      handleSubmit={handleCreateSubmit}
      initialState={emptyState}
    />
  );
};

export default connect(null, { createExamCenter })(ExamCenterCreate);
