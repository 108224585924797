import {
  SKILLSCOM_POST_ADD_BEGIN,
  SKILLSCOM_POST_ADD_FAILURE,
  SKILLSCOM_POST_ADD_SUCCESS,
  SKILLSCOM_POST_DELETE_SUCCESS,
  SKILLSCOM_POST_GET_BEGIN,
  SKILLSCOM_POST_GET_FAILURE,
  SKILLSCOM_POST_GET_ONE_SUCCESS,
  SKILLSCOM_POST_GET_SUCCESS,
} from "../../actionTypes";

const initialState = {
  posts: [],
  post: null,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SKILLSCOM_POST_ADD_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SKILLSCOM_POST_ADD_SUCCESS: {
      const posts = state.posts;
      //posts.push(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
        posts: posts,
      };
    }
    case SKILLSCOM_POST_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case SKILLSCOM_POST_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SKILLSCOM_POST_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case SKILLSCOM_POST_GET_SUCCESS: {
      let posts = state.posts;

      if (!Array.isArray(posts)) {
        posts = [];
      }
      posts = posts.concat(action.payload);
      posts = posts.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj["id"]).indexOf(obj["id"]) === pos;
      });

      return {
        ...state,
        loading: false,
        error: null,
        posts: posts, //posts,
      };
    }

    case SKILLSCOM_POST_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        posts: state.posts.filter((n) => n.id !== action.payload.id),
      };
    }

    // SINGLE

    case SKILLSCOM_POST_GET_ONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        post: action.payload,
      };
    }

    default:
      return state;
  }
}
