import { SZAKKEPZES_LOCATIONS_GET_BEGIN, SZAKKEPZES_LOCATIONS_GET_FAILURE, SZAKKEPZES_LOCATIONS_GET_SUCCESS } from "../../actionTypes";
  
  const initialState = {
    locations: [],
    loading: false,
    error: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case SZAKKEPZES_LOCATIONS_GET_BEGIN: {
        return {
          ...state,
          loading: true,
          error: null,
        };
      }
      case SZAKKEPZES_LOCATIONS_GET_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: null,
          locations: action.payload,
        };
      }
      case SZAKKEPZES_LOCATIONS_GET_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload,
          locations: [],
        };
      }
      default:
        return state;
    }
  }
  