import React from "react";
import { connect } from "react-redux";
import { uploadMedia } from "../../../redux/actions/common/media";
import { uploadSKILLSCOM_EVENT } from "../../../redux/actions/skillscom/event";
import { ROUTES } from "../../../utils/routes";
import EventForm from "./EventForm";

const emptyState = {
  title: "",
  description: "",
  from: null,
  till: null,
  coverImgId: null,
  tags: [],
  location: {
    googleMapsUrl: "",
    name: "",
    // lat: "",
    // lng: "",
  },
  sendPush: false,
};

const EventCreate = (props) => {
  const handleCreateSubmit = async (state, setState) => {
    const res = await props.uploadSKILLSCOM_EVENT(state);
    if (res && res.id) props.history.push(ROUTES.skillsComEvents);
  };

  return (
    <EventForm
      title="Új esemény"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
    />
  );
};

export default connect(null, { uploadSKILLSCOM_EVENT, uploadMedia })(
  EventCreate
);
