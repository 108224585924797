import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  H3,
  Spinner,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  approveEvent,
  deleteSKILLSCOM_EVENT,
  getOneEvent,
  getSKILLSCOM_EVENT,
} from "../../../redux/actions/skillscom/event";
import { isAuthorized } from "../../../utils/auth";
import { API_BASE_IKK, ROUTES, SKILLSCOM_URL } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const EventsAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);
  const [rowToApprove, setRowToApprove] = useState();

  useEffect(() => {
    getAllEventsRecursively(0, 30);
  }, []);

  const getAllEventsRecursively = async (offset, limit) => {
    const j = await props.getSKILLSCOM_EVENT(offset, limit);
    if (Array.isArray(j) && j.length === limit) {
      getAllEventsRecursively(offset + limit, limit);
    }
  };

  // opens delete dialog
  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  // sends delete request and resets dialogs
  const handleDelete = async () => {
    await props.deleteSKILLSCOM_EVENT(rowToDelete.id);
    setRowToDelete(null);
    setRowToApprove(null);
  };

  // sends approve request
  const handleApprove = async (id) => {
    await props.approveEvent(id);
    getAllEventsRecursively(0, 30); // TODO should be handled in redux
  };

  // opens approve dialog
  const handleApproveDialog = (id) => async () => {
    const event = await props.getOneEvent(id);
    setRowToApprove(event);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Esemény neve",
        accessor: "title",
      },
      {
        Header: "Kezdés",
        accessor: "from",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Vége",
        accessor: "till",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },

      {
        Header: "Helyszín",
        accessor: "location",
        Cell: ({ value }) => (
          <a
            href={value?.googleMapsUrl}
            target="_blank"
            style={{ wordBreak: "break-all" }}
          >
            {value?.name}
          </a>
        ),
      },
      {
        Header: "Kép",
        accessor: "coverImgId",
        Cell: ({ value }) => (
          <img
            width="100"
            src={`${API_BASE_IKK}/media/${value}/sm`}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Elfogadva",
        accessor: "approved",
        Cell: ({ row, value }) => {
          if (
            isAuthorized({
              authNamespace: "zartrendszer",
              authPermission: "post:approve",
            }) &&
            !value
          ) {
            return (
              <Button
                intent="primary"
                onClick={handleApproveDialog(row.original.id)}
              >
                Előnézet
              </Button>
            );
          } else return <p>{value ? "Igen" : "Nem"}</p>;
        },
      },
      {
        Header: "Web",
        Cell: ({ row }) => (
          <AnchorButton
            small
            minimal
            icon="document-open"
            text="Megnyit"
            target="_blank"
            href={`${SKILLSCOM_URL}/esemenyek/${row.original.id}`}
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={
              isAuthorized({
                namespace: "zartrendszer",
                authPermission: "event:update",
              })
                ? generatePath(ROUTES.skillsComEventsEdit, {
                    id: row.original.id,
                  })
                : null
            }
            onDelBtnClick={
              isAuthorized({
                namespace: "zartrendszer",
                authPermission: "event:delete",
              })
                ? handleDeleteDialog
                : null
            }
          />
        ),
      },
    ],
    []
  );

  if (props.loading || !Array.isArray(props.events) || !props.events) {
    return <Spinner intent="primary" />;
  }

  return (
    <>
      <h2>Összes esemény</h2>

      <ApproveDialog
        isOpen={!!rowToApprove}
        event={rowToApprove}
        onApprove={handleApprove}
        onDelete={handleDeleteDialog}
        onCancel={() => setRowToApprove(null)}
      />

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      <TableAdvanced columns={columns} data={props.events} />
    </>
  );
};

const ApproveDialog = ({ isOpen, event, onCancel, onApprove, onDelete }) => {
  if (!isOpen || !event) return null;
  return (
    <Dialog
      icon="info-sign"
      isOpen={isOpen}
      onClose={onCancel}
      onApprove={() => onApprove(event.id)}
      usePortal={false}
      title="Esemény elbírálásra vár"
      style={{ width: 800 }}
    >
      <div className={Classes.DIALOG_BODY}>
        <H3>{event.title}</H3>
        <p>
          Kezdés: {event.from ? new Date(event.from).toLocaleString() : "-"}
        </p>
        <p>Vége: {event.till ? new Date(event.till).toLocaleString() : "-"}</p>
        <p>Helyszín: {event.location.name}</p>
        <div dangerouslySetInnerHTML={{ __html: event.description }} />
        <img
          src={`${API_BASE_IKK}/media/${event.coverImgId}/lg`}
          style={{
            marginTop: 10,
            width: "auto",
            maxHeight: 300,
            display: event.coverImgId ? "block" : "none",
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent="danger" onClick={() => onDelete(event)}>
            Töröl
          </Button>
          <Button onClick={onCancel}>Mégse</Button>
          <Button intent="primary" icon="tick-circle">
            Elfogad
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { events, loading } = state.skillsComEvents;
  return { events, loading };
};
export default connect(mapStateToProps, {
  getSKILLSCOM_EVENT,
  deleteSKILLSCOM_EVENT,
  getOneEvent,
  approveEvent,
})(EventsAll);
