import {
  Button,
  Classes,
  Dialog,
  H2,
  H4,
  HTMLSelect,
  Spinner,
  TextArea,
} from "@blueprintjs/core";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import truncate from "html-truncate";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getTexts, updateItem } from "../../redux/actions/ikk/constPageData";
import { pageNames } from "../../utils/pageNames";
import TableActionButtons from "../common/TableActionButtons";
import { TableAdvanced } from "../common/TableAdvanced";

const StaticTexts = (props) => {
  // const [texts, setTexts] = useState([]); // all static text
  const [rowEditing, setRowEditing] = useState(null); // selected row to edit
  const [page, setPage] = useState("home");

  useEffect(() => {
    props.getTexts(page);
  }, [page]);

  const handlePageFilter = (e) => {
    setPage(e.target.value);
  };

  const handleUpdate = async (e) => {
    // console.log("target", e.target);
    await props.updateItem(rowEditing.page, rowEditing.id, rowEditing.value);
    setRowEditing(null);
    props.getTexts(page);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Kulcs",
        accessor: "key",
        Cell: ({ value }) => (
          <div style={{ wordBreak: "break-all", minWidth: "100px" }}>{value}</div>
        ),
      },
      {
        Header: "Szöveg",
        accessor: "value",
        Cell: ({ value }) => (
          <div
            style={{ maxWidth: "700px" }}
            dangerouslySetInnerHTML={{ __html: truncate(value, 200) }}
          />
        ),
      },
      {
        Header: "Oldal",
        accessor: "page",
        Cell: ({ value }) => pageNames[value] || value,
      },
      {
        Header: "Típus",
        accessor: "type",
        Cell: ({ value }) => (value === "TEXT" ? "Szöveg" : "HTML"),
      },
      {
        Header: "Utoljára módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons row={row} hideDelete onEditBtnClick={setRowEditing} />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes statikus szöveg</h2>

      <Dialog
        onClose={() => setRowEditing(null)}
        transitionName={rowEditing?.type === "HTML" ? "" : undefined} // ckeditor's toolbar is collapsed if transition is not disabled
        style={{ width: "600px" }}
        title="Szöveg szerkesztése"
        isOpen={rowEditing !== null}
        usePortal={false}
        autoFocus={false}
        enforceFocus={false} //otherwise links are not editable
      >
        <div className={Classes.DIALOG_BODY}>
          <H4>{rowEditing?.key}</H4>

          {rowEditing?.type === "TEXT" ? (
            <TextArea
              fill
              growVertically={true}
              style={{ resize: "none" }}
              defaultValue={rowEditing?.value}
              onChange={(e) => {
                const { value } = e.target;
                console.log(value);
                setRowEditing((r) => ({ ...r, value: value }));
              }}
            />
          ) : (
            <CKEditor
              editor={ClassicEditor}
              data={rowEditing?.value}
              config={{
                removePlugins: ["ImageUpload", "Image", "MediaEmbed", "Table"],
                link: {
                  decorators: {
                    isExternal: {
                      mode: "manual",
                      label: "Új oldalon nyílik meg",
                      attributes: {
                        target: "_blank",
                      },
                    },
                  },
                },
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setRowEditing((r) => ({ ...r, value: data }));
                console.log({ event, editor, data });
              }}
            />
          )}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setRowEditing(null)}>Mégse</Button>
            <Button icon="floppy-disk" intent="success" onClick={handleUpdate}>
              Mentés
            </Button>
          </div>
        </div>
      </Dialog>

      <b>Szűrés:</b>
      <HTMLSelect style={{ marginLeft: "10px" }} onChange={handlePageFilter} value={page}>
        <option value="home">Főoldal</option>
        <option value="about">Rólunk</option>
        <option value="dual">Duális szakmai oktatás</option>
        <option value="akkreditalt">Akkreditált vizsgaközpontok</option>
        <option value="otr">Oktatói Továbbképzési Rendszer</option>
        {/* <option value="contact">Kapcsolat</option> */}
      </HTMLSelect>

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.texts} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { texts, loading } = state.constPageData;
  return { texts, loading };
};

export default connect(mapStateToProps, { getTexts, updateItem })(StaticTexts);
