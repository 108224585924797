import { Spinner } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { getAllGroups } from "../../../redux/actions/ikk/groups";
import { ROUTES } from "../../../utils/routes";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const GroupsAll = (props) => {
  useEffect(() => {
    props.getAllGroups();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Csoport nev",
        accessor: "name",
      },
      {
        Header: "Utoljára módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      }
    ],
    []
  );

  if (!props.groups.length) return <Spinner />;

  return (
    <>
      <h2>Összes Hír csoport</h2>

      <TableAdvanced data={props.groups} columns={columns} />
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.groups };
};
export default connect(mapStateToProps, {
  getAllGroups,
})(GroupsAll);
