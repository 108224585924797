import { GET_AUTH_USER_BEGIN, GET_AUTH_USER_FAILURE, GET_AUTH_USER_SUCCESS } from "../../actionTypes";

  
  const initialState = {
    users: [],
    loading: false,
    error: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_AUTH_USER_BEGIN: {
        return {
          ...state,
          loading: true,
          error: null,
        };
      }
      case GET_AUTH_USER_SUCCESS: {
        return {
          user: action.payload,
          loading: false,
          error: null,
        };
      }
      case GET_AUTH_USER_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
  
      default:
        return state;
    }
  }
  