import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import {
  EVENT_ADD_BEGIN,
  EVENT_ADD_FAILURE,
  EVENT_ADD_SUCCESS,
  EVENT_DELETE_BEGIN,
  EVENT_DELETE_FAILURE,
  EVENT_DELETE_SUCCESS,
  EVENT_GET_BEGIN,
  EVENT_GET_FAILURE,
  EVENT_GET_SUCCESS,
  EVENT_UPDATE_BEGIN,
  EVENT_UPDATE_FAILURE,
  EVENT_UPDATE_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const uploadEvent = (data) => async (dispatch) => {
  dispatch(uploadEventBegin());
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.EVENTS, data);
    dispatch(uploadEventSuccess(json));
    dispatch(addToastSuccess("Esemény létrehozva"));
    return json;
  } catch (error) {
    dispatch(uploadEventFailure(error));
    dispatch(addToastDanger(`Esemény létrehozása sikertelen: ${error}`));
  }
};

export const updateEvent = (id, data) => async (dispatch) => {
  dispatch(updateEventBegin());
  try {
    const json = await http.put(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.EVENTS}/${id}`,
      data
    );
    dispatch(updateEventSuccess(json));
    dispatch(addToastSuccess("Esemény módosítva"));
    return json;
  } catch (error) {
    dispatch(updateEventFailure(error));
    dispatch(addToastDanger(`Esemény módosítása sikertelen: ${error}`));
  }
};

export const getEvent = (id = false) => async (dispatch) => {
  dispatch(getEventBegin());
  try {
    const json = await http.get(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.EVENTS}/${id ? id : ""}`
    );
    dispatch(getEventSuccess(json));
    return json;
  } catch (error) {
    dispatch(getEventFailure(error));
    dispatch(addToastDanger(`Események betöltése sikertelen: ${error}`));
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  dispatch(deleteEventBegin());
  try {
    const json = await http.del(`${API_BASE_IKK_ADMIN}${API_ENDPOINT.EVENTS}/${id}`);
    dispatch(deleteEventSuccess(id));
    dispatch(addToastSuccess("Esemény törölve"));
    return json;
  } catch (error) {
    dispatch(deleteEventFailure(error));
    dispatch(addToastDanger(`Esemény törlése sikertelen: ${error}`));
  }
};

export const updateEventBegin = () => ({
  type: EVENT_UPDATE_BEGIN,
});

export const updateEventSuccess = (event) => ({
  type: EVENT_UPDATE_SUCCESS,
  payload: { event },
});

export const updateEventFailure = (error) => ({
  type: EVENT_UPDATE_FAILURE,
  payload: { error },
});

export const uploadEventBegin = () => ({
  type: EVENT_ADD_BEGIN,
});

export const uploadEventSuccess = (event) => ({
  type: EVENT_ADD_SUCCESS,
  payload: { event },
});

export const uploadEventFailure = (error) => ({
  type: EVENT_ADD_FAILURE,
  payload: { error },
});

export const getEventBegin = () => ({
  type: EVENT_GET_BEGIN,
});

export const getEventSuccess = (news) => ({
  type: EVENT_GET_SUCCESS,
  payload: news,
});

export const getEventFailure = (error) => ({
  type: EVENT_GET_FAILURE,
  payload: { error },
});

export const deleteEventBegin = () => ({
  type: EVENT_DELETE_BEGIN,
});

export const deleteEventSuccess = (id) => ({
  type: EVENT_DELETE_SUCCESS,
  payload: id,
});

export const deleteEventFailure = (error) => ({
  type: EVENT_DELETE_FAILURE,
  payload: { error },
});
