import { Spinner, Icon, AnchorButton } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { IKK_URL, ROUTES } from "../../../utils/routes";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import { API_BASE_IKK } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import { deletePage, getPages } from "../../../redux/actions/ikk/pages";

const PagesAll = (props) => {
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    props.getPages();
  }, []);

  const handleDeleteDialog = (row) => {
    setItemToDelete(row);
  };

  const handleDelete = async () => {
    await props.deletePage(itemToDelete.id);
    setItemToDelete(null);
    props.getPages();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Cím",
        accessor: "title",
      },
      {
        Header: "Alcím",
        accessor: "subtitle",
      },
      {
        Header: "Slug",
        accessor: "slug",
      },
      {
        Header: "Oldal",
        accessor: "domain",
      },
      {
        Header: "Link",
        Cell: ({ row }) => (
          <AnchorButton
            target="_blank"
            icon="arrow-top-right"
            href={`${IKK_URL}/p/${row.original.slug}`}
          >
            Ugrás
          </AnchorButton>
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.pagesEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes egyedi oldal</h2>

      <DeleteConfirmation
        isOpen={itemToDelete !== null}
        recordName={itemToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setItemToDelete(null)}
      />

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.pages} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { pages, loading } = state.pages;
  return { pages, loading };
};

export default connect(mapStateToProps, { deletePage, getPages })(PagesAll);
