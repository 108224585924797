import { Card, Classes } from "@blueprintjs/core";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import Login from "./components/common/Login";
import { MakeRoutes } from "./components/common/MakeRoutes";
import Toast from "./components/common/Toast";
import { NotificationProvider } from "./context/NotificationContext";
import { routesData } from "./utils/routes";

function App() {
  return (
    <Card className={`App radius0 ${Classes.DARK}`}>
      <NotificationProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />

            {/* protected routes */}
            {routesData.map((route, i) => (
              <MakeRoutes key={i} {...route} />
            ))}
          </Switch>
        </BrowserRouter>

        <Toast />
      </NotificationProvider>
    </Card>
  );
}

export default App;
