import {
  SKILLSCOM_EDITOR_ADD_BEGIN,
  SKILLSCOM_EDITOR_ADD_FAILURE,
  SKILLSCOM_EDITOR_ADD_SUCCESS,
  SKILLSCOM_EDITOR_DELETE_SUCCESS,
  SKILLSCOM_EDITOR_GET_BEGIN,
  SKILLSCOM_EDITOR_GET_FAILURE,
  SKILLSCOM_EDITOR_GET_ONE_SUCCESS,
  SKILLSCOM_EDITOR_GET_SUCCESS,
} from "../../actionTypes";

const initialState = {
  editors: [],
  article: null,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SKILLSCOM_EDITOR_ADD_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SKILLSCOM_EDITOR_ADD_SUCCESS: {
      const editors = state.editors;
      //editor.push(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
        editors: editors,
      };
    }
    case SKILLSCOM_EDITOR_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case SKILLSCOM_EDITOR_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SKILLSCOM_EDITOR_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case SKILLSCOM_EDITOR_GET_SUCCESS: {
      let editors = state.editors;

      if (!Array.isArray(editors)) {
        editors = [];
      }
      editors = editors.concat(action.payload);
      editors = editors.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj["id"]).indexOf(obj["id"]) === pos;
      });

      return {
        ...state,
        loading: false,
        error: null,
        editors: editors, //editor,
      };
    }

    case SKILLSCOM_EDITOR_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        editors: state.editors.filter((n) => n.id !== action.payload.id),
      };
    }

    // SINGLE

    case SKILLSCOM_EDITOR_GET_ONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        article: action.payload,
      };
    }

    default:
      return state;
  }
}
