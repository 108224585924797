import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneNews, updateNews } from "../../../redux/actions/ikk/news";
import { ROUTES } from "../../../utils/routes";
import NewsForm from "./NewsForm";

const NewsEdit = (props) => {
  const { id } = useParams();
  const [editArticle, setEditArticle] = useState();

  useEffect(() => {
    async function getSingleNews() {
      const res = await props.getOneNews(id);
      delete res.cover;
      setEditArticle({ ...res });
    }
    getSingleNews();
  }, []);

  const handleEditSubmit = async (state) => {
    const tagIds = state.tags.map((t) => t.id); // only send IDs
    const res = await props.updateNews(props.article.id, {
      ...state,
      category_id: Number(state.category_id),
    });
    if (res) props.history.push(ROUTES.news);
  };

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      {editArticle && (
        <NewsForm
          initialState={editArticle}
          submitBtnText="Módosít"
          title="Hír szerkesztése"
          onSubmit={handleEditSubmit}
          imageRequired={false}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { loading, article } = state.news;
  return { loading, article };
};
export default connect(mapStateToProps, { getOneNews, updateNews })(NewsEdit);
