import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { getAllGroups } from "../../../redux/actions/ikk/groups";
import { ROUTES } from "../../../utils/routes";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import http from "../../../utils/http";
import { API_BASE_IKK, API_ENDPOINT, API_BASE_IKK_ADMIN } from "../../../utils/routes";

const CategoriesAll = (props) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories()
  }, []);

  const getCategories = async () => {
    const res = await http.get(`${API_BASE_IKK}/admin/categories`);

    setCategories(res);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Kategória nev",
        accessor: "name",
      }, {
        Header: "Csoport",
        accessor: "group_id",
      },
      {
        Header: "Utoljára módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      
    ],
    []
  );

  if (!categories.length) return <Spinner />;

  return (
    <>
      <h2>Összes Hír Kategória</h2>

      <TableAdvanced data={categories} columns={columns} />
    </>
  );
};


export default CategoriesAll;
