import React from "react";
import { connect } from "react-redux";
import { createAboutRow } from "../../../redux/actions/ikk/aboutRow";
import { ROUTES } from "../../../utils/routes";
import AboutRowForm from "./AboutRowForm";

const emptyState = {
  name: "",
  body: "",
  order:1,
};

const AboutRowCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const res = await props.createAboutRow(state);
    // reset the form if submit was successful
    if (res && res.id) {
      // setState(emptyState);
      props.history.push(ROUTES.aboutRows);
    }
  };

  return (
    <AboutRowForm
      title="Új Rolunk szoveg blokk"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
      imageRequired={true}
    />
  );
};

export default connect(null, { createAboutRow })(AboutRowCreate);
