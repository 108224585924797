import {
  Button,
  Divider,
  FormGroup,
  Icon,
  InputGroup,
  NumericInput,
  Position,
  TextArea,
} from "@blueprintjs/core";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";
import "moment/locale/hu";
import React, { useState } from "react";
import MomentLocaleUtils from "react-day-picker/moment";
import { LIMIT } from "../../../utils/limits";
import TagsMultiSelect from "../../common/TagsMultiSelect";

const EventForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  // for event details only
  const handleInput = (e) => {
    const { value, name } = e.target;

    if (name.split(".")[0] === "location") {
      const nn = name.split(".")[1];
      setState((s) => ({ ...s, location: { ...s.location, [nn]: value } }));
      return;
    }

    setState((s) => ({ ...s, [name]: value }));
  };

  const handleCoordinates = (valNum, valStr, inputEl) => {
    console.log("handleCoordinates", valNum, valStr, inputEl.name);
    setState((s) => ({
      ...s,
      location: { ...s.location, [inputEl.name]: valNum },
    }));
  };

  const handleDateInput = (name) => (date, isUserChange) => {
    console.log(name, date, isUserChange);
    setState((s) => ({ ...s, [name]: date?.toISOString() }));
  };

  const handleTagsChange = (newTags) => {
    const newTagIds = newTags.map((t) => t.id);
    setState((s) => ({ ...s, tags: newTagIds }));
  };

  return (
    <div style={{ maxWidth: "700px", marginBottom: 50 }}>
      <h2>{props.title}</h2>

      <form onSubmit={handleSubmit}>
        <h4>
          <Icon icon="map-marker" />
          &nbsp;Helyszín
        </h4>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1.5fr 1fr",
            gridGap: "0 2rem",
          }}
        >
          <FormGroup label="Helyszín neve" labelInfo="(kötelező)">
            <InputGroup
              required
              placeholder="Helyszín neve..."
              onChange={handleInput}
              name="location.name"
              value={state.location.name}
            />
          </FormGroup>

          <FormGroup label="Szélességi koordináta" labelInfo="(kötelező)">
            <NumericInput
              required
              fill
              onValueChange={handleCoordinates}
              name="lat"
              value={state.location.lat}
            />
          </FormGroup>

          <FormGroup label="Maps link" labelInfo="(kötelező)">
            <InputGroup
              required
              type="url"
              placeholder="https://goo.gl..."
              onChange={handleInput}
              name="location.link"
              value={state.location.link}
            />
          </FormGroup>

          <FormGroup label="Hosszúsági koordináta" labelInfo="(kötelező)">
            <NumericInput
              required
              fill
              onValueChange={handleCoordinates}
              name="lng"
              value={state.location.lng}
            />
          </FormGroup>
        </div>

        <Divider />

        <h4>
          <Icon icon="timeline-events" />
          &nbsp;Esemény
        </h4>

        <FormGroup
          label="Esemény neve"
          labelInfo="(kötelező)"
          helperText={`${state.title?.length} / ${LIMIT.EVENT_TITLE_MAX_LEN}`}
          contentClassName="formHelperRight"
        >
          <InputGroup
            intent={
              state.title.length > LIMIT.EVENT_TITLE_MAX_LEN ? "danger" : ""
            }
            onChange={handleInput}
            name="title"
            value={state.title}
            required
            placeholder="Esemény neve..."
          />
        </FormGroup>

        <FormGroup
          label="Tagek"
          helperText="Az itt felvitt összerendelések szerint lesz látható az esemény a szakmakártyák oldalon"
        >
          <TagsMultiSelect
            initialTags={state.tags}
            onChange={handleTagsChange}
          />
        </FormGroup>

        <FormGroup label="Esemény leírása" labelInfo="(kötelező)">
          <TextArea
            onChange={handleInput}
            required
            name="description"
            value={state.description}
            fill
            placeholder="Leírás..."
          />
        </FormGroup>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1.5fr 1fr",
            gridGap: "0 2rem",
          }}
        >
          <FormGroup label="Esemény link" labelInfo="(kötelező)">
            <InputGroup
              type="url"
              placeholder="https://..."
              name="link"
              value={state.link}
              required
              onChange={handleInput}
            />
          </FormGroup>

          <FormGroup label="Kezdés időpont" labelInfo="(kötelező)">
            <DateInput
              fill
              showActionsBar
              todayButtonText="Ma"
              clearButtonText="Töröl"
              locale="hu"
              localeUtils={MomentLocaleUtils}
              timePrecision={TimePrecision.MINUTE}
              formatDate={(date) => date.toLocaleString()}
              parseDate={(str) => new Date(str)}
              placeholder={"ÉÉÉÉ-HH-NN"}
              popoverProps={{ position: Position.BOTTOM }}
              highlightCurrentDay
              onChange={handleDateInput("from")}
              value={state.from && new Date(state.from)}
              inputProps={{ leftIcon: "calendar", required: true }}
            />
          </FormGroup>

          <FormGroup label="Publikálás ideje" labelInfo="(kötelező)">
            <DateInput
              fill
              showActionsBar
              todayButtonText="Ma"
              clearButtonText="Töröl"
              locale="hu"
              localeUtils={MomentLocaleUtils}
              timePrecision={TimePrecision.MINUTE}
              formatDate={(date) => date.toLocaleString()}
              parseDate={(str) => new Date(str)}
              placeholder={"ÉÉÉÉ-HH-NN"}
              popoverProps={{ position: Position.BOTTOM }}
              highlightCurrentDay
              value={state.published_at && new Date(state.published_at)}
              inputProps={{ leftIcon: "calendar", required: true }}
              onChange={handleDateInput("published_at")}
            />
          </FormGroup>

          <FormGroup label="Vége időpont" labelInfo="(kötelező)">
            <DateInput
              fill
              showActionsBar
              todayButtonText="Ma"
              clearButtonText="Töröl"
              locale="hu"
              localeUtils={MomentLocaleUtils}
              timePrecision={TimePrecision.MINUTE}
              formatDate={(date) => date.toLocaleString()}
              parseDate={(str) => new Date(str)}
              placeholder={"ÉÉÉÉ-HH-NN"}
              popoverProps={{ position: Position.BOTTOM }}
              highlightCurrentDay
              inputProps={{ leftIcon: "calendar", required: true }}
              value={state.till && new Date(state.till)}
              onChange={handleDateInput("till")}
            />
          </FormGroup>
        </div>

        <FormGroup label="Megjegyzés">
          <InputGroup
            name="comment"
            value={state.comment}
            onChange={handleInput}
            placeholder="Rövid megjegyzés..."
          />
        </FormGroup>

        <Button
          text={props.submitBtnText}
          icon="floppy-disk"
          intent="success"
          type="submit"
        />
      </form>
    </div>
  );
};

export default EventForm;
