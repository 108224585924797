// export const login = ({ username, password, onError }) => {
//   // todo api request

import user from "../redux/reducers/auth/user";

//   http.post("/login", { username, password }).then((res) => {
//     if (res.success === false) {
//       onError();
//     } else {
//       sessionStorage.setItem("token", res.token);
//       window.location.replace("/");
//     }
//   });
// };
import { Mutex } from "async-mutex";
import JWT from "jsonwebtoken";
import http from "./http";
import { API_AUTH_PROVIDER } from "./routes";

export const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  window.location.replace("/login");
};

export const isAuthenticated = () => {
  const tokenSession = sessionStorage.getItem("token");
  const tokenLocal = localStorage.getItem("token");
  const isAuth =
    (tokenSession && tokenSession !== "undefined") ||
    (tokenLocal && tokenLocal !== "undefined");

  return isAuth;
};
export const getUser = () => {
  try {
    const token = getRawToken();
    const data = JSON.parse(atob(token.split(".")[1]));
    if (data.hasOwnProperty("user")) return data.user;
  } catch (e) {
    return null;
  }
};
export const isAuthorized = (namespaceRaw, permissionRaw) => {
  let namespace = undefined;
  let permission = undefined;
  if (typeof namespaceRaw === "object") {
    namespace = namespaceRaw.authNamespace;
    permission = namespaceRaw.authPermission;
  } else {
    namespace = namespaceRaw;
    permission = permissionRaw;
  }
  if (isAuthenticated()) {
    if (!namespace) return true;
    try {
      const token = getRawToken();
      const data = JSON.parse(atob(token.split(".")[1]));
      if (data.user.roles.hasOwnProperty(namespace)) {
        if (!permission) return true;
        return data.user.roles[namespace].includes(permission);
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

/**
 * Checks if user is authorized to view pages under IKK menu by counting the number
 * of roles in the `main` namespace excluding the `tags:read` and `media:write`
 * roles that are required universally.
 *
 * @returns {boolean} true if authorized
 */
export const isAuthorizedForIKK = () => {
  const token = getRawToken();
  try {
    const data = JSON.parse(atob(token.split(".")[1]));
    const mainRoles = data.user.roles.main;
    if (!mainRoles) return false;
    const otherRoles = mainRoles.filter((r) => !["tags:read", "media:write"].includes(r));
    if (otherRoles.length > 0) return true;
  } catch (error) {
    console.error(error);
  }
  return false;
};

const getRawToken = () => {
  return sessionStorage.getItem("token") || localStorage.getItem("token");
};
const getRawRefreshToken = () => {
  return sessionStorage.getItem("refreshToken") || localStorage.getItem("refreshToken");
};

const mutex = new Mutex();
export const getToken = async () => {
  const release = await mutex.acquire();
  try {
    let token = getRawToken();
    const decoded = JWT.decode(token);
    if (decoded && Date.now() <= decoded.exp * 1000) {
      release();
      return token;
    }
    const refreshToken = getRawRefreshToken();

    if (!refreshToken) {
      release();
      return undefined;
    }

    const newToken = await (
      await fetch(`${API_AUTH_PROVIDER}/auth/refresh`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ refreshToken: getRawRefreshToken() }),
      })
    ).json();
    if (!newToken.success) {
      release();
      return undefined;
    }
    localStorage.setItem("token", newToken.data.token);
    localStorage.setItem("refreshToken", newToken.data.refreshToken);
    sessionStorage.setItem("token", newToken.data.token);
    sessionStorage.setItem("refreshToken", newToken.data.refreshToken);
    release();

    return newToken.data.token;
  } catch (e) {
    release();
    return undefined;
  }
};

/**
 * Check if the current logged in user is IKK based on the email address in the JWT token
 * @returns true if IKK
 */
export const isIKK = () => {
  const token = getRawToken();
  try {
    const data = JSON.parse(atob(token.split(".")[1]));
    if (data.user.email === "info@ikk.hu") return true;
  } catch (error) {
    console.error("Error checking email in token:", error);
  }
  return false;
};
