import React from "react";
import http from "../../../utils/http";
import { API_BASE_QUALIFCARD, ROUTES } from "../../../utils/routes";
import PkCardForm from "./PkCardForm";

const emptyState = {
  description: "",
  healthRequirements: "",
  medias: [],
  maxLength: null,
  minLength: null,
  id: null,
  schoolRequirements: "",
  shortDescription: "",
  skillRequirements: "",
  slug: "",
  traineeDescription: "",
};

const PkCardCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    try {
      await http.post(`${API_BASE_QUALIFCARD}/cms/qualifications`, state);
      // reset the form if submit was successful
      setState(emptyState);
      props.history.push(ROUTES.pkCards);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PkCardForm
      title="Új PK kártya"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
      imageRequired={true}
    />
  );
};

export default PkCardCreate;
