import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateExamCenter,
  getOneExamCenter,
} from "../../../redux/actions/ikk/examCenters";
import { ROUTES } from "../../../utils/routes";
import ExamCenterForm from "./ExamCenterForm";

const ExamCenterEdit = (props) => {
  const { id } = useParams();
  const [center, setCenter] = useState();

  useEffect(() => {
    async function getData() {
      const res = await props.getOneExamCenter(id);
      setCenter({ ...res });
    }
    if (id) getData(id);
  }, [id]);

  const handleEditSubmit = async (formstate, setFormstate) => {
    const body = { ...formstate };
    const res = await props.updateExamCenter(id, body);
    console.log(res);
    if (res) props.history.push(ROUTES.examCenter);
  };

  if (!center) return <Spinner />;

  return (
    <ExamCenterForm
      initialState={center}
      submitBtnText="Módosít"
      title="Akkreditált vizsgaközpont szerkesztése"
      handleSubmit={handleEditSubmit}
    />
  );
};

export default connect(null, { updateExamCenter, getOneExamCenter })(
  ExamCenterEdit
);
