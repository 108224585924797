import {
  Button,
  Classes,
  Dialog,
  HTMLSelect,
  Spinner,
  TextArea,
} from "@blueprintjs/core";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAboutRows, deleteAboutRow } from "../../../redux/actions/ikk/aboutRow";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import truncate from "html-truncate";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import { generatePath } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";

const AllAboutRow = (props) => {
  // const [texts, setTexts] = useState([]); // all static text
  const [rowEditing, setRowEditing] = useState(null); // selected row to edit
  const [rowDelete, setRowDelete] = useState();

  useEffect(() => {
    props.getAboutRows();
  }, []);

  const handleDelete = (row) => {
    setRowDelete(row);
  };

  const handleDeleteSubmit = async () => {
    await props.deleteAboutRow(rowDelete.id);
    setRowDelete(null);
    props.getAboutRows();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
        {
            Header: "Sorrend",
            accessor: "order",
        },
      {
        Header: "Cim",
        accessor: "name",
      },
      {
        Header: "Szöveg",
        accessor: "body",
        Cell: ({ value }) => (
          <div
            style={{ maxWidth: "700px" }}
            dangerouslySetInnerHTML={{ __html: truncate(value, 300) }}
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.aboutRowsEdit, { id: row.original.id })}
            onDelBtnClick={() => handleDelete(row.original)}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes rolunk szöveg blokk</h2>

      {/* <Dialog
        onClose={() => setRowEditing(null)}
        transitionName={rowEditing?.type === "HTML" ? "" : undefined} // ckeditor's toolbar is collapsed if transition is not disabled
        style={{ width: "600px" }}
        title="Szöveg szerkesztése"
        isOpen={rowEditing !== null}
        usePortal={false}
        autoFocus={false}
        enforceFocus={false} //otherwise links are not editable
      >
        <div className={Classes.DIALOG_BODY}>
          {rowEditing?.type === "TEXT" ? (
            <TextArea
              fill
              growVertically={true}
              style={{ resize: "none" }}
              defaultValue={rowEditing?.value}
              onChange={(e) => {
                const { value } = e.target;
                console.log(value);
                setRowEditing((r) => ({ ...r, value: value }));
              }}
            />
          ) : (
            <CKEditor
              editor={ClassicEditor}
              data={rowEditing?.value}
              config={{
                removePlugins: ["ImageUpload", "Image", "MediaEmbed", "Table"],
                link: {
                  decorators: {
                    isExternal: {
                      mode: "manual",
                      label: "Új oldalon nyílik meg",
                      attributes: {
                        target: "_blank",
                      },
                    },
                  },
                },
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setRowEditing((r) => ({ ...r, value: data }));
                console.log({ event, editor, data });
              }}
            />
          )}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setRowEditing(null)}>Mégse</Button>

            <Button icon="floppy-disk" intent="success" onClick={handleUpdate}>
              Mentés
            </Button>
          </div>
        </div>
      </Dialog> */}

      <DeleteConfirmation
        isOpen={!!rowDelete}
        onCancel={() => setRowDelete(null)}
        onDelete={handleDeleteSubmit}
        recordName={`Rolunk szoveg blokk: ${rowDelete?.name}`}
      />

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.rows} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.aboutRows };
};

export default connect(mapStateToProps, { getAboutRows, deleteAboutRow })(AllAboutRow);
