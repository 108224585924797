import {
  CONST_DATA_IMAGES_GET_BEGIN,
  CONST_DATA_IMAGES_GET_FAILURE,
  CONST_DATA_IMAGES_GET_SUCCESS,
  CONST_DATA_TEXTS_GET_BEGIN,
  CONST_DATA_TEXTS_GET_FAILURE,
  CONST_DATA_TEXTS_GET_SUCCESS,
} from "../actionTypes";

const initialState = {
  texts: [],
  images: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CONST_DATA_TEXTS_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case CONST_DATA_TEXTS_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        texts: action.payload.filter((item) =>
          ["TEXT", "HTML"].includes(item.type)
        ),
      };
    }

    case CONST_DATA_TEXTS_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case CONST_DATA_IMAGES_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case CONST_DATA_IMAGES_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        images: action.payload.filter((item) => item.type === "FILE"),
      };
    }

    case CONST_DATA_IMAGES_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
}
