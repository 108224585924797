import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { uploadMedia } from "../../../redux/actions/common/media";
import { getOneSlide, updateSlide } from "../../../redux/actions/ikk/carousel";
import { ROUTES } from "../../../utils/routes";
import SlideForm from "./SlideForm";

const SlideEdit = (props) => {
  const [slide, setSlide] = useState();

  const { id } = useParams();

  useEffect(() => {
    async function getSlide() {
      const s = await props.getOneSlide(id);
      setSlide(s);
    }
    getSlide();
  }, []);

  const handleEditSubmit = async (state) => {
    let tmp_state={...state};
    delete tmp_state.cover;
    const res = await props.updateSlide(slide.id,tmp_state );
    if (res) props.history.push(ROUTES.slides);
  };

  return (
    <>
      {slide && (
        <SlideForm
          initialState={slide}
          submitBtnText="Módosít"
          title="Slide szerkesztése"
          handleSubmit={handleEditSubmit}
          imageRequired={false}
        />
      )}
    </>
  );
};

export default connect(null, { uploadMedia, updateSlide, getOneSlide })(
  SlideEdit
);
