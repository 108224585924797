import { Button, FormGroup, InputGroup, NumericInput } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { slugify } from "../../../utils/helpers";
import { API_BASE_IKK } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import MyCkEditor from "../../common/MyCkEditor";
import { SuggestKeorQualif } from "../../examcenter/exams/SuggestKeorQualif";

const PkCardForm = (props) => {
  const [exam, setExam] = useState(props.initialState);

  useEffect(() => {}, []);

  const handleInput = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setExam((s) => ({ ...s, [name]: val }));
  };

  const handleNumberInput = (valueAsNumber, valueAsString, element) => {
    setExam((s) => ({ ...s, [element.name]: valueAsNumber }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(exam, setExam);
  };

  const onQualifSelect = (q) => {
    const slug = slugify(q.name);
    handleInput({ target: { name: "slug", value: slug } });
    handleInput({ target: { name: "id", value: q.id } });
  };

  const handleFileUpload = (type) => (media) => {
    const newMed = [{ mediaId: media.id, type }];
    handleInput({ target: { name: "medias", value: newMed } });
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 800, marginBottom: 100 }}>
      <h2>{props.title}</h2>

      <FormGroup label="Szakképesítés" labelInfo="(kötelező)">
        <SuggestKeorQualif selectedItem={exam.id} onSelect={onQualifSelect} />
      </FormGroup>

      <FormGroup label="Slug" labelInfo="(automatikus)">
        <InputGroup name="slug" value={exam.slug} onChange={handleInput} />
      </FormGroup>

      <FormGroup label="Minimális óraszám" labelInfo="(kötelező)">
        <NumericInput
          required
          name="minLength"
          value={exam.minLength}
          onValueChange={handleNumberInput}
        />
      </FormGroup>

      <FormGroup label="Maximális óraszám" labelInfo="(kötelező)">
        <NumericInput
          required
          name="maxLength"
          min={0}
          value={exam.maxLength}
          onValueChange={handleNumberInput}
        />
      </FormGroup>

      <FormGroup label="Rövid leírás" labelInfo="(kötelező)">
        <MyCkEditor
          data={exam.shortDescription}
          onDataChange={(d) => setExam((e) => ({ ...e, shortDescription: d }))}
        />
      </FormGroup>

      <FormGroup label="Általános leírás" labelInfo="(kötelező)">
        <MyCkEditor
          data={exam.description}
          onDataChange={(d) => setExam((e) => ({ ...e, description: d }))}
        />
      </FormGroup>

      <FormGroup label="Egészségügyi alkalmassági követelmény" labelInfo="">
        <MyCkEditor
          data={exam.healthRequirements}
          onDataChange={(d) =>
            setExam((e) => ({ ...e, healthRequirements: d }))
          }
        />
      </FormGroup>
      <FormGroup label="Iskolai előképzettség" labelInfo="">
        <MyCkEditor
          data={exam.schoolRequirements}
          onDataChange={(d) =>
            setExam((e) => ({ ...e, schoolRequirements: d }))
          }
        />
      </FormGroup>

      <FormGroup label="Szakmai gyakorlat" labelInfo="">
        <MyCkEditor
          data={exam.traineeDescription}
          onDataChange={(d) =>
            setExam((e) => ({ ...e, traineeDescription: d }))
          }
        />
      </FormGroup>
      <FormGroup label="skillRequirements" labelInfo="">
        <MyCkEditor
          data={exam.skillRequirements}
          onDataChange={(d) => setExam((e) => ({ ...e, skillRequirements: d }))}
        />
      </FormGroup>

      <FormGroup label="Borító kép" labelInfo="(kötelező)">
        <FileUpload onUpload={handleFileUpload("COVER")} />
      </FormGroup>
      {exam.medias?.length > 0 ? (
        <div>
          <img
            src={`${API_BASE_IKK}/media/${exam.medias[0].mediaId}/xs/webp`}
          />
        </div>
      ) : (
        <p>Nincs kép feltöltve.</p>
      )}

      <Button
        style={{ marginTop: 20 }}
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

export default PkCardForm;
