import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deleteEditor, getEditor } from "../../../redux/actions/skillscom/editor";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const EditorAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);

  useEffect(() => {
    props.getEditor();
  }, []);

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = async () => {
    setRowToDelete(null);
    await props.deleteEditor(rowToDelete.id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Név",
        accessor: "name",
      },
      { Header: "Email", accessor: "email" },
      {
        Header: "Típus",
        accessor: "type",
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons row={row} onDelBtnClick={handleDeleteDialog} />
        ),
      },
    ],
    []
  );

  if (!props.editors || props.loading) return <Spinner />;

  return (
    <>
      <h2>Összes szerkesztő (tanár)</h2>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.name}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      <TableAdvanced columns={columns} data={props.editors} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { editors, loading } = state.skillsComEditors;
  console.log(state.skillsComEditors);
  return { editors, loading };
};
export default connect(mapStateToProps, {
  getEditor,
  deleteEditor,
})(EditorAll);
