import { Button, FormGroup, InputGroup, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  createProfCardQualifData,
  editProfCardQualifData,
  getProfQualifCardData,
} from "../../../redux/actions/szakkepzes/profCards";
import { LIMIT } from "../../../utils/limits";
import { API_BASE_IKK, ROUTES } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import MyCkEditor from "../../common/MyCkEditor";

// empty qualifdata state for POST
const initialState = {
  lead: "",
  body: "",
  requirements: "",
  skills: [],
  specializations: [],
  medias: [],
};

const ProfessionCardsQualifEdit = (props) => {
  const [qualif, setQualif] = useState(); // original qualification as returned from API
  const [qualifData, setQualifData] = useState({ ...initialState }); // new qualification cardData that will be sent to server
  const [loaded, setLoaded] = useState(false); // needed for ckeditor to render initial state properly
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    getQualifData();
  }, []);

  const getQualifData = async () => {
    const res = await props.getProfQualifCardData(id);
    setQualif(res);
    if (res.cardData) setQualifData(res.cardData);
    setLoaded(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...qualifData,
      body: qualifData.body.replaceAll("&nbsp;", " "), // to prevent bugs caused by non-breaking
      professionId: Number(id),
    };
    console.log("sending data:", data);
    if (!qualif.cardData) {
      // cardData not set, create new
      const res = await props.createProfCardQualifData(data);
    } else {
      // edit existing cardData
      const res = await props.editProfCardQualifData(id, data);
    }
    history.push(ROUTES.szakmakartyakQualifications);
  };

  const handleInput = (key, val) => {
    setQualifData((d) => ({ ...d, [key]: val }));
  };

  // add new item to skills/specializations array
  const handleNewItem = (key) => {
    setQualifData((d) => ({
      ...d,
      [key]: !d ? [""] : [...d[key], ""], // adding a new (empty string) item to the list
    }));
  };

  // remove item from skills/specializations array
  const removeItem = (key, idx) => (e) => {
    const newItems = [...qualifData[key]];
    newItems.splice(idx, 1);
    setQualifData((d) => ({
      ...d,
      [key]: newItems,
    }));
  };

  // skills/specializations changed string value
  const handleArrayItemChange = (key, idx) => (e) => {
    const newItems = qualifData[key] || [];
    newItems[idx] = e.target.value;
    setQualifData((q) => ({
      ...q,
      [key]: newItems,
    }));
  };

  const handleFileUpload = (type) => (media) => {
    const newMed = { mediaId: media.id, type };
    const found = qualifData.medias.find((m) => m.type === type);
    const newMedias = found
      ? qualifData.medias.map((m) => (m.type === type ? newMed : m)) // replacing existing media
      : [...qualifData.medias, newMed]; // adding new media item
    setQualifData((d) => ({ ...d, medias: newMedias }));
  };

  const mediaCover = qualifData.medias.find((m) => m.type === "COVER");
  const mediaImg1 = qualifData.medias.find((m) => m.type === "IMG1");
  const mediaImg2 = qualifData.medias.find((m) => m.type === "IMG2");
  const mediaImg3 = qualifData.medias.find((m) => m.type === "IMG3");

  if (!qualif || !loaded) return <Spinner intent="primary" />;

  return (
    <>
      <h1>Szakmakártya szakma szerkesztése: {qualif.name}</h1>

      <form onSubmit={handleSubmit}>
        <FormGroup
          label="Kiemelt szöveg"
          helperText={`${qualif.cardData?.lead?.length || 0} / ${
            LIMIT.PROFCARD_QUALIF_LEAD_MAX_LEN
          }`}
          contentClassName="formHelperRight"
        >
          <InputGroup
            value={qualifData?.lead}
            required
            onChange={(e) => handleInput("lead", e.target.value)}
          />
        </FormGroup>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <FormGroup
            style={{ marginRight: 30 }}
            label="Szövegtörzs"
            helperText={`${qualifData?.body?.length || 0} / ${
              LIMIT.PROFCARD_QUALIF_BODY_MAX_LEN
            }`}
            contentClassName="formHelperRight"
          >
            <MyCkEditor
              data={qualifData?.body}
              onDataChange={(data) => handleInput("body", data)}
            />
          </FormGroup>

          <FormGroup
            label="Kompetenciaelvárás"
            helperText={`${qualifData?.requirements?.length || 0} / ${
              LIMIT.PROFCARD_QUALIF_COMP_MAX_LEN
            }`}
            contentClassName="formHelperRight"
          >
            <MyCkEditor
              data={qualifData?.requirements}
              onDataChange={(data) => handleInput("requirements", data)}
            />
          </FormGroup>
        </div>

        <FormGroup label="A szakképzettséggel rendelkező">
          <ul style={{ margin: 0 }}>
            {qualifData?.skills?.map((skill, i) => (
              <div key={i} style={{ display: "flex" }}>
                <li style={{ flex: 1 }}>
                  <InputGroup
                    onChange={handleArrayItemChange("skills", i)}
                    required
                    value={skill}
                    style={{ marginBottom: 5 }}
                  />
                </li>
                <Button
                  icon="trash"
                  minimal
                  intent="danger"
                  onClick={removeItem("skills", i)}
                />
              </div>
            ))}
            <Button
              intent="primary"
              icon="plus"
              minimal
              onClick={() => handleNewItem("skills")}
            />
          </ul>
        </FormGroup>

        <FormGroup label="Választható szakmairányok">
          <ul style={{ margin: 0 }}>
            {qualifData?.specializations?.map((spec, i) => (
              <div key={i} style={{ display: "flex" }}>
                <li style={{ flex: 1 }}>
                  <InputGroup
                    onChange={handleArrayItemChange("specializations", i)}
                    required
                    value={spec}
                    style={{ marginBottom: 5 }}
                  />
                </li>
                <Button
                  icon="trash"
                  intent="danger"
                  minimal
                  onClick={removeItem("specializations", i)}
                />
              </div>
            ))}
            <Button
              intent="primary"
              icon="plus"
              minimal
              onClick={() => handleNewItem("specializations")}
            />
          </ul>
        </FormGroup>

        <FormGroup label="Borító kép ">
          <FileUpload onUpload={handleFileUpload("COVER")} />
        </FormGroup>
        <FormGroup>
          {!!mediaCover ? (
            <img src={`${API_BASE_IKK}/media/${mediaCover.mediaId}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        <FormGroup label="Kép 1 ">
          <FileUpload onUpload={handleFileUpload("IMG1")} />
        </FormGroup>
        <FormGroup>
          {!!mediaImg1 ? (
            <img src={`${API_BASE_IKK}/media/${mediaImg1.mediaId}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        <FormGroup label="Kép 2 ">
          <FileUpload onUpload={handleFileUpload("IMG2")} />
        </FormGroup>
        <FormGroup>
          {!!mediaImg2 ? (
            <img src={`${API_BASE_IKK}/media/${mediaImg2.mediaId}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        <FormGroup label="Kép 3">
          <FileUpload onUpload={handleFileUpload("IMG3")} />
        </FormGroup>
        <FormGroup>
          {!!mediaImg3 ? (
            <img src={`${API_BASE_IKK}/media/${mediaImg3.mediaId}`} height="100" />
          ) : (
            <p>Nincs kép felöltve</p>
          )}
        </FormGroup>

        <FormGroup label="Video" labelInfo="(opcionális)">
          <FileUpload onUpload={handleFileUpload("VIDEO")} />
        </FormGroup>

        <br />

        <Button intent="success" type="submit" icon="floppy-disk">
          Mentés
        </Button>
      </form>
      <br />
      <br />
    </>
  );
};

export default connect(null, {
  getProfQualifCardData,
  createProfCardQualifData,
  editProfCardQualifData,
})(ProfessionCardsQualifEdit);
