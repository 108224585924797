import { Spinner } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getOnePost, updatePost } from "../../../redux/actions/skillscom/post";
import { ROUTES } from "../../../utils/routes";
import PostsForm from "./PostsForm";

const PostsEdit = (props) => {
  const { id } = useParams();

  useEffect(() => {
    if (id) props.getOnePost(id);
  }, [id]);

  const handleEditSubmit = async (state) => {
    const realTags = [];
    if (state.hasOwnProperty("tags")) {
      for (const tag of state.tags) {
        if (Number.isInteger(tag)) {
          realTags.push(tag);
        } else if (tag?.hasOwnProperty("id")) {
          realTags.push(tag.id);
        }
      }
    }
    const res = await props.updatePost(props.post.id, {
      ...state,
      tags: realTags,
    });
    if (res) props.history.push(ROUTES.skillsComPosts);
  };

  if (props.loading) return <Spinner intent="primary" />;
  if (!props.post) return null;

  // map relatedNews to an id
  const formstate = { ...props.post, relatedNewsId: props.post.relatedNews?.id ?? null };

  return (
    <PostsForm
      initialState={formstate}
      submitBtnText="Módosít"
      title="Poszt szerkesztése"
      onSubmit={handleEditSubmit}
      imageRequired={false}
    />
  );
};

const mapStateToProps = (state) => {
  const { loading, post } = state.skillsComPosts;
  return { loading, post };
};
export default connect(mapStateToProps, { getOnePost, updatePost })(PostsEdit);
