import http from "../../../utils/http";
import { API_BASE_SZAKKEPZES, API_ENDPOINT } from "../../../utils/routes";
import {
  SZAKKEPZES_GET_KKK_BEGIN,
  SZAKKEPZES_GET_KKK_FAILURE,
  SZAKKEPZES_GET_KKK_SUCCESS,
  SZAKKEPZES_GET_SINGLE_KKK,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getAllKKK = () => async (dispatch) => {
  dispatch(getAllKKKBegin);
  try {
    const json = await http.get(
      `${API_BASE_SZAKKEPZES + API_ENDPOINT.KKK}/admin`
    );
    dispatch(getAllKKKSuccess(json));
    return json;
  } catch (error) {
    dispatch(getAllKKKFailure(error));
    dispatch(addToastDanger(`Dokumentumok lekérése sikertelen: ${error}`));
  }
};

export const getSingleKKK = (professionId) => (dispatch) => {
  dispatch({
    type: SZAKKEPZES_GET_SINGLE_KKK,
    payload: professionId,
  });
};

export const editKKK = (attachmentId, body) => async (dispatch) => {
  // todo reducers, id
  try {
    const json = await http.put(
      `${API_BASE_SZAKKEPZES + API_ENDPOINT.KKK}/${attachmentId}`,
      body
    );
    dispatch(addToastSuccess("Dokumentum módosítva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Dokumentum módosítás sikertelen: ${error}`));
  }
};

export const createKKK = (body) => async (dispatch) => {
  // todo reducers, id
  try {
    const json = await http.post(API_BASE_SZAKKEPZES + API_ENDPOINT.KKK, body);
    dispatch(addToastSuccess("Dokumentum létrehozva"));
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Dokumentum létrehozás sikertelen: ${error}`));
  }
};

export const deleteKKK = (attachmentId) => async (dispatch) => {
  // todo reducers, id
  try {
    const json = http.del(
      `${API_BASE_SZAKKEPZES + API_ENDPOINT.KKK}/${attachmentId}`
    );
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Dokumentum törlése sikertelen: ${error}`));
  }
};

const getAllKKKBegin = () => ({
  type: SZAKKEPZES_GET_KKK_BEGIN,
});
const getAllKKKSuccess = (kkks) => ({
  type: SZAKKEPZES_GET_KKK_SUCCESS,
  payload: kkks,
});
const getAllKKKFailure = (error) => ({
  type: SZAKKEPZES_GET_KKK_FAILURE,
  payload: { error },
});
