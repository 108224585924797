import {
  SKILLSCOM_INSTITUTE_ADD_BEGIN,
  SKILLSCOM_INSTITUTE_ADD_FAILURE,
  SKILLSCOM_INSTITUTE_ADD_SUCCESS,
  SKILLSCOM_INSTITUTE_DELETE_BEGIN,
  SKILLSCOM_INSTITUTE_DELETE_SUCCESS,
  SKILLSCOM_INSTITUTE_GET_BEGIN,
  SKILLSCOM_INSTITUTE_GET_FAILURE,
  SKILLSCOM_INSTITUTE_GET_SUCCESS,
  SKILLSCOM_INSTITUTE_VERIFY_SUCCESS,
} from "../../actionTypes";

const initialState = {
  institutes: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SKILLSCOM_INSTITUTE_ADD_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SKILLSCOM_INSTITUTE_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        institutes: state.institutes,
      };
    }
    case SKILLSCOM_INSTITUTE_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case SKILLSCOM_INSTITUTE_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SKILLSCOM_INSTITUTE_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case SKILLSCOM_INSTITUTE_GET_SUCCESS: {
      let institutes = state.institutes;

      if (!Array.isArray(institutes)) {
        institutes = [];
      }
      institutes = institutes.concat(action.payload);
      institutes = institutes.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj["id"]).indexOf(obj["id"]) === pos;
      }); // unique

      return {
        ...state,
        loading: false,
        error: null,
        institutes: institutes,
      };
    }

    case SKILLSCOM_INSTITUTE_VERIFY_SUCCESS: {
      const idx = state.institutes.findIndex((c) => c.id === action.payload);
      const inst = {
        ...state.institutes[idx],
        verified: new Date().toISOString(),
      };
      const newInstitutes = [...state.institutes];
      newInstitutes.splice(idx, 1, inst);
      return {
        ...state,
        institutes: newInstitutes,
      };
    }

    case SKILLSCOM_INSTITUTE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SKILLSCOM_INSTITUTE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        institutes: state.institutes.filter((e) => e.id !== action.payload),
      };
    default:
      return state;
  }
}
