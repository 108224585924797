import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import http from "../../../utils/http";
import { API_BASE_CEAM, ROUTES } from "../../../utils/routes";
import InstituteForm from "./InstituteForm";

const InstituteEdit = (props) => {
  const [institute, setInstitute] = useState();

  const { id } = useParams();

  useEffect(() => {
    getExam();
  }, []);

  const getExam = async () => {
    const data = await http.get(`${API_BASE_CEAM}/api/intezmeny/${id}/full`);
    setInstitute(data);
  };

  const handleEditSubmit = async (state) => {
    const data = await http.put(`${API_BASE_CEAM}/api/intezmeny/${id}`, state);
    if (data) props.history.push(ROUTES.edirInstituteAll);
  };

  if (!institute) return <Spinner />;

  return (
    <InstituteForm
      isEditing
      initialState={institute}
      submitBtnText="Módosít"
      title="EDIR Intézmény szerkesztése"
      handleSubmit={handleEditSubmit}
      imageRequired={false}
    />
  );
};

export default InstituteEdit;
