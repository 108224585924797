import { Card, H4 } from "@blueprintjs/core";
import React from "react";
import { IKK_URL } from "../../../utils/routes";
import { LayoutDefault } from "../../LayoutDefault";
import MenuIkk from "../MenuIkk/MenuIkk";

const styleWrap = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "auto 1fr",
  marginTop: "20px",
};

export const LayoutIkk = ({ children }) => {
  return (
    <LayoutDefault>
      <div style={{ ...styleWrap }}>
        <div>
          <MenuIkk />
        </div>

        <Card
          elevation={2}
          style={{ height: "calc(100vh - 90px)", overflow: "auto" }}
        >
          <H4>
            <a href={IKK_URL} target="_blank">
              IKK
            </a>
          </H4>
          <div>{children}</div>
        </Card>
      </div>
    </LayoutDefault>
  );
};
