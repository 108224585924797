import { AnchorButton, Button, Spinner } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getCompanies,
  verifyCompany,
} from "../../../redux/actions/skillscom/companies";
import { SKILLSCOM_URL } from "../../../utils/routes";
import { TableAdvanced } from "../../common/TableAdvanced";

const CompanyAll = (props) => {
  useEffect(() => {
    getAllCompaniesRecursively(0, 100);
  }, []);

  const getAllCompaniesRecursively = async (offset, limit) => {
    const j = await props.getCompanies(null, { offset, limit });
    if (Array.isArray(j) && j.length === limit) {
      getAllCompaniesRecursively(offset + limit, limit);
    }
  };

  const handleVerify = (row) => () => {
    const { name, id } = row.original;
    const msg = `Biztosan elfogadja az alábbi regisztrációt?\n\n${name}`;
    if (window.confirm(msg)) {
      props.verifyCompany(id);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Cég név",
        accessor: "name",
      },

      {
        Header: "Adószám",
        accessor: "vatNumber",
      },
      {
        Header: "Telefon",
        accessor: "phone",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Elfogadva",
        accessor: "verified",
        Cell: ({ row, value }) => {
          if (value === null) {
            return <Button onClick={handleVerify(row)}>Elfogadom</Button>;
          } else return <p>{new Date(value).toLocaleString()}</p>;
        },
      },
      {
        Header: "Web",
        Cell: ({ row }) => (
          <AnchorButton
            small
            minimal
            icon="document-open"
            text="Profil"
            target="_blank"
            href={`${SKILLSCOM_URL}/cegek/${row.original.slug}`}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes regisztrált cég</h2>

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.companies} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { companies, loading } = state.skillsComCompanies;
  return { companies, loading };
};
export default connect(mapStateToProps, {
  getCompanies,
  verifyCompany,
})(CompanyAll);
