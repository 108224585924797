import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import http from "../../../utils/http";
import {
  API_BASE_IKK,
  API_BASE_IKK_ADMIN,
  API_ENDPOINT,
  ROUTES,
} from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const CareerAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    getCareers();
  }, []);

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = async () => {
    const res = await http.del(
      `${API_BASE_IKK_ADMIN + API_ENDPOINT.CAREERS}/${rowToDelete.id}`
    );
    setRowToDelete(null);
    getCareers();
  };

  const getCareers = async () => {
    const res = await fetch(`${API_BASE_IKK}/const_data/carriers`);
    const data = await res.json();
    setCareers(data);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Cím",
        accessor: "title",
      },
      {
        Header: "Sorrend",
        accessor: "order",
      },
      {
        Header: "Létrehozva",
        accessor: "created_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.careerEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2>Összes álláshirdetés</h2>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      <TableAdvanced columns={columns} data={careers} />
    </>
  );
};

export default CareerAll;
