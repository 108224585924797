import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import {
  MEDIA_ADD_BEGIN,
  MEDIA_ADD_FAILURE,
  MEDIA_ADD_SUCCESS,
  MEDIA_GET_ALL_FILE_BEGIN,
  MEDIA_GET_ALL_FILE_FAILURE,
  MEDIA_GET_ALL_FILE_SUCCESS,
  MEDIA_GET_ALL_IMAGE_BEGIN,
  MEDIA_GET_ALL_IMAGE_FAILURE,
  MEDIA_GET_ALL_IMAGE_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "./toast";

export const uploadMedia = (file, name="") => async (dispatch) => {
  dispatch(uploadMediaBegin());
  try {
    var form = new FormData();
    form.append("file", file);
    form.append("name", name);
    const json = await http.uploadFile(API_BASE_IKK_ADMIN + API_ENDPOINT.UPLOAD, form);
    dispatch(uploadMediaSuccess(json));
    dispatch(addToastSuccess("Fájl feltöltés sikeres"));
    return json;
  } catch (error) {
    dispatch(uploadMediaFailure(error));
    dispatch(addToastDanger(`Fájl feltöltés sikertelen: ${error}`));
  }
};

export const getAllImage = () => async (dispatch) => {
  dispatch(getAllImageBegin());
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.UPLOAD);
    dispatch(getAllImageSuccess(json));
  } catch (error) {
    dispatch(getAllImageFailure(error));
    dispatch(addToastDanger(`Fájlok betöltése sikertelen: ${error}`));
  }
};

export const getAllFile = () => async (dispatch) => {
  dispatch(getAllFileBegin());
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.UPLOAD);
    dispatch(getAllFileSuccess(json));
  } catch (error) {
    dispatch(getAllFileFailure(error));
    dispatch(addToastDanger(`Fájlok betöltése sikertelen: ${error}`));
  }
};

const uploadMediaBegin = () => ({
  type: MEDIA_ADD_BEGIN,
});

const uploadMediaSuccess = (media) => ({
  type: MEDIA_ADD_SUCCESS,
  payload: { media },
});

const uploadMediaFailure = (error) => ({
  type: MEDIA_ADD_FAILURE,
  payload: { error },
});

const getAllImageBegin = () => ({
  type: MEDIA_GET_ALL_IMAGE_BEGIN,
});

const getAllImageSuccess = (allmedia) => ({
  type: MEDIA_GET_ALL_IMAGE_SUCCESS,
  payload: allmedia,
});

const getAllImageFailure = (error) => ({
  type: MEDIA_GET_ALL_IMAGE_FAILURE,
  payload: { error },
});

const getAllFileBegin = () => ({
  type: MEDIA_GET_ALL_FILE_BEGIN,
});

const getAllFileSuccess = (allmedia) => ({
  type: MEDIA_GET_ALL_FILE_SUCCESS,
  payload: allmedia,
});

const getAllFileFailure = (error) => ({
  type: MEDIA_GET_ALL_FILE_FAILURE,
  payload: { error },
});
