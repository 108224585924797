import { Card, H4 } from "@blueprintjs/core";
import React from "react";
import { EXAM_CENTER_URL } from "../../utils/routes";
import { LayoutDefault } from "../LayoutDefault";
import { MenuExamCenter } from "./MenuExamCenter";

const styleWrap = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "auto 1fr",
  marginTop: "20px",
};

export const LayoutExamCenter = ({ children }) => {
  return (
    <LayoutDefault>
      <div style={{ ...styleWrap }}>
        <div>
          <MenuExamCenter />
        </div>

        <Card elevation={2} style={{ height: "calc(100vh - 90px)", overflow: "auto" }}>
          <H4>
            <a href={EXAM_CENTER_URL} target="_blank">
              Vizsgaközpont
            </a>
          </H4>
          {children}
        </Card>
      </div>
    </LayoutDefault>
  );
};
