import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import {
  NEWS_ADD_BEGIN,
  NEWS_ADD_FAILURE,
  NEWS_ADD_SUCCESS,
  NEWS_DELETE_BEGIN,
  NEWS_DELETE_SUCCESS,
  NEWS_GET_BEGIN,
  NEWS_GET_FAILURE,
  NEWS_GET_ONE_SUCCESS,
  NEWS_GET_SUCCESS,
  NEWS_UPDATE_BEGIN,
  NEWS_UPDATE_FAILURE,
  NEWS_UPDATE_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const uploadNews = (data) => async (dispatch) => {
  dispatch(uploadNewsBegin());
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.NEWS, data);
    dispatch(uploadNewsSuccess(json));
    dispatch(addToastSuccess("Hír feltöltés sikeres"));
    return json;
  } catch (error) {
    dispatch(uploadNewsFailure(error));
    dispatch(addToastDanger(`Hír feltöltés sikertelen: ${error}`));
  }
};

export const deleteNews = (id) => async (dispatch) => {
  dispatch(newsDeleteBegin());
  try {
    const json = await http.del(`${API_BASE_IKK_ADMIN}${API_ENDPOINT.NEWS}/${id}`);
    dispatch(newsDeleteSuccess(id));
    dispatch(addToastSuccess("Törles sikeres"));
    console.log("json:", json);
    return json; // null
  } catch (error) {
    dispatch(addToastDanger(`Törles sikertelen: ${error}`));
  }
};

export const updateNews = (id, data) => async (dispatch) => {
  dispatch(updateNewsBegin());
  try {
    const json = await http.put(`${API_BASE_IKK_ADMIN}${API_ENDPOINT.NEWS}/${id}`, data);
    dispatch(updateNewsSuccess(json));
    dispatch(addToastSuccess("Hír frissítve"));
    return json;
  } catch (error) {
    dispatch(updateNewsFailure(error));
    dispatch(addToastDanger(`Hír frissítése sikertelen: ${error}`));
  }
};

export const getOneNews = (id) => async (dispatch) => {
  dispatch(getNewsBegin());
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.NEWS + "/" + id);
    dispatch(getOneNewsSuccess(json));
    return json;
  } catch (error) {
    dispatch(getNewsFailure(error));
    dispatch(addToastDanger(`Hír betöltése sikertelen: ${error}`));
  }
};

export const getNews = (offset = 0, limit = 30) => async (dispatch) => {
  dispatch(getNewsBegin());
  try {
    const json = await http.get(`${API_BASE_IKK_ADMIN}${API_ENDPOINT.NEWS}?offset=${offset}&limit=${limit}`);
    dispatch(getNewsSuccess(json));
    return json;
  } catch (error) {
    dispatch(getNewsFailure(error));
    dispatch(addToastDanger("Hírek betöltése sikertelen."));
  }
};

export const updateNewsBegin = () => ({
  type: NEWS_UPDATE_BEGIN,
});

export const updateNewsSuccess = (news) => ({
  type: NEWS_UPDATE_SUCCESS,
  payload: { news },
});

export const updateNewsFailure = (error) => ({
  type: NEWS_UPDATE_FAILURE,
  payload: { error },
});

export const uploadNewsBegin = () => ({
  type: NEWS_ADD_BEGIN,
});

export const uploadNewsSuccess = (news) => ({
  type: NEWS_ADD_SUCCESS,
  payload: { news },
});

export const uploadNewsFailure = (error) => ({
  type: NEWS_ADD_FAILURE,
  payload: { error },
});

export const getNewsBegin = () => ({
  type: NEWS_GET_BEGIN,
});

export const getNewsSuccess = (news) => ({
  type: NEWS_GET_SUCCESS,
  payload: news,
});

export const getOneNewsSuccess = (article) => ({
  type: NEWS_GET_ONE_SUCCESS,
  payload: article,
});

export const getNewsFailure = (error) => ({
  type: NEWS_GET_FAILURE,
  payload: { error },
});

const newsDeleteBegin = () => ({
  type: NEWS_DELETE_BEGIN,
});

const newsDeleteSuccess = (id) => ({
  type: NEWS_DELETE_SUCCESS,
  payload: { id },
});
