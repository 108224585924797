import {
  DOCUMENT_ADD_BEGIN,
  DOCUMENT_ADD_FAILURE,
  DOCUMENT_ADD_SUCCESS,
  DOCUMENT_DELETE_BEGIN,
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_GET_BEGIN,
  DOCUMENT_GET_FAILURE,
  DOCUMENT_GET_SUCCESS,
} from "../actionTypes";

const initialState = {
  documents: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DOCUMENT_ADD_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case DOCUMENT_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        documents: state.documents,
      };
    }
    case DOCUMENT_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case DOCUMENT_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case DOCUMENT_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case DOCUMENT_GET_SUCCESS: {
      // let documents = state.documents;

      // if (!Array.isArray(documents)) {
      //   documents = [];
      // }
      // documents = documents.concat(action.payload);
      // documents = documents.filter((obj, pos, arr) => {
      //   return arr.map((mapObj) => mapObj["id"]).indexOf(obj["id"]) === pos;
      // });

      return {
        ...state,
        loading: false,
        error: null,
        documents: action.payload, //documents,
      };
    }
    case DOCUMENT_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case DOCUMENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        documents: state.documents.filter((d) => d.id !== action.payload),
      };
    default:
      return state;
  }
}
