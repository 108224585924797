import { Callout, Card, H4 } from "@blueprintjs/core";
import React from "react";
import { LayoutDefault } from "../../LayoutDefault";
import MenuEDIR from "./MenuEdir";

const styleWrap = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "auto 1fr",
  marginTop: "20px",
};

export const LayoutEDIR = ({ children }) => {
  return (
    <LayoutDefault>
      <div style={{ ...styleWrap }}>
        <div>
          <MenuEDIR />
        </div>

        <Card elevation={2} style={{ height: "calc(100vh - 90px)", overflow: "auto" }}>
          <H4>
            <a href={"#"} target="_blank">
              Central EDIR Access Manager (CEAM)
            </a>
          </H4>
          {process.env.REACT_APP_CMS_ENV !== "live" ? (
            <Callout intent="primary">
              Az EDIR manager csak éles környezetben érhető el.
            </Callout>
          ) : (
            children
          )}
        </Card>
      </div>
    </LayoutDefault>
  );
};
