import { GET_AUTH_USERS_BEGIN, GET_AUTH_USERS_FAILURE, GET_AUTH_USERS_SUCCESS } from "../../actionTypes";

  
  const initialState = {
    users: [],
    loading: false,
    error: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_AUTH_USERS_BEGIN: {
        return {
          ...state,
          loading: true,
          error: null,
        };
      }
      case GET_AUTH_USERS_SUCCESS: {
        return {
            users: action.payload,
          loading: false,
          error: null,
        };
      }
      case GET_AUTH_USERS_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
  
      default:
        return state;
    }
  }
  