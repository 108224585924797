import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isAuthenticated, isAuthorized } from "../../utils/auth";
import { LayoutDefault } from "../LayoutDefault";

export const MakeRoutes = (route) => {
  const Layout = route.layout || LayoutDefault;

  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) =>{
          if(!isAuthenticated()){
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
          return isAuthorized(route.authNamespace, route.authPermission) ? (
            <Layout {...props}>
              <route.component {...props} routes={route.routes} />
            </Layout>
          ) :(
            <div>Insufficient permissions</div>
          )
      }
       
      }
    />
  );
};
