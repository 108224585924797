import React from "react";
import { connect } from "react-redux";
import { uploadMedia } from "../../../redux/actions/common/media";
import { createSlide, updateSlide } from "../../../redux/actions/ikk/carousel";
import { ROUTES } from "../../../utils/routes";
import SlideForm from "./SlideForm";

const emptyState = {
  title: "",
  link: "",
  body: "",
  position: "",
  cover_id: "",
  visible: false,
};

const SlidesCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const res = await props.createSlide(state);
    // reset the form if submit was successful
    if (res && res.id) {
      // setState(emptyState);
      props.history.push(ROUTES.slides);
    }
  };

  return (
    <SlideForm
      title="Új slide"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
      imageRequired={true}
    />
  );
};

export default connect(null, { createSlide, uploadMedia, updateSlide })(
  SlidesCreate
);
