import { Button } from "@blueprintjs/core";
import React from "react";

export const RandomPasswordButton = ({ isDisabled, handleInput }) => {
  const getRandomPass = () => {
    const pass = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36))
      .map((char) => (Math.random() > 0.5 ? char : char.toUpperCase()))
      .join("");
    handleInput({ target: { name: "password", value: pass } });
  };

  return (
    <Button icon="refresh" disabled={isDisabled} text="Random" onClick={getRandomPass} />
  );
};
