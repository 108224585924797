import {
  Button,
  Checkbox,
  FormGroup,
  Icon,
  InputGroup,
  NumericInput,
  TextArea,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { uploadMedia } from "../../../redux/actions/common/media";
import { LIMIT } from "../../../utils/limits";
import { API_BASE_IKK } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import style from "./Slides.module.scss";

const SlideForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleNumInput = (val) => {
    setState((s) => ({ ...s, position: val }));
  };

  const handleCheckbox = () => {
    setState((s) => ({ ...s, visible: !s.visible }));
  };

  const handleMediaUpload = (media) => {
    console.log("media:", media);
    setState((s) => ({ ...s, cover_id: media.id }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  return (
    <form className={style.createWrap} onSubmit={handleSubmit}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="multi-select" />
        &nbsp;<span>{props.title}</span>
      </h2>
      <FormGroup
        label="Cím"
        labelInfo="(kötelező)"
        helperText={`${state.title?.length} / ${LIMIT.SLIDE_TITLE_MAX_LEN}`}
        contentClassName="formHelperRight"
      >
        <InputGroup
          required
          placeholder="Cím..."
          name="title"
          value={state.title}
          onChange={handleInput}
          intent={
            state.title.length > LIMIT.SLIDE_TITLE_MAX_LEN ? "danger" : ""
          }
        />
      </FormGroup>

      <FormGroup label="Link" labelInfo="(kötelező)">
        <InputGroup
          required
          placeholder="https://..."
          name="link"
          type="url"
          value={state.link}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup
        label="Törzs"
        labelInfo="(kötelező)"
        helperText={`${state.body?.length} / ${LIMIT.SLIDE_BODY_MAX_LEN}`}
        contentClassName="formHelperRight"
      >
        <TextArea
          placeholder="Törzs szöveg..."
          fill
          required
          growVertically
          name="body"
          value={state.body}
          onChange={handleInput}
          style={{ resize: "none" }}
          intent={state.body.length > LIMIT.SLIDE_BODY_MAX_LEN ? "danger" : ""}
        />
      </FormGroup>

      <FormGroup label="Pozíció" labelInfo="(kötelező)">
        <NumericInput
          required
          min={0}
          name="position"
          value={state.position}
          onValueChange={handleNumInput}
        />
      </FormGroup>

      <FormGroup label="Kép" labelInfo="(kötelező)">
        <FileUpload
          onUpload={handleMediaUpload}
          isRequired={props.imageRequired}
        />
      </FormGroup>

      <FormGroup>
        {state.cover_id ? (
          <img src={`${API_BASE_IKK}/media/${state.cover_id}`} width="200" />
        ) : (
          <p>Nincs kép feltöltve</p>
        )}
      </FormGroup>

      {state.id && ( //only show on edit form
        <Checkbox
          label="Aktív"
          checked={state.visible}
          onChange={handleCheckbox}
        />
      )}

      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

export default connect(null, { uploadMedia })(SlideForm);
