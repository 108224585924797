import { Icon, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  getAllProfCardSector,
  deleteProfCardSectorData,
} from "../../../redux/actions/szakkepzes/profCards";
import { ROUTES } from "../../../utils/routes";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";

const ProfessionCardsSectorsAll = (props) => {
  const [sectors, setSectors] = useState([]);
  const [rowToDelete, setRowToDelete] = useState(); // set when clicking on delete button

  useEffect(() => {
    getSectors();
  }, []);

  const getSectors = async () => {
    const data = await props.getAllProfCardSector();
    setSectors(data);
  };

  const handleDelete = async () => {
    await props.deleteProfCardSectorData(rowToDelete.id);
    setRowToDelete(null);
    getSectors();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Ágazat neve",
        accessor: "name",
      },
      {
        Header: "Tanítják",
        accessor: "institutes",
        Cell: ({ value }) => `${value.length} helyen`,
      },
      {
        Header: "Utoljára módosítva",
        accessor: "cardData.updatedAt",
        Cell: ({ value }) => (value ? `${new Date(value).toLocaleString()}` : "-"),
      },
      {
        Header: "Szakmakártya adatok kitöltve?",
        accessor: (row) => !!row.cardData,
        Cell: ({ value }) =>
          value ? (
            <>
              <Icon icon="tick-circle" intent="success" /> Igen
            </>
          ) : (
            <>
              <Icon icon="error" intent="danger" /> Nem
            </>
          ),
        sortType: (rowA, rowB) =>
          rowA.original.cardData !== null && rowB.original.cardData === null ? -1 : 1,
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            onDelBtnClick={() => setRowToDelete(row.original)}
            editHref={generatePath(ROUTES.szakmakartyakSectorsEdit, {
              id: row.original.id,
            })}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes szakmakártya ágazat </h2>

      <DeleteConfirmation
        isOpen={!!rowToDelete}
        recordName={`${rowToDelete?.name} agazat szakmakartya adatai`}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      {!sectors.length ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced data={sectors} columns={columns} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.profCards };
};

export default connect(mapStateToProps, {
  getAllProfCardSector,
  deleteProfCardSectorData,
})(ProfessionCardsSectorsAll);
