import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import "moment/locale/hu";
import React, { useState } from "react";
import MyCkEditor from "../../common/MyCkEditor";

const JobsForm = ({ initialState, title, submitBtnText, onSubmit }) => {
  const [state, setState] = useState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(state, setState);
  };

  const handleInput = (e) => {
    if (e.target.type === "checkbox") {
      setState((s) => ({ ...s, [e.target.name]: e.target.checked }));
    } else {
      const { name, value } = e.target;
      setState((s) => ({ ...s, [name]: value }));
    }
  };

  return (
    <form style={{ maxWidth: "800px" }} onSubmit={handleSubmit}>
      <h2>{title}</h2>

      <FormGroup label="Meghirdetett állás neve" labelInfo="(kötelező)">
        <InputGroup
          name="title"
          value={state.title}
          onChange={handleInput}
          required
          placeholder="pl. Szakács"
        />
      </FormGroup>

      <FormGroup label="Helyszín" labelInfo="(kötelező)">
        <InputGroup
          name="location"
          placeholder="pl. Budapest VI. kerület"
          value={state.location}
          onChange={handleInput}
          required
        />
      </FormGroup>

      <FormGroup label="Munkaidő" labelInfo="(kötelező)">
        <InputGroup
          name="workingHours"
          placeholder="pl. Teljes munkaidő"
          value={state.workingHours}
          onChange={handleInput}
          required
        />
      </FormGroup>

      <FormGroup label="Leírás" labelInfo="(kötelező)">
        <MyCkEditor
          data={state.description}
          onDataChange={(d) => handleInput({ target: { value: d, name: "description" } })}
        />
      </FormGroup>

      <br />

      <Button text={submitBtnText} icon="floppy-disk" intent="success" type="submit" />

      <br />
    </form>
  );
};

export default JobsForm;
