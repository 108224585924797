import { AnchorButton, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import {
  deleteDocument,
  deleteQualification,
  getAllQualification,
} from "../../../redux/actions/szakkepesites/pk";
import { API_BASE_IKK, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const initialstate = {
  qualification_id: "",
  qualification_name: "",
  name: "",
  media_id: "",
  attachment_id: "",
};

const AllQualifications = (props) => {
  // const [editKKK, setEditKKK] = useState(initialstate);
  const [deleteQualif, setDeleteQualif] = useState(initialstate);
  const [deleteDoc, setDeleteDoc] = useState();
  const [editDoc, setEditDoc] = useState();

  useEffect(() => {
    props.getAllQualification();
  }, []);

  // opens delete dialog
  const handleDelete = (row) => {
    setDeleteQualif(row);
  };

  const handleDialogClose = () => {
    setEditDoc(null);
  };

  // delete qualification submitted
  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    const res = await props.deleteQualification(deleteQualif.id);
    setDeleteQualif(null);
    props.getAllQualification();
  };

  // opens delete dialog
  const handleDeleteDoc = (row, doc) => {
    setDeleteDoc({ ...doc, qualifId: row.id, qualifName: row.name });
  };

  const handleEditDoc = (row, doc) => {
    setEditDoc(doc);
  };

  // deletes a document of a qualification
  const handleDeleteDocSubmit = async (e) => {
    e.preventDefault();
    const res = await props.deleteDocument(deleteDoc.qualifId, deleteDoc.id);
    setDeleteDoc(null);
    props.getAllQualification();
  };

  const handleEditDocSubmit = () => {
    // todo
  };

  const handleDocUpload = (media) => {
    //todo
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Szakképesítés",
        accessor: "name",
      },
      {
        Header: "Kód",
        accessor: "code",
      },
      {
        Header: "Szám",
        accessor: "num",
      },
      {
        Header: "MKKR",
        accessor: "mkkr",
      },
      {
        Header: "EKKR",
        accessor: "ekkr",
      },
      {
        Header: "DKKR",
        accessor: "dkkr",
      },
      {
        Header: "Látható",
        accessor: "visible",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Fájlok",
        accessor: "documents",
        Cell: ({ row, value }) =>
          value?.map((doc, i) => (
            <div key={i}>
              <AnchorButton
                icon="share"
                small
                minimal
                text={doc.name || "Előnézet"}
                href={`${API_BASE_IKK}/media/${doc.mediaId}`}
                target="_blank"
              />
              <TableActionButtons
                row={row}
                onDelBtnClick={() => handleDeleteDoc(row.original, doc)}
              />
            </div>
          )),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.szakkepesitesQualifEdit, {
              id: row.original.id,
            })}
            onDelBtnClick={() => handleDelete(row.original)}
          />
        ),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;

  return (
    <>
      <h2>Összes szakképesítés és programkövetelmény (PK) dokumentumok</h2>

      <TableAdvanced data={props.qualifications} columns={columns} />

      {/* delete qualification */}
      <DeleteConfirmation
        isOpen={deleteQualif?.name}
        onCancel={() => setDeleteQualif(null)}
        onDelete={handleDeleteSubmit}
        recordName={`Szakképesítés: ${deleteQualif?.name}`}
      />

      {/* delete document  */}
      <DeleteConfirmation
        isOpen={deleteDoc?.name}
        onCancel={() => setDeleteDoc(null)}
        onDelete={handleDeleteDocSubmit}
        recordName={`${deleteDoc?.qualifName} ${deleteDoc?.name} dokumentuma`}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.pk };
};
export default connect(mapStateToProps, {
  getAllQualification,
  deleteDocument,
  deleteQualification,
})(AllQualifications);
