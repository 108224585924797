import { Icon, Spinner, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import { deleteNews, getNews } from "../../../redux/actions/ikk/news";
import { IKK_URL, ROUTES } from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const NewsAll = (props) => {
  const [rowToDelete, setRowToDelete] = useState(null);

  useEffect(() => {
    getAllNews(0, 30);
  }, []);

  const getAllNews = async (offset, limit) => {
    const j = await props.getNews(offset, limit);
    if (j.length === limit) getAllNews(offset + limit, limit);
  };

  const handleDeleteDialog = (row) => {
    setRowToDelete(row);
  };

  const handleDelete = () => {
    props.deleteNews(rowToDelete.id);
    setRowToDelete(null);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Publikálás dátum",
        accessor: "published_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Prioritás dáum",
        accessor: "priority_date",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Utoljára módosítva",
        accessor: "updated_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Cím",
        accessor: "title",
      },
      {
        Header: "Tagek",
        accessor: "tags",
        Cell: ({ value }) => {
          return value.map((tag) => (
            <Tag round minimal key={tag.id} style={{ margin: "2px" }}>
              {tag.name}
            </Tag>
          ));
        },
      },
      {
        Header: "Nézettség",
        accessor: "views",
      },
      {
        Header: "Link",
        accessor: "slug",
        Cell: ({ value }) => (
          <a href={`${IKK_URL}/hirek/${value}`} target="_blank">
            <Icon icon="share" />
          </a>
        ),
      },
      {
        Header: "Kép",
        accessor: "cover",
        Cell: ({ value }) => (
          <img
            width="100"
            src={value.uri.find((u) => u.size === "xs").uri}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            editHref={generatePath(ROUTES.newsEdit, { id: row.original.id })}
            onDelBtnClick={handleDeleteDialog}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2>Összes hír</h2>

      <DeleteConfirmation
        isOpen={rowToDelete !== null}
        recordName={rowToDelete?.title}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      {props.loading ? (
        <Spinner intent="primary" />
      ) : (
        <TableAdvanced columns={columns} data={props.news} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { news, loading } = state.news;
  return { news, loading };
};

export default connect(mapStateToProps, { getNews, deleteNews })(NewsAll);
