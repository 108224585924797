import { Button, Checkbox, MenuItem } from "@blueprintjs/core";
import "moment/locale/hu";
import React, { Fragment, useState } from "react";

const data = [
  {
    namespace: "cms",
    permission: "cms.skillscom.ikk.hu",
    helper: "Access Skillscom CMS",
  },
  { namespace: "cms", permission: "cms.ikk.hu", helper: "Access CMS" },
  { namespace: "auth", permission: "*.list:read", helper: "Read all users" },
  {
    namespace: "auth",
    permission: "*.single:read",
    helper: "Read a single user (need to know the UUID)",
  },
  {
    namespace: "auth",
    permission: "*.single:write:roles.main",
    helper: "Edit a users roles in the main namespace",
  },

  { namespace: "main", permission: "news:read", helper: "Read all news" },
  {
    namespace: "main",
    permission: "news:write",
    helper: "Write any news (create, edit, delete)",
  },

  { namespace: "main", permission: "events:read", helper: "Read all events" },
  {
    namespace: "main",
    permission: "events:write",
    helper: "Write any event (create, edit, delete)",
  },

  {
    namespace: "main",
    permission: "publicData:read",
    helper: "Read all public data",
  },
  {
    namespace: "main",
    permission: "publicData:write",
    helper: "Write any public data (create, edit, delete)",
  },

  {
    namespace: "main",
    permission: "constData:read",
    helper: "Read all constant data",
  },
  {
    namespace: "main",
    permission: "constData:write",
    helper: "Write any constant data (create, edit delete)",
  },

  { namespace: "main", permission: "media:read", helper: "Read all media" },
  {
    namespace: "main",
    permission: "media:write",
    helper: "Create a new media",
  },  { namespace: "main", permission: "tags:read", helper: "Read all tags" },
  {
    namespace: "main",
    permission: "tags:write",
    helper: "Create a new tags",
  },
  {
    namespace: "profession",
    permission: "*",
    helper: "Mindenre jogosult",
  },
  {
    namespace: "qualification",
    permission: "*",
    helper: "Mindenre jogosult",
  },
  {
    namespace: "professioncard",
    permission: "sector:read",
    helper: "Read all sector cards",
  },
  {
    namespace: "professioncard",
    permission: "sector:write",
    helper: "write a sector card (create, edit delete)",
  },

  {
    namespace: "professioncard",
    permission: "profession:read",
    helper: "Read all profession cards",
  },
  {
    namespace: "professioncard",
    permission: "profession:write",
    helper: "Write a profession card (create, edit, delete)",
  },
  /**ZÁRT RENDSZER */
  {
    namespace: "zartrendszer",
    permission: "article:read",
    helper: "Read own articles",
  },
  {
    namespace: "zartrendszer",
    permission: "article:create",
    helper: "Create article",
  },
  {
    namespace: "zartrendszer",
    permission: "article:delete",
    helper: "Delete own article",
  },
  {
    namespace: "zartrendszer",
    permission: "article:update",
    helper: "Update own article",
  },
  {
    namespace: "zartrendszer",
    permission: "article:approve",
    helper: "Approve articles",
  },
  //events
  {
    namespace: "zartrendszer",
    permission: "event:read",
    helper: "Read own events",
  },
  {
    namespace: "zartrendszer",
    permission: "event:create",
    helper: "Create article",
  },
  {
    namespace: "zartrendszer",
    permission: "event:delete",
    helper: "Delete own event",
  },
  {
    namespace: "zartrendszer",
    permission: "event:update",
    helper: "Update own event",
  },
  {
    namespace: "zartrendszer",
    permission: "event:approve",
    helper: "Approve events",
  },

  //post
  {
    namespace: "zartrendszer",
    permission: "post:read",
    helper: "Read own posts",
  },
  {
    namespace: "zartrendszer",
    permission: "post:create",
    helper: "Create post",
  },
  {
    namespace: "zartrendszer",
    permission: "post:delete",
    helper: "Delete own post",
  },
  {
    namespace: "zartrendszer",
    permission: "post:update",
    helper: "Update own post",
  },
  {
    namespace: "zartrendszer",
    permission: "post:approve",
    helper: "Approve posts",
  },

  {
    namespace: "zartrendszer",
    permission: "editor:delete",
    helper: "Able to remove editor (teacher) from institute",
  },
  {
    namespace: "zartrendszer",
    permission: "editor:create",
    helper: "Can assign new editors (teachers) to the institute",
  },
  {
    namespace: "zartrendszer",
    permission: "editor:read",
    helper: "Can read all editors (teachers) assigned to the institute",
  },

  {
    namespace: "zartrendszer",
    permission: "company:read",
    helper: "Can read all registered companies (dual partners)",
  },
  {
    namespace: "zartrendszer",
    permission: "company:verify",
    helper: "Able to accept company (dual partner) registration",
  },

  {
    namespace: "zartrendszer",
    permission: "institute:read",
    helper: "Can read all registered insitutes",
  },
  {
    namespace: "zartrendszer",
    permission: "institute:verify",
    helper: "Able to accept institute registration",
  },
  {
    namespace: "zartrendszer",
    permission: "statistics:read",
    helper: "Read SkillsCom stats",
  },
  // Vizsgakozpont
  {
    namespace: "vizsgakozpont",
    permission: "candidate:read",
    helper: "Vizsgara jelentkezok listazasa",
  },
  {
    namespace: "vizsgakozpont",
    permission: "candidate:write",
    helper: "Vizsgara jelentkezok statuszanak mosoditasa",
  },
  {
    namespace: "vizsgakozpont",
    permission: "candidate:generate",
    helper: "Vizsgara jelentkezok exportalasa",
  },
  {
    namespace: "vizsgakozpont",
    permission: "exam:read",
    helper: "Vizsgak listazasa",
  },
  {
    namespace: "vizsgakozpont",
    permission: "exam:write",
    helper: "Vizsgak modositasa",
  },
  // CEAM
  {
    namespace: "ceam",
    permission: "szc:read",
    helper: "EDIR SZC-k listazasa",
  },
  {
    namespace: "ceam",
    permission: "szc:write",
    helper: "EDIR SZC-k felvetele, modositasa",
  },
  {
    namespace: "ceam",
    permission: "intezmeny:read",
    helper: "EDIR intezmenyek listazasa",
  },
  {
    namespace: "ceam",
    permission: "intezmeny:write",
    helper: "EDIR intezmenyek felvetele, modositasa",
  },
  // qualification card
  {
    namespace: "qualificationcard",
    permission: "qualification:read",
    helper: "PK kartyak listazasa",
  },
  {
    namespace: "qualificationcard",
    permission: "qualification:write",
    helper: "PK kartyak felvetele, modositasa",
  },
  {
    namespace: "qualificationcard",
    permission: "institute:read",
    helper:
      "Intezmenyek es szakkepesitesek osszerendelesenek listazasa (ASZOK)",
  },
  {
    namespace: "qualificationcard",
    permission: "institute:write",
    helper:
      "Intezmenyek es szakkepesitesek osszerendelesenek modositasa (ASZOK)",
  },
  // Scholarships
  {
    namespace: "scholarship",
    permission: "template:write",
    helper: "Sablon szerkesztese",
  },
  {
    namespace: "scholarship",
    permission: "applicant:read",
    helper: "Jelentkezok listazasa",
  },
  {
    namespace: "scholarship",
    permission: "applicant:statusedit",
    helper: "Jelentkezok szerkesztese",
  },
  {
    namespace: "scholarship",
    permission: "publicfile:upload",
    helper: "Sablon fajlok feltoltese",
  },
  {
    namespace: "scholarship",
    permission: "privatefile:download",
    helper: "Bekuldott fajlok megnezese",
  },
  //6110
  {
    namespace: "6110",
    permission: "rk:write",
    helper: "Rendszer követelmények létrehozása, módosítása",
  },
  {
    namespace: "6110",
    permission: "pk:write",
    helper: "PK-k létrehozása, módosítása",
  },
  {
    namespace: "6110",
    permission: "kkk:write",
    helper: "KKK/PTT-k létrehozása, módosítása",
  },
];

const groupData = () => {
  const resp = {};
  data.map((row) => {
    if (resp.hasOwnProperty(row.namespace)) {
      resp[row.namespace].push(row.permission);
    } else {
      resp[row.namespace] = [row.permission];
    }
  });
  return resp;
};
const getHelper = (ns, perm) => {
  return data.find((d) => d.namespace === ns && d.permission == perm).helper;
};

const UsersForm = ({
  initialState,
  title,
  submitBtnText,
  onSubmit,
  imageRequired,
}) => {
  const [state, setState] = useState(initialState);
  const [step, setStep] = useState(0);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleInputDate = (name) => (date, isUser) => {
    setState((s) => ({ ...s, [name]: date ? date.toISOString() : null }));
  };

  const handleCoverUpload = (media) => {
    setState((s) => ({ ...s, cover_id: media.id }));
  };
  const itemRenderer = (item, { handleClick, modifiers, query }) => (
    <MenuItem
      label={item.namespace}
      key={item}
      onClick={handleClick}
      text={item.permission}
    />
  );
  const selectItem = (c) => {
    if (state.roles.hasOwnProperty(c.namespace)) {
      setState((s) => ({
        ...s,
        roles: {
          ...s.roles,
          [c.namespace]: [...state.roles[c.namespace], c.permission],
        },
      }));
    } else {
      setState((s) => ({
        ...s,
        roles: { ...s.roles, [c.namespace]: [c.permission] },
      }));
    }
  };
  const isChecked = (ns, perm) => {
    return state.roles.hasOwnProperty(ns) && state.roles[ns].includes(perm);
  };
  const handleCheck = (ns, perm) => {
    if (isChecked(ns, perm)) {
      handleDeleteRow(ns, state.roles[ns].indexOf(perm))();
    } else {
      selectItem({ namespace: ns, permission: perm });
    }
  };
  const handleDeleteRow = (ns, index) => () => {
    const actualRoles = [...state.roles[ns]];
    actualRoles.splice(index, 1);
    setState((s) => ({ ...s, roles: { ...s.roles, [ns]: actualRoles } }));
  };
  const handleCreateNewPermission = (textPerm) => {
    const roleRawArr = textPerm.split("->");
    return { namespace: roleRawArr[0], permission: roleRawArr[1] };
  };
  const renderCreatePermissionOption = (query, active, handleClick) => {
    if (query.includes("->")) {
      return (
        <MenuItem
          icon="add"
          text={`Create "${query}"`}
          active={active}
          onClick={handleClick}
          shouldDismissPopover={false}
        />
      );
    }
    return (
      <MenuItem
        icon="add"
        text={`Format should be namespace->permission`}
        active={active}
        shouldDismissPopover={false}
      />
    );
  };
  const rawData = groupData();
  const renderTag = (data) => data;

  return (
    <div>
      <h2>{title}</h2>

      <div>
        {Object.keys(rawData).map((keys, i) => (
          <Fragment key={i}>
            <li style={{ listStyleType: "none" }}>{keys}</li>
            <ul>
              {rawData[keys].map((perms, index) => (
                <li key={index} style={{ listStyleType: "none" }}>
                  <Checkbox
                    checked={isChecked(keys, perms)}
                    style={{ display: "flex" }}
                    onChange={() => handleCheck(keys, perms)}
                  >
                    <div style={{ width: 300 }}>{perms}</div>
                    <div>{getHelper(keys, perms)}</div>
                  </Checkbox>
                </li>
              ))}
            </ul>
          </Fragment>
        ))}

        <br />

        <Button
          text="Mentés"
          intent="primary"
          rightIcon="chevron-right"
          onClick={() => onSubmit(state)}
        />
      </div>
      <br />
    </div>
  );
};

export default UsersForm;
