import React from "react";
import { connect } from "react-redux";
import { createFaq, getAllFaq } from "../../../redux/actions/szakkepzes/faq";
import { ROUTES } from "../../../utils/routes";
import FaqForm from "./FaqForm";

const emptyState = {
  category_id: "",
  question: "",
  answer: "",
  order:0,
};

const FaqCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const data = await props.createFaq(state);
    // reset form is submit was successful
    if (data) {
      setState(emptyState);
      props.history.push(ROUTES.faq);
      props.getAllFaq();
    }
  };

  return (
    <FaqForm
      title="Gyakori kérdés létrehozása"
      initialState={emptyState}
      submitBtnText="Létrehoz"
      handleSubmit={handleCreateSubmit}
    />
  );
};

export default connect(null, { createFaq, getAllFaq })(FaqCreate);
