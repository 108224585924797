import {
  Button,
  Checkbox,
  FormGroup,
  Icon,
  InputGroup,
  NumericInput,
  TextArea,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { uploadMedia } from "../../../redux/actions/common/media";
// import style from "./Slides.module.scss";
import MyCkEditor from "../../common/MyCkEditor";

const AboutRowForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: "800px" }}>
      <h2>{props.title}</h2>

      <FormGroup label="Cim" labelInfo="(kötelező)">
        <InputGroup
          required
          placeholder="Cim..."
          name="name"
          type="text"
          value={state.name}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Szoveg" labelInfo="(kötelező)">
        <MyCkEditor
          data={state.body}
          onDataChange={(data) => setState((s) => ({ ...s, body: data }))}
        />
      </FormGroup>
        <FormGroup label="Sorrend" labelInfo="(kötelező)">
            <InputGroup
                required
                placeholder="Sorrend"
                name="order"
                type="number"
                value={state.order}
                onChange={handleInput}
            />
        </FormGroup>
      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

export default connect(null, {})(AboutRowForm);
