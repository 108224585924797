import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { login } from "../../redux/actions/common/login";

const Login = (props) => {
  const [open, setOpen] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const handleSubmit = () => {
    setPassword("");
    props.login(username, password, remember);
  };

  return (
    <>
      <div
        style={{
          height: "90vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: "2rem" }}>
          <img src="/logo_full.svg" alt="" />
        </div>

        <Button
          autoFocus
          intent="primary"
          text="Belépés"
          rightIcon="arrow-right"
          onClick={() => setOpen(true)}
        />
      </div>

      <Dialog
        usePortal={false}
        title="Admin belépés"
        isOpen={open}
        onClose={() => setOpen(false)}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
        icon="lock"
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Felhasználónév">
              <InputGroup
                large
                leftIcon="user"
                placeholder="Felhasználónév..."
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormGroup>

            <FormGroup label="Jelszó">
              <InputGroup
                type="password"
                large
                leftIcon="key"
                placeholder="Jelszó..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button icon="log-in" intent="primary" type="submit" onClick={handleSubmit}>
                Belépés
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default connect(null, { login })(Login);
