import React from "react";
import { API_BASE_IKK_ADMIN, API_ENDPOINT, ROUTES } from "../../../utils/routes";
import http from "../../../utils/http";
import PopupForm from "./PopupForm";
import { showToast } from "../../../utils/helpers";

const emptyState = {
  title: "",
  body: "",
  url: undefined,
  url_title: undefined,
  type: "ONCE",
  enabled: false,
  img_media_id: null,
  show_on_pages: [".*"],
};

export const PopupCreate = (props) => {
  const handleSubmit = async (state, setState) => {
    try {
      const res = await http.post(`${API_BASE_IKK_ADMIN + API_ENDPOINT.POPUP}`, state);
      console.log(res);
      showToast({ intent: "success", message: "Popup létrehozva!" });
      props.history.push(ROUTES.popup);
    } catch (error) {
      const msg = error.message ?? "Minden kötelező mező ki van töltve?";
      showToast({
        intent: "danger",
        message: `Hiba történt! ${msg}`,
      });
    }
  };

  return (
    <PopupForm
      title="Új Popup létrehozása"
      submitBtnText="Létrehoz"
      initialState={emptyState}
      handleSubmit={handleSubmit}
    />
  );
};
