import truncate from "html-truncate";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {generatePath} from "react-router-dom";
import {
    deleteFaq,
    deleteFaqCategory,
    getAllFaq,
} from "../../../redux/actions/szakkepzes/faq";
import {ROUTES} from "../../../utils/routes";
import {DeleteConfirmation} from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import {TableAdvanced} from "../../common/TableAdvanced";

const FaqAll = (props) => {
    const [faqToDelete, setRowToDelete] = useState();

    useEffect(() => {
        props.getAllFaq();
    }, []);

    const handleDeleteDialog = (row) => {
        setRowToDelete(row);
    };

    const handleDelete = () => {
        props.deleteFaq(faqToDelete.id);
        setRowToDelete(null);
        props.getAllFaq();
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Témakör",
                accessor: "category.name",
            },
            {
                Header: "Kérdés",
                accessor: "question",
            },
            {
                Header: "Pozició",
                Cell: ({row}) => (
                    `${row.original.category.order}.${row.original.order}`
                ),
            },
            {
                Header: "Válasz",
                accessor: "answer",
                Cell: ({value}) => (
                    <div dangerouslySetInnerHTML={{__html: truncate(value, 200)}}/>
                ),
            },
            {
                Header: "Művelet",
                Cell: ({row}) => (
                    <TableActionButtons
                        row={row}
                        editHref={generatePath(ROUTES.faqEdit, {id: row.original.id})}
                        onDelBtnClick={handleDeleteDialog}
                    />
                ),
            },
        ],
        []
    );

    return (
        <>
            <h2>Összes gyakori kérdés</h2>

            <DeleteConfirmation
                isOpen={!!faqToDelete}
                recordName={faqToDelete?.question}
                onDelete={handleDelete}
                onCancel={() => setRowToDelete(null)}
            />

            <TableAdvanced data={props.faqs.sort((a,b)=>(a.category.order*1000+a.order)-(b.category.order*1000+b.order))} columns={columns}/>
        </>
    );
};

const mapStateToProps = (state) => {
    return {...state.faq};
};
export default connect(mapStateToProps, {
    getAllFaq,
    deleteFaq,
    deleteFaqCategory,
})(FaqAll);
