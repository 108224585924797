import React from "react";
import http from "../../../utils/http";
import { API_BASE_CEAM, ROUTES } from "../../../utils/routes";
import InstituteForm from "./InstituteForm";

const emptyState = {
  name: "",
  password: "",
  realDomains: [],
  username: "admin@ikk.hu",
  dbId: "",
  frontendEdirDomain: "",
  backendEdirDomain: "",
  mainCMSDomain: "cms.intezmeny.edir.hu",
  tag: "prod",
  createdCertForEdirDomain: false,
  version: 2,
  storage: 2,
};

const InstituteCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    try {
      const json = await http.post(`${API_BASE_CEAM}/api/intezmeny`, state);

      // reset the form if submit was successful
      setState(emptyState);
      props.history.push(ROUTES.edirInstituteAll);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <InstituteForm
      title="Új EDIR Intézmény"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
      imageRequired={true}
    />
  );
};

export default InstituteCreate;
