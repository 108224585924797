import {
  Button,
  FormGroup,
  HTMLSelect,
  MenuItem,
  Position,
  Spinner,
} from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createKKK } from "../../../redux/actions/szakkepzes/kkk";
import { API_BASE_SZAKKEPZES } from "../../../utils/routes";
import FileUpload from "../../common/FileUpload";
import style from "./KKK.module.scss";

const initialstate = {
  qualification_id: "",
  media_id: "",
  name: "",
};

const KKKCreate = (props) => {
  const [state, setState] = useState(initialstate);
  const [qualifications, setQualificaitons] = useState([]);

  useEffect(() => {
    getQualifications();
  }, []);

  function getQualifications() {
    fetch(API_BASE_SZAKKEPZES + "/qualifications")
      .then((res) => res.json())
      .then((jsonarr) => {
        console.log(jsonarr);
        setQualificaitons(jsonarr.map((q) => ({ name: q.name, id: q.id })));
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await props.createKKK({ ...state });
    res && document.location.reload();
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((kkk) => ({ ...kkk, [name]: value }));
  };

  const handleUpload = (media) => {
    setState((kkk) => ({ ...kkk, media_id: media.id }));
  };

  if (qualifications.length === 0) return <Spinner />;

  const filterQualifications = (query, qualif, _index, exactMatch) => {
    const normalizedName = qualif.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedName === normalizedQuery;
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0;
    }
  };

  const itemRenderer = (item, { handleClick, modifiers, query }) => (
    <MenuItem
      key={item.id}
      text={item.name}
      onClick={handleClick}
      active={modifiers.active}
    />
  );

  return (
    <>
      <h2>Új dokumentum feltöltése szakmához</h2>

      <form onSubmit={handleSubmit}>
        <FormGroup label="Szakma">
          <Suggest
            items={qualifications}
            itemRenderer={itemRenderer}
            inputValueRenderer={(item) => item.name}
            noResults={<MenuItem disabled={true} text="Nincs találat" />}
            onItemSelect={(item, e) =>
              setState((kkk) => ({ ...kkk, qualification_id: item.id }))
            }
            popoverProps={{
              popoverClassName: style.qualifselectPopover,
              position: Position.BOTTOM_LEFT,
            }}
            itemPredicate={filterQualifications}
            inputProps={{ required: true, placeholder: "Válasszon..." }}
          ></Suggest>
        </FormGroup>

        <FormGroup label="Dokumentum típusa">
          <HTMLSelect
            value={state.name}
            onChange={handleInput}
            required
            name="name"
          >
            <option value="" disabled></option>
            <option value="KKK">KKK</option>
            <option value="PTT">PTT</option>
          </HTMLSelect>
        </FormGroup>

        <FormGroup>
          <FileUpload onUpload={handleUpload} isRequired />
        </FormGroup>

        <br />
        <Button
          intent="success"
          type="submit"
          text="Mentés"
          icon="floppy-disk"
        />
      </form>
    </>
  );
};

export default connect(null, { createKKK })(KKKCreate);
