import {
  MENU_CATEGORY_GET_BEGIN,
  MENU_CATEGORY_GET_SUCCESS,
  MENU_CATEGORY_GET_FAILURE,
  MENU_CATEGORY_UPDATE_BEGIN,
  MENU_CATEGORY_UPDATE_SUCCESS,
  MENU_CATEGORY_UPDATE_FAILURE,
  MENU_ITEM_CREATE_BEGIN,
  MENU_ITEM_CREATE_SUCCESS,
  MENU_ITEM_CREATE_FAILURE,
  MENU_ITEM_DELETE_BEGIN,
  MENU_ITEM_DELETE_SUCCESS,
  MENU_ITEM_DELETE_FAILURE,
  MENU_ITEM_UPDATE_BEGIN,
  MENU_ITEM_UPDATE_SUCCESS,
} from "../../../redux/actionTypes";
import http from "../../../utils/http";
import {
  API_BASE_IKK_ADMIN,
  API_ENDPOINT,
} from "../../../utils/routes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getCategories = () => async (dispatch) => {
  dispatch({ type: MENU_CATEGORY_GET_BEGIN });
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.MENU);
    dispatch({ type: MENU_CATEGORY_GET_SUCCESS, payload: json });
    return json;
  } catch (error) {
    dispatch({ type: MENU_CATEGORY_GET_FAILURE, payload: error });
    dispatch(addToastDanger("Menü kategóriák betöltése sikertelen"));
  }
};

export const getOneCategory = (id) => async (dispatch) => {
  try {
    const json = await http.get(
      API_BASE_IKK_ADMIN + API_ENDPOINT.MENU + "/category/" + id
    );
    return json;
  } catch (error) {
    dispatch({ type: MENU_CATEGORY_GET_FAILURE, payload: error });
    dispatch(addToastDanger(`Menü kategória betöltése sikertelen: ${error}`));
  }
};

export const updateCategory = (id, data) => async (dispatch) => {
  dispatch({ type: MENU_CATEGORY_UPDATE_BEGIN });
  try {
    const json = await http.put(
      API_BASE_IKK_ADMIN + API_ENDPOINT.MENU + "/category/" + id,
      data
    );
    dispatch({ type: MENU_CATEGORY_UPDATE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Menü kategória módosítása sikeres"));
    return json;
  } catch (error) {
    dispatch({ type: MENU_CATEGORY_UPDATE_FAILURE, payload: error });
    dispatch(addToastDanger(`Menü kategória módosítása sikertelen: ${error}`));
  }
};

export const createItem = (data) => async (dispatch) => {
  dispatch({ type: MENU_ITEM_CREATE_BEGIN });
  try {
    const json = await http.post(
      API_BASE_IKK_ADMIN + API_ENDPOINT.MENU + "/item",
      data
    );

    dispatch({ type: MENU_ITEM_CREATE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Menü item létrehozva"));
    return json;
  } catch (error) {
    dispatch({ type: MENU_ITEM_CREATE_FAILURE, payload: error });
    dispatch(addToastDanger(`Menü item létrehozása sikertelen: ${error}`));
  }
};

export const getOneItem = (id) => async (dispatch) => {
  try {
    const json = await http.get(
      API_BASE_IKK_ADMIN + API_ENDPOINT.MENU + "/item/" + id
    );
    return json;
  } catch (error) {
    dispatch(addToastDanger(`Menü item betöltése sikertelen: ${error}`));
  }
};

export const deleteItem = (id) => async (dispatch) => {
  dispatch({ type: MENU_ITEM_DELETE_BEGIN });
  try {
    const json = await http.del(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.MENU}/item/${id}`
    );
    dispatch({ type: MENU_ITEM_DELETE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Menü item törölve"));
    return json;
  } catch (error) {
    dispatch({ type: MENU_ITEM_DELETE_FAILURE, payload: error });
    dispatch(addToastDanger(`Menü item törlése sikertelen: ${error}`));
  }
};

export const updateItem = (id, data) => async (dispatch) => {
  dispatch({ type: MENU_ITEM_UPDATE_BEGIN });
  try {
    const json = await http.put(
      API_BASE_IKK_ADMIN + API_ENDPOINT.MENU + "/item/" + id,
      data
    );
    dispatch({ type: MENU_ITEM_UPDATE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Menü item módosítása sikeres"));
    return json;
  } catch (error) {
    dispatch({ type: MENU_ITEM_CREATE_FAILURE, payload: error });
    dispatch(addToastDanger(`Menü item módosítása sikertelen: ${error}`));
  }
};
