import {
  EXAM_CENTER_GET_BEGIN,
  EXAM_CENTER_GET_SUCCESS,
  EXAM_CENTER_GET_FAILURE,
  EXAM_CENTER_UPDATE_BEGIN,
  EXAM_CENTER_UPDATE_SUCCESS,
  EXAM_CENTER_UPDATE_FAILURE,
  EXAM_CENTER_CREATE_BEGIN,
  EXAM_CENTER_CREATE_SUCCESS,
  EXAM_CENTER_CREATE_FAILURE,
  EXAM_CENTER_DELETE_BEGIN,
  EXAM_CENTER_DELETE_SUCCESS,
  EXAM_CENTER_DELETE_FAILURE,
} from "../../actionTypes";
import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getExamCenters = () => async (dispatch) => {
  dispatch({ type: EXAM_CENTER_GET_BEGIN });
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.EXAM_CENTER);
    dispatch({ type: EXAM_CENTER_GET_SUCCESS, payload: json });
    return json;
  } catch (error) {
    dispatch({ type: EXAM_CENTER_GET_FAILURE, payload: error });
    dispatch(
      addToastDanger("Akkreditált vizsgaközpontok betöltése sikertelen")
    );
  }
};

export const getOneExamCenter = (id) => async (dispatch) => {
  try {
    const json = await http.get(
      API_BASE_IKK_ADMIN + API_ENDPOINT.EXAM_CENTER + "/" + id
    );
    return json;
  } catch (error) {
    dispatch({ type: EXAM_CENTER_GET_FAILURE, payload: error });
    dispatch(
      addToastDanger(`Akkreditált vizsgaközpont betöltése sikertelen: ${error}`)
    );
  }
};

export const updateExamCenter = (id, data) => async (dispatch) => {
  dispatch({ type: EXAM_CENTER_UPDATE_BEGIN });
  try {
    const json = await http.put(
      API_BASE_IKK_ADMIN + API_ENDPOINT.EXAM_CENTER + "/" + id,
      data
    );
    dispatch({ type: EXAM_CENTER_UPDATE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Akkreditált vizsgaközpont módosítása sikeres"));
    return json;
  } catch (error) {
    dispatch({ type: EXAM_CENTER_UPDATE_FAILURE, payload: error });
    dispatch(
      addToastDanger(
        `Akkreditált vizsgaközpont módosítása sikertelen: ${error}`
      )
    );
  }
};

export const createExamCenter = (data) => async (dispatch) => {
  dispatch({ type: EXAM_CENTER_CREATE_BEGIN });
  try {
    const json = await http.post(
      API_BASE_IKK_ADMIN + API_ENDPOINT.EXAM_CENTER,
      data
    );
    dispatch({ type: EXAM_CENTER_CREATE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Akkreditált vizsgaközpont létrehozva"));
    return json;
  } catch (error) {
    dispatch({ type: EXAM_CENTER_CREATE_FAILURE, payload: error });
    dispatch(
      addToastDanger(
        `Akkreditált vizsgaközpont létrehozása sikertelen: ${error}`
      )
    );
  }
};

export const deleteExamCenter = (id) => async (dispatch) => {
  dispatch({ type: EXAM_CENTER_DELETE_BEGIN });
  try {
    const json = await http.del(
      API_BASE_IKK_ADMIN + API_ENDPOINT.EXAM_CENTER + "/" + id
    );
    dispatch({ type: EXAM_CENTER_DELETE_SUCCESS, payload: json });
    dispatch(addToastSuccess("Akkreditált vizsgaközpont törölve"));
    return json;
  } catch (error) {
    dispatch({ type: EXAM_CENTER_DELETE_FAILURE, payload: error });
    dispatch(
      addToastDanger(`Akkreditált vizsgaközpont törlése sikertelen: ${error}`)
    );
  }
};
