import { Checkbox } from "@blueprintjs/core";
import { useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";

export const Divider = ({ initialState, onComponentSave }) => {
  const { state, isDirty, handleSimpleInput } = useInputState({ ...initialState });

  return (
    <>
      <Checkbox
        name="isDashed"
        label="Szaggatott vonal"
        checked={state.isDashed}
        onChange={handleSimpleInput}
      />
      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
