import {
  PROJECT_GET_BEGIN,
  PROJECT_GET_FAILURE,
  PROJECT_GET_SUCCESS,
} from "../../actionTypes";
import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getProjects = () => async (dispatch) => {
  dispatch(getProjectsBegin());
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.PROJECT);
    dispatch(getProjectsSuccess(json));
    return json;
  } catch (error) {
    dispatch(getProjectsFail(error));
    dispatch(addToastDanger(`Projektek betoltese sikertelen: ${error}`));
  }
};

export const deleteProject = (id) => async (dispatch) => {
  // dispatch(deleteProjectsBegin());
  try {
    const json = await http.del(`${API_BASE_IKK_ADMIN + API_ENDPOINT.PROJECT}/${id}`);
    // dispatch(getProjectsSuccess(json));
    dispatch(addToastSuccess("Projekt torolve"));
    return json;
  } catch (error) {
    // dispatch(getProjectsFail(error));
    dispatch(addToastDanger(`Projekt torlese sikertelen: ${error}`));
  }
};

export const createProject = (data) => async (dispatch) => {
  // dispatch(deleteProjectsBegin());
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.PROJECT, data);
    // dispatch(getProjectsSuccess(json));
    dispatch(addToastSuccess("Projekt letrehozva"));
    return json;
  } catch (error) {
    // dispatch(getProjectsFail(error));
    dispatch(addToastDanger(`Projekt letrehozasa sikertelen: ${error}`));
  }
};

export const updateProject = (id, data) => async (dispatch) => {
  // dispatch(deleteProjectsBegin());
  try {
    const json = await http.put(
      `${API_BASE_IKK_ADMIN + API_ENDPOINT.PROJECT}/${id}`,
      data
    );
    // dispatch(getProjectsSuccess(json));
    dispatch(addToastSuccess("Projekt modositva"));
    return json;
  } catch (error) {
    // dispatch(getProjectsFail(error));
    dispatch(addToastDanger(`Projekt modositasa sikertelen: ${error}`));
  }
};

export const getProjectsBegin = () => ({
  type: PROJECT_GET_BEGIN,
});
export const getProjectsSuccess = (items) => ({
  type: PROJECT_GET_SUCCESS,
  payload: items,
});
export const getProjectsFail = (err) => ({
  type: PROJECT_GET_FAILURE,
  payload: { err },
});
