import { Button, FormGroup, InputGroup, HTMLSelect } from "@blueprintjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
// import style from "./Slides.module.scss";
import MyCkEditor from "../../common/MyCkEditor";

const projectCategories = [
  "Széchenyi 2020",
  "Helyreállítási és Ellenállóképességi Eszköz (RRF)",
  "2021-27 Széchenyi Terv Plusz",
];

const colorOptions = [
  { label: "Kék", value: "BLUE" },
  { label: "Sárga", value: "YELLOW" },
  { label: "Zöld", value: "GREEN" },
  { label: "Piros", value: "RED" },
];

const ProjectForm = (props) => {
  const [state, setState] = useState(props.initialState);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(state, setState);
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: "800px" }}>
      <h2>{props.title}</h2>

      <FormGroup label="Cim" labelInfo="(kötelező)">
        <InputGroup
          required
          placeholder="Cim..."
          name="title"
          type="text"
          value={state.title}
          onChange={handleInput}
        />
      </FormGroup>

      <FormGroup label="Szoveg" labelInfo="(kötelező)">
        <MyCkEditor
          data={state.body}
          onDataChange={(data) => setState((s) => ({ ...s, body: data }))}
        />
      </FormGroup>
      <FormGroup label="Kategória" labelInfo="(kötelező)">
        <HTMLSelect
          required
          name="category"
          value={state.category}
          onChange={handleInput}
          style={{ maxWidth: "300px" }}
        >
          <option value="">Válasszon...</option>
          {projectCategories.map((c, i) => (
            <option key={i}>{c}</option>
          ))}
        </HTMLSelect>
      </FormGroup>

      <FormGroup label="Szín" labelInfo="(kötelező)">
        <HTMLSelect
          required
          name="color"
          value={state.color}
          onChange={handleInput}
          style={{ maxWidth: "300px" }}
        >
          <option value="">Válasszon...</option>
          {colorOptions.map((c, i) => (
            <option key={i} value={c.value}>
              {c.label}
            </option>
          ))}
        </HTMLSelect>
      </FormGroup>

      <FormGroup label="Sorrend" labelInfo="(kötelező)">
        <InputGroup
          required
          placeholder="Sorrend"
          name="order"
          type="number"
          value={state.order}
          onChange={handleInput}
        />
      </FormGroup>
      <br />
      <Button
        text={props.submitBtnText}
        icon="floppy-disk"
        intent="success"
        type="submit"
      />
    </form>
  );
};

export default connect(null, {})(ProjectForm);
