import {
  Button,
  Card,
  Classes,
  H5,
  H1,
  H2,
  H4,
  Colors,
  HTMLTable,
} from "@blueprintjs/core";
import React from "react";
import { getUser } from "../../utils/auth";
import {
  EXAM_CENTER_URL,
  IKK_URL,
  SKILLSCOM_URL,
  SZAKKEPESITES_URL,
  SZAKKEPZES_URL,
} from "../../utils/routes";

const Home = () => {
  return (
    <>
      <div className="container">
        <H1 style={{ marginTop: 100 }}>Üdvözlünk! </H1>
        <H2 style={{ color: Colors.BLUE5 }}>{getUser()?.email}</H2>
        <H4 style={{ marginTop: 30 }}>Hasznos linkek</H4>

        <HTMLTable>
          <tbody>
            <tr>
              <td>IKK főoldal</td>
              <td>
                <a href={IKK_URL} target="_blank" rel="noopener noreferrer">
                  {IKK_URL}
                </a>
              </td>
            </tr>
            <tr>
              <td>Szakképzés </td>
              <td>
                <a href={SZAKKEPZES_URL} target="_blank" rel="noopener noreferrer">
                  {SZAKKEPZES_URL}
                </a>
              </td>
            </tr>
            <tr>
              <td>Szakképesítés </td>
              <td>
                <a href={SZAKKEPESITES_URL} target="_blank" rel="noopener noreferrer">
                  {SZAKKEPESITES_URL}
                </a>
              </td>
            </tr>
            <tr>
              <td>Vizsgaközpont </td>
              <td>
                <a href={EXAM_CENTER_URL} target="_blank" rel="noopener noreferrer">
                  {EXAM_CENTER_URL}
                </a>
              </td>
            </tr>
            <tr>
              <td>SkillsCom </td>
              <td>
                <a href={SKILLSCOM_URL} target="_blank" rel="noopener noreferrer">
                  {SKILLSCOM_URL}
                </a>
              </td>
            </tr>
          </tbody>
        </HTMLTable>
      </div>
    </>
  );
};

export default Home;
