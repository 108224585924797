import { Button, TagInput } from "@blueprintjs/core";

export const ControlledTagInput = ({ values, onChange, ...rest }) => {
  const handleClear = () => onChange([]);

  const ClearButton =
    values?.length > 0 ? (
      <Button icon="cross" minimal={true} onClick={handleClear} />
    ) : undefined;

  return (
    <TagInput
      addOnBlur
      onChange={onChange}
      rightElement={ClearButton}
      tagProps={{ minimal: true }}
      values={values}
      {...rest}
    />
  );
};
