import { Button, FormGroup, HTMLSelect, InputGroup } from "@blueprintjs/core";
import { Details } from "../../../common/Details";
import { Grid } from "../../../common/LayoutHelpers";
import MyCkEditor from "../../../common/MyCkEditor";
import { DeleteButton } from "../DeleteButton";
import { colors, useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";

const FancyAccordionItemFields = ({ item, onItemChange }) => {
  return (
    <>
      <FormGroup label="Cím" labelInfo="(kötelező)">
        <InputGroup
          required
          value={item.title}
          onChange={(e) => onItemChange("title", e.target.value)}
        />
      </FormGroup>
      <FormGroup label="Tartalom" labelInfo="(kötelező)">
        <MyCkEditor
          data={item.body?.html}
          onDataChange={(data) => onItemChange("body", { html: data })}
        />
      </FormGroup>
      <Grid columns={2}>
        <FormGroup label="Gomb felirat">
          <InputGroup
            value={item.buttonTitle}
            onChange={(e) => onItemChange("buttonTitle", e.target.value)}
          />
        </FormGroup>
        <FormGroup label="Szín">
          <HTMLSelect
            value={item.color}
            onChange={(e) => onItemChange("color", e.target.value)}
          >
            <option value="" disabled>
              Válasszon...
            </option>
            {colors.map((c, idx) => (
              <option key={idx} value={c}>
                {c}
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>
      </Grid>
    </>
  );
};

export const FancyAccordion = ({ initialState, onComponentSave }) => {
  const { state, isDirty, setState } = useInputState(initialState);

  const onAddNewItem = () => {
    setState((s) => ({
      ...s,
      items: [...s.items, { title: "Title", categoryName: "" }],
    }));
  };

  const onRemoveItem = (index) => {
    const temp = structuredClone(state.items);
    temp.splice(index, 1);
    setState((s) => ({ ...s, items: temp }));
  };

  const onItemChange = (index) => (key, value) => {
    const temp = structuredClone(state);
    temp.items[index][key] = value;
    setState(temp);
  };

  return (
    <>
      <FormGroup label="Itemek">
        {state.items.map((item, i) => (
          <Details key={i} summary={`${i + 1}. ${item.title}`}>
            <FancyAccordionItemFields item={item} onItemChange={onItemChange(i)} />
            <DeleteButton onClick={() => onRemoveItem(i)}>Item törlése</DeleteButton>
          </Details>
        ))}

        <Button minimal icon="plus" intent="primary" onClick={onAddNewItem}>
          Új item
        </Button>
      </FormGroup>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
