import {
  Button,
  Card,
  Checkbox,
  FileInput,
  FormGroup,
  HTMLSelect,
  Icon,
  InputGroup,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { uploadMedia } from "../../../redux/actions/common/media";
import {
  createCategory,
  createItem,
  getCategory,
  getItems,
} from "../../../redux/actions/ikk/publicInfo";

const initialState = {
  new_cat_name: "",

  new_sub_cat_name: "",
  new_sub_cat_parent: "", //id

  new_item_file: null,
  new_item_name: "",
  new_item_url: null,
  new_item_category: "", //id
  new_item_visible: false,

  modify_cat: null,
  modify_cat_name: "",
  modify_cat_visible: null,
  modify_cat_button_disabled: false,
  modify_cat_deep_link: null,

  modify_item: null,
  modify_item_name: "",
  modify_item_visible: null,
  modify_item_file: null,
  modify_item_url: null,
  modify_item_file_val: "",
  modify_item_button_disabled: false,
  popup: null,
};

const PublicInfoCreate = (props) => {
  // console.log("props:", props);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    props.getCategory();
  }, []);

  const handleInput = (e) => {
    const { name, value, checked } = e.target;
    console.log("handleInput", name, value, checked);
    setState((s) => ({ ...s, [name]: value }));
  };

  const createNewItem = async (e) => {
    e.preventDefault();
    let res;
    if (state.new_item_file) {
      res = await props.uploadMedia(state.new_item_file);
    }

    await props.createItem(
      state.new_item_name,
      res?.id || null,
      state.new_item_category,
      state.new_item_visible,
      state.new_item_url
    );
    setState(initialState);
    props.getItems();
  };

  // save new main category
  const handleNewMainCatSubmit = async (e) => {
    e.preventDefault();
    await props.createCategory(state.new_cat_name, "PUBLIC_DATA");
    // todo reset form
    props.getCategory();
  };

  // save new sub-category
  const handleNewSubCatSubmit = async (e) => {
    e.preventDefault();
    await props.createCategory(
      state.new_sub_cat_name,
      "PUBLIC_DATA",
      state.new_sub_cat_parent
    );
    // TODO reset form
    props.getCategory();
  };

  const handleFileInput = (e) => {
    const { name, value, files } = e.target;
    console.log("handleFileInput", name, value, files);
    const file = files[0];
    setState((s) => ({ ...s, [name]: file }));
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setState((s) => ({ ...s, [name]: checked }));
  };

  return (
    <>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="document" />
        &nbsp;&nbsp;<span>Új közérdekű adat </span>
      </h2>

      <div
        style={{
          display: "grid",
          gridGap: "20px",
          gridTemplateColumns: "repeat(auto-fill, minmax(400px,1fr))",
        }}
      >
        <Card>
          <form onSubmit={handleNewMainCatSubmit}>
            <h3 style={{ marginTop: 0 }}>Új főkategória</h3>
            <FormGroup label="Név">
              <InputGroup required name="new_cat_name" onChange={handleInput} />
            </FormGroup>
            <Button
              type="submit"
              icon="floppy-disk"
              intent="primary"
              text="Mentés"
            />
          </form>
        </Card>

        <Card>
          <form onSubmit={handleNewSubCatSubmit}>
            <h3 style={{ marginTop: 0 }}>Új alkategória</h3>
            <FormGroup label="Szülő kategória">
              <HTMLSelect
                required
                name="new_sub_cat_parent"
                value={state.new_sub_cat_parent}
                onChange={handleInput}
                style={{ maxWidth: "300px" }}
              >
                <option value="" disabled>
                  Válasszon
                </option>
                {props.flat_categories.map((c) => (
                  <option key={c.id} value={c.id}>
                    {" ⟶ ".repeat(c.depth) + c.name}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>

            <FormGroup label="Alkategória neve">
              <InputGroup
                required
                name="new_sub_cat_name"
                value={state.new_sub_cat_name}
                onChange={handleInput}
              />
            </FormGroup>
            <Button
              type="submit"
              icon="floppy-disk"
              intent="primary"
              text="Mentés"
            />
          </form>
        </Card>

        <Card>
          <form onSubmit={createNewItem}>
            <h3 style={{ marginTop: 0 }}>Új dokumentum</h3>
            <FormGroup label="Kategória">
              <HTMLSelect
                required
                name="new_item_category"
                value={state.new_item_category}
                onChange={handleInput}
                style={{ maxWidth: "300px" }}
              >
                <option value="" disabled>
                  Válasszon
                </option>
                {props.flat_categories.map((c) => (
                  <option key={c.id} value={c.id}>
                    {" ⟶ ".repeat(c.depth) + c.name}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>

            <FormGroup label="Dokumentum címe">
              <InputGroup
                required
                name="new_item_name"
                value={state.new_item_name}
                onChange={handleInput}
              />
            </FormGroup>
            <FormGroup label="Link url (opcionális)">
              <InputGroup
                name="new_item_url"
                value={state?.new_item_url}
                onChange={handleInput}
              />
            </FormGroup>
            <FormGroup>
              <FileInput
                buttonText="Tallóz"
                text={state.new_item_file?.name || "Fájl választása"}
                onInputChange={handleFileInput}
                inputProps={{ required: false, name: "new_item_file" }}
              />
            </FormGroup>

            <FormGroup>
              <Checkbox
                label="Látható"
                onChange={handleCheckbox}
                name="new_item_visible"
                checked={state.new_item_visible}
              />
            </FormGroup>

            <Button
              type="submit"
              icon="floppy-disk"
              intent="primary"
              text="Mentés"
            />
          </form>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const pi = state.publicInfo;
  return {
    ...pi,
    categories: pi.categories.filter((c) => c.type === "PUBLIC_DATA"),
    flat_categories: pi.flat_categories.filter((c) => c.type === "PUBLIC_DATA"),
  };
};

export default connect(mapStateToProps, {
  getItems,
  getCategory,
  createCategory,
  createItem,
  uploadMedia,
})(PublicInfoCreate);
