import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getOnePage, updatePage } from "../../../redux/actions/ikk/pages";
import { ROUTES } from "../../../utils/routes";
import { generatePath } from "react-router-dom";
import PagesForm from "./PagesForm";

const PagesEdit = (props) => {
  const { id } = useParams();
  const [page, setPage] = useState();

  useEffect(() => {
    async function getData() {
      const res = await props.getOnePage(id);
      setPage({ ...res });
    }
    if (id) getData(id);
  }, [id]);

  const handleEditSubmit = async (formstate, setFormstate) => {
    const body = { ...formstate };
    const res = await props.updatePage(id, body);
    console.log(res);
    if (res)
      props.history.push(
        generatePath(ROUTES.pages, {
          id: formstate.id,
        })
      );
  };

  if (!page) return <Spinner />;

  return (
    <PagesForm
      initialState={page}
      submitBtnText="Módosít"
      title="Oldal szerkesztése"
      handleSubmit={handleEditSubmit}
    />
  );
};

export default connect(null, { updatePage, getOnePage })(PagesEdit);
