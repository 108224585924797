import { FormGroup } from "@blueprintjs/core";
import MyCkEditor from "../../../common/MyCkEditor";
import { useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";

export const RichText = ({ initialState, onComponentSave }) => {
  const { state, isDirty, handleSimpleInput } = useInputState(initialState);

  return (
    <>
      <FormGroup label="Szöveg">
        <MyCkEditor
          data={state.html}
          onDataChange={(data) =>
            handleSimpleInput({ target: { name: "html", value: data } })
          }
        />
      </FormGroup>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
