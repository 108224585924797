import { Button } from "@blueprintjs/core";
import React from "react";

export const ToggleVisibilityButton = () => {
  const toggle = () => {
    const input = document.querySelector('[name="password"]');
    if (input.type === "password") input.type = "text";
    else input.type = "password";
  };

  return <Button icon="eye-open" onClick={toggle} />;
};
