import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import http from "../../../utils/http";
import {
  API_BASE_EXAM_CENTER,
  API_BASE_SZAKKEPESITES,
  ROUTES,
} from "../../../utils/routes";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";

const ExamsAll = () => {
  const [exams, setExams] = useState([]);
  const [rowToDelete, setRowToDelete] = useState();
  const [keorData, setKeorData] = useState();

  const qualifs = keorData
    ?.flatMap((k) => k.directions)
    .flatMap((d) => d.specializations)
    .flatMap((s) => s.qualifications);

  useEffect(() => {
    getExams();
    getKeorData();
  }, []);

  const handleDelete = async () => {
    const json = await http.del(`${API_BASE_EXAM_CENTER}/exams/${rowToDelete.id}`);
    setRowToDelete(null);
    getExams();
  };

  const getKeorData = async () => {
    const res = await fetch(`${API_BASE_SZAKKEPESITES}/keor`);
    const data = await res.json();
    setKeorData(data);
  };

  const getExams = async () => {
    const json = await http.get(`${API_BASE_EXAM_CENTER}/exams`);
    setExams(json);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Név",
        accessor: "name",
      },
      {
        Header: "Szakképesítés",
        accessor: (data) => qualifs?.find((q) => q.id === data.qualificationId)?.name,
        Cell: ({ value }) => value || "-",
      },
      {
        Header: "Vizsgadíj",
        accessor: "price",
      },
      {
        Header: "Nyelv",
        accessor: "language",
      },
      {
        Header: "Látható",
        accessor: "visible",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Aktív",
        accessor: "enabled",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Online",
        accessor: "online",
        Cell: ({ value }) => (value ? "Igen" : "Nem"),
      },
      {
        Header: "Művelet",
        Cell: ({ row }) => (
          <TableActionButtons
            row={row}
            onDelBtnClick={() => setRowToDelete(row.original)}
            editHref={generatePath(ROUTES.examEdit, {
              id: row.original.id,
            })}
          />
        ),
      },
    ],
    [keorData]
  );

  if (!keorData) return <Spinner />;

  return (
    <>
      <DeleteConfirmation
        isOpen={!!rowToDelete}
        recordName={rowToDelete?.name}
        onDelete={handleDelete}
        onCancel={() => setRowToDelete(null)}
      />

      <h2>Összes vizsga</h2>
      <TableAdvanced columns={columns} data={exams} />
    </>
  );
};

export default ExamsAll;
