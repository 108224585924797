import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  FormGroup,
  Position,
  Spinner,
  InputGroup,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";
import MomentLocaleUtils from "react-day-picker/moment";
import { connect } from "react-redux";
import { deleteKKK } from "../../../redux/actions/szakkepzes/kkk";
import { getAllKKK6110, editKKK6110 } from "../../../redux/6110/kkk";
import { DeleteConfirmation } from "../../common/DeleteConfirmation";
import TableActionButtons from "../../common/TableActionButtons";
import { TableAdvanced } from "../../common/TableAdvanced";
import http from "../../../utils/http";
import { API_BASE_SZAKKEPZES_6110 } from "../../../utils/routes";

const initialstate = {
  qualification_id: "",
  name: "",
  fileName: "",
  media_id: "",
  attachment_id: "",
  publishDate: null,
  archiveDate: null,
};

const KKK_PTT_6110 = (props) => {
  const [editKKK, setEditKKK] = useState(initialstate);
  const [deleteKKK, setDeleteKKK] = useState(initialstate);

  useEffect(() => {
    props.getAllKKK6110();
  }, []);

  // opens edit dialog
  const handleEdit = (row, attachment) => {
    setEditKKK((kkk) => ({
      ...kkk,
      qualification_id: row.id,
      media_id: attachment.media_id,
      attachment_id: attachment.id,
      publishDate: attachment.publishDate,
      archiveDate: attachment.archiveDate,
      version: attachment.version,
      fileName: attachment.fileName,
      name: attachment.name,
    }));
  };

  // opens delete dialog
  const handleDelete = (row, attachment) => {
    setDeleteKKK((kkk) => ({
      ...kkk,
      qualification_name: row.name,
      qualification_id: row.id,
      name: attachment.name,
      media_id: attachment.media_id,
      attachment_id: attachment.id,
    }));
  };

  const handleDialogClose = () => {
    setEditKKK(initialstate);
  };

  const handleUpload = (media) => {
    setEditKKK((kkk) => ({ ...kkk, media_id: media.id }));
  };

  const handleEditSubmit = async (e) => {
    //todo
    e.preventDefault();
    const res = await props.editKKK6110(editKKK.attachment_id, {
      qualification_id: editKKK.qualification_id,
      name: editKKK.name,
      fileName: editKKK.fileName,
      media_id: editKKK.media_id,
      publishDate: editKKK.publishDate,
      archiveDate: editKKK.archiveDate,
    });
    setEditKKK(initialstate);
    res && props.getAllKKK6110();
  };

  const handleDeleteSubmit = async (e) => {
    //todo
    e.preventDefault();
    const res = await http.del(
      `${API_BASE_SZAKKEPZES_6110}/v2/profession/attachments/${deleteKKK.attachment_id}`
    );
    setDeleteKKK(initialstate);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditKKK((kkk) => ({ ...kkk, [name]: value }));
  };
  const handleInputDate = (name) => (date) => {
    setEditKKK((s) => ({ ...s, [name]: date ? date.toISOString() : null }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Szakma",
        accessor: "name",
      },
      {
        Header: "Ágazat",
        accessor: "sector.name",
      },
      {
        Header: "Fájlok",
        accessor: "attachments",
        Cell: ({ row, value }) =>
          value.map((attachment, i) => (
            <div key={i}>
              <AnchorButton
                icon="import"
                small
                minimal
                text={`${attachment.name} ${attachment.publishDate} - ${attachment.archiveDate} v${attachment.version}`}
                href={attachment.media}
                target="_blank"
              />
              <TableActionButtons
                row={row}
                onEditBtnClick={() => handleEdit(row.original, attachment)}
                onDelBtnClick={() => handleDelete(row.original, attachment)}
              />
            </div>
          )),
      },
    ],
    []
  );

  if (props.loading) return <Spinner intent="primary" />;
  return (
    <>
      <h2>KKK és PTT dokumentumok</h2>

      <TableAdvanced data={props.items} columns={columns} />

      <DeleteConfirmation
        isOpen={deleteKKK.attachment_id}
        onCancel={() => setDeleteKKK(initialstate)}
        onDelete={handleDeleteSubmit}
        recordName={`${deleteKKK.fileName}`}
      />

      <Dialog
        title="Dokumentum szerkesztése"
        isOpen={editKKK.attachment_id}
        onClose={handleDialogClose}
        usePortal={false}
      >
        <form onSubmit={handleEditSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Fájl címe" labelInfo="(kötelező)">
              <InputGroup
                isRequired
                name="fileName"
                placeholder="Fájl címe"
                value={editKKK.fileName}
                onChange={handleInput}
              />
            </FormGroup>

            <FormGroup label="Verzió" labelInfo="(kötelező)">
              <InputGroup
                type="text"
                name="version"
                value={editKKK.version}
                onChange={handleInput}
              />
            </FormGroup>
            <FormGroup label="Publikálva ekkor">
              <InputGroup
                type="date"
                name="publishDate"
                value={editKKK.publishDate}
                onChange={handleInput}
              />
            </FormGroup>
            <FormGroup
              label="Archiválva ekkor"
              labelInfo="(Csak ha archiválni szeretnénk!)"
            >
              <InputGroup
                type="date"
                name="archiveDate"
                value={editKKK.archiveDate}
                onChange={handleInput}
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="Mégsem" onClick={handleDialogClose} />
              <Button
                intent="success"
                type="submit"
                text="Mentés"
                icon="floppy-disk"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.project6110.kkk };
};
export default connect(mapStateToProps, {
  getAllKKK6110,
  editKKK6110,
  deleteKKK,
})(KKK_PTT_6110);
