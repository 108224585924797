import {
  NEWS_ADD_BEGIN,
  NEWS_ADD_FAILURE,
  NEWS_ADD_SUCCESS,
  NEWS_DELETE_SUCCESS,
  NEWS_GET_BEGIN,
  NEWS_GET_FAILURE,
  NEWS_GET_ONE_SUCCESS,
  NEWS_GET_SUCCESS,
} from "../actionTypes";

const initialState = {
  news: [],
  article: null,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NEWS_ADD_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case NEWS_ADD_SUCCESS: {
      const news = state.news;
      //news.push(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
        news: news,
      };
    }
    case NEWS_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case NEWS_GET_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case NEWS_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case NEWS_GET_SUCCESS: {
      let news = state.news;

      if (!Array.isArray(news)) {
        news = [];
      }
      news = news.concat(action.payload);
      news = news.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj["id"]).indexOf(obj["id"]) === pos;
      });

      return {
        ...state,
        loading: false,
        error: null,
        news: news, //news,
      };
    }

    case NEWS_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        news: state.news.filter((n) => n.id !== action.payload.id),
      };
    }

    // SINGLE

    case NEWS_GET_ONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        article: action.payload,
      };
    }

    default:
      return state;
  }
}
