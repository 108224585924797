import React from "react";
import { connect } from "react-redux";
import { createItem } from "../../../redux/actions/ikk/menu";
import { ROUTES } from "../../../utils/routes";
import MenuItemForm from "./MenuItemForm";
import { generatePath } from "react-router-dom";

const emptyState = {
  name: "",
  body: "",
  link: "",
  isExternal: false,
  order: null,
  category_id: "",
  cover_id: null,
  slug: "",
};

const MenuItemCreate = (props) => {
  // handle create button press on form
  const handleCreateSubmit = async (state, setState) => {
    const res = await props.createItem(state);
    // reset the form if submit was successful
    if (res && res.id) {
      setState(emptyState);
      props.history.push(generatePath(ROUTES.menuItem, {
        id: state.category_id,
      }));
    }
  };

  return (
    <MenuItemForm
      title="Új menü item"
      initialState={emptyState}
      handleSubmit={handleCreateSubmit}
      submitBtnText="Létrehoz"
    />
  );
};

export default connect(null, { createItem })(MenuItemCreate);
