import {
  SZAKKEPZES_GET_KKK_6110_BEGIN,
  SZAKKEPZES_GET_KKK_6110_FAILURE,
  SZAKKEPZES_GET_KKK_6110_SUCCESS,
  SZAKKEPZES_GET_SINGLE_6110_KKK,
} from "../../actionTypes";

const initialState = {
  items: [],
  loading: false,
  error: null,
  singleItem: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SZAKKEPZES_GET_KKK_6110_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SZAKKEPZES_GET_KKK_6110_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload,
      };
    }
    case SZAKKEPZES_GET_KKK_6110_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        items: [],
      };
    }
    case SZAKKEPZES_GET_SINGLE_6110_KKK: {
      return {
        ...state,
        loading: false,
        error: false,
        singleItem: state.items.find((i) => i.id === action.payload),
      };
    }
    default:
      return state;
  }
}
