import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { authFetch } from "../../utils/http";
import { API_BASE_SKILLSCOM } from "../../utils/routes";

const Stats = () => {
  const [data, setData] = useState();

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    const data = await authFetch(`${API_BASE_SKILLSCOM}/admin/statistics`);
    if (data.success) setData(data.data);
  };

  if (!data) return <Spinner />;

  return (
    <>
      <h1>Statisztika</h1>

      <table className="bp3-html-table bp3-html-table-striped">
        <thead>
          <tr>
            <th>Metrika</th>
            <th>Érték</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Összes felhasználó szám</td>
            <td>{data.userCount}</td>
          </tr>
          {data.usersCountByType.map((d) => (
            <tr>
              <td>Felhasználó szám: {d.type}</td>
              <td>{d.count}</td>
            </tr>
          ))}
          <tr>
            <td>Összes poszt szám</td>
            <td>{data.postCount}</td>
          </tr>
          <tr>
            <td>Összes hír szám</td> <td>{data.newsCount}</td>
          </tr>
          <tr>
            <td>Összes esemény szám</td>
            <td> {data.eventCount}</td>
          </tr>
          <tr>
            <td>Összes like szám</td> <td>{data.likeCount}</td>
          </tr>
          <tr>
            <td>Összes követések száma</td> <td>{data.followCount}</td>
          </tr>
          <tr>
            <td>Összes esemeny "ott leszek" száma</td> <td>{data.goingEventCount}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Stats;
