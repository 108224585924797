import { Card, H4 } from "@blueprintjs/core";
import React from "react";
import { LayoutDefault } from "../LayoutDefault";
import MenuMedia from "./MenuMedia";

const styleWrap = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "auto 1fr",
  marginTop: "20px",
};

export const LayoutMedia = ({ children }) => {
  return (
    <LayoutDefault>
      <div style={{ ...styleWrap }}>
        <div>
          <MenuMedia />
        </div>

        <Card
          elevation={2}
          style={{ height: "calc(100vh - 90px)", overflow: "auto" }}
        >
          <H4>
            <a href="#">Médiatár</a>
          </H4>
          <div>{children}</div>
        </Card>
      </div>
    </LayoutDefault>
  );
};
