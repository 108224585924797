import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getDocument, updateDocument } from "../../../redux/actions/ikk/documents";
import { ROUTES } from "../../../utils/routes";
import DocsForm from "./DocsForm";

const DocsEdit = (props) => {
  const [doc, setDoc] = useState();

  const { id } = useParams();

  useEffect(() => {
    fetchDoc();
  }, []);

  async function fetchDoc() {
    const alldoc = await props.getDocument();
    const d = alldoc?.find((d) => d.id === Number(id));
    setDoc(d);
  }

  const handleEditSubmit = async (state, setState) => {
    const res = await props.updateDocument(doc.id, state);
    if (res) props.history.push(ROUTES.documents);
  };

  return (
    <>
      {doc && (
        <DocsForm
          initialState={doc}
          submitBtnText="Módosít"
          title="Dokumentum módosítása"
          handleSubmit={handleEditSubmit}
        />
      )}
    </>
  );
};

export default connect(null, { getDocument, updateDocument })(DocsEdit);
