import {
  Classes,
  Divider,
  Menu,
  MenuDivider,
  MenuItem,
} from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";

const MenuIkk = () => {
  return (
    <Menu className={Classes.ELEVATION_2}>
      <MenuDivider title="Intézmények" />
      <MenuItem
        icon="th"
        text="Feltöltés"
        tagName={Link}
        to={ROUTES.szakkepzesImport}
      />
      <MenuItem
        icon="import"
        text="Exportálás"
        tagName={Link}
        to={ROUTES.szakkepzesExport}
      />
      <MenuItem
        icon="notifications"
        text="Értesítések"
        tagName={Link}
        to={ROUTES.szakkepzesNotifications}
      />
      <MenuDivider />

      <MenuItem icon="help" text="GYIK">
        <MenuItem
          tagName={Link}
          to={ROUTES.faqCreate}
          icon="plus"
          text="Új gyakori kérdés"
        />
        <MenuItem
          tagName={Link}
          to={ROUTES.faq}
          icon="edit"
          text="Összes gyakori kérdés"
        />
        <Divider />
        <MenuItem
          tagName={Link}
          to={ROUTES.faqCategoryCreate}
          icon="plus"
          text="Új témakör"
        />
        <MenuItem
          tagName={Link}
          to={ROUTES.faqCategory}
          icon="edit"
          text="Összes témakör"
        />
      </MenuItem>

      <MenuItem
        tagName={Link}
        to={ROUTES.location}
        icon="map-marker"
        text="Koordináták"
      />

      <MenuItem icon="document" text="KKK/PTT">
        <MenuItem
          icon="plus"
          text="Új KKK/PTT"
          tagName={Link}
          to={ROUTES.kkkCreate}
        />
        <MenuItem
          icon="edit"
          text="Összes KKK/PTT"
          tagName={Link}
          to={ROUTES.kkk}
        />
      </MenuItem>

      <MenuItem icon="heat-grid" text="Szakmakártyák">
        <MenuItem
          icon="edit"
          text="Ágazatok szerkesztese"
          tagName={Link}
          to={ROUTES.szakmakartyakSectors}
        />
        <MenuItem
          icon="edit"
          text="Szakmák szerkesztese"
          tagName={Link}
          to={ROUTES.szakmakartyakQualifications}
        />
      </MenuItem>
    </Menu>
  );
};

export default MenuIkk;
